<div class="container d-flex flex-column justify-content-center">
    <div class="d-flex justify-content-center mb-1 mat-status">
        <mat-icon [svgIcon]="icon" [color]="state == 'error' ? 'warn' : 'primary'"></mat-icon>
    </div>

    <div class="d-block text-center mb-2">
        <h2 class="mb-0">{{ title }}</h2>
    </div>

    <div class="d-block text-center mb-3">
        <p class="mb-0 mat-body-2">{{ description }}</p>
    </div>

    <div class="d-flex justify-content-center">
        <button mat-raised-button bn-large-button mat-dialog-close color="primary">{{ label }}</button>
    </div>
</div>
