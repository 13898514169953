import { Deserializable } from '../deserializable.model'

export class FlexObject implements Deserializable {
    _id: string
    name: string
    body: Object

    get hasTemplate() {
        return this.body ? true : false
    }

    deserialize(input: any) {
        Object.assign(this, input)
        return this
    }

    retreiveBody(json: Object) {
        this.body = json
    }

    onUpdateObject(data: FlexObject) {
        if(data._id) this._id = data._id
        if(data.name) this.name = data.name
        if(data.body) this.body = data.body
    }

    convertObjectToServer() {
        return {
          _id: this._id,
          type: 'flex',
            object_name: this.name.includes('FX_') ? this.name : `FX_${this.name}`,
            list_object: [this.body]
        }
    }
}
