import { Injectable } from "@angular/core";
import { Observable, forkJoin } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ApiList, ButtonList, RmList, CrsList, ImageList, QrpList, DlList, FlexList, EntityList, PayloadList, AudioList } from "@core/models/table";
import {
    ManageApiObjectService,
    ManageButtonObjectService,
    ManageCrsObjectService,
    ManageImageObjectService,
    ManageQrpObjectService,
    ManageRmObjectService,
    ManageDlObjectService,
    ManageFxObjectService,
    ManagePayloadObjectService,
    ManageAudioObjectService,
    ManagePersistentObjectService,
} from "./";
import { ManageEntitiesService } from "@core/http/entities/manage-entities.service";

export interface TemplateData {
    button: Array<ButtonList>;
    carousel: Array<CrsList>;
    quick_reply: Array<QrpList>;
    image: Array<ImageList>;
}

@Injectable()
export class ObjectService {
    $templateData: Observable<TemplateData>;
    $entityTemplateData: Observable<Array<EntityList>>;

    constructor(
        private _apiService: ManageApiObjectService,
        private _buttonService: ManageButtonObjectService,
        private _crsService: ManageCrsObjectService,
        private _imageSevice: ManageImageObjectService,
        private _qrpService: ManageQrpObjectService,
        private _rmService: ManageRmObjectService,
        private _dlService: ManageDlObjectService,
        private _fxService: ManageFxObjectService,
        private _enSerivice: ManageEntitiesService,
        private _payloadService: ManagePayloadObjectService,
        private _audioService: ManageAudioObjectService,
        private _persistentService: ManagePersistentObjectService
    ) {}

    $getTemplateData = (): Observable<TemplateData> => {
        if (!this.$templateData)
            this.$templateData = forkJoin({
                button: this.$getButtonObjectList(),
                carousel: this.$getCrsObjectList(),
                quick_reply: this.$getQrpObjectService(),
                image: this.$getImageObjectList(),
                audio: this.$getAudioService(),
            }).pipe(shareReplay(1));

        return this.$templateData;
    };

    $getEntityTemplateData = (): Observable<Array<EntityList>> => {
        if (!this.$entityTemplateData) this.$entityTemplateData = this.$getEntityService().pipe(shareReplay(1));
        return this.$entityTemplateData;
    };

    $getAPIObjectList = (): Observable<Array<ApiList>> => {
        return this._apiService.$getAPIObjectList().pipe(map((data) => data.map((d) => new ApiList().convertObjectToList(d))));
    };

    $getButtonObjectList = (): Observable<Array<ButtonList>> => {
        return this._buttonService.$getButtonObjectList().pipe(map((data) => data.map((d) => new ButtonList().convertObjectToList(d))));
    };

    $getCrsObjectList = (): Observable<Array<CrsList>> => {
        return this._crsService.$getCrsObjectList().pipe(map((data) => data.map((d) => new CrsList().convertObjectToList(d))));
    };

    $getImageObjectList = (): Observable<Array<ImageList>> => {
        return this._imageSevice.$getImageObjectList().pipe(map((data) => data.map((d) => new ImageList().convertObjectToList(d))));
    };

    $getQrpObjectService = (): Observable<Array<QrpList>> => {
        return this._qrpService.$getQrpObjectList().pipe(map((data) => data.map((d) => new QrpList().convertObjectToList(d))));
    };

    $getRmObjectService = (): Observable<Array<RmList>> => {
        return this._rmService.$getRmObjectList().pipe(map((data) => data.map((d) => new RmList().convertObjectToList(d))));
    };

    $getDlObjectService = (): Observable<Array<DlList>> => {
        return this._dlService.$getDlObjectList().pipe(map((data) => data.map((d) => new DlList().convertObjectToList(d))));
    };

    $getFlexObjectService = (): Observable<Array<FlexList>> => {
        return this._fxService.$getFlexObjectList().pipe(map((data) => data.map((d) => new FlexList().convertObjectToList(d))));
    };

    $getEntityService = (): Observable<Array<EntityList>> => {
        return this._enSerivice.$getEntityObjectList().pipe(map((data) => data.map((d) => new EntityList().convertObjectToList(d))));
    };

    $getPayloadService = (): Observable<Array<PayloadList>> => {
        return this._payloadService.$getObjectList().pipe(map((data) => data.map((d) => new PayloadList().convertObjectToList(d))));
    };

    $getAudioService = (): Observable<Array<AudioList>> => {
        return this._audioService.$getAudioObjectList().pipe(map((data) => data.map((d) => new AudioList().convertObjectToList(d))));
    };

    clearCacheData = (): void => {
        this.$templateData = undefined;
        this._apiService.clearCacheData();
        this._buttonService.clearCacheData();
        this._crsService.clearCacheData();
        this._imageSevice.clearCacheData();
        this._qrpService.clearCacheData();
        this._rmService.clearCacheData();
        this._dlService.clearCacheData();
        this._fxService.clearCacheData();
        this._enSerivice.clearCacheData();
        this._payloadService.clearCacheData();
        this._audioService.clearCacheData();
        this._persistentService.clearCacheData();
    };
}
