import { Injectable } from "@angular/core";
import { CanActivate, CanLoad, Route, UrlSegment, Router, NavigationEnd, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { environment as env } from "@env/environment";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { UserProfileService } from "@core/services/authentication/user-profile.service";
import { BotProfileService } from "@core/services/authentication/bot-profile.service";
import { DetectExtensionService } from "@core/services/extensions/detect-extension.service";
import { SnackBarAlertService } from "@shared/services/snack-bar-alert/snack-bar-alert.service";

@Injectable()
export class PermissionGuard implements CanActivate, CanLoad {
    get featureFlags() {
        return env.featureFlags;
    }

    get username() {
        return this._usrProfile.getUsername() ?? "";
    }

    get canAccessDatabase() {
        return this.featureFlags.featureDatabase;
    }
    get canAccessLivechat() {
        return !this.featureFlags.featurePayment || (this._botProfile?.profile?.addOnLivechat && !this._botProfile?.profile?.current_plan?.isExpired);
    }
    get canAccessMarketplace() {
        return this.featureFlags.featureMarketplace;
    }

    canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        switch (this._botProfile?.isSelectedBOT()) {
            case true:
                switch (true) {
                    case _state.url.includes("training"):
                        if (this._botProfile?.canTrain || this._botProfile?.canView) return true;
                        return this._redirectMainBot();
                    case _state.url.includes("entity"):
                        if (this._botProfile?.canEntity) return true;
                        return this._redirectMainBot();
                    case _state.url.includes("objects"):
                        if (this._botProfile?.canObject) return true;
                        return this._redirectMainBot();
                    case _state.url.includes("dialog"):
                        if (this._botProfile?.canDialog) return true;
                        return this._redirectMainBot();
                    case _state.url.includes("lead-gen"):
                        if (this.canAccessDatabase) return true;
                        return this._redirectMainBot();
                    case _state.url.includes("chat"):
                        if (this._botProfile.canLivechat && this.canAccessLivechat) return true;

                        switch (true) {
                            case !this._botProfile?.profile?.addOnLivechat || this._botProfile?.profile?.current_plan?.isExpired:
                                if (!this._botProfile.isOwner) {
                                    this._snack.open("warn", $localize`:@@permission-access-error-message:You have not permission to access this page.`);
                                    return;
                                }
                                this._detectExtension.detectUpgradeExtension(true, "livechat").subscribe((_) => this._botProfile.verifyBotProfile(true));
                                return false;
                            default:
                                return this._redirectMainBot();
                        }
                    case _state.url.includes("marketplace"):
                        if (this._botProfile?.isOwner && this.canAccessMarketplace) return true;
                        return this._redirectMainBot();
                    case _state.url.includes("settings"):
                        if (this._botProfile?.isOwner) return true;
                        return this._redirectMainBot();
                }
                return true;
            default:
                this._router.navigate(["/manage"]);
                return false;
        }
    }

    canLoad(_route: Route, _segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        switch (this._botProfile?.isSelectedBOT()) {
            case true:
                switch (_route.path) {
                    case "training":
                        if (this._botProfile?.canTrain || this._botProfile?.canView) return true;
                        return this._redirectMainBot(true);
                    case "entity":
                        if (this._botProfile?.canEntity) return true;
                        return this._redirectMainBot(true);
                    case "objects":
                        if (this._botProfile?.canObject) return true;
                        return this._redirectMainBot(true);
                    case "dialog":
                        if (this._botProfile?.canDialog) return true;
                        return this._redirectMainBot(true);
                    case "lead-gen":
                        if (this.canAccessDatabase) return true;
                        return this._redirectMainBot(true);
                    case "chat":
                        if (this._botProfile.canLivechat && this.canAccessLivechat) return true;

                        switch (true) {
                            case !this._botProfile?.profile?.addOnLivechat || this._botProfile?.profile?.current_plan?.isExpired:
                                return false;
                            default:
                                return this._redirectMainBot(true);
                        }
                    case "marketplace":
                        if (this._botProfile?.isOwner && this.canAccessMarketplace) return true;
                        return this._redirectMainBot(true);
                    case "settings":
                        if (this._botProfile?.isOwner) return true;
                        return this._redirectMainBot(true);
                }
                return true;
            default:
                this._router.navigate(["/manage"]);
                return false;
        }
    }

    private _previousPath: string;

    constructor(
        private _detectExtension: DetectExtensionService,
        private _botProfile: BotProfileService,
        private _usrProfile: UserProfileService,
        private _router: Router,
        private _snack: SnackBarAlertService
    ) {
        this._botProfile.verifyBotProfile();
        this._router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => (this._previousPath = event.url));
    }

    private _redirectMainBot = (canLoad?: boolean): boolean => {
        if (!canLoad) {
            this._snack.open("warn", $localize`:@@error-not-have-permission:You have not permission to access this page.`);
            this._router.navigate([this._previousPath ?? "/home"]);
        }

        return false;
    };
}
