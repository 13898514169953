<div mat-dialog-title>
    <h3 i18n="@@Announcement">ประกาศ</h3>
    <button mat-mini-fab bn-small-button mat-dialog-close bn-dialog-close color="primary">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<mat-dialog-content class="container pt-2">
    <div class="d-block overflow-vertical" style="min-height: 350px; max-height: 80vh" [innerHTML]="html | safeHTML"></div>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <div class="d-flex justify-content-between align-items-center w-100">
        <mat-checkbox i18n="@@do-not-show-this-again" [(ngModel)]="notShowChecked">Do not show this again</mat-checkbox>
        <button mat-stroked-button bn-small-button color="primary" (click)="onClose()">
            <state-i18n key="BTN_CLOSE"></state-i18n>
        </button>
    </div>
</mat-dialog-actions>
