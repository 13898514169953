import { Injectable } from "@angular/core";
import { WebSocketSubject } from "rxjs/webSocket";
import { Observable, Subject, timer } from "rxjs";
import { map, tap, filter } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { SocketInBoundMessage, SocketOutBoundMessage, SocketAgentState } from "@core/models/livechat/socket-message.model";
import { AuthService } from "@core/services/authentication/auth.service";
import { BotProfileService } from "@core/services/authentication/bot-profile.service";
import { LocalService } from "@core/services/cache/local.service";

const SOCKET_GATEWAYS_URL: string = env.socketGatewayURL;

@Injectable()
export class SocketService {
    // Socket Client
    private socketOutBoundSubject: WebSocketSubject<SocketOutBoundMessage>;
    private socketInBoundSubject: WebSocketSubject<SocketInBoundMessage>;
    private socketStateSubject: WebSocketSubject<SocketAgentState>;

    private agentStateSubject: Subject<SocketAgentState> = new Subject();
    private _agentList: Array<SocketAgentState> = [];

    private _outBound: SocketOutBoundMessage;

    constructor(private _authService: AuthService, private _botProfile: BotProfileService, private local: LocalService) {}

    onInitSocketInBound = (): void => {
        this.socketInBoundSubject = new WebSocketSubject({
            url: `${SOCKET_GATEWAYS_URL}/livechat/inbound?bot_id=${this._botProfile.getID}&access_token=${this._authService.getAccessToken}`,
            openObserver: {
                next: () => {
                    console.log("livechat inbound connetion ok");
                },
            },
            closeObserver: {
                next: () => {
                    console.log("livechat inbound unsubscription");
                },
            },
        });
    };

    onConnectInBound(): Observable<SocketInBoundMessage> {
        return this.socketInBoundSubject.pipe(
            filter((response: any) => (response.type ? false : true)),
            tap((response) => {
                if (this._outBound) {
                    response.source.source_id == this._outBound.source_id && response.msg_reply_token
                        ? (this._outBound.msg_reply_token = response.msg_reply_token)
                        : null;
                }
            }),
            map((response) => new SocketInBoundMessage().deserialize(response))
        );
    }

    onOutBoundEmit(type: "message" | "image" | "intent", msg: string, trained: boolean): void {
        if (!this._outBound) return;
        switch (type) {
            case "image":
                this.socketOutBoundSubject.next(this._outBound.onOutBoundImageMessage(msg, trained));
                break;
            case "intent":
                this.socketOutBoundSubject.next(this._outBound.onOutBoundIntentMessage(msg, trained));
                break;
            default:
                this.socketOutBoundSubject.next(this._outBound.onOutBoundMessage(msg, trained));
        }
    }

    onInitSocketOutBound = (
        page_id: string,
        source_id: string,
        source_channel: "line" | "facebook" | "line_modular" | "app",
        source_app_id: string,
        source_type: "group" | "room" | "user",
        msg_reply_token: string
    ): void => {
        this._outBound = new SocketOutBoundMessage().deserialize({
            bot_id: this._botProfile.getID,
            page_id: page_id,
            source_id: source_id,
            source_channel: source_channel,
            source_app_id: source_app_id,
            source_type: source_type,
            msg_reply_token: msg_reply_token,
        });

        if (this.socketOutBoundSubject) {
            this.socketOutBoundSubject.unsubscribe();
        }

        this.socketOutBoundSubject = new WebSocketSubject({
            url: `${SOCKET_GATEWAYS_URL}/livechat/outbound?bot_id=${this._botProfile.getID}&access_token=${this._authService.getAccessToken}`,
            openObserver: {
                next: () => {
                    console.log("livechat outbound connetion ok");
                },
            },
            closeObserver: {
                next: () => {
                    console.log("livechat outbound unsubscription");
                },
            },
        });
    };

    onConnectOutBound(): WebSocketSubject<SocketOutBoundMessage> {
        return this.socketOutBoundSubject;
    }

    onInitSocketState = (): void => {
        this.socketStateSubject = new WebSocketSubject({
            url: `${SOCKET_GATEWAYS_URL}/livechat/conversation/inbound?bot_id=${this._botProfile.getID}&access_token=${this._authService.getAccessToken}`,
            openObserver: {
                next: () => {
                    console.log("conversation inbound connetion ok");
                },
            },
            closeObserver: {
                next: () => {
                    console.log("conversation inbound unsubscription");
                },
            },
        });
    };

    onConnectState(): Observable<SocketAgentState> {
        return this.socketStateSubject.pipe(
            filter((response: any) => (response.type ? false : true)),
            map((response) => {
                response = new SocketAgentState().deserialize(response);
                this.agentStateSubject.next(response);
                return response;
            })
        );
    }

    onInitAgentStateSubject = (): void => {
        const encryptData = this.local.decryptGet(`_agent_${this._botProfile.getID}`);
        this._agentList = !!encryptData ? encryptData : [];
        timer(100).subscribe(() => this.agentStateSubject.next(null));
    };

    onSubscribeAgentState = (): Observable<number> => {
        return this.agentStateSubject.pipe(
            tap((response) => {
                if (!!response) {
                    const index = this._agentList.findIndex((user) => user._id == response._id);
                    switch (index) {
                        case -1:
                            if (response?.agent_state?.active) this._agentList.push(response);
                            break;
                        default:
                            if (!response?.agent_state?.active) this._agentList = this._agentList.filter((_, i) => i !== index);
                    }
                    this.local.encryptSet(`_agent_${this._botProfile.getID}`, this._agentList);
                }
            }),
            map((_) => this._agentList.length)
        );
    };

    onClearAgentStateCache = (): void => {
        this._agentList = [];
        this.local.encryptSet(`_agent_${this._botProfile.getID}`, this._agentList);
        this.agentStateSubject.next(null);
    };
}
