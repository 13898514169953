<div class="d-block p-relative px-4">
    <button mat-mini-fab bn-small-button mat-dialog-close bn-dialog-close color="primary">
        <mat-icon svgIcon="close"></mat-icon>
    </button>

    <h2 i18n="@@contact-support-title" class="text-center mb-2">Please contact our support</h2>

    <div class="d-flex justify-content-center pb-2">
        <mat-icon svgIcon="smile"></mat-icon>
    </div>

    <p i18n="@@contact-support-desc" class="text-center">To upgrade the chatbot above the {{planName}} package, please contact our support team.</p>

    <p i18n="@@contact-support-email" class="text-center text-blue">admin@botnoigroup.com</p>

</div>

<mat-dialog-actions align="center">
    <button mat-stroked-button mat-dialog-close color="primary"><state-i18n key="BTN_CLOSE"></state-i18n></button>
</mat-dialog-actions>
