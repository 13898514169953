<div class="bn-toolbar" style="position: relative">
    <button *ngIf="hasMenu" mat-mini-fab color="primary" [disabled]="breakPoint" (click)="toggleExpand()" style="width: 35px; height: 35px;">
        <mat-icon style="color: #5b6370 !important; width: 18px; height: 18px; position: relative; top:-3px" svgIcon="align-center"></mat-icon>
    </button>

    <div data-cy="go-to-main-select-chatbot-button" class="logo-contain" [ngClass]="{'transparent': transparent}" (click)="goManageBOT()">
        <img src="assets/botnoi-logo/botnoi.svg" />
        <h2 i18n="@@botnoi">Botnoi</h2>
    </div>

    <div class="bn-spacer"></div>

    <div class="d-flex flex-center">

        <p i18n="@@report-problem" class="mr-1 text-muted">Report a problem</p>
        <a
            mat-raised-button
            bn-square-button
            bn-small-button
            color="default"
            class="commu-bttn mr-1"
            href="https://forms.gle/a68wjRwsWW2sME6W7"
            target="_blank"
        >
            <mat-icon svgIcon="mail" class="medium-icon"></mat-icon>
        </a>

        <p i18n="@@communities" class="mr-1 text-muted">Communities</p>

        <a
            mat-raised-button
            bn-square-button
            bn-small-button
            color="default"
            class="commu-bttn mr-1"
            href="https://line.me/ti/g2/fE3AGkfX3uqwyOBoHcg1Pkz20DT5oh8wVuSWnA?utm_source=invitation&utm_medium=link_copy&utm_campaign=default"
            target="_blank"
        >
            <mat-icon svgIcon="line_logo" class="large-icon"></mat-icon>
        </a>
        <a
            mat-raised-button
            bn-square-button
            bn-small-button
            color="default"
            class="commu-bttn mr-2"
            href="https://web.facebook.com/groups/360794321699507/"
            target="_blank"
        >
            <mat-icon svgIcon="facebook_logo" class="medium-icon"></mat-icon>
        </a>
    </div>
    <span class="mr-1">{{ username }}</span>
    <mat-icon svgIcon="down" [ngClass]="{'opened': isToggle}" (click)="isToggle = !isToggle" cdkOverlayOrigin #trigger="cdkOverlayOrigin"></mat-icon>

    <ng-content></ng-content>
</div>

<!-- This template displays the overlay content and is connected to the button -->
<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isToggle"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayPositions]="positions"
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    (backdropClick)="isToggle = false"
    (overlayKeydown)="overlayKeydown($event)"
>
    <ul class="options-list">
        <li (click)="goManageBOT()">
            <mat-icon svgIcon="tiles"></mat-icon>
            <ng-container i18n="@@my-bot"> My bot </ng-container>
        </li>
        <li (click)="goPaymentCenter()">
            <mat-icon svgIcon="credit-card"></mat-icon>
            <ng-container i18n="@@payment-center"> Payment center </ng-container>
        </li>
        <li (click)="onLogout()">
            <mat-icon svgIcon="exit-right"></mat-icon>
            <ng-container i18n="@@log-out"> Log out </ng-container>
        </li>
    </ul>
</ng-template>
