import { Deserializable } from "../deserializable.model";
import { StatIntent } from "./stat-intent.model";
import { StatChitchat } from "./stat-chitchat.model";

export interface UserMessageStat {
    user_stat: UserStatModel;
    user_stats_by_time_series: Array<UserStatByTimeSeriesModel>;
    message_stat: MessageStatModel;
    message_stats_by_time_series: Array<MessageStatByTimeSeriesModel>;
    chitchat_stat: StatChitchat;
    intent_stat: StatIntent;
}
export class UserMessageStat implements Deserializable {
    user_stat: UserStatModel;
    user_stats_by_time_series: Array<UserStatByTimeSeriesModel>;
    message_stat: MessageStatModel;
    message_stats_by_time_series: Array<MessageStatByTimeSeriesModel>;
    chitchat_stat: StatChitchat;
    intent_stat: StatIntent;

    deserialize(input: UserMessageStat) {
        Object.assign(this, input);
        if (!input?.message_stats_by_time_series?.length) input.message_stats_by_time_series = [];

        if (!!input?.user_stat) this.user_stat = new UserStatModel().deserialize(input?.user_stat);
        if (!!input?.user_stats_by_time_series)
            this.user_stats_by_time_series = input?.user_stats_by_time_series.map((stat) => new UserStatByTimeSeriesModel().deserialize(stat));
        if (!!input?.message_stat) this.message_stat = new MessageStatModel().deserialize(input?.message_stat);
        if (!!input?.message_stats_by_time_series)
            this.message_stats_by_time_series = input?.message_stats_by_time_series.map((stat) => new MessageStatByTimeSeriesModel().deserialize(stat));
        if (!!input?.chitchat_stat) this.chitchat_stat = new StatChitchat().deserialize(input?.chitchat_stat);
        if (!!input?.intent_stat) this.intent_stat = new StatIntent().deserialize(input?.intent_stat);
        return this;
    }
}

interface UserStatModel {
    total_user: number;
    total_user_change_percent: number;
    total_active_user: number;
    active_user_change_percent: number;
    total_new_user: number;
    new_user_change_percent: number;
    total_retention_user: number;
    retention_user_change_percent: number;
}
class UserStatModel implements Deserializable {
    total_user: number;
    total_user_change_percent: number;
    total_active_user: number;
    active_user_change_percent: number;
    total_new_user: number;
    new_user_change_percent: number;
    total_retention_user: number;
    retention_user_change_percent: number;

    deserialize(input: UserStatModel) {
        Object.assign(this, input);
        return this;
    }
}

interface MessageStatModel {
    agent_call: number;
    agent_call_change_percent: number;
    responded: number;
    responded_change_percent: number;
    non_responded: number;
    non_responded_change_percent: number;
    total_message: number;
    total_message_change_percent: number;
    incoming_message: number;
    incoming_message_change_percent: number;
}
class MessageStatModel implements Deserializable {
    agent_call: number;
    agent_call_change_percent: number;
    responded: number;
    responded_change_percent: number;
    non_responded: number;
    non_responded_change_percent: number;
    total_message: number;
    total_message_change_percent: number;
    incoming_message: number;
    incoming_message_change_percent: number;

    deserialize(input: MessageStatModel) {
        Object.assign(this, input);
        return this;
    }
}

interface UserStatByTimeSeriesModel {
    date_range: string;
    total_user: number;
    total_active_user: number;
    total_new_user: number;
    total_retention_user: number;
}
class UserStatByTimeSeriesModel implements Deserializable {
    date_range: string;
    total_user: number;
    total_active_user: number;
    total_new_user: number;
    total_retention_user: number;

    deserialize(input: UserStatByTimeSeriesModel) {
        Object.assign(this, input);
        return this;
    }
}

interface MessageStatByTimeSeriesModel {
    date_range: string;
    total_message: number;
    incoming_message: number;
    chat_bot_incoming: number;
    respondes: number;
    non_responded: number;
    agent_call: number;
}
class MessageStatByTimeSeriesModel implements Deserializable {
    date_range: string;
    total_message: number;
    incoming_message: number;
    chat_bot_incoming: number;
    respondes: number;
    non_responded: number;
    agent_call: number;

    deserialize(input: MessageStatByTimeSeriesModel) {
        Object.assign(this, input);
        return this;
    }
}
