<div mat-dialog-title>
    <h3 i18n="@@payment">Payment</h3>
    <button mat-mini-fab bn-small-button bn-dialog-close color="primary" (click)="onClose()">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<mat-dialog-content class="container py-2">
    <h3 i18n="@@qr-code-title" class="text-strong mb-1 ls-1">QR Code</h3>

    <div class="box-container py-2 px-2 d-flex flex-column flex-center">
        <div class="warn-snack-contain mb-2">
            <mat-icon svgIcon="warning"></mat-icon>
            <div class="snack-label-container">
                <h2 i18n="@@warning" class="mb-0">Warning</h2>
                <p>While making a transaction, do not close this window. After a successful transaction, the window will close itself.</p>
                <p>ในระหว่างการทำธุรกรรม กรุณาอย่าปิดหน้าต่างนี้ หลังจากทำธุรกรรมเสร็จสิ้นแล้ว หน้าต่างนี้จะปิดตัวเองอัตโนมัติ</p>
            </div>
        </div>

        <p i18n="@@scan-with-mobile-banking" class="text-strong mb-0">Scan with mobile banking</p>

        <ng-container [ngSwitch]="hasQrCode">
            <qrcode *ngSwitchCase="true" [qrdata]="qrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>

            <ng-container *ngSwitchDefault>
                <div class="qr-blank-container d-flex flex-center">
                    <div class="loader"></div>
                    <state-i18n key="LABEL_LOADING"></state-i18n>
                </div>
            </ng-container>
        </ng-container>

        <p class="mb-0">{{ amount | currency }} <ng-container i18n="@@currency-label">THB</ng-container></p>
    </div>
</mat-dialog-content>
