import { Injectable } from "@angular/core";
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "@core/services/authentication/auth.service";
import { BotProfileService } from "@core/services/authentication/bot-profile.service";

@Injectable()
export class LoginGuard implements CanActivate, CanLoad {
    canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        switch (this._auth?.isLoggedIn()) {
            case true:
                return true;
            default:
                this._router.navigate(["identify"]);
                return false;
        }
    }
    canLoad(route: Route, _segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        switch (route.path) {
            case "identify":
                if (this._auth?.isLoggedIn()) {
                    if (this._botProfile?.isSelectedBOT()) this._router.navigate(["/home"]);
                    else this._router.navigate(["/manage"]);

                    return false;
                }
                return true;
            default:
                if (!this._auth?.isLoggedIn()) {
                    this._router.navigate(["identify"]);
                    return false;
                }
                return true;
        }
    }

    constructor(private _router: Router, private _auth: AuthService, private _botProfile: BotProfileService) {
        this._auth.verifyToken();
        this._botProfile.verifyBotProfile();
    }
}
