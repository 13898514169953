import { Component, Renderer2, ElementRef, Input } from "@angular/core";

@Component({
    selector: "bn-hint",
    templateUrl: "./bn-hint.component.html",
    styleUrls: ["./bn-hint.component.scss"],
})
export class BnHintComponent {
    @Input() align: "start" | "end" = "start";

    constructor(private ele: ElementRef, private _renderer: Renderer2) {
        this._renderer.addClass(this.ele.nativeElement, "mat-caption");
        this._renderer.addClass(this.ele.nativeElement, "bn-hint");
    }

    getElement = (): ElementRef => {
        return this.ele.nativeElement;
    };
}
