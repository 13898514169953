import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackBarTitleService } from "./snack-bar-title.service";
import { SuccessAlertComponent, ErrorAlertComponent, WarnAlertComponent, NormalAlertComponent } from "../../components/alert";

@Injectable()
export class SnackBarAlertService {
    constructor(private _snackBar: MatSnackBar, private _snackTitle: SnackBarTitleService) {}

    open = (state: "success" | "error" | "warn" | "normal", desc: string, title?: string, icon?: string, color?: "primary" | "accent" | "warn"): void => {
        this._snackTitle.description = desc.charAt(0).toUpperCase() + desc.slice(1);
        switch (state) {
            case "success":
                this._snackBar.openFromComponent(SuccessAlertComponent, {
                    duration: 5000,
                    horizontalPosition: "center",
                    verticalPosition: "top",
                });
                break;
            case "error":
                this._snackBar.openFromComponent(ErrorAlertComponent, {
                    duration: 5000,
                    horizontalPosition: "center",
                    verticalPosition: "top",
                });
                break;
            case "warn":
                this._snackBar.openFromComponent(WarnAlertComponent, {
                    duration: 5000,
                    horizontalPosition: "center",
                    verticalPosition: "top",
                });
                break;
            default:
                if (title) this._snackTitle.title = title;
                this._snackTitle.icon = icon ? icon : "ok-circle";
                this._snackTitle.color = color ?? null;
                this._snackBar.openFromComponent(NormalAlertComponent, {
                    duration: 5000,
                    horizontalPosition: "center",
                    verticalPosition: "top",
                });
                break;
        }
    };
}
