import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { Observable, of, throwError } from "rxjs";
import { map, catchError, retry, switchMap } from "rxjs/operators";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { LocalService } from "@core/services/cache/local.service";
import { UploadService } from "@core/http/s3/upload.service";
import { ImageObject } from "@core/models/object/image-object.model";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

interface ImageObjectResponse {
    _id: string;
    object_name: string;
    type: string;
    list_object: Array<{
        original_url: string;
        preview_image_url: string;
    }>;
}

@Injectable()
export class ManageImageObjectService {
    get url() {
        return `${API_GATEWAYS_URL}platform-go/object/image`;
    }

    constructor(private _http: HttpRequest, private _local: LocalService, private _uploadService: UploadService) {}

    $getImageObjectList = (): Observable<Array<ImageObject>> => {
        return this._http
            .get<Array<ImageObjectResponse>>({
                url: this.url.toString(),
                cacheMins: 1,
            })
            .pipe(
                retry(genericRetry),
                map((response) =>
                    response.map((res) =>
                        new ImageObject().deserialize({
                            _id: res._id,
                            name: res.object_name,
                            url: res.list_object[0]?.original_url,
                        })
                    )
                ),
                catchError((error) => throwError(() => error))
            );
    };

    $insertImageObject = (body: ImageObject, file?: File): Observable<{ _id: string }> => {
        return of(!!file).pipe(
            switchMap((state) => {
                if (state)
                    return this._uploadService.$onUploadImage(file).pipe(
                        retry(genericRetry),
                        map((response) => {
                            return { state: state, img_url: response.file_url };
                        }),
                        catchError((error) => throwError(() => error))
                    );
                return of({ state: state, img_url: null });
            }),
            switchMap((response) => {
                if (response?.img_url) body.url = response.img_url;
                const request = body.convertObjectToServer();
                delete request._id;
                return this._http
                    .post<{ _id: string }>({
                        url: this.url.toString(),
                        body: request,
                    })
                    .pipe(
                        retry(genericRetry),
                        catchError((error) => throwError(() => error))
                    );
            })
        );
    };

    $duplicateImgObject = (_id: string): Observable<ImageObject> => {
        return this._http
            .post<{ data: ImageObjectResponse }>({
                url: `${API_GATEWAYS_URL}platform-go/object/duplicate`,
                body: { _id: _id },
            })
            .pipe(
                retry(genericRetry),
                map((response) => {
                    this.clearCacheData();
                    return new ImageObject().deserialize({
                        _id: response?.data?._id,
                        name: response?.data?.object_name,
                        url: response?.data.list_object[0]?.original_url,
                    });
                }),
                catchError((error) => throwError(() => error))
            );
    };

    $updateImageObject = (body: ImageObject, file?: File): Observable<{ msg: string }> => {
        return of(!!file).pipe(
            switchMap((state) => {
                if (state)
                    return this._uploadService.$onUploadImage(file).pipe(
                        retry(genericRetry),
                        map((response) => {
                            this._uploadService.$onDeleteImage([body.url]).subscribe();
                            return { state: state, img_url: response.file_url };
                        }),
                        catchError((error) => throwError(() => error))
                    );
                return of({ state: state, img_url: null });
            }),
            switchMap((response) => {
                if (response?.img_url) body.url = response.img_url;
                const request = body.convertObjectToServer();
                return this._http
                    .put<{ msg: string }>({
                        url: this.url.toString(),
                        body: request,
                    })
                    .pipe(
                        retry(genericRetry),
                        catchError((error) => throwError(() => error))
                    );
            })
        );
    };

    $deleteImageObjectList = (body: Array<ImageObject>): Observable<{ msg: string }> => {
        return this._http
            .delete<{ msg: string }>({
                url: this.url.toString(),
                body: { _id: body.map((b) => b._id) },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    clearCacheData = (): void => {
        this._local.delete(this.url.toString());
    };
}
