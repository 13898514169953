import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { LocalService } from "@core/services/cache/local.service";
import { FlexObject } from "@core/models/object/flex-object.model";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

interface FlexObjectResponse {
    _id: string;
    object_name: string;
    type: string;
    list_object: Array<any>;
}

@Injectable()
export class ManageFxObjectService {
    get url() {
        return `${API_GATEWAYS_URL}platform-go/object/flex`;
    }

    constructor(private _http: HttpRequest, private local: LocalService) {}

    $getFlexObjectList = (): Observable<Array<FlexObject>> => {
        return this._http
            .get<Array<FlexObjectResponse>>({
                url: this.url.toString(),
                cacheMins: 1,
            })
            .pipe(
                retry(genericRetry),
                map((response) =>
                    response.map((res) =>
                        new FlexObject().deserialize({
                            _id: res._id,
                            name: res.object_name,
                        })
                    )
                ),
                catchError((error) => throwError(() => error))
            );
    };

    $getFlexBody = (_id: string): Observable<FlexObject> => {
        return this._http
            .get<FlexObjectResponse>({
                url: `${API_GATEWAYS_URL}platform-go/object/flex/detail?object_id=${_id}`,
            })
            .pipe(
                retry(genericRetry),
                map((response) =>
                    new FlexObject().deserialize({
                        _id: response._id,
                        name: response.object_name,
                        body: response.list_object[0],
                    })
                ),
                catchError((error) => throwError(() => error))
            );
    };

    $insertFlexObject = (body: FlexObject): Observable<{ _id: string }> => {
        const request = body.convertObjectToServer();
        delete request._id;
        return this._http
            .post<{ _id: string }>({
                url: this.url.toString(),
                body: request,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $duplicateFlexObject = (_id: string): Observable<FlexObject> => {
        return this._http
            .post<{ data: FlexObjectResponse }>({
                url: `${API_GATEWAYS_URL}platform-go/object/duplicate`,
                body: { _id: _id },
            })
            .pipe(
                retry(genericRetry),
                map((response) => {
                    this.clearCacheData();
                    return new FlexObject().deserialize({
                        _id: response?.data?._id,
                        name: response?.data?.object_name,
                    });
                }),
                catchError((error) => throwError(() => error))
            );
    };

    $updateFlexObject = (body: FlexObject): Observable<{ _id: string }> => {
        const request = body.convertObjectToServer();
        return this._http
            .put<{ _id: string }>({
                url: this.url.toString(),
                body: request,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteFlexObjectList = (body: Array<FlexObject>): Observable<{ msg: string }> => {
        return this._http
            .delete<{ msg: string }>({
                url: this.url.toString(),
                body: { _id: body.map((b) => b._id) },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    clearCacheData = (): void => {
        this.local.delete(this.url.toString());
    };
}
