import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
import * as env from "@env/environment";

const EncyptKey: string = env.environment.key;

@Injectable()
export class LocalService {
    private storage: Storage;

    constructor() {
        this.storage = localStorage;
    }

    set(key: string, value: any): void {
        try {
            this.storage.setItem(key, value);
        } catch (_) {
            console.error(`${key}: cant set localStorage`);
        }
    }

    encryptSet(key: string, value: any): void {
        try {
            const encryptValue = CryptoJS.AES.encrypt(JSON.stringify(value), EncyptKey).toString();
            this.storage.setItem(key, encryptValue);
        } catch (_) {
            console.error(`${key}: cant set localStorage`);
        }
    }

    get(key: string): any {
        const value = this.storage.getItem(key);
        return value;
    }

    decryptGet(key: string): any {
        const encryptValue = this.storage.getItem(key);
        const decryptValue = CryptoJS.AES.decrypt(encryptValue ?? "", EncyptKey).toString(CryptoJS.enc.Utf8);
        return !!decryptValue ? JSON.parse(decryptValue) : decryptValue;
    }

    delete(key: string): void {
        this.storage.removeItem(key);
    }

    clearAll(): void {
        this.storage.clear();
    }
}
