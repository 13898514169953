<div class="p-fixed d-flex side-container" [@expandCollapse]="autoTestCollapse ? 'collapsed' : 'expanded'">
    <div class="toggle-button-container">
        <button class="toggle-button p-relative ls-1" [ngClass]="{'text-blue': !autoTestCollapse}" (click)="toggleAutoTestButton()">
            <ng-container [ngSwitch]="autoTestCollapse">
                <ng-container *ngSwitchCase="true">
                    <mat-icon svgIcon="bubble"></mat-icon>
                    <ng-container i18n="@@test-bot">Test Bot</ng-container>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <mat-icon svgIcon="close"></mat-icon>
                    <state-i18n key="BTN_CLOSE"></state-i18n>
                </ng-container>
            </ng-container>
        </button>
    </div>

    <div class="card-element">
        <div class="header-contain d-flex align-items-center">
            <div class="user-logo-container">
                <img [src]="avatar" />
            </div>
            <h4 class="ls-1 mb-0">{{ titleTestBot }}</h4>

            <div bnSpacer></div>

            <!-- <mat-slide-toggle [(ngModel)]="openedAutotest" (ngModelChange)="toggleAutoTest($event)" class="ml-1" color="primary"></mat-slide-toggle> -->
        </div>

        <ng-container [ngSwitch]="!openedAutotest">
            <ng-container *ngSwitchCase="true">
                <div class="d-block w-100 px-1" style="height: 300px"></div>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <ng-container [ngSwitch]="true">
                    <div *ngSwitchCase="processing" class="d-flex flex-column flex-center w-100" style="height: 334px">
                        <mat-icon svgIcon="reload" color="primary" class="mb-1" [@reload]="reloadState" (@reload.done)="onDoneReloadState($event)"></mat-icon>
                        <p class="text-center">Processing {{ percentage }}%</p>
                    </div>

                    <div *ngSwitchCase="hasResults" class="d-block w-100 px-1" style="height: 334px">
                        <div class="d-flex align-items-center py-1 w-100">
                            <h4 i18n="@@result" class="mb-0">Result</h4>
                            <div bnSpacer></div>
                            <div class="d-flex align-items-center mat-status mr-1">
                                <mat-icon svgIcon="ok-circle" color="primary"></mat-icon>
                                <span class="text-green">{{ successResult }}</span>
                            </div>
                            <div class="d-flex align-items-center mat-status">
                                <mat-icon svgIcon="close-circle" color="warn"></mat-icon>
                                <span class="text-red">{{ failResult }}</span>
                            </div>
                        </div>

                        <div class="d-block w-100 overflow-vertical" style="height: 294px">
                            <ng-container *ngFor="let item of results index as i">
                                <ng-container [ngSwitch]="item?.is_correct">
                                    <ng-container *ngSwitchCase="false">
                                        <button
                                            class="d-flex align-items-center py-1 px-6-px w-100 bg-white border-none"
                                            [ngClass]="isExpandedResult(i) ? 'bg-sky' : 'bg-white'"
                                            style="height: 40px; border-radius: 8px"
                                            (click)="toggleSelectResult(i)"
                                        >
                                            <mat-icon svgIcon="close-circle" class="text-red"></mat-icon>
                                            <span [ngClass]="{'text-blue': isExpandedResult(i)}">{{ item.intent }}</span>
                                            <div bnSpacer></div>
                                            <button mat-icon-button bn-small-button>
                                                <ng-container [ngSwitch]="isExpandedResult(i)">
                                                    <mat-icon *ngSwitchCase="true" svgIcon="up"></mat-icon>
                                                    <mat-icon *ngSwitchDefault svgIcon="down"></mat-icon>
                                                </ng-container>
                                            </button>
                                        </button>

                                        <div class="d-block w-100 result-container" [@expandCollapsePanel]="isExpandedResult(i) ? 'expanded' : 'collapsed'">
                                            <p class="mb-0">
                                                <b i18n="@@response-is">Response is: </b>
                                                {{ item?.response }}
                                            </p>
                                            <p class="mb-0">
                                                <b i18n="@@correct-is">Correct is: </b>
                                                {{ item?.correct_ans }}
                                            </p>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchDefault>
                                        <div class="d-flex align-items-center py-1 px-6-px">
                                            <mat-icon svgIcon="ok-circle" class="text-green"></mat-icon>
                                            <span>{{ item.intent }}</span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>

                    <div *ngSwitchDefault class="d-block w-100 pl-1" style="height: 334px">
                        <div class="d-flex align-items-center w-100">
                            <h4 i18n="@@intent" class="mb-0">Intent</h4>
                            <div bnSpacer></div>
                            <button mat-button bn-small-button color="primary" (click)="openSelectObjectDialog()">
                                <mat-icon svgIcon="plus-circle" style="margin-right: 5px"></mat-icon>
                                <ng-container i18n="@@import-intent">Import intent</ng-container>
                            </button>
                        </div>

                        <ng-container *ngIf="hasSelectedIntent">
                            <div class="d-flex align-items-center py-1">
                                <mat-checkbox
                                    i18n="@@select-all"
                                    [checked]="isAllCheckedIntent"
                                    [indeterminate]="isIndeterminateIntent"
                                    (change)="allCheckedChange($event)"
                                    disableRipple="true"
                                >
                                    Select all
                                </mat-checkbox>
                            </div>

                            <div class="d-block w-100 overflow-vertical" style="height: 257px">
                                <div *ngFor="let item of selectedIntent" class="d-flex align-items-center py-1">
                                    <mat-checkbox [checked]="isCheckedIntent(item)" (change)="toggleCheckedIntent(item)" disableRipple="true">
                                        <ng-container>{{ item }}</ng-container>
                                    </mat-checkbox>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container [ngSwitch]="!openedAutotest">
            <ng-container *ngSwitchCase="true">
                <div class="d-flex flex-column justify-content-between border-top-grey py-1 w-100">
                    <bn-form-field bnFormSmall class="w-100 h-100" borderColor="transparent">
                        <textarea
                            i18n-placeholder="@@send-a-message"
                            #replymessage
                            bnInput
                            bnTextareaAutosize
                            [bnAutosizeMinRows]="1"
                            [bnAutosizeMaxRows]="5"
                            placeholder="Send a message..."
                            class="w-100"
                        ></textarea>
                    </bn-form-field>

                    <div class="d-flex align-items-center px-1">
                        <button
                            i18n-matTooltip="@@import-photo"
                            mat-mini-fab
                            bn-small-button
                            matTooltip="Import Photo"
                            matTooltipPosition="above"
                            (click)="fileSelect.click()"
                        >
                            <mat-icon svgIcon="picture"></mat-icon>
                        </button>
                        <button
                            i18n-matTooltip="@@object-and-intent-title"
                            mat-mini-fab
                            bn-small-button
                            cdkOverlayOrigin
                            matTooltip="Object & Intent"
                            matTooltipPosition="above"
                        >
                            <mat-icon svgIcon="intent"></mat-icon>
                        </button>
                        <input #fileSelect multiple type="file" accept="image/*" style="display: none" />

                        <div bnSpacer></div>

                        <button mat-stroked-button bn-small-button color="primary" class="ml-2">
                            <mat-icon svgIcon="send"></mat-icon>
                            <ng-container i18n="@@send">Send</ng-container>
                        </button>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <div class="d-flex justify-content-center w-100 py-1">
                    <ng-container [ngSwitch]="true">
                        <button *ngSwitchCase="processing" i18n="@@stop" mat-raised-button bn-small-button color="primary" (click)="onCancelProcessing()">
                            <mat-icon svgIcon="pause-filled"></mat-icon>
                            Stop
                        </button>

                        <button *ngSwitchCase="hasResults" i18n="@@ok" mat-raised-button bn-small-button color="primary" (click)="onOkayResults()">OK</button>

                        <button
                            *ngSwitchDefault
                            i18n="@@run-test"
                            mat-raised-button
                            bn-small-button
                            color="primary"
                            [disabled]="!hasSelectionIntent"
                            (click)="onRunTesting()"
                        >
                            <mat-icon svgIcon="play"></mat-icon>
                            Run test
                        </button>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
