import { Routes } from "@angular/router";
import { environment as env } from "@env/environment";
import { ManageBotLayoutComponent } from "../layouts/manage-bot-layout/manage-bot-layout.component";
import { MainBotLayoutComponent } from "../layouts/main-bot-layout/main-bot-layout.component";
import { LoginGuard } from "../guards/login/login.guard";
import { BotGuard } from "../guards/bot/bot.guard";
import { PermissionGuard } from "../guards/permission/permission.guard";

const PRODUCT_ALL_ROUTES: Routes = [
    {
        path: "identify",
        canLoad: [LoginGuard],
        loadChildren: () => import("../../modules/user/user.module").then((m) => m.UserModule),
        data: { title: "Botnoi SME Platform - บอทน้อย แชทบอท" },
    },
    {
        path: "manage",
        canLoad: [LoginGuard],
        component: ManageBotLayoutComponent,
        loadChildren: () => import("../../modules/manage-bot/manage-bot.module").then((m) => m.ManageBotModule),
        data: { title: "Botnoi SME Platform - Manage" },
    },
    {
        path: "home",
        canActivate: [LoginGuard, BotGuard],
        component: MainBotLayoutComponent,
        data: { title: "Botnoi SME Platform" },
        children: [
            {
                path: "dashboard",
                canLoad: [LoginGuard],
                loadChildren: () => import("../../modules/dashboard/dashboard.module").then((m) => m.DashboardModule),
                data: { title: "Dashboard" },
            },
            {
                path: "training",
                canActivate: [PermissionGuard],
                canLoad: [LoginGuard],
                loadChildren: () => import("../../modules/train-bot/train-bot.module").then((m) => m.TrainBotModule),
                data: { title: "Train" },
            },
            {
                path: "entity",
                canActivate: [PermissionGuard],
                canLoad: [LoginGuard],
                loadChildren: () => import("../../modules/entity/entity.module").then((m) => m.EntityModule),
                data: { title: "Entity" },
            },
            {
                path: "dialog",
                canActivate: [PermissionGuard],
                canLoad: [LoginGuard],
                loadChildren: () => import("../../modules/dialog-object/dialog-object.module").then((m) => m.DialogObjectModule),
                data: { title: "Dialogue" },
            },
            {
                path: "api",
                canActivate: [PermissionGuard],
                canLoad: [LoginGuard],
                loadChildren: () => import("../../modules/api-object/api-object.module").then((m) => m.ApiObjectModule),
                data: { title: "API" },
            },
            {
                path: "objects",
                canActivate: [PermissionGuard],
                canLoad: [LoginGuard],
                loadChildren: () => import("../../modules/objects/objects.module").then((m) => m.ObjectsModule),
            },
            {
                path: "lead-gen",
                canActivate: [PermissionGuard],
                canLoad: [LoginGuard],
                loadChildren: () => import("../../modules/lead-gen/lead-gen.module").then((m) => m.LeadGenModule),
            },
            {
                path: "chat",
                canActivate: [PermissionGuard],
                canLoad: [LoginGuard],
                loadChildren: () => import("../../modules/live-chat/live-chat.module").then((m) => m.LiveChatModule),
                data: { title: "Livechat" },
            },
            {
                path: "docs",
                canLoad: [LoginGuard],
                loadChildren: () => import("../../modules/document/document.module").then((m) => m.DocumentModule),
                data: { title: "Document" },
            },
            {
                path: "marketplace",
                canActivate: [PermissionGuard],
                canLoad: [LoginGuard],
                loadChildren: () => import("../../modules/market-place/market-place.module").then((m) => m.MarketPlaceModule),
                data: { title: "Marketplace" },
            },
            {
                path: "settings",
                canActivate: [PermissionGuard],
                canLoad: [LoginGuard],
                loadChildren: () => import("../../modules/settings/settings.module").then((m) => m.SettingsModule),
                data: { title: "Settings" },
            },
            { path: "**", redirectTo: "dashboard", pathMatch: "full" },
        ],
    },
    {
        path: "customer-chat",
        loadChildren: () => import("../../modules/customer-chat/customer-chat.module").then((m) => m.CustomerChatModule),
        data: { title: "Customerchat" },
    },
    {
        path: "customer-chat",
        outlet: "chat",
        loadChildren: () => import("../../modules/customer-chat/customer-chat.module").then((m) => m.CustomerChatModule),
        data: { title: "Customerchat" },
    },
    {
        path: "legal",
        loadChildren: () => import("../../modules/legal/legal.module").then((m) => m.LegalModule),
    },
];

const DEV_ALL_ROUTES: Routes = [
    {
        path: "library",
        loadChildren: () => import("../../modules/component-library/component-library.module").then((m) => m.ComponentLibraryModule),
        data: { title: "Botnoi SME Platform - Library" },
    },
];

const REDIRECT_ROUTES: Routes = [{ path: "**", redirectTo: "identify", pathMatch: "full" }];

export const ALL_ROUTES: Routes = !env.production
    ? PRODUCT_ALL_ROUTES.concat(DEV_ALL_ROUTES).concat(REDIRECT_ROUTES)
    : PRODUCT_ALL_ROUTES.concat(REDIRECT_ROUTES);
