import * as moment from "moment";
import { Deserializable } from "../deserializable.model";
import { Access } from "@core/models/bot/bot-permission.model";
import { ManualChannel, ModularChannel } from "@core/models/connection/line-subscribe-list.model";
import { SubscriptionPackage, SubscriptionAddOns } from "@core/models/bot/subscription-plan.model";

// Add-on feature keys
const livechat: string = "livechat";
const dashboard: string = "dashboard";
const ads: string = "ads";
//////////////////////

export class BotProfile implements Deserializable {
    _id: string;
    bot_avatar: string;
    bot_name: string;
    business_type: string;
    access_channel: {
        facebook: Array<FacebookAccessChannel>;
        line: ManualChannel;
        line_modular: ModularChannel;
    };
    channel_active_status: {
        facebook: boolean;
        line: boolean;
        ex10: boolean;
    };
    current_plan: CurrentPlan;
    add_ons: Array<AddOns>;
    owner: string;
    permission: Access;
    total_followers: number;
    total_followers_updated_at: Date;
    _create_date: Date;

    // Check the chatbot have connect to socail channel
    get isFacebookConnected() {
        return this.access_channel?.facebook.length > 0 ? true : false || false;
    }
    get isLineConnected() {
        return this.access_channel?.line?.hasChannel ?? false;
    }
    get isLineModularConnected() {
        return this.access_channel?.line_modular?.hasChannel ?? false;
    }
    ///////////////////////////////////////////////////

    // Check the chatbot package limitation
    get followerPercentage() {
        if (!this.current_plan?.plan?.limit_subscribe_number) return 100;
        return parseInt(((100 * this.total_followers) / this.current_plan?.plan.limit_subscribe_number).toFixed(2));
    }
    get daysRemainPercentage() {
        if (this.current_plan?.isFreePlan) return 0;
        if (!this.current_plan?.totalDays) return 100;
        return 100 - parseInt(((100 * this.current_plan?.remainDays) / this.current_plan?.totalDays).toFixed(2));
    }
    get isLimited() {
        return this.followerPercentage >= 100;
    }
    //////////////////////////////////////

    // Check the chatbot have add-ons
    get addOnLivechat() {
        return this.add_ons?.some((ao) => ao.feature == livechat) ?? false;
    }
    get addOnLivechatIsExpired() {
        return this.add_ons?.some((ao) => ao.feature == livechat && this.current_plan?.isExpired) ?? true;
    }
    get addOnDashboard() {
        return this.add_ons?.some((ao) => ao.feature == dashboard) ?? false;
    }
    get addOnAds() {
        return this.add_ons?.some((ao) => ao.feature == ads) ?? false;
    }
    /////////////////////////////////

    deserialize(input: any) {
        Object.assign(this, input);
        if (!!input?.access_channel) {
            this.access_channel = {
                facebook: input?.access_channel?.facebook.map((fbp: any) => new FacebookAccessChannel().deserialize(fbp)) ?? [],
                line: new ManualChannel().deserialize(input?.access_channel?.line) ?? null,
                line_modular: new ModularChannel().deserialize(input?.access_channel?.line_modular) ?? null,
            };
        }
        if (!!input?.current_plan) this.current_plan = new CurrentPlan().deserialize(input?.current_plan);
        if (!!input?.add_ons) this.add_ons = input?.add_ons?.map((ao: any) => new AddOns().deserialize(ao));
        if (!!input?.permission) {
            switch (true) {
                case Array.isArray(input.permission):
                    this.permission = new Access().deserialize(input?.permission[0]);
                    break;
                default:
                    this.permission = new Access().deserialize(input?.permission);
            }
        }
        if (!!input?.total_followers_updated_at) this.total_followers_updated_at = new Date(input?.total_followers_updated_at);
        if (!!input?._create_date) this._create_date = new Date(input?._create_date);
        if (!!input?.owner_email) this.owner = input.owner_email;
        return this;
    }

    // REMARK:: Unusedd
    age: number;
    personality: Array<string>;
    sex: string;
    status: string;

    get personal(): string {
        return this.personality.toString().replace(/,/g, ", ");
    }

    get channel(): Array<string> {
        let channel = [];
        if (this.isFacebookConnected) channel.push("facebook");
        if (this.isLineConnected || this.isLineModularConnected) channel.push("line");
        return channel;
    }
    ////////////////////
}

class FacebookAccessChannel implements Deserializable {
    page_id: string;
    first_token: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}

class CurrentPlan implements Deserializable {
    plan: SubscriptionPackage;
    active_at: Date;
    expired_at: Date;

    private _remainHours: number;

    get isFreePlan() {
        return this.plan?.cost_per_day == 0;
    }

    get isExpired() {
        return this.isFreePlan
            ? false
            : new Date().getTime() -
                  moment(this.expired_at ?? new Date())
                      .toDate()
                      .getTime() >
                  0;
    }

    get remainDaysMoment() {
        if (this.isFreePlan) return moment(0);

        switch (true) {
            case this._remainHours > 0:
                return moment(0).add(this._remainHours, "hours");
            default:
                return moment(0);
        }
    }

    get expiredAtLabel() {
        if (this.isFreePlan) return "-";
        return moment(this.expired_at).format("DD MMM YYYY");
    }

    private _remainDays: number;
    get remainDays() {
        return this.isFreePlan ? 0 : this._remainDays >= 0 ? this._remainDays : 0;
    }

    private _totalDays: number;
    get totalDays() {
        return this.isFreePlan ? 0 : this._totalDays >= 0 ? this._totalDays : 0;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        if (input?.plan) this.plan = new SubscriptionPackage().deserialize(input.plan);
        if (input?.active_at) this.active_at = new Date(input.active_at);
        if (input?.expired_at) {
            this.expired_at = new Date(input.expired_at);
            this._remainHours = moment(this.expired_at ?? null).diff(moment(), "hours", true);
            this._remainDays = moment(this.expired_at ?? new Date()).diff(moment(new Date()), "days", true);
            this._totalDays = moment(this.expired_at ?? new Date()).diff(moment(this.active_at ?? new Date()), "days", true);
        }
        return this;
    }
}

export class AddOns implements Deserializable {
    addons_detail: SubscriptionAddOns;

    get feature() {
        return this.addons_detail?.feature;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        if (input?.addons_detail) this.addons_detail = new SubscriptionAddOns().deserialize(input.addons_detail);
        return this;
    }
}
