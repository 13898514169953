import { Component, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators, ValidatorFn } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Button } from "@core/models/object/button-object.model";
import { SnackBarAlertService } from "@shared/services/snack-bar-alert/snack-bar-alert.service";
import { I18nState, getI18nState } from "@shared/translation/i18n-state";

@Component({
    selector: "app-add-button-type-dialog",
    templateUrl: "./add-button-type-dialog.component.html",
    styleUrls: ["./add-button-type-dialog.component.scss"],
})
export class AddButtonTypeDialogComponent {
    object: FormGroup;

    get title() {
        return this.data.button ? getI18nState(I18nState.BTN_EDIT) : getI18nState(I18nState.BTN_ADD);
    }

    get type() {
        return this.object.get("type") as FormControl;
    }

    get message() {
        return this.object.get("message") as FormControl;
    }

    get hasType() {
        return this.data.hasType;
    }

    get isPersistent() {
        return this.data?.isPersistent;
    }

    getMessageValidator(type?: "message" | "phone" | "url"): Array<ValidatorFn> {
        switch (type) {
            case "phone":
                return [Validators.required, Validators.pattern("^(\\+?\\d{1,2})?[\\s.-]?\\d{2,3}[\\s.-]?\\d{3}[\\s.-]?\\d{4}$")];
            case "url":
                return [
                    Validators.required,
                    Validators.pattern(
                        "[Hh][Tt][Tt][Pp][Ss]?:\\/\\/(?:(?:[a-zA-Z\\u00a1-\\uffff0-9]+-?)*[a-zA-Z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-zA-Z\\u00a1-\\uffff0-9]+-?)*[a-zA-Z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-zA-Z\\u00a1-\\uffff]{2,}))(?::\\d{2,5})?(?:\\/[^\\s]*)?"
                    ),
                ];
            default:
                return [Validators.required, Validators.maxLength(300)];
        }
    }

    constructor(
        private _dialogRef: MatDialogRef<AddButtonTypeDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        private data: { button: Button; hasType: boolean; isPersistent?: boolean },
        private _snackBar: SnackBarAlertService
    ) {
        if (this.data.button) {
            this.object = new FormGroup({
                label: new FormControl(this.data.button.label, [Validators.required, Validators.maxLength(20)]),
                type: new FormControl(this.data.button.type, [Validators.required]),
                message: new FormControl(this.data.button.message, this.getMessageValidator(this.data.button.type)),
            });
        } else {
            this.object = new FormGroup({
                label: new FormControl("", [Validators.required, Validators.maxLength(20)]),
                type: new FormControl("message", [Validators.required]),
                message: new FormControl("", this.getMessageValidator()),
            });
        }

        this.type.valueChanges.subscribe((type) => {
            this.message.setValidators(this.getMessageValidator(type));
            this.message.updateValueAndValidity();
        });
    }

    onSubmit() {
        if (this.object.valid) {
            const object = new Button().deserialize(this.object.value);
            this._dialogRef.close(object);
        } else {
            this._snackBar.open("error", $localize`:@@error-button-format:Invalid Button format.`);
        }
    }
}
