<div mat-dialog-title>
    <h3>{{ title }} <state-i18n key="OBJ_ENT"></state-i18n></h3>
    <button mat-mini-fab bn-small-button mat-dialog-close bn-dialog-close color="primary">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <form id="entity-form" class="d-flex flex-column" [formGroup]="object" (ngSubmit)="onSubmit()">
        <h4 i18n="@@entity-name-title">Entity Name</h4>
        <bn-form-field class="mb-1" bnFormLarge>
            <span bnPrefix class="prefix-text">ENT_&nbsp;</span>
            <input
                #nameInput
                type="text"
                bnInput
                formControlName="name"
                i18n-placeholder="@@enter-entity-name-title"
                placeholder="Enter Entity Name"
                cdkFocusInitial
                maxlength="40"
            />
            <span bnSuffix>{{ nameInput.value.length }}/40</span>
        </bn-form-field>

        <div class="d-flex align-items-center">
            <h4 class="mb-0 mr-1"><state-i18n key="LABEL_CON"></state-i18n></h4>
            <bn-form-field bnFormSmall class="mr-2" style="width: 65px">
                <input #confidenceInput bnInput [value]="confidencePercentage" (keyup)="onInputChange(confidenceInput.value)" placeholder="0" type="number" />
                <span bnSuffix>%</span>
            </bn-form-field>
            <mat-slider
                [value]="confidence"
                thumbLabel
                [displayWith]="formatLabel"
                (change)="onSliderChange($event)"
                class="w-100"
                min="0"
                max="1"
                step="0.01"
                color="primary"
            ></mat-slider>
        </div>

        <ng-container *ngIf="hasKeywords">
            <h4 i18n="@@keywords">Keywords</h4>
            <bn-form-field>
                <mat-chip-list class="mb-1">
                    <mat-chip *ngFor="let keyword of keywords index as i" color="default" (removed)="onRemoveKeyword(i)">
                        <span class="wrap-text">{{ keyword }}</span>
                        <mat-icon matChipRemove svgIcon="close-circle-filled" style="min-width: 18px"></mat-icon>
                    </mat-chip>
                </mat-chip-list>
                <input
                    #bnInput
                    i18n-placeholder="@@enter-keyword"
                    bnInput
                    type="text"
                    placeholder="Enter keyword"
                    (keydown.enter)="$event.preventDefault()"
                    (keyup.enter)="onAppendMessage(bnInput)"
                />
            </bn-form-field>
        </ng-container>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button form="entity-form" type="submit" mat-raised-button color="primary">
        <div *ngIf="fetching" class="loader"></div>
        <state-i18n key="BTN_SAVE"></state-i18n>
    </button>
</mat-dialog-actions>
