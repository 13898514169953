import { Component, OnInit, Inject, HostListener } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription, interval, Subject } from "rxjs";
import { switchMap, takeUntil, tap } from "rxjs/operators";
import { SubscriptionService } from "@core/http/bot";
import { BotProfile, SubscriptionPackage, CacheSubscriptionListModel, CacheSubscriptionModel } from "@core/models/bot";
import { CacheCookieService } from "@core/services/cache";
import { CheckStatusPaymentComponent, ConfirmationDialogComponent } from "@shared/components/dialog";
import { SnackBarAlertService } from "@shared/services/snack-bar-alert/snack-bar-alert.service";
import { I18nState, getI18nState } from "@shared/translation/i18n-state";
import { CalculatePrice } from "@core/models/bot/subscription-plan.model";

const WARNING_MSG = $localize`:@@confirm-close-qr-code-dialog-desc:Are you sure you want to close this dialog? If you close the dialog now, your payment may not be successful and you may encounter issues with your transaction. Please ensure that you have completed the payment process before closing this dialog.`;

@Component({
    selector: "app-qr-code-payment",
    templateUrl: "./qr-code-payment-dialog.component.html",
    styleUrls: ["./qr-code-payment-dialog.component.scss"],
})
export class QrCodePaymentComponent implements OnInit {
    @HostListener("window:beforeunload", ["$event"])
    unloadNotification($event: any) {
        const confirmationMessage = WARNING_MSG;
        $event.returnValue = confirmationMessage;
        return confirmationMessage;
    }

    private _subscription: Subscription;
    private $interval: Subject<void>;

    private _product: SubscriptionPackage;

    private _botProfile: BotProfile;
    get botProfile() {
        return this._botProfile;
    }

    get productId() {
        return this._product?._id;
    }

    get amount() {
        return this._data?.calculate?.calculate_price?.total_amount;
    }

    private _subscriptionID: string;

    get hasQrCode() {
        return this.qrCode.length > 0;
    }
    private _qrCode: string;
    get qrCode() {
        return this._qrCode ?? "";
    }

    get botID() {
        return this._data?.data?._id;
    }

    private _cacheSubscription: CacheSubscriptionListModel;

    constructor(
        private _matDialog: MatDialog,
        private _matDialogRef: MatDialogRef<QrCodePaymentComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: { calculate: CalculatePrice; data: BotProfile },
        private _subscriptionService: SubscriptionService,
        private _cookie: CacheCookieService,
        private _snackbar: SnackBarAlertService
    ) {
        this._subscription = new Subscription();
        this.$interval = new Subject();
        this._cacheSubscription = [];
    }

    private _initialData = (): void => {
        this._product = this.botProfile?.current_plan?.plan;
    };

    ngOnInit(): void {
        this._subscription.add(this._matDialogRef.backdropClick().subscribe(() => this.onClose()));
        this._botProfile = this._data?.data;
        this._initialData();
        this._confirmationSubsctiption();
    }

    ngOnDestroy(): void {
        if (!!this._subscriptionID) this._subscriptionService.$cancelSubscription(this._subscriptionID, this.botID).subscribe(() => {});

        this._subscription.unsubscribe();
    }

    private _confirmationSubsctiption = (): void => {
        const _c_sub = this._cookie.decryptGet("_c_sub");
        this._cacheSubscription = !!_c_sub ? _c_sub : [];

        this._subscription.add(
            this._subscriptionService
                .$getSubscriptionPlan(this._data.calculate.calculate_price, this.botID)
                .pipe(
                    tap((response) => {
                        this._subscriptionID = response;

                        const _c: CacheSubscriptionModel = { bot_id: this.botID, subscription_id: this._subscriptionID, create_date: new Date().getTime() };
                        this._cookie.encryptSet("_c_sub", [...this._cacheSubscription, _c]);
                    }),
                    switchMap((_) => {
                        return this._subscriptionService.$confirmSubscription(this._subscriptionID, this.botID).pipe(tap((_) => this._$validatePaymentStatus()));
                    })
                )
                .subscribe({
                    next: (response) => {
                        this._qrCode = response.qr_code;
                    },
                    error: (error) => {
                        console.error(error);
                        this._snackbar.open("error", getI18nState(I18nState.GLOBAL_ERR_MSG));
                        this._matDialogRef.close();
                    },
                })
        );
    };

    private _$validatePaymentStatus = (): void => {
        this._subscription.add(
            interval(5000)
                .pipe(
                    tap((_) => this._validatePaymentStatus()),
                    takeUntil(this.$interval)
                )
                .subscribe((_) => {})
        );
    };

    private _validatePaymentStatus = (): void => {
        this._subscription.add(
            this._subscriptionService.$subscriptionCheckStatus(this._subscriptionID, this.botID).subscribe({
                next: (response) => {
                    if (response.status == "success") {
                        this.$interval.next();
                        this._subscription.add(
                            this._matDialog
                                .open(CheckStatusPaymentComponent, {
                                    data: {
                                        data: this._data.data,
                                        days: this._data.calculate.calculate_price.day_amount,
                                        invoice: response.invoice,
                                        subscription_id: this._subscriptionID,
                                        status: "success",
                                    },
                                })
                                .afterClosed()
                                .subscribe((result) => {
                                    this._subscriptionID = null;
                                    this._matDialogRef.close(result);
                                })
                        );
                    }
                },
                error: () => {
                    this._subscription.add(
                        this._matDialog
                            .open(CheckStatusPaymentComponent, {
                                data: {
                                    data: this._data.data,
                                    days: this._data?.calculate.calculate_price.day_amount,
                                    invoice: null,
                                    subscription_id: this._subscriptionID,
                                    status: "error",
                                },
                            })
                            .afterClosed()
                            .subscribe(() => {
                                this._matDialogRef.close();
                            })
                    );
                },
            })
        );
    };

    private async _safeClose(): Promise<boolean> {
        const confirmResult = await this._matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    state: "delete",
                    icon: "info",
                    title: $localize`:@@confirm-close-qr-code-dialog:Confirm close QR Code dialog?`,
                    description: WARNING_MSG,
                    submitLabel: $localize`:@@sure:Sure`,
                    cancelLabel: $localize`:@@cancel:Cancel`,
                },
            })
            .afterClosed()
            .toPromise();

        return confirmResult;
    }

    async onClose(): Promise<void> {
        const confirmation = await this._safeClose();
        if (confirmation == undefined) return;
        if (confirmation) this._matDialogRef.close();
    }
}
