import { Directive, OnInit, Renderer2, ElementRef, Input, HostListener, EventEmitter } from "@angular/core";
import { timer } from "rxjs";
import { BnInputDirective } from "@shared/directives/forms-element/bn-input.directive";

@Directive({
    selector: "textarea[bnTextareaAutosize] textarea[bnInput]",
})
export class BnTextareaAutosizeDirective implements OnInit {
    @HostListener("focus", ["$event"]) focus = ($event: any) => {
        this._setMaxContentHeight($event);
    };
    @HostListener("blur", ["$event"]) blur = (_: any) => {
        if (!this.bnAutosizeFixHeight) {
            this._minHeight = (this._formFieldSize === "large" ? 30 : this._formFieldSize === "small" ? 20 : 24) * parseInt(this.bnAutosizeMinRows.toString());
            this._height = this._minHeight;
            this._renderer.setStyle(this.ele.nativeElement, "min-height", `${this._minHeight}px`);
            this._renderer.setStyle(this.ele.nativeElement, "height", `${this._height}px`);
            this._renderer.setStyle(this.ele.nativeElement, "line-height", `${this._lineHeight}px`);
        }
    };
    @HostListener("keyup", ["$event"]) keyup = ($event: any) => {
        if ($event.altKey && $event.keyCode == 13) {
            $event.target.value = $event.target.value + "\r\n";
            this._bnInput.setValue($event.target.value);
        }
        this._setMaxContentHeight($event);

        timer(100).subscribe((_) => {
            this._setMaxContentHeight($event);
        });
    };

    @Input() bnAutosizeFixHeight: boolean;
    @Input() bnAutosizeMinRows: number | string;
    @Input() bnAutosizeMaxRows: number | string;

    private _formField: HTMLElement;
    private _formFieldSize: "small" | "default" | "large";

    private _height: number;
    private _lineHeight: number;
    private _minHeight: number;
    private _maxHeight: number;

    onChangeHeight: EventEmitter<void> = new EventEmitter<void>();

    constructor(private ele: ElementRef, private _renderer: Renderer2, private _bnInput: BnInputDirective) {
        this._renderer.addClass(this.ele.nativeElement, "bn-textarea-autosize");
        this._renderer.setAttribute(this.ele.nativeElement, "rows", "1");
    }

    ngOnInit() {
        this._formField = this.ele.nativeElement.parentElement.parentElement.parentElement.parentElement;
        this._formFieldSize = this._checkFormFieldSize(this._formField);
        this._initTextareaHeight(this._formFieldSize, parseInt(this.bnAutosizeMinRows.toString()), parseInt(this.bnAutosizeMaxRows.toString()));
    }

    private _checkFormFieldSize = (_formField: HTMLElement): "small" | "default" | "large" => {
        switch (true) {
            case _formField.classList.contains("bn-form-large"):
                return "large";
            case _formField.classList.contains("bn-form-small"):
                return "small";
            default:
                return "default";
        }
    };

    private _initTextareaHeight = (_formSize: "small" | "default" | "large", minRow: number, maxRow: number): void => {
        this._height = _formSize === "large" ? 30 : _formSize === "small" ? 20 : 24;
        this._lineHeight = this._height;
        this._minHeight = this._height;
        this._renderer.setStyle(this.ele.nativeElement, "height", `${this._height}px`);

        if (minRow) {
            this._minHeight = (_formSize === "large" ? 30 : _formSize === "small" ? 20 : 24) * minRow;
            this._height = this._minHeight;
            this._renderer.setStyle(this.ele.nativeElement, "min-height", `${this._minHeight}px`);
            this._renderer.setStyle(this.ele.nativeElement, "height", `${this._height}px`);
            this._renderer.setStyle(this.ele.nativeElement, "line-height", `${this._lineHeight}px`);
        }

        if (maxRow) {
            this._maxHeight = (_formSize === "large" ? 30 : _formSize === "small" ? 20 : 24) * maxRow;
            this._renderer.setStyle(this.ele.nativeElement, "max-height", `${this._maxHeight}px`);
        }

        if (this.bnAutosizeFixHeight) this._setMaxContentHeight();
    };

    private _autoSizeTextareaHeight = (scrollHeight: number): void => {
        let cacheHeight = this._height;

        if (scrollHeight > this._height && scrollHeight >= this._minHeight) {
            this._height = scrollHeight;
        }

        if (scrollHeight < this._height) {
            this._height = scrollHeight;
        }

        this._renderer.setStyle(this.ele.nativeElement, "height", `${this._height}px`);

        if (cacheHeight !== this._height) this.onChangeHeight.emit();
    };

    private _setMaxContentHeight = ($event?: any): void => {
        this._renderer.setStyle(this.ele.nativeElement, "height", `auto`);
        const scrollHeight = !!$event ? $event.target.scrollHeight : this.ele.nativeElement.scrollHeight;
        this._autoSizeTextareaHeight(scrollHeight);
    };
}
