import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
    name: "dateDurationFormat",
})
export class DateDurationFormatPipe implements PipeTransform {
    transform(value: moment.Moment, format: string, remain?: boolean, fromNow?: boolean): string {
        const dateObject = value.toObject();
        let year = dateObject?.years ?? 0;
        let month = dateObject?.months ?? 0;
        let day = dateObject?.date ?? 0;
        let hour = dateObject?.hours ?? 0;

        if (fromNow) {
            const diff = value.diff(moment(), "hours");
            const dObject = moment(0).add(diff, "hours").toObject();
            year = dObject?.years ?? 0;
            month = dObject?.months ?? 0;
            day = dObject?.date ?? 0;
            hour = dObject?.hours ?? 0;
        }

        if (remain) {
            if (year >= 1970) year = year - 1970;
            if (day >= 1) day = day - 1;
            if (hour >= 7) hour = hour - 7;
        }

        format = format
            .replace(/h/g, !!hour ? ` ${hour} hours ` : ``)
            .replace(/D/g, !!day ? ` ${day} days ` : ``)
            .replace(/M/g, !!month ? ` ${month} months ` : ``)
            .replace(/Y/g, !!year ? ` ${year} years ` : ``);
        format = format.trim();
        return !!format.length ? format : "-";
    }
}
