import { Injectable } from "@angular/core";
import { GapiSession } from "./gapi.session.service";
import { FileSession } from "./file.session.service";

@Injectable()
export class AppSession {
    constructor(private _gapiSession: GapiSession, private _fileSession: FileSession) {}

    get File(): FileSession {
        return this._fileSession;
    }

    get Gapi(): GapiSession {
        return this._gapiSession;
    }
}
