import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "roundDemical",
})
export class RoundDemicalPipe implements PipeTransform {
    transform(value: number | string, digit?: number): number {
        if (typeof value == "string") value = parseFloat(value);
        return parseFloat(value.toFixed(digit ?? 0));
    }
}
