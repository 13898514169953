import { Component, OnInit, OnDestroy, Inject, Renderer2, ViewChild, ElementRef } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Platform } from "@angular/cdk/platform";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { BehaviorSubject, Observable, Subscription, timer } from "rxjs";
import { debounceTime, filter, map, tap } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { LocalfileService } from "@core/services/localfile/localfile.service";
import { AuthService } from "@core/services/authentication/auth.service";
import { NotificationMessageService } from "@core/http/notification/notification-message.service";
import { LocalService } from "@core/services/cache/local.service";
import { CookieService } from "@core/services/cache/cookie.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    private _subscription: Subscription;

    awaitRegister: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    awaitPlatform: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    awaitWebchatRoute: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private _htmlMessage: string;
    get htmlMessage() {
        return this._htmlMessage ? this._htmlMessage : null;
    }

    get pageContainerHeight() {
        return this.htmlMessage ? "calc(100% - 41px)" : null;
    }

    // FAQ Customer chat
    @ViewChild("customerChat") private _customerChat: ElementRef;
    get customerChat() {
        return this._customerChat?.nativeElement as HTMLDivElement;
    }

    isLoggedIn$: Observable<boolean>;

    get openChat() {
        return !!this.customerChat?.classList?.contains("open");
    }
    ////////////////////

    constructor(
        private _router: Router,
        private _title: Title,
        private _matIconRegistry: MatIconRegistry,
        private _domSanitizer: DomSanitizer,
        private _platform: Platform,
        private _swUpdate: SwUpdate,
        private _renderer: Renderer2,
        @Inject(DOCUMENT) private _document: Document,
        private _localfileService: LocalfileService,
        private _authService: AuthService,
        private _notificationService: NotificationMessageService,
        private _local: LocalService,
        private _cookie: CookieService
    ) {
        this._subscription = new Subscription();
        this.isLoggedIn$ = new BehaviorSubject<boolean>(false);

        this._setTitleFromEvent();

        this._addGAScript();
        this._detectPlatform();
        this._loadIconList();
        // this._initialFAQ();
        this._getNotificationMessage();
    }

    ngOnInit(): void {
        if (this._swUpdate.isEnabled) {
            this._swUpdate.versionUpdates
                .pipe(
                    filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"),
                    map((evt) => ({
                        type: "UPDATE_AVAILABLE",
                        current: evt.currentVersion,
                        available: evt.latestVersion,
                    }))
                )
                .subscribe((evt) => {
                    console.warn("Current version is:: ", evt.current.hash);
                    console.warn("Availabel version is:: ", evt.available.hash);

                    this._swUpdate.activateUpdate().then((_) => {
                        console.warn("Processing update version");
                        this._local.clearAll();
                        this._cookie.clearAll();
                        window.location.reload();
                    });
                });

            this._swUpdate.checkForUpdate().then(
                () => {
                    console.warn("Checking for updates");
                },
                (error) => {
                    console.error("Could not check for app updates", error);
                }
            );
        }
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    onToggleChat = (): void => {
        switch (!this.openChat) {
            case true:
                this._renderer.addClass(this._customerChat.nativeElement, "open");
                this._renderer.removeClass(this._customerChat.nativeElement, "bn_customer_chat_bounce_out");
                this._renderer.addClass(this._customerChat.nativeElement, "bn_customer_chat_bounce_in");

                this._router.navigate([
                    {
                        outlets: {
                            chat: [
                                "customer-chat",
                                {
                                    bot_id: env?.faqID,
                                },
                            ],
                        },
                    },
                ]);
                break;
            default:
                this._renderer.removeClass(this._customerChat.nativeElement, "bn_customer_chat_bounce_in");
                this._renderer.addClass(this._customerChat.nativeElement, "bn_customer_chat_bounce_out");

                this._subscription.add(
                    timer(300).subscribe((_) => {
                        this._renderer.removeClass(this._customerChat.nativeElement, "open");
                        this._router.navigate([{ outlets: { chat: null } }]);
                    })
                );
        }
    };

    private _setTitleFromEvent = (): void => {
        this._subscription.add(
            this._router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((_) => {
                this.awaitWebchatRoute.next(this._router.url.includes("/customer-chat"));
                let title = this._getTitle(this._router.routerState, this._router.routerState.root).join("-");
                this._title.setTitle(title);
            })
        );
    };

    private _addGAScript = (): void => {
        const gaUrlScript = this._renderer.createElement("script");
        gaUrlScript.async = "";
        gaUrlScript.src = `https://www.googletagmanager.com/gtag/js?id=${env.gaID}`;
        this._renderer.appendChild(this._document.head, gaUrlScript);

        const gaFuncScript = this._renderer.createElement("script");
        gaUrlScript.text = `
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag("js", new Date()); gtag("config", "${env.gaID}");
        `;
        this._renderer.appendChild(this._document.head, gaFuncScript);
    };

    private _detectPlatform = (): void => {
        const isWebchatRoute = window.location.href.includes("customer-chat");
        const isMobile = this._platform.IOS || this._platform.ANDROID;
        this.awaitPlatform.next(!isWebchatRoute && isMobile ? false : true);
    };

    private _loadIconList = (): void => {
        this._subscription.add(
            this._localfileService.getListIconName().subscribe((response) => {
                response.forEach((name) => {
                    this._matIconRegistry.addSvgIcon(name.replace(".svg", ""), this._domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon-svg/${name}`));
                });

                this.awaitRegister.next(true);
            })
        );
    };

    // private _initialFAQ = (): void => {
    //     this.isLoggedIn$ = this._authService.isLoggedIn$.pipe(
    //         debounceTime(300),
    //         tap((_) => this._router.navigate([{ outlets: { chat: null } }], { queryParamsHandling: "merge" })),
    //         filter((state) => (env?.production ? false : state))
    //     );
    // };

    private _getTitle = (state: any, parent: ActivatedRoute): Array<string> => {
        let data: Array<string> = [];

        if (parent && parent.snapshot.data && parent.snapshot.data.title) data.push(parent.snapshot.data.title);

        if (state && parent) data.push(...this._getTitle(state, state.firstChild(parent)));

        data = data.filter((d, index, data) => index == data.indexOf(d));
        return data;
    };

    private _getNotificationMessage = (): void => {
        this._subscription.add(this._notificationService.$getNotificationMessage().subscribe((response) => (this._htmlMessage = response)));
    };

    onCloseNotificationMessage = (): void => {
        this._htmlMessage = null;
    };
}
