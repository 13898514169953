import { Deserializable } from "../deserializable.model";

export class AudioObject implements Deserializable {
    _id: string;
    name: string;
    url: string;
    duration: number;

    private _cacheFile: { file: File; binary: string };

    get hasFile(): boolean {
        return this._cacheFile ? true : false;
    }

    get cacheFile(): File {
        return this._cacheFile.file;
    }

    deserialize(input: any) {
        return Object.assign(this, input);
    }

    convertObjectToServer = (): any => {
        return {
            _id: this._id,
            type: "audio",
            object_name: this.name.includes("AUD_") ? this.name : `AUD_${this.name}`,
            list_object: [
                {
                    audio_url: this.url,
                    audio_duration: this.duration,
                },
            ],
        };
    };

    onChangeFile = (file: File, binary: string): void => {
        this._cacheFile = { file: file, binary: binary };
    };
}
