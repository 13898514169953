import { Deserializable } from '../deserializable.model'

export class ImageObject implements Deserializable {
    _id: string
    name: string
    url: string

    private _cacheFile: { file: File, binary: string }

    get hasFile(): boolean {
        return this._cacheFile ? true : false
    }

    get cacheFile(): File {
        return this._cacheFile.file
    }

    deserialize(input: any) {
        return Object.assign(this, input)
    }

    convertObjectToServer = (): any => {
        return {
            _id: this._id,
            type: 'image',
            object_name: this.name.includes('IMG_') ? this.name : `IMG_${this.name}`,
            list_object: [{
                original_url: this.url,
                preview_image_url: this.url
            }]
        }
    }

    onChangeImage = (file: File, binary: string): void => {
        this._cacheFile = { file: file, binary: binary }
    }
}
