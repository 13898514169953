import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { BotProfileService } from "@core/services/authentication/bot-profile.service";

@Injectable()
export class BotGuard implements CanActivate {
    canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        switch (this._botProfile?.isSelectedBOT()) {
            case true:
                return true;
            default:
                this._router.navigate(["/manage"]);
                return false;
        }
    }

    constructor(private _botProfile: BotProfileService, private _router: Router) {
        this._botProfile.verifyBotProfile();
    }
}
