import { NgModule, Optional, SkipSelf, SecurityContext } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@shared/shared.module";
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatBadgeModule } from "@angular/material/badge";
import { LayoutModule } from "@angular/cdk/layout";
import { CookieService } from "ngx-cookie-service";
import { HighlightModule, HIGHLIGHT_OPTIONS } from "ngx-highlightjs";
import { MarkdownModule } from "ngx-markdown";

// Components
import { NavigationBarComponent } from "./components/navigation-bar/navigation-bar.component";
import { SideBarComponent } from "./components/side-bar/side-bar.component";
import { ManageBotLayoutComponent } from "./layouts/manage-bot-layout/manage-bot-layout.component";
import { MainBotLayoutComponent } from "./layouts/main-bot-layout/main-bot-layout.component";

// Services
import { LocalfileService } from "./services/localfile/localfile.service";
import { AuthService, UserProfileService, BotProfileService } from "./services/authentication";
import { LocalService, CacheCookieService } from "./services/cache";
import { DetectExtensionService } from "./services/extensions/detect-extension.service";

// Guard & Interceptor
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { LoginGuard } from "./guards/login/login.guard";
import { BotGuard } from "./guards/bot/bot.guard";
import { PermissionGuard } from "./guards/permission/permission.guard";
import { CanDeactivateGuard } from "./guards/deactivate/can-deactivate.guard";

// Request Services
import { HttpRequest, CacheService } from "./http";
import { LoginRequestService, ChangePasswordService } from "./http/user";
import { ManageBotRequestService, PermissionBotService, SubscriptionService, BillingInformationService } from "./http/bot";
import { ObjectService } from "./http/object/object.service";
import {
    ManageApiObjectService,
    ManageButtonObjectService,
    ManageImageObjectService,
    ManageQrpObjectService,
    ManageCrsObjectService,
    ManageRmObjectService,
    ManageFxObjectService,
    ManageDlObjectService,
    ManagePayloadObjectService,
    ManageParamsService,
    ManageAudioObjectService,
    ManagePersistentObjectService,
} from "./http/object";
import { ManageEntitiesService } from "./http/entities/manage-entities.service";
import { UploadService } from "./http/s3/upload.service";
import { TrainBotService, PredefineIntentService } from "./http/nlp";
import { LivechatService, SocketService, AssetsService } from "./http/livechat";
import { FacebookService, LineService, Ex10Service } from "./http/connection";
import { DashboardService } from "./http/dashboard/dashboard.service";
import { CustomerChatService } from "./http/customer-chat/customer-chat.service";
import { CustomerSocketService } from "./http/customer-chat/customer-socket.service";
import { NotificationMessageService } from "./http/notification/notification-message.service";
import { LeadGenService } from "./http/smart-lead";
import { MarketPlaceService } from "./http/market-place/market-place.service";
import { AutomateTestService } from "./http/automate-test/automate-test.service";

// Google API
import { AppContext } from "./services/gapi/app.context.service";
import { GapiSession, AppSession, FileSession } from "./services/gapi/sessions";
import { AppRepository, FileRepository } from "./services/gapi/repositories";
import { AutoTestButtonComponent } from "./components/auto-test-button/auto-test-button.component";

@NgModule({
    declarations: [NavigationBarComponent, SideBarComponent, ManageBotLayoutComponent, MainBotLayoutComponent, AutoTestButtonComponent],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        HttpClientModule,
        MatSidenavModule,
        MatBadgeModule,
        LayoutModule,
        HighlightModule,
        MarkdownModule.forRoot({
            loader: HttpClient,
            sanitize: SecurityContext.NONE,
        }),
    ],
    providers: [
        CookieService,
        LocalfileService,
        AuthService,
        UserProfileService,
        BotProfileService,
        LocalService,
        CacheCookieService,
        DetectExtensionService,
        LoginGuard,
        BotGuard,
        PermissionGuard,
        CanDeactivateGuard,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        HttpRequest,
        CacheService,
        LoginRequestService,
        ChangePasswordService,
        ManageBotRequestService,
        PermissionBotService,
        SubscriptionService,
        BillingInformationService,
        ObjectService,
        ManageApiObjectService,
        ManageButtonObjectService,
        ManageImageObjectService,
        ManageQrpObjectService,
        ManageCrsObjectService,
        ManageRmObjectService,
        ManageFxObjectService,
        ManageDlObjectService,
        ManagePayloadObjectService,
        ManageParamsService,
        ManageAudioObjectService,
        ManagePersistentObjectService,
        ManageEntitiesService,
        UploadService,
        TrainBotService,
        PredefineIntentService,
        LivechatService,
        FacebookService,
        LineService,
        Ex10Service,
        DashboardService,
        SocketService,
        AssetsService,
        CustomerChatService,
        CustomerSocketService,
        NotificationMessageService,
        LeadGenService,
        MarketPlaceService,
        AutomateTestService,
        AppContext,
        GapiSession,
        AppSession,
        FileSession,
        AppRepository,
        FileRepository,
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
                coreLibraryLoader: () => import("highlight.js/lib/core.js"),
                languages: {
                    javascript: () => import("highlight.js/lib/languages/javascript"),
                    xml: () => import("highlight.js/lib/languages/xml"),
                },
            },
        },
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() core: CoreModule) {
        if (core) {
            throw new Error("You should import core module only in the root module");
        }
    }
}
