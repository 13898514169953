import { Component } from "@angular/core";
import { environment as env } from "@env/environment";

@Component({
    selector: "app-main-bot-layout",
    templateUrl: "./main-bot-layout.component.html",
    styleUrls: ["./main-bot-layout.component.scss"],
})
export class MainBotLayoutComponent {
    get featureAutomateTest() {
        return env?.featureFlags?.featureAutomateTest;
    }

    constructor() {}
}
