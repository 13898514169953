import { Deserializable } from "../deserializable.model";

export class SocketMessage implements Deserializable {
    sender: string;
    message: MessageModel;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    get messageTemplate() {
        switch (this.message.type) {
            case "text":
                return { type: this.messageTemplateType, message: this.messageTemplateValue };
            case "image":
                return { type: this.messageTemplateType, url: this.messageTemplateValue };
            case "carousel":
                return { type: this.messageTemplateType, template: this.messageTemplateValue };
            case "flex":
            case "buttons":
                return { type: this.messageTemplateType, template: [this.messageTemplateValue] };
            case "quick_reply":
                return { type: this.messageTemplateType, message: this.messageTemplateValue, quickReply: this.message.quick_reply_choices };
            default:
                return { type: this.messageTemplateType, template: [this.messageTemplateValue] };
        }
    }

    get messageTemplateType() {
        switch (this.message.type) {
            case "text":
                return "message";
            case "image":
                return "image";
            case "carousel":
            case "buttons":
                return "template";
            case "flex":
                return "flex";
            case "quick_reply":
                return "message";
            default:
                return this.message.type;
        }
    }

    get messageTemplateValue() {
        switch (this.message.type) {
            case "text":
                return this.message.text;
            case "image":
                return this.message.image.preview_img_url;
            case "video":
                return this.message.video.video_url;
            case "audio":
                return this.message.audio.audio_url;
            case "file":
                return this.message.file.file_url;
            case "carousel":
                return this.message.carousel_cards.map((crs) => {
                    return { url: crs.image_url, title: crs.title, subtitle: crs.subtitle, buttons: crs.buttons };
                });
            case "buttons":
                return { title: this.message.button_title, buttons: this.message.button_actions };
            case "flex":
                return this.message.flex_contents;
            case "quick_reply":
                return this.message.text;
            default:
                return this.message;
        }
    }
}

export interface MessageModel {
    type: "text" | "quick_reply" | "location" | "image" | "video" | "audio" | "file" | "flex" | "carousel" | "buttons";
    text?: string;
    location?: { address: string; latitude: Float64Array; longtitude: Float64Array };
    image?: { img_url: string; preview_img_url: string };
    video?: { video_url: string };
    file?: { file_url: string };
    audio?: { audio_url: string };
    quick_reply_choices?: Array<{ type: string; label: string; data: string }>;
    carousel_cards?: Array<{ image_url: string; title: string; subtitle: string; buttons: Array<{ type: string; label: string; data: string }> }>;
    button_title?: string;
    button_actions?: Array<{ type: string; label: string; data: string }>;
    flex_contents?: { type: string; contents: Array<any>; body: Array<any>; footer: Array<any>; hero: Array<any> };
}
