import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription, timer } from "rxjs";
import { SubscriptionService } from "@core/http/bot";
import { BotProfile } from "@core/models/bot/bot-profile.model";
import { SnackBarAlertService } from "@shared/services/snack-bar-alert/snack-bar-alert.service";
import { I18nState, getI18nState } from "@shared/translation/i18n-state";

interface CheckStatusModalData {
    data: BotProfile;
    days: number;
    invoice: boolean;
    subscription_id: string;
    status: string;
}

@Component({
    selector: "app-check-status-payment",
    templateUrl: "./check-status-payment-dialog.component.html",
    styleUrls: ["./check-status-payment-dialog.component.scss"],
})
export class CheckStatusPaymentComponent implements OnInit, OnDestroy {
    private _subscription: Subscription;

    get success() {
        return this._data.status == "success";
    }

    get price() {
        return this._data.data?.current_plan?.plan?.cost_per_day * this._data?.days ?? 0;
    }

    get remainDays() {
        return Math.round(this._data?.data?.current_plan?.remainDays + this._data?.days ?? 0);
    }

    get invoice() {
        return this._data?.invoice;
    }

    constructor(
        private _matDialogRef: MatDialogRef<CheckStatusPaymentComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: CheckStatusModalData,
        private _subscriptionService: SubscriptionService,
        private _snackbar: SnackBarAlertService
    ) {
        this._subscription = new Subscription();
    }

    ngOnInit(): void {
        //this._subscription.add(
        //timer(1000).subscribe(() => {
        //if (!this.invoice) this._matDialogRef.close();
        //})
        //);
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    onExportReceipt(): void {
        this._subscription.add(
            this._subscriptionService.$getReceiptPayment(this._data?.subscription_id).subscribe(
                (response) => {
                    const url = window.URL.createObjectURL(response);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `receipt_${new Date().getTime()}.pdf`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                },
                (error) => {
                    console.error(error);
                    this._snackbar.open("error", getI18nState(I18nState.GLOBAL_ERR_MSG));
                }
            )
        );
    }

    onClose = (): void => {
        this._matDialogRef.close(true);
    };
}
