import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { UserChatLogModel, SourceModel, NoteModel } from "@core/models/livechat";
import { RoomProfile } from "@core/models/livechat/room-profile.model";
import { NoteMessage } from "@core/models/livechat/note-message.model";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

interface createNoteBody {
    note: string;
    update_by: string;
}

interface updateNoteBody {
    id: string;
    note: string;
    update_by: string;
}

interface updateTagBody {
    bot_id: string;
    name: string;
    mapping_intent: Array<string>;
}

@Injectable()
export class AssetsService {
    constructor(private _http: HttpRequest) {}

    $getChatRoomProfile = (source: SourceModel): Observable<RoomProfile> => {
        const url = new URL(`${API_GATEWAYS_URL}livechat/conversations/profile`);
        if (!!source) {
            url.searchParams.set("source_id", source.source_id);
            url.searchParams.set("source_channel", source.source_channel);
            url.searchParams.set("source_type", source.source_type);
            url.searchParams.set("source_app_id", source.source_app_id);
        }

        return this._http
            .get<RoomProfile>({
                url: url.toString(),
            })
            .pipe(
                retry(genericRetry),
                map((response) => new RoomProfile().deserialize(response)),
                catchError((error) => throwError(() => error))
            );
    };

    $updateChatRoomProfile = (source: SourceModel, body: RoomProfile): Observable<void> => {
        return this._http
            .put<void>({
                url: `${API_GATEWAYS_URL}livechat/conversations/profile`,
                body: {
                    ...source,
                    ...body.value,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getChatRoomTags = (source: SourceModel): Observable<Array<string>> => {
        const url = new URL(`${API_GATEWAYS_URL}livechat/conversations/tags`);
        if (!!source) {
            url.searchParams.set("source_id", source.source_id);
            url.searchParams.set("source_channel", source.source_channel);
            url.searchParams.set("source_type", source.source_type);
            url.searchParams.set("source_app_id", source.source_app_id);
        }

        return this._http
            .get<Array<string>>({
                url: url.toString(),
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $setChatRoomTags = (source: SourceModel, tags: Array<string>): Observable<void> => {
        return this._http
            .put<void>({
                url: `${API_GATEWAYS_URL}livechat/conversations/tags`,
                body: {
                    ...source,
                    tags: tags,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getTags = (): Observable<Array<string>> => {
        return this._http
            .get<Array<{ name: string }>>({
                url: `${API_GATEWAYS_URL}livechat/tag`,
            })
            .pipe(
                retry(genericRetry),
                map((response) => response.map((res) => res.name)),
                catchError((error) => throwError(() => error))
            );
    };

    $getTagsForMapIntent = (): Observable<Array<updateTagBody>> => {
        return this._http
            .get<any>({
                url: `${API_GATEWAYS_URL}livechat/tag`,
            })
            .pipe(
                retry(genericRetry),
                map((response) => response),
                catchError((error) => throwError(() => error))
            );
    };

    $getAutoTagStatus = (bot_id: string): Observable<{ active: boolean; bot_id: string }> => {
        return this._http.get<any>({ url: `${API_GATEWAYS_URL}livechat/tag-status?bot_id=${bot_id}` }).pipe(
            retry(genericRetry),
            map((response) => response),
            catchError((error) => throwError(() => error))
        );
    };

    $updateTagsForMapIntent = (body: Array<updateTagBody>): Observable<{ message: string; status: string }> => {
        return this._http
            .put<any>({
                url: `${API_GATEWAYS_URL}livechat/mapping-tag`,
                body: {
                    data: body,
                },
            })
            .pipe(
                retry(genericRetry),
                map((response) => response),
                catchError((error) => throwError(() => error))
            );
    };

    $removeSelectedTag = (name: Array<string>, bot_id: string): Observable<any> => {
        return this._http
            .delete<any>({
                url: `${API_GATEWAYS_URL}livechat/mapping-tag`,
                body: {
                    bot_id: bot_id,
                    name: name,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $updateStatusAutoTag = (status: boolean): Observable<{ status: string }> => {
        return this._http
            .put<any>({
                url: `${API_GATEWAYS_URL}livechat/tag-status`,
                body: {
                    active: status,
                },
            })
            .pipe(
                retry(genericRetry),
                map((response) => response),
                catchError((error) => throwError(() => error))
            );
    };

    $createTags = (name: string, mapping_intent: Array<string>): Observable<any> => {
        return this._http
            .post<any>({
                url: `${API_GATEWAYS_URL}livechat/tag`,
                body: {
                    name: name,
                    mapping_intent: mapping_intent,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $removeTags = (name: string): Observable<any> => {
        return this._http
            .delete<any>({
                url: `${API_GATEWAYS_URL}livechat/tag`,
                body: {
                    name: name,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $createChatRoomNote = (source: SourceModel, note: createNoteBody): Observable<NoteMessage> => {
        return this._http
            .post<NoteModel>({
                url: `${API_GATEWAYS_URL}livechat/note`,
                body: {
                    ...source,
                    ...note,
                },
            })
            .pipe(
                retry(genericRetry),
                map((response) => new NoteMessage().deserialize(response)),
                catchError((error) => throwError(() => error))
            );
    };

    $updateChatRoomNote = (source: SourceModel, note: updateNoteBody): Observable<void> => {
        return this._http
            .put({
                url: `${API_GATEWAYS_URL}livechat/note`,
                body: {
                    ...source,
                    ...note,
                },
            })
            .pipe(
                retry(genericRetry),
                map(() => null),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteChatRoomNote = (source: SourceModel, _id: string): Observable<void> => {
        return this._http
            .delete({
                url: `${API_GATEWAYS_URL}livechat/note`,
                body: {
                    ...source,
                    id: _id,
                },
            })
            .pipe(
                retry(genericRetry),
                map(() => null),
                catchError((error) => throwError(() => error))
            );
    };

    $getUserAssets = (source: SourceModel, msg_type: string): Observable<Array<UserChatLogModel>> => {
        let params = new HttpParams();
        if (!!source) {
            params = params.set("source_id", source?.source_id);
            params = params.set("source_channel", source?.source_channel);
            params = params.set("source_app_id", source?.source_app_id);
            params = params.set("source_type", source?.source_type);
        }

        if (!!msg_type) params = params.set("msg_type", msg_type);

        return this._http
            .get<Array<UserChatLogModel>>({
                url: `${API_GATEWAYS_URL}livechat/messages/type`,
                params: params,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };
}
