import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { Observable, throwError } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { LocalService } from "@core/services/cache/local.service";
import { ButtonObject } from "@core/models/object/button-object.model";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

interface ButtonObjectResponse {
    _id: string;
    object_name: string;
    type: string;
    list_object: Array<{
        title: string;
        action: Array<{
            label: string;
            data: string;
            type: string;
        }>;
    }>;
}

@Injectable()
export class ManageButtonObjectService {
    get url() {
        return `${API_GATEWAYS_URL}platform-go/object/button`;
    }

    constructor(private _http: HttpRequest, private local: LocalService) {}

    $getButtonObjectList = (): Observable<Array<ButtonObject>> => {
        return this._http
            .get<Array<ButtonObjectResponse>>({
                url: this.url.toString(),
                cacheMins: 1,
            })
            .pipe(
                retry(genericRetry),
                map((response) =>
                    response.map((res) =>
                        new ButtonObject().deserialize({
                            _id: res._id,
                            name: res.object_name,
                            title: res.list_object[0]?.title,
                            buttons: res.list_object[0]?.action.map((a) => {
                                return {
                                    label: a.label,
                                    type: a.type == "postback" ? "message" : a.type == "web_url" ? "url" : "phone",
                                    message: a.data,
                                };
                            }),
                        })
                    )
                ),
                catchError((error) => throwError(() => error))
            );
    };

    $insertButtonObject = (body: ButtonObject): Observable<{ _id: string }> => {
        const request = body.convertObjectToServer();
        delete request._id;
        return this._http
            .post<{ _id: string }>({
                url: this.url.toString(),
                body: request,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $duplicateButtonObject = (_id: string): Observable<ButtonObject> => {
        return this._http
            .post<{ data: ButtonObjectResponse }>({
                url: `${API_GATEWAYS_URL}platform-go/object/duplicate`,
                body: { _id: _id },
            })
            .pipe(
                retry(genericRetry),
                map((response) => {
                    this.clearCacheData();
                    return new ButtonObject().deserialize({
                        _id: response?.data?._id,
                        name: response?.data?.object_name,
                        title: response?.data?.list_object[0]?.title,
                        buttons: response?.data?.list_object[0]?.action.map((a) => {
                            return {
                                label: a.label,
                                type: a.type == "postback" ? "message" : a.type == "web_url" ? "url" : "phone",
                                message: a.data,
                            };
                        }),
                    });
                }),
                catchError((error) => throwError(() => error))
            );
    };

    $updateButtonObject = (body: ButtonObject): Observable<{ msg: string }> => {
        const request = body.convertObjectToServer();
        return this._http
            .put<{ msg: string }>({
                url: this.url.toString(),
                body: request,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteButtonObjectList = (body: Array<ButtonObject>): Observable<{ msg: string }> => {
        return this._http
            .delete<{ msg: string }>({
                url: this.url.toString(),
                body: { _id: body.map((b) => b._id) },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    clearCacheData = (): void => {
        this.local.delete(this.url.toString());
    };
}
