import { Deserializable } from "../deserializable.model";
import { RmObject } from "../object/rm-object.model";

export class RmList implements Deserializable {
    _id: string;
    selected: boolean;
    name: string;
    url: string;
    selected_auto: boolean;
    default: boolean;
    actions: Array<Area>;
    size: Size;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    convertObjectToList(input: RmObject) {
        Object.assign(this, {
            ...input,
            name: input.name.includes("RM_") ? input.name : `RM_${input.name}`,
        });
        this.selected = false;
        return this;
    }

    convertListToObject(): RmObject {
        return new RmObject().deserialize({
            _id: this._id,
            name: this.name,
            url: this.url,
            default: this.default,
            selected_auto: this.selected_auto,
            actions: this.actions,
            size: this.size,
        });
    }
}

interface Size {
    mode: "rectangle" | "square";
    row: number;
    column: number;
}

interface Area {
    action: Action;
    bound: Bound;
}

interface Action {
    label: string;
    type: "message" | "url" | "intent" | "text";
    message: string;
}

interface Bound {
    order: number;
}
