import { Deserializable } from "../deserializable.model";
import { AudioObject } from "../object/audio-object.model";

export class AudioList implements Deserializable {
    _id: string;
    selected: boolean;
    played: boolean;
    name: string;
    url: string;

    deserialize(input: any) {
        Object.assign(this, input);
        this.played = false;
        return this;
    }

    convertObjectToList(input: AudioObject) {
        Object.assign(this, {
            ...input,
            name: input.name.includes("AUD_") ? input.name : `AUD_${input.name}`,
        });
        this.selected = false;
        return this;
    }

    convertListToObject(): AudioObject {
        return new AudioObject().deserialize({
            _id: this._id,
            name: this.name,
            url: this.url,
        });
    }
}
