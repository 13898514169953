import { Deserializable } from "../deserializable.model";
import { ChatRoomProfileModel } from "./response.model";

export class RoomProfile implements Deserializable, ChatRoomProfileModel {
    full_name: string = "";
    first_name: string = "";
    last_name: string = "";
    phone: string = "";
    email: string = "";
    address: string = "";
    country: string = "";
    province: string = "";
    gender: string = "";
    age: number;

    get value() {
        return {
            full_name: this.full_name,
            phone: this.phone,
            email: this.email,
            address: this.address,
            gender: this.gender,
            age: this.age,
        };
    }

    private _onUpdateData(input: RoomProfile): void {
        Object.assign(this, input);
        if (input?.full_name) {
            this.first_name = input?.full_name ?? "";
            this.last_name = "";
            if (input?.full_name.split(" ").length == 2) {
                this.first_name = input?.full_name.split(" ")[0] ?? "";
                this.last_name = input?.full_name.split(" ")[1] ?? "";
            }
        }
        if (input?.address) {
            this.country = "";
            this.province = "";
            if (input?.address.split(", ").length == 2) {
                this.country = input?.address.split(", ")[0] ?? "";
                this.province = input?.address.split(", ")[1] ?? "";
            }
        }
    }

    deserialize(input: any) {
        this._onUpdateData(input);
        return this;
    }

    onTriggerUpdate(data: RoomProfile): void {
        if (!!data?.first_name || !!data?.last_name) this.full_name = `${data?.first_name} ${data?.last_name}`;
        if (!!data?.country || !!data?.province) this.address = `${data.country}, ${data.province}`;
        this.full_name = this.full_name.trim() ?? "";
        this.address = this.address.trim() ?? "";
    }
}

export interface RoomProfile {
    full_name: string;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    address: string;
    country: string;
    province: string;
    gender: string;
    age: number;
}
