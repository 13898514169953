import { Deserializable } from "../deserializable.model";

export class ApiObject implements Deserializable {
    _id: string;
    name: string;
    url: string;
    method: string;
    header: Array<HeaderType>;
    json: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    convertObjectToServer() {
        return {
            _id: this._id,
            type: "api",
            object_name: this.name.includes("API_")
                ? this.name
                : `API_${this.name}`,
            list_object: [
                {
                    api_url: this.url,
                    method: this.method,
                    api_body: this.json,
                    api_header: this.header.reduce((prev, cur) => {
                        prev[cur.name] = cur.value;
                        return prev;
                    }, {}),
                },
            ],
        };
    }
}

class HeaderType implements Deserializable {
    name: string;
    value: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
