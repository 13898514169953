import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { BotProfile } from "@core/models/bot/bot-profile.model";
import { TopUpDialogComponent } from "../top-up-dialog/top-up-dialog.component";

interface notificationUpgradeModel {
    data: BotProfile;
    isFromLiveChat: boolean;
}

@Component({
    selector: "app-notification-upgrade",
    templateUrl: "./notification-upgrade.component.html",
    styleUrls: ["./notification-upgrade.component.scss"],
})
export class NotificationUpgradeComponent implements OnInit, OnDestroy {
    private _subscription: Subscription;

    get isLimited() {
        return this._data?.data?.isLimited;
    }

    get title() {
        return this.isLimited ? $localize`:@@please-upgrade-plan:Please upgrade the chatbot` : $localize`:@@please-top-up:Please top-up`;
        //return this.isLimited ? $localize`:@@please-upgrade-plan:กรุณาอัพเกรดแชทบอท` : $localize`:@@please-top-up:กรุณาเติมวันใช้งาน`;
    }

    get isFromLiveChat() {
        return this._data?.isFromLiveChat
    }

    get description() {
        return this.isLimited
            ? $localize`:@@please-upgrade-plan-desc:The maximum number of users has been reached. Extra users' communications
        can’t be replied to by chatbots. So that chatbots may respond to
        more messages. Upgrade your chatbot as soon as possible.`
            : $localize`:please-top-up-desc:The number of days of use is insufficient. During this time, chatbots will not
        be able to respond to messages. Please top-up your chatbot usage date.`;
        //return this.isLimited
        //? $localize`:@@please-upgrade-plan-desc:จำนวนผู้ใช้เกินลิมิต แชทบอทไม่สามารถตอบข้อความในส่วนของผู้ใช้ ที่เกินเข้ามาได้ เพื่อที่จะให้แชทบอทสามารถตอบผู้ใช้ได้เพิ่มขึ้น กรุณาอัพเกรดแชทบอทของคุณ`
        //: $localize`:please-top-up-desc:จำนวนวันใช้งานหมด แชทบอทจะไม่สามารถตอบข้อความได้ในขณะนี้ กรุณาเติมวันใช้งานแชทบอทของคุณ`;
    }

    notShowChecked: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: notificationUpgradeModel,
        private _matDialogRef: MatDialogRef<NotificationUpgradeComponent>,
        private _matDialog: MatDialog
    ) {
        this._subscription = new Subscription();
    }

    ngOnInit(): void {
        this._detectBackDropClick();
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    private _detectBackDropClick = (): void => {
        this._subscription.add(
            this._matDialogRef.backdropClick().subscribe((_) => {
                this._matDialogRef.close(this.notShowChecked);
            })
        );
    };

    onClose = (): void => {
        this._matDialogRef.close(false);
    };

    onSubmit = (): void => {
        if (this._data.isFromLiveChat) {
            this._subscription.add(
                this._matDialog
                    .open(TopUpDialogComponent, { data: { data: this._data?.data } })
                    .afterClosed()
                    .subscribe(() => {
                        this._matDialogRef.close(true)
                    })
            );
            return;
        }

        this._matDialogRef.close(this.notShowChecked);
    };
}
