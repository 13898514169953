import { Component, Inject, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSliderChange } from "@angular/material/slider";
import { Subscription, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { EntitiesObjectList } from "@core/models/entities/entities.model";
import { ManageEntitiesService } from "@core/http/entities/manage-entities.service";
import { SnackBarAlertService } from "@shared/services/snack-bar-alert/snack-bar-alert.service";
import { I18nState, getI18nState } from "@shared/translation/i18n-state";

@Component({
    selector: "app-create-entity-dialog",
    templateUrl: "./create-entity-dialog.component.html",
    styleUrls: ["./create-entity-dialog.component.scss"],
})
export class CreateEntityDialogComponent implements OnDestroy {
    private _subscription: Subscription;

    object: FormGroup;

    get title() {
        return this.data ? getI18nState(I18nState.BTN_EDIT) : getI18nState(I18nState.BTN_ADD);
    }

    get confidence() {
        return this.object.get("confidence").value;
    }

    private _fetching: boolean;
    set fetching(state: boolean) {
        this._fetching = state;
    }
    get fetching() {
        return this._fetching;
    }

    get confidencePercentage() {
        return this._round2DemicalNumber((this.object.get("confidence").value ?? 0) * 100);
    }

    get hasKeywords() {
        return !!this.data?.trained;
    }
    private _keywords: Array<string>;
    get keywords() {
        return this._keywords;
    }

    formatLabel(value: number): string {
        return Math.round((value + Number.EPSILON) * 100) + "%";
    }

    constructor(
        private _dialogRef: MatDialogRef<CreateEntityDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: EntitiesObjectList,
        private _snackBar: SnackBarAlertService,
        private _manageEntity: ManageEntitiesService
    ) {
        this._subscription = new Subscription();
        this.fetching = false;

        this.object = new FormGroup({
            name: new FormControl(this.data?.name?.replace("ENT_", "") ?? "", [Validators.required, Validators.maxLength(40)]),
            confidence: new FormControl(this.data?.confidence ?? 0, [Validators.min(0), Validators.max(1), Validators.required]),
        });
        this._keywords = this.data?.trained ?? [];
    }

    private _round2DemicalNumber = (number: number): number => {
        return Math.round((number + Number.EPSILON) * 100) / 100;
    };

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    onSliderChange = ($event: MatSliderChange): void => {
        this.object.get("confidence").patchValue($event.value ?? 0);
    };

    onInputChange = (value: string): void => {
        this.object.get("confidence").patchValue(value ? this._round2DemicalNumber(parseInt(value) / 100) : 0);
    };

    onRemoveKeyword = (index: number): void => {
        this._keywords = this._keywords.filter((_, i) => i !== index);
    };

    onAppendMessage = (input: HTMLInputElement): void => {
        if (input?.value.length == 0) return;
        this._keywords.push(input?.value);
        input.value = "";
    };

    onSubmit = (): void => {
        if (this.fetching) return;

        if (this.object.invalid) return this._snackBar.open("error", getI18nState(I18nState.GLOBAL_ERR_MSG));

        this.fetching = true;
        const data = new EntitiesObjectList().deserialize({
            ...this.object.value,
            _id: this.data?._id ?? null,
            trained: this.keywords ?? [],
        });
        this._subscription.add(
            of(!!this.data?._id)
                .pipe(
                    switchMap((state) => {
                        if (state)
                            return this._manageEntity.$updateEntityObject(data).pipe(
                                map((_) => {
                                    return { state: state, _id: this.data?._id };
                                })
                            );
                        return this._manageEntity.$insertEntityObject(data).pipe(
                            map((response) => {
                                return { state: state, _id: response._id };
                            })
                        );
                    })
                )
                .subscribe(
                    (response) => {
                        data._id = response._id;
                        this._snackBar.open(
                            "success",
                            response.state
                                ? $localize`:@@success-update-entity:Update Entity object successful.`
                                : $localize`:@@success-add-entity:Add Entity object successful.`
                        );
                        this.fetching = false;
                        this._dialogRef.close(data);
                    },
                    (error) => {
                        console.error(error);
                        this.fetching = false;
                        this._snackBar.open("error", getI18nState(I18nState.GLOBAL_ERR_MSG));
                    }
                )
        );
    };
}
