import { Component, OnDestroy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { BotProfile } from "@core/models/bot/bot-profile.model";
import { BotProfileService } from "@core/services/authentication/bot-profile.service";
import { ManageBotRequestService } from "@core/http/bot/manage-bot-request.service";
import { SnackBarAlertService } from "@shared/services/snack-bar-alert/snack-bar-alert.service";

@Component({
    selector: "app-delete-chatbot-dialog",
    templateUrl: "./delete-chatbot-dialog.component.html",
    styleUrls: ["./delete-chatbot-dialog.component.scss"],
})
export class DeleteChatbotDialogComponent implements OnDestroy {
    private _subscription: Subscription;

    get bot_id() {
        return this._data?._id ?? this._botProfileService?.getID ?? null;
    }

    get bot_avatar() {
        return this._data?.bot_avatar ?? this._botProfileService?.getLogo ?? "";
    }
    get bot_name() {
        return this._data?.bot_name ?? this._botProfileService?.getName ?? "";
    }

    get logo() {
        return this.bot_avatar.length > 0 ? this.bot_avatar : "assets/botnoi-logo/botnoi.svg";
    }

    get canDelete() {
        return this._data?.bot_name == this.typingName || this._botProfileService?.getName == this.typingName;
    }

    private _fetching: boolean;
    get fetching() {
        return this._fetching;
    }

    typingName: string;

    constructor(
        private _matDialogRef: MatDialogRef<DeleteChatbotDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: BotProfile,
        private _botProfileService: BotProfileService,
        private _manageBotService: ManageBotRequestService,
        private _snackbar: SnackBarAlertService
    ) {
        this._subscription = new Subscription();
        this._fetching = false;
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    onDelete = (): void => {
        if (!this.bot_id) {
            this._snackbar.open("error", $localize`:@@error-delete-chatbot:We couldn’t delete your chatbot. Please try again or contact our support team.`);
            this._matDialogRef.close();
            return;
        }

        this._fetching = true;

        this._subscription.add(
            this._manageBotService.$deleteBot(this.bot_id).subscribe(
                (_) => {
                    this._fetching = false;
                    this._snackbar.open("success", $localize`:@@success-delete-chatbot:Your chatbot has been deleted.`);
                    this._matDialogRef.close(true);
                },
                () => {
                    this._fetching = false;
                    this._snackbar.open(
                        "error",
                        $localize`:@@error-delete-chatbot:We couldn’t delete your chatbot. Please try again or contact our support team.`
                    );
                    this._matDialogRef.close();
                }
            )
        );
    };

    onClose = (): void => {
        this._matDialogRef.close();
    };
}
