import { Deserializable } from "../deserializable.model";
import {NoteMessage} from "./note-message.model";
import { SenderModel, SourceModel, MessageModel, LastMessageReadModel, NoteModel, ChatRoomProfileModel } from "./response.model";

export interface SocketInBoundMessage {
    _id: string;
    bot_id: string;
    page_id: string;
    profile_image_url: string;
    display_name: string;
    msg_reply_token: string;
    sender: SenderModel;
    source: SourceModel;
    message: MessageModel;
    timestamp: Date;
    success: boolean;
    is_spam_msg: boolean;
    error_msg: string;
    chat_room_profile: ChatRoomProfileModel;
    note: Array<NoteMessage>;
    tags: Array<string>;
}

export class SocketInBoundMessage implements Deserializable, SocketInBoundMessage {
    _id: string;
    bot_id: string;
    page_id: string;
    profile_image_url: string;
    display_name: string;
    msg_reply_token: string;
    sender: SenderModel;
    source: SourceModel;
    message: MessageModel;
    timestamp: Date;
    success: boolean;
    is_spam_msg: boolean;
    error_msg: string;
    chat_room_profile: ChatRoomProfileModel;
    note: Array<NoteMessage>;
    tags: Array<string>;

    deserialize(input: any) {
        Object.assign(this, input);
        if (input?.timestamp) this.timestamp = new Date(input?.timestamp);
        return this;
    }

    get messageTemplateType() {
        switch (this.message.type) {
            case "text":
            case "postback":
                return "message";
            case "carousel":
                return "template";
            case "buttons":
                return "template";
            case "quick_reply":
                return "message";
            default:
                return this.message.type;
        }
    }

    get messageTamplateValue() {
        switch (this.message.type) {
            case "text":
            case "postback":
                return this.message.text;
            case "image":
                return this.message.list_object[0].original_url;
            case "video":
                return this.message.list_object[0].video_url;
            case "audio":
                return this.message.list_object[0].audio_url;
            case "file":
                return this.message.list_object[0].file_url;
            case "carousel":
                return this.message.list_object.map((obj: any) => {
                    return {
                        url: obj.image_url,
                        title: obj.title,
                        subtitle: obj.subtitle,
                        buttons: obj.buttons.map((bttn: any) => {
                            return { label: bttn.label };
                        }),
                    };
                });
            case "buttons":
                return this.message.list_object.map((obj: any) => {
                    return {
                        title: obj.title,
                        buttons: obj.action.map((bttn: any) => {
                            return { label: bttn.label };
                        }),
                    };
                });
            case "flex":
                return this.message.list_object[0];
            case "quick_reply":
                return this.message.list_object[0].text;
            case "sticker":
                return this.message.list_object[0]?.line_sticker_id ?? null;
            default:
                return this.message;
        }
    }

    get messageTemplate(): {
        role: "user" | "admin";
        type: string;
        value: any;
        sender_id: string;
        profile_pic: string;
        timestamp: Date;
        success: boolean;
    } {
        return {
            role: this.sender.sender_type == "customer" ? "user" : "admin",
            type: this.messageTemplateType,
            value: this.messageTamplateValue,
            sender_id: this.sender.sender_id ? this.sender.sender_id : null,
            profile_pic: this.sender.profile_image_url ? this.sender.profile_image_url : null,
            timestamp: this.timestamp,
            success: this.success,
        };
    }
}

export interface SocketOutBoundMessage {
    bot_id: string;
    page_id: string;
    source_id: string;
    source_channel: "line" | "facebook" | "line_modular" | "app";
    source_app_id: string;
    source_type: "group" | "room" | "user";
    msg_reply_token: string;
    messages: MessageModel;
    send_and_train: boolean;
}

export class SocketOutBoundMessage implements Deserializable, SocketOutBoundMessage {
    bot_id: string;
    page_id: string;
    source_id: string;
    source_channel: "line" | "facebook" | "line_modular" | "app";
    source_app_id: string;
    source_type: "group" | "room" | "user";
    msg_reply_token: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    onOutBoundMessage = (msg: string, trained: boolean): SocketOutBoundMessage => {
        return {
            ...this,
            messages: { bot_id: this.bot_id, type: "text", text: msg.trim() },
            send_and_train: trained,
        };
    };

    onOutBoundImageMessage = (url: string, trained: boolean): SocketOutBoundMessage => {
        return {
            ...this,
            messages: { bot_id: this.bot_id, type: "image", object_name: "", list_object: [{ original_url: url, preview_image_url: url }] },
            send_and_train: trained,
        };
    };

    onOutBoundIntentMessage = (intent: string, trained: boolean): SocketOutBoundMessage => {
        return {
            ...this,
            messages: { bot_id: this.bot_id, type: "intent", intent: intent.trim() },
            send_and_train: trained,
        };
    };
}

export interface SocketAgentState {
    _id: string;
    bot_id: string;
    page_id: string;
    display_name: string;
    profile_image_url: string;
    agent_state: {
        active: boolean;
        active_at: Date;
    };
    last_active: Date;
    last_message: {
        message: MessageModel;
        sender: SenderModel;
        timestamp: Date;
    };
    last_msg_read: LastMessageReadModel;
    source: SourceModel;
    chat_room_profile: ChatRoomProfileModel;
    note: Array<NoteModel>;
    tags: Array<string>;
    is_spam: boolean;
}

export class SocketAgentState {
    _id: string;
    bot_id: string;
    page_id: string;
    display_name: string;
    profile_image_url: string;
    agent_state: {
        active: boolean;
        active_at: Date;
    };
    last_active: Date;
    last_message: {
        message: MessageModel;
        sender: SenderModel;
        timestamp: Date;
    };
    last_msg_read: LastMessageReadModel;
    source: SourceModel;
    chat_room_profile: ChatRoomProfileModel;
    note: Array<NoteModel>;
    tags: Array<string>;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
