<div
    cdkOverlayOrigin
    #container="cdkOverlayOrigin"
    class="bn-form-field-wrapper"
    [ngStyle]="{
        'padding-bottom': isWrapping ? '19px' : '0px'
    }"
>
    <div
        class="bn-form-field-flex"
        [ngStyle]="{
            'border-radius': borderRadius + 'px',
            'background-color': backgroundColor,
            'border-color': borderColor ? borderColor : null
        }"
    >
        <div class="bn-form-field-prefix">
            <ng-content select="[bnPrefix]"></ng-content>
        </div>
        <div class="bn-form-field-infix">
            <ng-content select="mat-chip-list"></ng-content>
            <ng-content select="input"></ng-content>
            <ng-content select="textarea"></ng-content>
            <ng-content select="bn-select"></ng-content>
            <ng-content select="mat-date-range-input"></ng-content>
        </div>
        <div class="bn-form-field-suffix">
            <ng-content select="[bnSuffix]"></ng-content>
        </div>
    </div>

    <div class="bn-form-field-subscript-wrapper">
        <div *ngIf="!isError" class="bn-form-field-hint-wrapper">
            <ng-content select="bn-hint[align=start]"></ng-content>
            <div class="bn-form-field-hint-spacer"></div>
            <ng-content select="bn-hint[align=end]"></ng-content>
        </div>
        <div *ngIf="isError" class="bn-form-field-error-wrapper">
            <ng-content select="bn-error"></ng-content>
        </div>
    </div>
</div>

<!-- This template displays the overlay actions-container and is connected to the button -->
<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="container"
    [cdkConnectedOverlayOpen]="showActions"
    [cdkConnectedOverlayPositions]="actoionWrapperPositions"
>
    <div class="bn-form-field-actions-wrapper mat-dark" (mouseenter)="onMouseEnterTemplate()" (mouseleave)="onMouseLeaveTemplate()">
        <div class="d-flex align-items-center actions-wrapping">
            <button
                *ngIf="hasEmoji"
                cdkOverlayOrigin
                #trigger="cdkOverlayOrigin"
                type="button"
                mat-raised-button
                bn-small-button
                bn-square-button
                color="primary"
                (click)="onToggleEmoji(trigger)"
            >
                <mat-icon svgIcon="smile"></mat-icon>
            </button>
            <button *ngIf="hasParam" type="button" mat-raised-button bn-small-button bn-square-button color="primary" (click)="openParamsDialog()">
                <mat-icon svgIcon="param" style="width: 23px"></mat-icon>
            </button>
            <button *ngIf="hasObject" type="button" mat-raised-button bn-small-button bn-square-button color="primary" (click)="openSelectObjectDialog()">
                <mat-icon svgIcon="intent"></mat-icon>
            </button>
        </div>
    </div>
</ng-template>

<!-- This template displays the overlay emoji-mart and is connected to the button -->
<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="triggerOrigin"
    [cdkConnectedOverlayOpen]="isOpenToggle"
    [cdkConnectedOverlayHasBackdrop]="true"
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    (backdropClick)="onCloseEmoji()"
    (overlayKeydown)="overlayKeydown($event)"
>
    <emoji-mart
        color="#01bffb"
        [isNative]="true"
        [showPreview]="false"
        [darkMode]="false"
        [emojiSize]="18"
        (emojiSelect)="onSelectEmoji($event)"
        (mouseenter)="onMouseEnterTemplate()"
    ></emoji-mart>
</ng-template>
