import { Deserializable } from '../deserializable.model'

export class QrpObject implements Deserializable {
    _id: string
    name: string
    message: string
    buttons: Array<Button>

    deserialize(input: any) {
        Object.assign(this, input)
        this.buttons = this.buttons.map(button => new Button().deserialize(button))
        return this
    }

    convertObjectToServer() {
        return {
          _id: this._id,
          type: 'quick_reply',
            object_name: this.name.includes('QRP_') ? this.name : `QRP_${this.name}`,
            list_object: [{
                text: this.message,
                quick_reply: this.buttons.map(bttn => bttn.convertObjectToServer())
            }]
        }
    }
}

export class Button implements Deserializable {
    label: string
    type: 'message' | 'url' | 'phone'
    message: string

    deserialize(input: any) {
        Object.assign(this, input)
        this.type = 'message'
        return this
    }

    convertObjectToServer() {
        return {
            type: 'text',
            label: this.label,
            data: this.message
        }
    }
}
