import { Injectable } from "@angular/core";
import { WebSocketSubject } from "rxjs/webSocket";
import { Observable, Subject } from "rxjs";
import { map, filter } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { SocketMessage } from "@core/models/customer-chat/customer-socket.model";

const API_GATEWAYS_WSS: string = env.socketGatewayURL;

@Injectable()
export class CustomerSocketService {
    private _socketSubject: WebSocketSubject<any>;
    get hasSocketSubject() {
        return !!this._socketSubject;
    }

    private _$isConnected: Subject<boolean>;
    get $isConnected() {
        return this._$isConnected;
    }

    private _bot_id: string;
    private _uid: string;

    constructor() {
        this._$isConnected = new Subject();
        this._bot_id = "";
        this._uid = "";
    }

    onInitSocket = (access_token: string, bot_id: string, uid: string): void => {
        this._bot_id = bot_id;
        this._uid = uid;
        this._socketSubject = new WebSocketSubject({
            url: `${API_GATEWAYS_WSS}/webchat/connect?access_token=${access_token}`,
            openObserver: {
                next: () => {
                    console.log(`socket connection ok`);
                    this._$isConnected.next(true);
                },
            },
            closeObserver: {
                next: () => {
                    console.log(`socket unsubscription`);
                    this._$isConnected.next(false);
                },
            },
        });
    };

    onConnectSocket = (): Observable<SocketMessage> => {
        return this._socketSubject.pipe(
            filter(
                (response) =>
                    response.hasOwnProperty("bot_id") &&
                    response.hasOwnProperty("source") &&
                    response.hasOwnProperty("sender") &&
                    response.hasOwnProperty("message")
            ),
            filter((response) => response.sender.sender_id == this._bot_id && response.source.source_id == this._uid),
            map((response) => new SocketMessage().deserialize({ sender: response.sender.sender_id, message: response.message }))
        );
    };

    onSocketEmitMessage = (value: string): void => {
        this._socketSubject.next({ type: "text", text: value });
    };

    onSocketEmitPostback = (title: string, payload: string): void => {
        this._socketSubject.next({ type: "postback", text: title, postback_data: payload });
    };

    onSocketEmitFile = (url: string): void => {
        this._socketSubject.next({ type: "file", file_url: url });
    };

    onSocketEmitImage = (url: string): void => {
        this._socketSubject.next({ type: "image", image_url: url });
    };

    onSocketEmitAudio = (url: string): void => {
        this._socketSubject.next({ type: "audio", audio_url: url });
    };

    onSocketEmitVideo = (url: string): void => {
        this._socketSubject.next({ type: "video", video_url: url });
    };
}
