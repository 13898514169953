import { Injectable } from "@angular/core";
import { CookieService as Cookie } from "ngx-cookie-service";
import * as CryptoJS from "crypto-js";
import * as env from "@env/environment";

const EncyptKey: string = env.environment.key;

@Injectable()
export class CookieService {
    constructor(private cookie: Cookie) {}

    set(key: string, value: any, expires?: number | Date): void {
        try {
            this.cookie.set(key, value, expires ?? 0, "/", null, env.environment.production);
        } catch (_) {
            console.error(`${key}: cant set localStorage`);
        }
    }

    encryptSet(key: string, value: any, expires?: number | Date): void {
        try {
            const encryptValue = CryptoJS.AES.encrypt(JSON.stringify(value), EncyptKey).toString();
            this.cookie.set(key, encryptValue, expires ?? 0, "/", null, env.environment.production);
        } catch (_) {
            console.error(`${key}: cant set localStorage`);
        }
    }

    get(key: string): any {
        const value = this.cookie.get(key);
        return value;
    }

    decryptGet(key: string): any {
        const encryptValue = this.cookie.get(key);
        const decryptValue = CryptoJS.AES.decrypt(encryptValue ?? "", EncyptKey).toString(CryptoJS.enc.Utf8);
        return !!decryptValue ? JSON.parse(decryptValue) : decryptValue;
    }

    delete(key: string): void {
        this.cookie.delete(key);
    }

    clearAll(): void {
        this.cookie.deleteAll("/");
    }
}
