import { Deserializable } from "../deserializable.model";

export interface StatIntent {
    total_reply_by_intent: MessageTotal;
    data: Array<Data>;
}
export class StatIntent implements Deserializable {
    total_reply_by_intent: MessageTotal;
    data: Array<Data>;

    deserialize(input: StatIntent) {
        Object.assign(this, input);
        if (!!input.total_reply_by_intent) this.total_reply_by_intent = new MessageTotal().deserialize(input.total_reply_by_intent);
        this.data = input?.data?.map((d) => new Data().deserialize(d)) ?? [];
        return this;
    }
}

interface MessageTotal {
    total: number;
    change_percentage: number;
}
class MessageTotal implements Deserializable {
    total: number;
    change_percentage: number;

    get percentage() {
        return this.change_percentage ? this.change_percentage.toFixed(2) : "0";
    }

    deserialize(input: MessageTotal) {
        Object.assign(this, input);
        return this;
    }
}

interface Data {
    intent_name: string;
    ratio: number;
    total: number;
}
class Data implements Deserializable {
    intent_name: string;
    ratio: number;
    total: number;

    get ratioPercentage() {
        return this.ratio ? this.ratio.toFixed(2) : "0";
    }

    deserialize(input: Data) {
        Object.assign(this, input);
        return this;
    }
}
