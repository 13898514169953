import { Deserializable } from "../deserializable.model";

export class BillingInformation implements Deserializable {
    _id: string;
    user_id: string;
    first_name: string;
    last_name: string;
    bill_address: string;
    province: string;
    district: string;
    sub_district: string;
    postal: string;
    phone: string;
    email: string;
    tax_number: string;
    holding_tax: boolean;
    company: string;
    type: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}

