import { Directive, ElementRef, Renderer2 } from "@angular/core";

@Directive({
    selector: "button[bn-large-button], a[bn-large-button]",
})
export class BnLargeButtonDirective {
    constructor(private ele: ElementRef, private _renderer: Renderer2) {
        this._renderer.addClass(this.ele.nativeElement, "bn-large-button");
    }
}
