import { Directive, Renderer2, ElementRef } from "@angular/core";

@Directive({
    selector: "bn-form-field[bnFormLarge]",
})
export class BnFormFieldLargeDirective {
    constructor(private ele: ElementRef, private _renderer: Renderer2) {
        this._renderer.addClass(this.ele.nativeElement, "bn-form-large");
    }
}
