import * as moment from "moment";
import { timer } from "rxjs";
import { Deserializable } from "../deserializable.model";
import { NoteModel } from "./response.model";

export class NoteMessage implements Deserializable, NoteModel {
    _id: string;
    message: string;
    timestamp: Date;
    update_by: string;

    private _isEditing: boolean;

    get editing() {
        return this._isEditing ?? false;
    }

    get formatTime() {
        return moment(this.timestamp).format("DD MMM YY");
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    onUpdateEditingState(state: boolean): void {
        timer(100).subscribe((_) => (this._isEditing = state));
    }
}
