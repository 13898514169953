import { Deserializable } from '../deserializable.model'
import { EntitiesObjectList } from '../entities/entities.model'

export class EntityList implements Deserializable {
    _id: string
    selected: boolean
    name: string
    confidence: number
    trained: Array<string>

    deserialize(input: any) {
        Object.assign(this, input)
        return this
    }

    convertObjectToList(input: EntitiesObjectList) {
        Object.assign(this, {
            ...input,
            name: input.name.includes('ENT_') ? input.name : `ENT_${input.name}`
        })
        this.selected = false
        return this
    }

    convertListToObject(): EntitiesObjectList {
        return new EntitiesObjectList().deserialize({
            _id: this._id,
            name: this.name,
            confidence: this.confidence,
            trained: this.trained
        })
    }
}
