import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { Observable, throwError } from "rxjs";
import { map, catchError, shareReplay, retry } from "rxjs/operators";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { BotPermission, PermissionUser } from "@core/models/bot/bot-permission.model";
import { HttpHeaders } from "@angular/common/http";
import { AuthService } from "@core/services/authentication";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

@Injectable()
export class PermissionBotService {

    get fbToken() {
        return this._authService?.getFbAccessToken;
    }

    constructor(private _http: HttpRequest, private _authService: AuthService) {}

    $getPermissionList = (): Observable<BotPermission> => {
        return this._http
            .get<any>({
                url: `${API_GATEWAYS_URL}platform-go/permission`,
            })
            .pipe(
                shareReplay(1),
                retry(genericRetry),
                map((response) => new BotPermission().deserialize(response)),
                catchError((error) => throwError(() => error))
            );
    };

    $addPermissionUser = (user: PermissionUser): Observable<any> => {
        return this._http
            .post<any>({
                url: `${API_GATEWAYS_URL}platform-go/permission`,
                body: {
                    ...user.transmit(),
                },
            })
            .pipe(
                shareReplay(1),
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $updatePermissionUser = (user: PermissionUser): Observable<any> => {
        return this._http
            .put<any>({
                url: `${API_GATEWAYS_URL}platform-go/permission`,
                body: {
                    ...user.transmit(),
                },
            })
            .pipe(
                shareReplay(1),
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deletePermissionUser = (_id: string): Observable<{ message: string }> => {
        return this._http
            .delete<any>({
                url: `${API_GATEWAYS_URL}platform-go/permission`,
                body: {
                    _id: [_id],
                },
            })
            .pipe(
                shareReplay(1),
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $changeBotOwner = (bot_id: string, email: string): Observable<any> => {
        return this._http
            .put<any>({
                headers: new HttpHeaders({
                    x_fb_user_token: this.fbToken
                }),
                url: `${API_GATEWAYS_URL}platform-go/permission/owner`,
                body: {
                    bot_id: bot_id,
                    email: email,
                },
            })
            .pipe(
                shareReplay(1),
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };
}
