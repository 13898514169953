import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { map, retry, catchError, take, switchMap } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { PersistentObject } from "@core/models/object/persistent-object.model";
import { LocalService } from "@core/services/cache/local.service";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

interface PersistentResponse {
    _id: string;
    object_name: string;
    is_active: boolean;
    persistent_menu: Array<{
        call_to_actions: Array<{
            type: string;
            title: string;
            payload?: string;
            url?: string;
        }>;
    }>;
}

@Injectable()
export class ManagePersistentObjectService {
    get url() {
        return `${API_GATEWAYS_URL}platform-go/object/persistent_menu`;
    }

    constructor(private _http: HttpRequest, private local: LocalService) {}

    $getPersistentObject = (): Observable<PersistentObject> => {
        return this._http
            .get<PersistentResponse>({
                url: this.url.toString(),
                cacheMins: 1,
            })
            .pipe(
                retry(genericRetry),
                take(1),
                map((response) =>
                    new PersistentObject().deserialize({
                        _id: response?._id,
                        name: response?.object_name,
                        is_active: response?.is_active,
                        buttons: !!response?.persistent_menu
                            ? response?.persistent_menu[0]?.call_to_actions.map((bttn) => ({
                                  label: bttn.title,
                                  type: bttn.type == "web_url" ? "url" : "message",
                                  message: bttn.type == "web_url" ? bttn.url : bttn.payload,
                              }))
                            : [],
                    })
                ),
                catchError((error) => throwError(() => error))
            );
    };

    $upsertPersistentObject = (item: PersistentObject): Observable<{ _id: string }> => {
        return of(!!item?._id?.length).pipe(
            retry(genericRetry),
            switchMap((state) => {
                if (state)
                    return this._http
                        .put<{ msg: string }>({
                            url: this.url.toString(),
                            body: item.convertObjectToServer(),
                        })
                        .pipe(
                            map(() => ({ _id: item._id })),
                            catchError((error) => throwError(() => error))
                        );

                const request = item.convertObjectToServer();
                delete request._id;
                return this._http
                    .post<{ _id: string }>({
                        url: this.url.toString(),
                        body: request,
                    })
                    .pipe(catchError((error) => throwError(() => error)));
            }),
            catchError((error) => throwError(() => error))
        );
    };

    $deletePersistentObject = (_id: string): Observable<void> => {
        return this._http
            .delete<{ msg: string }>({
                url: this.url.toString(),
                body: { _id: _id },
            })
            .pipe(
                map(() => null),
                catchError((error) => throwError(() => error))
            );
    };

    clearCacheData = (): void => {
        this.local.delete(this.url.toString());
    };
}
