import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { Observable, throwError } from "rxjs";
import { catchError, map, retry } from "rxjs/operators";
import { HttpRequest, genericRetry } from "@core/http/http.service";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

@Injectable({
    providedIn: "root",
})
export class PdpaService {
    constructor(private _http: HttpRequest) {}

    $getPDPAStatus = (): Observable<string> => {
        return this._http.get<any>({ url: `${API_GATEWAYS_URL}platform-go/user/pdpa-status` }).pipe(
            retry(genericRetry),
            catchError((error) => throwError(() => error))
        );
    };

    $getPDPAContent = (): Observable<string> => {
        return this._http.get<any>({ url: `${API_GATEWAYS_URL}platform-go/user/pdpa-info`, responseType: "text" }).pipe(
            retry(genericRetry),
            catchError((error) => throwError(() => error))
        );
    };

    $acceptPDPA = (): Observable<string> => {
        return this._http.get<any>({ url: `${API_GATEWAYS_URL}platform-go/user/pdpa-acception` }).pipe(
            retry(genericRetry),
            catchError((error) => throwError(() => error))
        );
    };
}
