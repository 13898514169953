import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment as env } from "@env/environment";
import { catchError, map } from "rxjs/operators";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

@Injectable()
export class NotificationMessageService {
    constructor(private _http: HttpClient) {}

    $getNotificationMessage = (): Observable<string> => {
        return this._http
            .request("GET", `${API_GATEWAYS_URL}message-bar`, {
                responseType: "text",
            })
            .pipe(
                map((response) => response),
                catchError((error) => throwError(() => error))
            );
    };
}
