import { Deserializable } from '../deserializable.model'

export class ButtonObject implements Deserializable {
    _id: string
    name: string
    title: string
    buttons: Array<Button>

    deserialize(input: any) {
        Object.assign(this, input)
        this.buttons = this.buttons.map(button => new Button().deserialize(button))
        return this
    }

    convertObjectToServer() {
        return {
          _id: this._id,
          type: 'buttons',
            object_name: this.name.includes('BTN_') ? this.name : `BTN_${this.name}`,
            list_object: [{
                title: this.title,
                action: this.buttons.map(bttn => bttn.convertObjectToServer())
            }]
        }
    }
}

export class Button implements Deserializable {
    label: string
    type: 'message' | 'url' | 'phone'
    message: string

    deserialize(input: any) {
        Object.assign(this, input)
        return this
    }

    convertObjectToServer() {
        return {
            type: this.type == 'message' ? 'postback' : this.type == 'url' ? 'web_url' : 'phone',
            label: this.label,
            data: this.message
        }
    }
}
