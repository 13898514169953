import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { catchError, map, Observable, retry, throwError } from "rxjs";
import { HttpRequest, genericRetry } from "@core/http/http.service";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

@Injectable()
export class Ex10Service {
    constructor(private _http: HttpRequest) {}

    getEx10Webhook = (): Observable<string> => {
        return this._http
            .get<{ url: string }>({
                url: `${API_GATEWAYS_URL}platform-go/bot/webhook`,
            })
            .pipe(
                retry(genericRetry),
                map((response) => response?.url ?? ""),
                catchError((error) => throwError(() => error))
            );
    };
}
