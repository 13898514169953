import { Component, Inject, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription, fromEvent, BehaviorSubject, forkJoin, of } from "rxjs";
import { filter, debounceTime, catchError } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { ObjectService } from "@core/http/object/object.service";
import { ApiList, ButtonList, CrsList, DlList, EntityList, FlexList, ImageList, QrpList, RmList, PayloadList, AudioList } from "@core/models/table";
import { IntentGroupResponse, TrainBotService } from "@core/http/nlp/train-bot.service";
import { I18nState, getI18nState } from "@shared/translation/i18n-state";

interface SelectObjectDialogModel {
    type: "object" | "intent" | "entity" | "richmenu";
    multipleSelect?: boolean;
    intent?: Array<string>;
}

@Component({
    selector: "app-select-object",
    templateUrl: "./select-object.component.html",
    styleUrls: ["./select-object.component.scss"],
})
export class SelectObjectComponent implements OnInit, AfterViewInit, OnDestroy {
    get featureFlags() {
        return env.featureFlags;
    }

    @ViewChild("scrollContainer") scrollContain: ElementRef;

    get searchPlaceholder() {
        return getI18nState(I18nState.LABEL_SEARCH_PHD);
    }

    private _subscription: Subscription;
    private $filterData: BehaviorSubject<string>;

    displayedColumns: string[];
    dataSource = new MatTableDataSource<any>();

    get isMultiple() {
        return this.data?.multipleSelect;
    }

    private _selectedID: { _id: string; name: string };
    get selectedID() {
        return this._selectedID;
    }

    private _selectedIDMultiple: Array<string>;
    get selectedIDMultiple() {
        return this._selectedIDMultiple;
    }

    dataForCheck(name: string): boolean {
        return this.selectedIDMultiple.some((elt) => elt === name);
    }

    private _expandContent: boolean;
    set expandContent(state: boolean) {
        this._expandContent = state;
    }
    get expandContent() {
        return this._expandContent;
    }

    private _fetching: boolean;
    set fetching(state: boolean) {
        this._fetching = state;
    }
    get fetching() {
        return this._fetching;
    }

    private _state: string;
    set state(state: string) {
        this._state = state;
    }
    get state() {
        return this._state;
    }

    private _title: string;
    set title(title: string) {
        this._title = title;
    }
    get title() {
        return this._title;
    }

    private _filter: string;
    set filter(filter: string) {
        this._filter = filter;
    }
    get filter() {
        return this._filter;
    }

    private _stateColumn: Array<{ state: string; label: string; icon: string }>;
    get stateColumn() {
        return this._stateColumn;
    }

    private _intentList: IntentGroupResponse;
    private _dialogObjectList: Array<DlList>;
    private _imageObjectList: Array<ImageList>;
    private _carouselObjectList: Array<CrsList>;
    private _buttonObjectList: Array<ButtonList>;
    private _quickObjectList: Array<QrpList>;
    private _flexObjectList: Array<FlexList>;
    private _apiObjectList: Array<ApiList>;
    private _richMenuObjectList: Array<RmList>;
    private _payloadObjectList: Array<PayloadList>;
    private _entityObjectList: Array<EntityList>;
    private _audioObjectList: Array<AudioList>;

    private _audio: HTMLAudioElement;

    constructor(
        private _dialogRef: MatDialogRef<SelectObjectComponent>,
        @Inject(MAT_DIALOG_DATA) private data: SelectObjectDialogModel,
        private _objectService: ObjectService,
        private _trainBotService: TrainBotService
    ) {
        this._subscription = new Subscription();
        this.$filterData = new BehaviorSubject(null);

        this.fetching = true;
        this._stateColumn = [];
        this.expandContent = false;
        this.filter = "";
        this._selectedIDMultiple = this.data?.intent || [];
    }

    ngOnInit(): void {
        this._initObjectValue();
        this._initStateObject();
        this._onFilterEvent();
    }

    ngAfterViewInit(): void {
        this._onScrollEvent();
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
        if (this.$filterData) this.$filterData.unsubscribe();
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.filter = filterValue.trim().toLowerCase();
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.$filterData.next(this.filter);
    }

    updateSelectedItem = (_id: string, name: string): void => {
        if (this.data?.multipleSelect) {
            this._selectedIDMultiple.indexOf(name) === -1
                ? this._selectedIDMultiple.push(name)
                : (this._selectedIDMultiple = this._selectedIDMultiple.filter((item) => item !== name));
            return;
        }
        this._selectedID = { _id: _id, name: name };
    };

    onSelectState = (state: string): void => {
        this.state = state;
        this.title = this._setTitle(state);
        this.displayedColumns = this._setColumn(state);
        this.dataSource = new MatTableDataSource(this._setDataSourceValue(state));
        if (this.filter) this.dataSource.filter = this.filter;
    };

    private _onScrollEvent = (): void => {
        this._subscription.add(
            fromEvent(this.scrollContain.nativeElement, "scroll")
                .pipe(
                    filter((_) => this.state == "int"),
                    filter(($event: any) => {
                        let position = $event.target.scrollTop + $event.target.offsetHeight;
                        let maxScroll = $event.target.scrollHeight;
                        return maxScroll - position <= 50;
                    }),
                    debounceTime(100)
                )
                .subscribe((_) => {
                    if (this._intentList.next) this._appendNextIntentData();
                })
        );
    };

    private _onFilterEvent = (): void => {
        this._subscription.add(
            this.$filterData
                .pipe(
                    filter((value) => value !== null),
                    debounceTime(300)
                )
                .subscribe((value) => {
                    this.fetching = true;
                    this._subscription.add(
                        this._trainBotService
                            .$getListIntentGroup({
                                bf_id: "",
                                search: value,
                            })
                            .subscribe({
                                next: (response) => {
                                    this._intentList.data = response.data;
                                    this._intentList.next = response.next;
                                    this.onSelectState(this.state);
                                    this.fetching = false;
                                },
                                error: (_) => (this.fetching = false),
                            })
                    );
                })
        );
    };

    private _initStateObject = (): void => {
        switch (this.data.type) {
            case "intent":
                this._stateColumn = [{ state: "int", label: "Intent", icon: "chat" }];
                this.onSelectState("int");
                break;
            case "richmenu":
                this._stateColumn = [{ state: "rm", label: "Rich Menu", icon: "rich menu" }];
                this.onSelectState("rm");
                break;
            case "object":
                this._stateColumn = [
                    { state: "dl", label: "Dialogue", icon: "map" },
                    { state: "img", label: "Image", icon: "picture" },
                    { state: "crs", label: "Carousel", icon: "carousel" },
                    { state: "btn", label: "Button", icon: "response btn" },
                    {
                        state: "qrp",
                        label: "Quick Reply",
                        icon: "conversation",
                    },
                    { state: "fx", label: "Flex", icon: "flex" },
                    { state: "api", label: "API", icon: "cloud-API" },
                    { state: "rm", label: "Rich Menu", icon: "rich menu" },
                    { state: "cp", label: "Payload", icon: "paper-center" },
                ];
                if (this.featureFlags?.featureAudioObject) this._stateColumn.push({ state: "aud", label: "Audio", icon: "volume" });
                this.onSelectState("dl");
                break;
            case "entity":
                this._stateColumn = [{ state: "en", label: "Entity", icon: "map" }];
                this.onSelectState("en");
                break;
            default:
                this._stateColumn = [
                    { state: "int", label: "Intent", icon: "chat" },
                    { state: "dl", label: "Dialogue", icon: "map" },
                    { state: "img", label: "Image", icon: "picture" },
                    { state: "crs", label: "Carousel", icon: "carousel" },
                    { state: "btn", label: "Button", icon: "response btn" },
                    {
                        state: "qrp",
                        label: "Quick Reply",
                        icon: "conversation",
                    },
                    { state: "fx", label: "Flex", icon: "flex" },
                    { state: "api", label: "API", icon: "cloud-API" },
                    { state: "rm", label: "Rich Menu", icon: "rich menu" },
                    { state: "cp", label: "Payload", icon: "paper-center" },
                ];
                if (this.featureFlags?.featureAudioObject) this._stateColumn.push({ state: "aud", label: "Audio", icon: "volume" });
                this.onSelectState("int");
        }
    };

    private _initObjectValue = (): void => {
        this._subscription.add(
            forkJoin({
                intent: this._trainBotService
                    .$getListIntentGroup({
                        bf_id: "",
                        search: "",
                    })
                    .pipe(catchError(() => of({ next: null, data: [] }))),
                image: this._objectService.$getImageObjectList().pipe(catchError(() => of([]))),
                carousel: this._objectService.$getCrsObjectList().pipe(catchError(() => of([]))),
                button: this._objectService.$getButtonObjectList().pipe(catchError(() => of([]))),
                quickReply: this._objectService.$getQrpObjectService().pipe(catchError(() => of([]))),
                api: this._objectService.$getAPIObjectList().pipe(catchError(() => of([]))),
                richMenu: this._objectService.$getRmObjectService().pipe(catchError(() => of([]))),
                dialog: this._objectService.$getDlObjectService().pipe(catchError(() => of([]))),
                flex: this._objectService.$getFlexObjectService().pipe(catchError(() => of([]))),
                payload: this._objectService.$getPayloadService().pipe(catchError(() => of([]))),
                entity: this._objectService.$getEntityService().pipe(catchError(() => of([]))),
                audio: this._objectService.$getAudioService().pipe(catchError(() => of([]))),
            }).subscribe({
                next: (response) => {
                    this.fetching = false;
                    this._intentList = response.intent;
                    this._imageObjectList = response.image;
                    this._carouselObjectList = response.carousel;
                    this._buttonObjectList = response.button;
                    this._quickObjectList = response.quickReply;
                    this._apiObjectList = response.api;
                    this._richMenuObjectList = response.richMenu;
                    this._dialogObjectList = response.dialog;
                    this._flexObjectList = response.flex;
                    this._payloadObjectList = response.payload;
                    this._entityObjectList = response.entity;
                    this._audioObjectList = response.audio;
                    this.onSelectState(this.state);
                },
                error: (_) => {
                    this.fetching = false;
                },
            })
        );
    };

    private _setTitle = (state: string): string => {
        switch (state) {
            case "dl":
                return getI18nState(I18nState.OBJ_DL);
            case "img":
                return getI18nState(I18nState.OBJ_IMG);
            case "crs":
                return getI18nState(I18nState.OBJ_CRS);
            case "btn":
                return getI18nState(I18nState.OBJ_BTN);
            case "qrp":
                return getI18nState(I18nState.OBJ_QRP);
            case "fx":
                return getI18nState(I18nState.OBJ_FX);
            case "api":
                return getI18nState(I18nState.OBJ_API);
            case "rm":
                return getI18nState(I18nState.OBJ_RM);
            case "cp":
                return getI18nState(I18nState.OBJ_CUSTOM);
            case "en":
                return getI18nState(I18nState.OBJ_ENT);
            case "aud":
                return $localize`:@@audio:Audio`;
            default:
                return $localize`:@@intent:Intent`;
        }
    };

    private _setColumn = (state: string): Array<string> => {
        switch (state) {
            case "dl":
                return ["selected", "name"];
            case "img":
                return ["selected", "name", "url"];
            case "crs":
                return ["selected", "name", "cards"];
            case "btn":
                return ["selected", "name", "buttons"];
            case "qrp":
                return ["selected", "name", "buttons"];
            case "fx":
                return ["selected", "name"];
            case "api":
                return ["selected", "name", "api_url", "method"];
            case "rm":
                return ["selected", "name", "rich_url"];
            case "cp":
                return ["selected", "name", "type"];
            case "en":
                return ["selected", "name", "confidence"];
            case "aud":
                return ["selected", "name", "audio"];
            default:
                return ["selected", "name"];
        }
    };

    private _setDataSourceValue = (state: string): Array<any> => {
        switch (state) {
            case "dl":
                return this._dialogObjectList;
            case "img":
                return this._imageObjectList;
            case "crs":
                return this._carouselObjectList;
            case "btn":
                return this._buttonObjectList;
            case "qrp":
                return this._quickObjectList;
            case "fx":
                return this._flexObjectList;
            case "api":
                return this._apiObjectList;
            case "rm":
                return this._richMenuObjectList;
            case "cp":
                return this._payloadObjectList;
            case "en":
                return this._entityObjectList;
            case "aud":
                return this._audioObjectList;
            default:
                return this._intentList ? this._intentList.data : [];
        }
    };

    private _appendNextIntentData = (): void => {
        const cacheNext = this._intentList.next;
        this._intentList.next = null;

        this._subscription.add(
            this._trainBotService
                .$getListIntentGroup({
                    bf_id: cacheNext.bf_id,
                    search: cacheNext.search,
                })
                .subscribe((response) => {
                    this._intentList.data = this._intentList.data.concat(response.data);
                    this._intentList.next = response.next;
                    this.onSelectState(this.state);
                })
        );
    };

    onTogglePlayAudio = (item: AudioList): void => {
        const state = item.played;
        this.dataSource.data.map((d) => (d.played = false));
        item.played = !state;
        if (!!this._audio) this._audio.pause();
        if (item.played) {
            this._audio = new Audio(item.url);
            this._audio.play();
        }
    };

    onSubmitSelectObject = (): void => {
        if (!this.selectedID && !this.selectedIDMultiple) return;

        if (this.data.multipleSelect) {
            return this._dialogRef.close({
                data: this.selectedIDMultiple,
            });
        }
        let obj = this._setDataSourceValue(this.state).filter((x) => x._id == this.selectedID._id)[0];
        this._dialogRef.close({
            name: this.state == "int" ? `{{${this.selectedID.name}}}` : this.selectedID.name,
            type: this.state,
            data: obj,
        });
    };

    onClose = (): void => {
        this._dialogRef.close();
    };
}
