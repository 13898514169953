<div class="add-key-container p-relative">
    <button mat-mini-fab bn-small-button color="primary" class="esc-bttn" (click)="onClose()">
        <mat-icon svgIcon="close"></mat-icon>
    </button>

    <div class="d-flex flex-column flex-center">
        <h2 class="mb-3"><state-i18n key="BTN_CLONE_CHATBOT"></state-i18n></h2>

        <div class="d-flex mb-2">
            <bn-form-field backgroundColor="#fff" style="width: 350px">
                <input
                    data-cy="input-chatbot-key"
                    i18n-placeholder="@@enter-chatbot-clone-key"
                    bnInput
                    [(ngModel)]="key"
                    placeholder="Enter chatbot clone key"
                />
            </bn-form-field>
        </div>

        <p i18n="@@enter-chatbot-clone-key-desc-1" class="mb-1 text-center text-light-grey">Enter a chatbot clone key to clone a chatbot.</p>
        <p i18n="@@@@enter-chatbot-clone-key-desc-2" class="mb-1 text-center text-light-grey">This process will take some time to complete.</p>

        <p
            class="mb-2 text-center text-blue"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            (mouseover)="mouseOnTooltip(true)"
            (mouseleave)="mouseOnTooltip(false)"
        >
            <state-i18n key="TITLE_HOW_TO_GET_KEY"></state-i18n>
        </p>

        <div class="d-flex align-items-center">
            <button mat-stroked-button class="mr-1" color="primary" (click)="onClose()">
                <state-i18n key="BTN_CANCEL"></state-i18n>
            </button>
            <button data-cy="submit-add-chatbot-key-button" mat-raised-button color="primary" (click)="confirmClone()">
                <state-i18n key="BTN_SUBMIT"></state-i18n>
            </button>
        </div>
    </div>
</div>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="onHover" [cdkConnectedOverlayPositions]="positions">
    <div class="tooltip-container">
        <h4 class="text-center"><state-i18n key="TITLE_HOW_TO_GET_KEY"></state-i18n></h4>
        <p i18n="@@how-get-chatbot-clone-key-1" class="text-center">
            1.) To clone your own chatbot, press the key icon to copy a clone key in the bot list page.
        </p>
        <img src="assets/tooltip/tooltip-gen-key.png" class="mb-1" />
        <p i18n="@@how-get-chatbot-clone-key-2" class="text-center">
            2.) To clone a chatbot that isn't yours, you can get a key from the owner using the same process.
        </p>
    </div>
</ng-template>
