import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { Observable, throwError } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { LocalService } from "@core/services/cache/local.service";
import { QrpObject } from "@core/models/object/qrp-object.model";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

interface QRPObjectResponse {
    _id: string;
    object_name: string;
    type: string;
    list_object: Array<{
        text: string;
        quick_reply: Array<{
            label: string;
            data: string;
            type: string;
        }>;
    }>;
}

@Injectable()
export class ManageQrpObjectService {
    get url() {
        return `${API_GATEWAYS_URL}platform-go/object/quickreply`;
    }

    constructor(private _http: HttpRequest, private local: LocalService) {}

    $getQrpObjectList = (): Observable<Array<QrpObject>> => {
        return this._http
            .get<Array<QRPObjectResponse>>({
                url: this.url.toString(),
                cacheMins: 1,
            })
            .pipe(
                retry(genericRetry),
                map((response) =>
                    response.map((res) =>
                        new QrpObject().deserialize({
                            _id: res._id,
                            name: res.object_name,
                            message: res.list_object[0].text,
                            buttons: !!res.list_object[0].quick_reply
                                ? res.list_object[0].quick_reply.map((b) => {
                                      return {
                                          label: b.label,
                                          message: b.data,
                                          type: b.type == "postback" ? "message" : b.type == "web_url" ? "url" : "phone",
                                      };
                                  })
                                : [],
                        })
                    )
                ),
                catchError((error) => throwError(() => error))
            );
    };

    $insertQrpObject = (body: QrpObject): Observable<{ _id: string }> => {
        const request = body.convertObjectToServer();
        delete request._id;
        return this._http
            .post<{ _id: string }>({
                url: this.url.toString(),
                body: request,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $duplicateQrpObject = (_id: string): Observable<QrpObject> => {
        return this._http
            .post<{ data: QRPObjectResponse }>({
                url: `${API_GATEWAYS_URL}platform-go/object/duplicate`,
                body: { _id: _id },
            })
            .pipe(
                retry(genericRetry),
                map((response) => {
                    this.clearCacheData();
                    return new QrpObject().deserialize({
                        _id: response?.data?._id,
                        name: response?.data?.object_name,
                        message: response?.data?.list_object[0].text,
                        buttons: !!response?.data?.list_object[0]?.quick_reply
                            ? response?.data?.list_object[0]?.quick_reply.map((b) => {
                                  return {
                                      label: b.label,
                                      message: b.data,
                                      type: b.type == "postback" ? "message" : b.type == "web_url" ? "url" : "phone",
                                  };
                              })
                            : [],
                    });
                }),
                catchError((error) => throwError(() => error))
            );
    };

    $updateQrpObject = (body: QrpObject): Observable<{ msg: string }> => {
        const request = body.convertObjectToServer();
        return this._http
            .put<{ msg: string }>({
                url: this.url.toString(),
                body: request,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteQrpObjectList = (body: Array<QrpObject>): Observable<{ msg: string }> => {
        return this._http
            .delete<{ msg: string }>({
                url: this.url.toString(),
                body: { _id: body.map((b) => b._id) },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    clearCacheData = (): void => {
        this.local.delete(this.url.toString());
    };
}
