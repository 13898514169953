import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable, throwError } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { HttpRequest, genericRetry } from "@core/http/http.service";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

export class IntentGroupRequest {
    bf_id?: string;
    bot_id?: string;
    search?: string;
    sort_by?: string;
    sort_dir?: string;
}
export class IntentGroupResponse {
    next: {
        bf_id?: string;
        bot_id?: string;
        search?: string;
        sort_by?: string;
        sort_dir?: string;
    };
    data: Array<IntentGroupDataResponse>;
}
export interface IntentGroupDataResponse {
    _id: string;
    name: string;
    count_trained: number;
    selected?: boolean;
    new_update?: boolean;
}

export class CreateIntentRequest {
    bot_id: string;
    name: string;
}

export class CreateIntentResponse {
    _id: string;
    name: string;
    count_trained: number;
}

export class EditIntentRequest {
    _id: string;
    bot_id?: string;
    name: string;
}

export class EditIntentResponse {
    _id: string;
    name: string;
    count_trained: number;
}

export class DeleteIntentRequest {
    _id: string;
    bot_id: string;
}

export class TrainRecord {
    _id: string;
    keyword: string;
    intent: string;
}

export class GetTrainRecordResponse {
    next?: {
        bot_id?: string;
        intent?: string;
        bf_id?: string;
        bf_cd?: string;
        search?: string;
        start?: string;
        end?: string;
    };
    data: TrainRecord[];
}

export class UntrainRecord {
    _id: string;
    ask: string;
    ans: string;
    intent: string;
    distance: number;
}

export class GetUntrainRecordResponse {
    next?: {
        bot_id?: string;
        intent?: string;
        bf_id?: string;
        bf_cd?: string;
        search?: string;
        start?: string;
        end?: string;
    };
    data: UntrainRecord[];
}

export class DatetimeSearch {
    start?: string;
    end?: string;
    search?: string; // keyword
    intent?: string;
    bf_id?: string;
    bf_cd?: string;
    sort_type?: "ascending" | "descending";
}

export class TextObject {
    type: "text";
    text: string;
}

export class IntentObject {
    type: "intent";
    text: string;
}

export class ImageListObject {
    original_url: string;
    preview_image_url: string;
}

export class CarouselObject {
    image_url: string;
    title: string;
    subtitle: string;
    buttons: Array<{
        type: string;
        label: string;
        data: string;
    }>;
}

export class ButtonObject {
    title: string;
    action: Array<{
        type: string;
        label: string;
        data: string;
    }>;
}

export class QuickReplyObject {
    text: string;
    quick_reply: Array<{
        type: string;
        label: string;
        data: string;
    }>;
}

// only line channel
export class FlexObject {
    type: "carousel" | "bubble";
    body: any;
    footer?: any;
    heroku?: any;
}

export class RichMenuObject {
    is_default_rich_menu: string;
    rich_menu_selected: string;
    rich_menu_image_url: string;
}

export class APIObject {
    api_url: string;
    parameter: object;
}

export class DialogueObject {
    nodeList: Array<{
        _nid: string;
        name: string;
        questionList: Array<string>;
        error: string;
    }>;
    edgeList: Array<{
        _eid: string;
        lineName: string;
        param: string;
        fromNode: string;
        toNode: string;
        condition: Array<{
            type: string;
            data: string;
        }>;
    }>;
    position: Array<{
        _id: string;
        transform: string;
    }>;
}

export class PayloadObject {
    payload_channel: string;
    payload: string;
}

export class AudioObject {
    audio_url: string;
    audio_duration: number;
}

export class GenericObject<T> {
    list_object?: Array<T>;
    _id?: string;
    text?: string;
    object_name?: string;
    type: string;
}

export class GetMappingMessageByIntentData {
    _id: string;
    intent: string;
    message: string;
    selected?: boolean;
    objects: Array<
        GenericObject<
            | TextObject
            | IntentObject
            | ButtonObject
            | QuickReplyObject
            | ImageListObject
            | CarouselObject
            | RichMenuObject
            | APIObject
            | DialogueObject
            | FlexObject
            | PayloadObject
            | AudioObject
        >
    >;
}

export class GetMappingMessageByIntent {
    data: Array<GetMappingMessageByIntentData>;
}

export class NLPConfig {
    bot_id?: string;
    default_messages: Array<DefaultMessage>;
    max_distance: number;
}

export class DefaultMessage {
    message: string;
    objects?: Array<
        GenericObject<
            | TextObject
            | IntentObject
            | ButtonObject
            | QuickReplyObject
            | ImageListObject
            | CarouselObject
            | RichMenuObject
            | APIObject
            | DialogueObject
            | FlexObject
            | PayloadObject
            | AudioObject
        >
    >;
    selected?: boolean;
}

export class NLPViewRecord {
    keyword: string;
    intent: string;
    _creby: string;
    _credate?: string;
    _upby: string;
    _update?: string;
}

export class CreateNLPViewRecordRequest {
    bot_id: string;
    keyword: string;
    intent: string;
}

export class CreateNLPViewRecordResponse {
    _id: string;
    bot_id: string;
    keyword: string;
    intent: string;
    is_mapping: boolean;
}

export class BulkCreateNLPViewRecordRequest {
    bot_id?: string;
    data: Array<{
        keyword: string;
        intent: string;
    }>;
}

export class BulkCreateNLPViewRecordResponse {
    message: string;
    status: string;
}

export class UpdateNLPViewRecordRequest {
    _id: string;
    bot_id: string;
    keyword: string;
    intent: string;
}

export class UpdateNLPViewRecordResponse {
    _id: string;
    bot_id: string;
    keyword: string;
    intent: string;
    is_mapping: boolean;
}

export class DeleteNLPViewRecordRequest {
    bot_id: string;
    _id: Array<string>;
}

export class ApproveTrainingLogRequest {
    bot_id?: string;
    data: Array<{
        _id: string;
        ans: string;
        ask: string;
    }>;
}

export class ApproveTrainingLogResponse {
    data: Array<{
        _id: string;
        keyword: string;
        intent: string;
    }>;
}

export class BulkDeleteTrainingLogRequest {
    bot_id: string;
    _ids: Array<string>;
}

export class BulkDeleteTrainingLogResponse {}

export class DeleteMappingMsgRequest {
    _id: Array<string>;
    bot_id: string;
}

export class DeleteMappingMsgResponse {}

export class UpdateMappingMsgRequest {
    _id: string;
    bot_id?: string;
    intent: string;
    message: string;
}

export class UpdateMappingMsgResponse {
    _id: string;
    bot_id: string;
    intent: string;
    message: string;
}

export class CreateMappingMsgRequest {
    bot_id?: string;
    intent: string;
    message: string;
}

export class CreateMappingMsgResponse {
    _id: string;
    bot_id: string;
    intent: string;
    message: string;
}

@Injectable()
export class TrainBotService {
    private _cacheDefaultMessage: NLPConfig;

    constructor(private _httpClient: HttpRequest) {}

    private _getDate(date: string, end?: boolean): number | string {
        if (end) return moment(date, "YYYY-MM-DD").endOf("day").unix();
        return moment(date, "YYYY-MM-DD").unix();
    }

    $createMappingMessage(body: CreateMappingMsgRequest): Observable<CreateMappingMsgResponse> {
        return this._httpClient
            .post<CreateMappingMsgResponse>({
                url: `${API_GATEWAYS_URL}platform-go/mapping`,
                body: body,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    }

    $updateMappingMessage(body: UpdateMappingMsgRequest): Observable<UpdateMappingMsgResponse> {
        return this._httpClient
            .put<UpdateMappingMsgResponse>({
                url: `${API_GATEWAYS_URL}platform-go/mapping`,
                body: body,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    }

    $deleteMappingMessage(body: DeleteMappingMsgRequest): Observable<DeleteMappingMsgResponse> {
        return this._httpClient
            .delete<DeleteMappingMsgResponse>({
                url: `${API_GATEWAYS_URL}platform-go/mapping`,
                body: body,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    }

    $createIntent = (body: CreateIntentRequest): Observable<CreateIntentResponse> => {
        return this._httpClient
            .post<CreateIntentResponse>({
                url: `${API_GATEWAYS_URL}platform-go/intent`,
                body: body,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $editIntent = (body: EditIntentRequest): Observable<EditIntentResponse> => {
        return this._httpClient
            .put<EditIntentResponse>({
                url: `${API_GATEWAYS_URL}platform-go/intent`,
                body: body,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteIntent = (body: DeleteIntentRequest): Observable<any> => {
        return this._httpClient
            .delete({
                url: `${API_GATEWAYS_URL}platform-go/intent`,
                body: body,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteIntentList = (body: Array<string>): Observable<any> => {
        return this._httpClient
            .delete({
                url: `${API_GATEWAYS_URL}platform-go/intent/bulk`,
                body: {
                    ids: body,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getListIntentGroup = (body: IntentGroupRequest): Observable<IntentGroupResponse> => {
        const url = new URL(`${API_GATEWAYS_URL}platform-go/intent`);
        if (!!body?.search) url.searchParams.set("search", body.search);
        if (!!body?.bf_id) url.searchParams.set("bf_id", body.bf_id);
        if (!!body?.sort_by) url.searchParams.set("sort_by", body.sort_by);
        if (!!body?.sort_dir) url.searchParams.set("sort_dir", body.sort_dir);

        return this._httpClient
            .get<IntentGroupResponse>({
                url: url.toString(),
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getNLPConfig(): Observable<NLPConfig> {
        return this._httpClient
            .get<NLPConfig>({
                url: `${API_GATEWAYS_URL}platform-go/nlp/config`,
                cacheMins: 0.5,
            })
            .pipe(
                retry(genericRetry),
                tap((response) => {
                    this._cacheDefaultMessage = response;
                }),
                catchError((error) => throwError(() => error))
            );
    }

    $postNLPConfig(distance: number, messages: Array<DefaultMessage>): Observable<NLPConfig> {
        const body = new NLPConfig();
        if (distance == null || distance == undefined) body.max_distance = this._cacheDefaultMessage.max_distance;
        if (!messages) body.default_messages = this._cacheDefaultMessage.default_messages;

        if (!!distance) body.max_distance = distance;
        if (!!messages) {
            body.default_messages = messages.map((message, index) => {
                return {
                    message: message.message,
                    selected: message.selected ?? this._cacheDefaultMessage?.default_messages[index]?.selected ?? true,
                };
            });
        }

        return this._httpClient
            .post<NLPConfig>({
                url: `${API_GATEWAYS_URL}platform-go/nlp/config`,
                body: body,
                responseType: "text",
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                tap((_) => {
                    this._cacheDefaultMessage = body;
                }),
                catchError((error) => throwError(() => error))
            );
    }

    $bulkCreateNlpViewRecord = (body: BulkCreateNLPViewRecordRequest): Observable<BulkCreateNLPViewRecordResponse> => {
        return this._httpClient
            .post<BulkCreateNLPViewRecordResponse>({
                url: `${API_GATEWAYS_URL}platform-go/nlp/record`,
                body: body,
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $createNlpViewRecord = (body: CreateNLPViewRecordRequest): Observable<CreateNLPViewRecordResponse> => {
        return this._httpClient
            .post<CreateNLPViewRecordResponse>({
                url: `${API_GATEWAYS_URL}platform-go/nlp/record/v2`,
                body: body,
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $bulkDeleteNlpTrainingLog = (body: BulkDeleteTrainingLogRequest): Observable<BulkDeleteTrainingLogResponse> => {
        return this._httpClient
            .delete<BulkDeleteTrainingLogResponse>({
                url: `${API_GATEWAYS_URL}platform-go/nlp/training/bulk`,
                body: body,
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteBulkNlpTrainingLogByIntent = (intent: string, query: string): Observable<void> => {
        return this._httpClient
            .delete<void>({
                url: `${API_GATEWAYS_URL}platform-go/nlp/training/bulk-by-intent`,
                body: {
                    intent: intent,
                    search: query,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $approveNlpTrainingLog = (body: ApproveTrainingLogRequest): Observable<ApproveTrainingLogResponse> => {
        return this._httpClient
            .post<ApproveTrainingLogResponse>({
                url: `${API_GATEWAYS_URL}platform-go/nlp/training/v2/approve`,
                body: body,
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $updateNlpViewRecord = (body: UpdateNLPViewRecordRequest): Observable<UpdateNLPViewRecordResponse> => {
        return this._httpClient
            .put<UpdateNLPViewRecordResponse>({
                url: `${API_GATEWAYS_URL}platform-go/nlp/record/v2`,
                body: body,
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteNlpViewRecord = (body: DeleteNLPViewRecordRequest): Observable<string> => {
        return this._httpClient
            .delete<string>({
                url: `${API_GATEWAYS_URL}platform-go/nlp/record/bulk`,
                body: body,
                responseType: "text",
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteBulkNlpViewRecordByIntent = (intent: string, query: string): Observable<void> => {
        return this._httpClient
            .delete<void>({
                url: `${API_GATEWAYS_URL}platform-go/nlp/record/bulk-by-intent`,
                body: {
                    intent: intent,
                    search: query,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getNlpViewRecordByIntent = (query: DatetimeSearch): Observable<GetTrainRecordResponse> => {
        const start_time = this._getDate(query.start);
        const end_time = this._getDate(query.end, true);

        const url = new URL(`${API_GATEWAYS_URL}platform-go/nlp/record/v2/by-intent`);
        url.searchParams.set("start", start_time.toString());
        url.searchParams.set("end", end_time.toString());
        url.searchParams.set("intent", query.intent);
        if (!!query?.search) url.searchParams.set("search", query.search);
        if (!!query?.bf_id) url.searchParams.set("bf_id", query.bf_id);

        return this._httpClient
            .get<GetTrainRecordResponse>({
                url: url.toString(),
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getNlpViewRecordByPredefinedIntent = (query: DatetimeSearch): Observable<GetTrainRecordResponse> => {
        const start_time = this._getDate(query.start);
        const end_time = this._getDate(query.end, true);

        return this._httpClient
            .get<GetTrainRecordResponse>({
                url: `${API_GATEWAYS_URL}platform-go/intent/pre-defined/record?start=${start_time}&end=${end_time}&search=${query.search}&intent=${query.intent}&bf_id=${query.bf_id}`,
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getNlpViewRecordByChitchat = (query: DatetimeSearch): Observable<GetTrainRecordResponse> => {
        const start_time = this._getDate(query.start);
        const end_time = this._getDate(query.end, true);

        const url = new URL(`${API_GATEWAYS_URL}platform-go/nlp/record/v2/chitchat`);
        url.searchParams.set("start", start_time.toString());
        url.searchParams.set("end", end_time.toString());
        if (!!query?.search) url.searchParams.set("search", query.search);
        if (!!query?.bf_id) url.searchParams.set("bf_id", query.bf_id);

        return this._httpClient
            .get<GetTrainRecordResponse>({
                url: url.toString(),
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteBulkNlpViewRecordByChitchat = (query: string): Observable<void> => {
        return this._httpClient
            .delete<void>({
                url: `${API_GATEWAYS_URL}platform-go/nlp/record/chitchat/bulk`,
                body: {
                    search: query,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getNlpTrainingLog = (query: DatetimeSearch): Observable<GetUntrainRecordResponse> => {
        const start_time = this._getDate(query.start);
        const end_time = this._getDate(query.end, true);

        return this._httpClient
            .get<GetUntrainRecordResponse>({
                url: `${API_GATEWAYS_URL}platform-go/nlp/training/v2/all?start=${start_time}&end=${end_time}&search=${query.search}&bf_id=${
                    query.bf_id
                }&sort_type=${query.sort_type ? query.sort_type : ""}`,
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getNlpTrainingLogByIntent = (query: DatetimeSearch): Observable<GetUntrainRecordResponse> => {
        const start_time = this._getDate(query.start);
        const end_time = this._getDate(query.end, true);

        const url = new URL(`${API_GATEWAYS_URL}platform-go/nlp/training/v2/by-intent`);
        url.searchParams.set("start", start_time.toString());
        url.searchParams.set("end", end_time.toString());
        url.searchParams.set("intent", query.intent);
        if (!!query?.search) url.searchParams.set("search", query.search);
        if (!!query?.bf_id) url.searchParams.set("bf_id", query.bf_id);
        if (!!query?.sort_type) url.searchParams.set("sort_type", query.sort_type);

        return this._httpClient
            .get<GetUntrainRecordResponse>({
                url: url.toString(),
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getNlpTrainingLogByChitchat = (query: DatetimeSearch): Observable<GetUntrainRecordResponse> => {
        const start_time = this._getDate(query.start);
        const end_time = this._getDate(query.end, true);

        const url = new URL(`${API_GATEWAYS_URL}platform-go/nlp/training/v2/chitchat`);
        url.searchParams.set("start", start_time.toString());
        url.searchParams.set("end", end_time.toString());
        if (!!query?.search) url.searchParams.set("search", query.search);
        if (!!query?.bf_id) url.searchParams.set("bf_id", query.bf_id);
        if (!!query?.sort_type) url.searchParams.set("sort_type", query.sort_type);

        return this._httpClient
            .get<GetUntrainRecordResponse>({
                url: url.toString(),
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteBulkNlpTrainingLogByChitchat = (query: string): Observable<void> => {
        return this._httpClient
            .delete<void>({
                url: `${API_GATEWAYS_URL}platform-go/nlp/training/chit-chat/bulk`,
                body: {
                    search: query,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getNlpTrainingLogByDefaultMessage = (query: DatetimeSearch): Observable<GetUntrainRecordResponse> => {
        const start_time = this._getDate(query.start);
        const end_time = this._getDate(query.end, true);

        const url = new URL(`${API_GATEWAYS_URL}platform-go/nlp/training/v2/default-msg`);
        url.searchParams.set("start", start_time.toString());
        url.searchParams.set("end", end_time.toString());
        if (!!query?.search) url.searchParams.set("search", query.search);
        if (!!query?.bf_id) url.searchParams.set("bf_id", query.bf_id);
        if (!!query?.sort_type) url.searchParams.set("sort_type", query.sort_type);

        return this._httpClient
            .get<GetUntrainRecordResponse>({
                url: url.toString(),
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteBulkNlpTrainingLogDefault = (query: string): Observable<void> => {
        return this._httpClient
            .delete<void>({
                url: `${API_GATEWAYS_URL}platform-go/nlp/training/default/bulk`,
                body: {
                    search: query,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getMappingMessageByIntent = (intent: string): Observable<GetMappingMessageByIntent> => {
        return this._httpClient
            .get<GetMappingMessageByIntent>({
                url: `${API_GATEWAYS_URL}platform-go/mapping/by-intent?intent=${intent}`,
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $updateSelectedResponseByIntent = (body: { _id: string; selected: boolean }): Observable<{ msg: string }> => {
        return this._httpClient
            .put<{ msg: string }>({
                url: `${API_GATEWAYS_URL}platform-go/mapping/selected`,
                body: body,
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getRandomChitchat = (): Observable<string> => {
        return this._httpClient
            .get<string>({
                url: `${API_GATEWAYS_URL}platform-go/nlp/record/chitchat-rnd-msg`,
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $bulkCreateNlpRandomViewRecord = (body: BulkCreateNLPViewRecordRequest): Observable<BulkCreateNLPViewRecordResponse> => {
        return this._httpClient
            .post<BulkCreateNLPViewRecordResponse>({
                url: `${API_GATEWAYS_URL}platform-go/nlp/record/chitchat-rnd-msg`,
                body: body,
                cacheMins: 0,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };
}
