import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { BotProfileService } from "@core/services/authentication/bot-profile.service";
import { ManageBotRequestService } from "@core/http/bot/manage-bot-request.service";
import { SnackBarAlertService } from "@shared/services/snack-bar-alert/snack-bar-alert.service";
import { I18nState, getI18nState } from "@shared/translation/i18n-state";

@Component({
    selector: "app-clone-chatbot-dialog",
    templateUrl: "./clone-chatbot-dialog.component.html",
    styleUrls: ["./clone-chatbot-dialog.component.scss"],
})
export class CloneChatbotDialogComponent implements OnInit, OnDestroy {
    private _subscription: Subscription;

    get bot_id() {
        return this._data ?? this._botProfile?.getID ?? null;
    }

    private _key: string;
    get key() {
        return this._key;
    }

    private _fetching: boolean;
    get fetching() {
        return this._fetching;
    }

    constructor(
        private _matDialogRef: MatDialogRef<CloneChatbotDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: string,
        private _botProfile: BotProfileService,
        private _manageBotService: ManageBotRequestService,
        private _snackbar: SnackBarAlertService
    ) {
        this._subscription = new Subscription();
        this._key = "";
        this._fetching = true;
    }

    ngOnInit(): void {
        this._subscription.add(
            this._manageBotService.$generatePublicKey(this.bot_id).subscribe({
                next: (response) => {
                    this._fetching = false;
                    this._key = response.key;
                },
                error: (error) => {
                    console.error(error);
                    this._fetching = false;
                    this._snackbar.open("error", getI18nState(I18nState.GLOBAL_ERR_MSG));
                },
            })
        );
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    openSnackClipboard = (): void => {
        this._snackbar.open("success", $localize`:@@success-copied-clone-key:Successfully copied the clone key!`);
    };

    onClose = (): void => {
        this._matDialogRef.close();
    };
}
