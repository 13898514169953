export const environment = {
    production: true,
    ngsw: true,
    apiGatewaysURL: "https://api-gateway-enterprise.botnoi.ai/",
    socketGatewayURL: "wss://api-gateway-enterprise.botnoi.ai",
    key: "Botnoi-Enterprise",
    gaID: "G-9P8KE0565R",
    faqID: "6087c4d5e527050749207d18",
    featureFlags: {
        featurePayment: false,
        featureAds: false,
        featureMarketplace: false,
        featureDatabase: false,
        featureAnnouncement: false,
        featurePredefined: false,
        featureAudioObject: false,
        featureSwitchRichmenu: false,
        featureAutomateTest: false,
        featureDashboardRealtime: false,
        featurePersistent: false,
        featureEx10: false,
    },
};
