import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { LocalService } from "@core/services/cache/local.service";
import { PayloadObject } from "@core/models/object/payload-object.model";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

interface ResponsePayload {
    _id: string;
    object_name: string;
    type: string;
    list_object: Array<{ payload_channel: string; payload: string }>;
}

@Injectable()
export class ManagePayloadObjectService {
    get url() {
        return `${API_GATEWAYS_URL}platform-go/object/custom_payload`;
    }

    constructor(private _http: HttpRequest, private local: LocalService) {}

    $getObjectList = (): Observable<Array<PayloadObject>> => {
        return this._http
            .get<Array<ResponsePayload>>({
                url: this.url.toString(),
                cacheMins: 1,
            })
            .pipe(
                retry(genericRetry),
                map((response) =>
                    response.map((res) =>
                        new PayloadObject().deserialize({
                            _id: res._id,
                            name: res.object_name,
                            type: res.list_object[0]?.payload_channel ?? "",
                            body: JSON.parse(res?.list_object[0]?.payload ?? ""),
                        })
                    )
                ),
                catchError((error) => throwError(() => error))
            );
    };

    $insertObject = (body: PayloadObject): Observable<{ _id: string }> => {
        const request = body.convertObjectToServer();
        delete request._id;
        return this._http
            .post<{ _id: string }>({
                url: this.url.toString(),
                body: request,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $duplicateObject = (_id: string): Observable<PayloadObject> => {
        return this._http
            .post<{ data: ResponsePayload }>({
                url: `${API_GATEWAYS_URL}platform-go/object/duplicate`,
                body: { _id: _id },
            })
            .pipe(
                retry(genericRetry),
                map((response) => {
                    this.clearCacheData();
                    return new PayloadObject().deserialize({
                        _id: response?.data?._id,
                        name: response?.data?.object_name,
                        type: response?.data?.list_object[0]?.payload_channel ?? "",
                        body: JSON.parse(response?.data?.list_object[0]?.payload ?? ""),
                    });
                }),
                catchError((error) => throwError(() => error))
            );
    };

    $updateObject = (body: PayloadObject): Observable<{ _id: string }> => {
        const request = body.convertObjectToServer();
        return this._http
            .put<{ _id: string }>({
                url: this.url.toString(),
                body: request,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteObjectList = (body: Array<PayloadObject>): Observable<{ msg: string }> => {
        return this._http
            .delete<{ msg: string }>({
                url: this.url.toString(),
                body: { _id: body.map((b) => b._id) },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    clearCacheData = (): void => {
        this.local.delete(this.url.toString());
    };
}
