import { Component, Inject, OnDestroy } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription, Subject, interval, of } from "rxjs";
import { filter, startWith, switchMap, takeUntil } from "rxjs/operators";
import { UploadService, ImportProgressModel } from "@core/http/s3/upload.service";
import { SnackBarAlertService } from "@shared/services/snack-bar-alert/snack-bar-alert.service";
import { AlertDialogComponent, ImportFileStatusComponent, ConfirmationDialogComponent } from "@shared/components/dialog";
import { I18nState, getI18nState } from "@shared/translation/i18n-state";

@Component({
    selector: "app-browse-file",
    templateUrl: "./browse-file.component.html",
    styleUrls: ["./browse-file.component.scss"],
})
export class BrowseFileComponent implements OnDestroy {
    private _subscription: Subscription;

    $progress: Subject<number>;
    private _progress: number;
    get uploadSize() {
        const size = <number>this.fileSize;
        return this._progress > 0 ? ((this._progress * size) / 100).toFixed(3) : 0;
    }

    private _isUploading: boolean;
    get isUploading() {
        return this._isUploading;
    }

    get type() {
        return this.data;
    }

    get title() {
        switch (this.type) {
            case "nlp":
                return getI18nState(I18nState.TITLE_TRAIN_BOT);
            case "image":
                return getI18nState(I18nState.OBJ_IMG);
            case "api":
                return getI18nState(I18nState.OBJ_API);
            case "dialogue":
                return getI18nState(I18nState.OBJ_DL);
            case "button":
                return getI18nState(I18nState.OBJ_BTN);
            case "carousel":
                return getI18nState(I18nState.OBJ_CRS);
            case "quickreply":
                return getI18nState(I18nState.OBJ_QRP);
            case "custom_payload":
                return getI18nState(I18nState.OBJ_CUSTOM);
            case "flex":
                return getI18nState(I18nState.OBJ_FX);
            case "entity":
                return `:@@entity:Entity`;
            default:
                return this.type;
        }
    }

    private _file: File;
    get file() {
        return this._file;
    }

    get hasFile() {
        return this.file ? true : false;
    }

    get fileName() {
        return this.hasFile ? this.file.name : "";
    }
    get fileSize() {
        return this.hasFile ? (this.file.size / 1000000).toFixed(3) : 0;
    }

    constructor(
        private _matDialogRef: MatDialogRef<BrowseFileComponent>,
        private _matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        private data: "nlp" | "image" | "api" | "dialogue" | "button" | "carousel" | "quickreply" | "custom_payload" | "flex" | "entity",
        private _uploadService: UploadService,
        private _snackbar: SnackBarAlertService
    ) {
        this._subscription = new Subscription();
        this._progress = 0;
        this.$progress = new Subject<number>();
        this._isUploading = false;
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }

    onSelectFile = ($event: InputEvent | Event): void => {
        const input = <HTMLInputElement>$event.target;
        this._file = input.files[0];
    };

    onDownloadTemplate = (): void => {
        let url: string = null;
        switch (this.type) {
            case "image":
                url = `${this._uploadService.templateURL}example_img_objs.xlsx`;
                break;
            case "api":
                url = `${this._uploadService.templateURL}example_api_objs.xlsx`;
                break;
            case "dialogue":
                url = `${this._uploadService.templateURL}example_dl_objs.xlsx`;
                break;
            case "button":
                url = `${this._uploadService.templateURL}example_btn_objs.xlsx`;
                break;
            case "carousel":
                url = `${this._uploadService.templateURL}example_crs_objs.xlsx`;
                break;
            case "quickreply":
                url = `${this._uploadService.templateURL}example_qrp_objs.xlsx`;
                break;
            case "custom_payload":
                url = `${this._uploadService.templateURL}example_custom_payload_objs.xlsx`;
                break;
            case "flex":
                url = `${this._uploadService.templateURL}example_flex_objs.xlsx`;
                break;
            case "entity":
                url = `${this._uploadService.templateURL}example_entities.xlsx`;
                break;
            default:
                url = null;
        }
        if (url) window.open(url, `_blank`);
    };

    onDownloadNLPTemplate = (type?: string): void => {
        let url: string = null;
        switch (type) {
            case "intent-phrase":
                url = `${this._uploadService.templateURL}example_intent_phrase.xlsx`;
                break;
            case "intent-response":
                url = `${this._uploadService.templateURL}example_intent_response.xlsx`;
                break;
            case "chitchat-response":
                url = `${this._uploadService.templateURL}example_chitchat_response.xlsx`;
                break;
            default:
                url = `${this._uploadService.templateURL}example_nlp_view_records.xlsx`;
        }
        if (url) window.open(url, "_blank");
    };

    onUploadObject = (): void => {
        this._subscription.add(
            this._matDialog
                .open(ConfirmationDialogComponent, {
                    data: {
                        state: "confirm",
                        title: $localize`:@@replace-import-title:Some file name already exists.<br>Do you want to replace it?`,
                        description: $localize`:@@replace-import-description:A file with the same name already exists on the platform. Replacing it will overwrite its current contents.`,
                        submitLabel: $localize`:@@replace:Replace`,
                        noIcon: true,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result) this._uploadObjectFromTemplate();
                })
        );
    };

    private _uploadObjectFromTemplate = (): void => {
        this._isUploading = true;

        this._subscription.add(
            this._uploadService
                .$importObject(this.type, this.file)
                .pipe(
                    switchMap((response) => {
                        if (!!response.status_id)
                            return interval(3000).pipe(
                                startWith(0),
                                switchMap((_) => this._uploadService.$importObjectValidate(this.type, response.status_id)),
                                takeUntil(this.$progress.pipe(filter((progress) => progress == 100)))
                            );

                        return of<ImportProgressModel>({
                            status_id: "",
                            status: "success",
                            message: "",
                            success_excel: 0,
                            errors_excel: [],
                            process: 100,
                        });
                    })
                )
                .subscribe({
                    next: (response) => {
                        if (!!response?.process) this._progress = response.process;
                        if (!response?.process) if (this._progress < 90) this._progress += 10;
                        this.$progress.next(this._progress);

                        if (response.status == "success") {
                            this._isUploading = false;

                            if (this.type == "nlp") {
                                this._snackbar.open("success", $localize`:@@success-import:Import ${this.title} successful`);
                                this._matDialogRef.close(true);
                                return;
                            }

                            this._subscription.add(
                                this._matDialog
                                    .open(ImportFileStatusComponent, {
                                        data: response,
                                    })
                                    .afterClosed()
                                    .subscribe((_) => {
                                        if (!!response.success_excel) this._matDialogRef.close(true);
                                    })
                            );
                        }
                    },
                    error: (error) => {
                        console.error(error);
                        this._isUploading = false;
                        this._progress = 0;
                        this.$progress.next(this._progress);
                        this._file = null;

                        if (error.status == 0) return;

                        this._subscription.add(
                            this._matDialog
                                .open(AlertDialogComponent, {
                                    data: {
                                        state: "error",
                                        title: $localize`:@@import-file-status-title:Import File Status`,
                                        description: $localize`:@@import-file-status-desc:Please correct the mistake in your import file and re-import it.`,
                                    },
                                })
                                .afterClosed()
                                .subscribe((_) => this._matDialogRef.close())
                        );
                        this._snackbar.open("error", getI18nState(I18nState.GLOBAL_ERR_MSG));
                    },
                })
        );
    };
}
