import { Component } from "@angular/core";

@Component({
    selector: "app-manage-bot-layout",
    templateUrl: "./manage-bot-layout.component.html",
    styleUrls: ["./manage-bot-layout.component.scss"],
})
export class ManageBotLayoutComponent {
    constructor() {}
}
