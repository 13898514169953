import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { map, catchError, retry, switchMap } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { LocalService } from "@core/services/cache/local.service";
import { UploadService } from "@core/http/s3/upload.service";
import { RmObject } from "@core/models/object/rm-object.model";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

interface RMObjectResponse {
    _id: string;
    object_name: string;
    type: string;
    list_object?: Array<{
        rich_menu_selected: boolean;
        rich_menu_image_url: string;
        rich_menu_image?: string;
        is_default_rich_menu: boolean;
        rich_menu_chat_bar_text?: string;
        rich_menu_size?: { width: number; height: number };
        areas?: Array<{
            bounds: {
                x: number;
                y: number;
                width: number;
                height: number;
            };
            action: {
                label: string;
                data: string;
                type: string;
                alt_uri: {
                    desktop: string;
                };
            };
        }>;
    }>;
}

@Injectable()
export class ManageRmObjectService {
    get url() {
        return `${API_GATEWAYS_URL}platform-go/object/rich_menu`;
    }

    constructor(private _http: HttpRequest, private local: LocalService, private _upload: UploadService) {}

    $getRmObjectList = (): Observable<Array<RmObject>> => {
        return this._http
            .get<Array<RMObjectResponse>>({
                url: this.url.toString(),
                cacheMins: 1,
            })
            .pipe(
                retry(genericRetry),
                map((response) =>
                    response.map((res) =>
                        new RmObject().deserialize({
                            _id: res._id,
                            name: res.object_name,
                            url: res?.list_object[0]?.rich_menu_image_url,
                            default: res?.list_object[0]?.is_default_rich_menu,
                            selected_auto: res?.list_object[0]?.rich_menu_selected,
                        })
                    )
                ),
                catchError((error) => throwError(() => error))
            );
    };

    $getRmObjectDetail = (_id: string): Observable<RmObject> => {
        return this._http
            .get<RMObjectResponse>({
                url: `${this.url.toString()}/detail?id=${_id}`,
            })
            .pipe(
                retry(genericRetry),
                map((response) =>
                    new RmObject().deserialize({
                        _id: response._id,
                        name: response.object_name,
                        url: response?.list_object[0]?.rich_menu_image_url,
                        default: response?.list_object[0]?.is_default_rich_menu,
                        selected_auto: response?.list_object[0]?.rich_menu_selected,
                        size: response?.list_object[0]?.rich_menu_size,
                        actions: response?.list_object[0]?.areas.map((area, index) => {
                            return {
                                action: {
                                    label: area.action.label,
                                    type: area.action.type == "uri" ? "url" : area.action.type,
                                    message: area.action.data,
                                },
                                bound: {
                                    order: index,
                                    x: area.bounds.x,
                                    y: area.bounds.y,
                                },
                            };
                        }),
                    })
                ),
                catchError((error) => throwError(() => error))
            );
    };

    $insertRmObject = (body: RmObject): Observable<{ _id: string }> => {
        return of(body.hasFile).pipe(
            switchMap((state) => {
                if (!state) return throwError(() => ({ status: 204, message: "no contents" }));

                return this._upload.$onUploadImage(body.cacheFile).pipe(
                    retry(genericRetry),
                    switchMap((url) => {
                        body.onUpdateRichImage(url.file_url);
                        const request = body.convertObjectToServer();
                        delete request._id;
                        return this._http
                            .post<{ _id: string }>({
                                url: this.url.toString(),
                                body: request,
                            })
                            .pipe(
                                retry(genericRetry),
                                catchError((error) => throwError(() => error))
                            );
                    })
                );
            }),
            catchError((error) => throwError(() => error))
        );
    };

    $duplicateRmObject = (_id: string): Observable<RmObject> => {
        return this._http
            .post<{ data: RMObjectResponse }>({
                url: `${API_GATEWAYS_URL}platform-go/object/rich_menu/duplicate`,
                body: { _id: _id },
            })
            .pipe(
                retry(genericRetry),
                map((response) => {
                    this.clearCacheData();
                    return new RmObject().deserialize({
                        _id: response?.data?._id,
                        name: response?.data?.object_name,
                        url: response?.data?.list_object[0]?.rich_menu_image_url ?? response?.data?.list_object[0]?.rich_menu_image,
                        default: response?.data?.list_object[0]?.is_default_rich_menu,
                        selected_auto: response?.data?.list_object[0]?.rich_menu_selected,
                    });
                }),
                catchError((error) => throwError(() => error))
            );
    };

    $updateRmObject = (body: RmObject): Observable<{ msg: string }> => {
        return of(body?.hasFile).pipe(
            switchMap((state) => {
                if (state)
                    return this._upload.$onUploadImage(body.cacheFile).pipe(
                        retry(genericRetry),
                        map((response) => {
                            return { state: state, img_url: response.file_url };
                        }),
                        catchError((error) => throwError(() => error))
                    );
                return of({ state: state, img_url: null });
            }),
            switchMap((response) => {
                if (response?.img_url) body.onUpdateRichImage(response.img_url);
                const request = body.convertObjectToServer();
                return this._http
                    .put<{ msg: string }>({
                        url: this.url.toString(),
                        body: request,
                    })
                    .pipe(
                        retry(genericRetry),
                        catchError((error) => throwError(() => error))
                    );
            })
        );
    };

    $deleteRmObjectList = (body: Array<RmObject>): Observable<{ msg: string }> => {
        return this._http
            .delete<{ msg: string }>({
                url: `${this.url.toString()}/bulk`,
                body: { _ids: body.map((b) => b._id) },
            })
            .pipe(
                retry(genericRetry),
                map((response) => {
                    this.clearCacheData();
                    return response;
                }),
                catchError((error) => throwError(() => error))
            );
    };

    clearCacheData = (): void => {
        this.local.delete(this.url.toString());
    };
}
