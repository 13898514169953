import { Component, Input, OnInit, OnDestroy, ViewChildren, QueryList } from "@angular/core";
import { Subject, Observable, Subscription, timer } from "rxjs";
import { ESCAPE } from "@angular/cdk/keycodes";
import { ConnectionPositionPair } from "@angular/cdk/overlay";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { environment as env } from "@env/environment";
import { BotProfile } from "@core/models/bot/bot-profile.model";
import { UserProfileService } from "@core/services/authentication/user-profile.service";
import { ManageBotRequestService } from "@core/http/bot/manage-bot-request.service";
import { ObjectService } from "@core/http/object/object.service";
import { BotProfileService } from "@core/services/authentication/bot-profile.service";
import { SocketService } from "@core/http/livechat/socket.service";

@Component({
    selector: "side-bar",
    templateUrl: "./side-bar.component.html",
    styleUrls: ["./side-bar.component.scss"],
})
export class SideBarComponent implements OnInit, OnDestroy {
    get featureFlags() {
        return env.featureFlags;
    }

    @Input() expand: boolean = false;

    isSelectBotToggle: boolean;

    private _subscriptionBotProfile: Subscription;
    private _bot_name: string;
    get bot_name() {
        return this._bot_name;
    }

    private _avatar: string;
    get avatar() {
        return this._avatar === "" ? "assets/botnoi-logo/logo.svg" : this._avatar;
    }

    get username() {
        return this._usrProfile.getUsername() ?? "";
    }

    $isConnectedLine: Subject<boolean>;
    private _isConnectedLine: boolean;
    get isConnectedLine() {
        return this._isConnectedLine;
    }

    $isConnectedFacebook: Subject<boolean>;
    private _isConnectedFacebook: boolean;
    get isConnectedFacebook() {
        return this._isConnectedFacebook;
    }

    @ViewChildren(CdkVirtualScrollViewport) private _viewports: QueryList<CdkVirtualScrollViewport>;
    private _subscription: Subscription;
    private _$botList: Observable<Array<BotProfile>>;
    $myBots: Subject<Array<BotProfile>>;
    $myBotsLength: Subject<number>;
    $sharedBots: Subject<Array<BotProfile>>;
    $sharedBotsLength: Subject<number>;

    private _cacheMybot: Array<BotProfile>;
    private _cacheSharedBot: Array<BotProfile>;

    private onConnectStateSubscription: Subscription;
    private _numberOfAgent: number;
    get hasUserInAgent() {
        return this._numberOfAgent > 0;
    }
    get userNumberInAgent() {
        return this._numberOfAgent;
    }

    defaultPositions = [new ConnectionPositionPair({ originX: "end", originY: "bottom" }, { overlayX: "center", overlayY: "top" }, 0, 0)];
    overlayKeydown = ($event: KeyboardEvent): void => {
        if ($event.keyCode == ESCAPE) this.isSelectBotToggle = false;
    };

    constructor(
        private _manageBotService: ManageBotRequestService,
        private _usrProfile: UserProfileService,
        private _botProfile: BotProfileService,
        private _objService: ObjectService,
        private _socketService: SocketService
    ) {
        this.isSelectBotToggle = false;

        this.$isConnectedLine = new Subject();
        this.$isConnectedFacebook = new Subject();
        this.$isConnectedLine.subscribe((s) => (this._isConnectedLine = s));
        this.$isConnectedFacebook.subscribe((s) => (this._isConnectedFacebook = s));

        this._subscription = new Subscription();
        this.$myBots = new Subject();
        this.$myBotsLength = new Subject();
        this.$sharedBots = new Subject();
        this.$sharedBotsLength = new Subject();

        this._numberOfAgent = 0;
    }

    private _triggerFetchBotList = (): void => {
        this._$botList = this._manageBotService.$getBotList();

        this._subscription.add(
            this._$botList.subscribe({
                next: (response) => this._groupingChatbotList(response),
                error: (error) => {
                    console.error(error);
                },
            })
        );
    };

    private _groupingChatbotList = (chatbot: Array<BotProfile>): void => {
        this._cacheMybot = chatbot.filter((bots) => bots.owner == this.username);
        this._cacheSharedBot = chatbot.filter((bots) => bots.owner !== this.username);
    };

    ngOnInit(): void {
        this.$isConnectedLine.next(false);
        this.$isConnectedFacebook.next(false);
        this._subscriptionBotProfile = this._botProfile.$profile.subscribe((response) => {
            this.$isConnectedLine.next(response.isLineConnected || response.isLineModularConnected);
            this.$isConnectedFacebook.next(response.isFacebookConnected);
            this._avatar = response.bot_avatar;
            this._bot_name = response.bot_name;
        });

        this._triggerFetchBotList();

        this._socketService.onInitSocketState();
        this._socketService.onInitAgentStateSubject();

        this.onConnectStateSubscription = this._socketService.onSubscribeAgentState().subscribe((number) => (this._numberOfAgent = number));
    }

    ngOnDestroy(): void {
        if (this._subscriptionBotProfile) this._subscriptionBotProfile.unsubscribe();
        if (this.onConnectStateSubscription) this.onConnectStateSubscription.unsubscribe();
        this._subscription.unsubscribe();
    }

    onToggleSelectBot = (): void => {
        this.isSelectBotToggle = !this.isSelectBotToggle;
        this._subscription.add(
            timer(100).subscribe((_) => {
                if (this.isSelectBotToggle) {
                    this.$myBots.next(this._cacheMybot);
                    this.$myBotsLength.next(this._cacheMybot.length);
                    this.$sharedBots.next(this._cacheSharedBot);
                    this.$sharedBotsLength.next(this._cacheSharedBot.length);
                    this._viewports.forEach((viewport) => viewport.checkViewportSize());
                }
            })
        );
    };

    onChangeBot = (data: BotProfile): void => {
        this._botProfile.resetBotProfile();
        this._objService.clearCacheData();
        this._botProfile.initBotProfile(data);
        this.isSelectBotToggle = false;

        this._subscription.add(
            timer(100).subscribe((_) => {
                window.location.reload();
            })
        );
    };
}
