import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import * as moment from "moment";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ManageBotRequestService, SubscriptionService } from "@core/http/bot";
import { SubscriptionPackage } from "@core/models/bot/subscription-plan.model";
import { MarketPlaceService } from "@core/http/market-place/market-place.service";
import { MarketItem } from "@core/models/market-place/market-item.model";
import { BotProfile } from "@core/models/bot/bot-profile.model";
import { NotificationUpgradeComponent } from "@shared/components/dialog";
import { CapitalizePipe } from "@shared/pipes/capitalize.pipe";
import { SnackBarAlertService } from "@shared/services/snack-bar-alert/snack-bar-alert.service";
import { DateDurationFormatPipe } from "@shared/pipes/date-duration-format.pipe";
import { I18nState, getI18nState } from "@shared/translation/i18n-state";

interface ConfirmationUpgradeData {
    data: BotProfile;
    feature?: string;
}

enum FEATURE {
    LIVECHAT = "livechat",
    ADS = "ads",
}

@Component({
    selector: "app-confirmation-upgrade",
    templateUrl: "./confirmation-upgrade.component.html",
    styleUrls: ["./confirmation-upgrade.component.scss"],
})
export class ConfirmationUpgradeComponent implements OnInit, OnDestroy {
    private _subscription: Subscription;

    private _fetching: boolean;
    get fetching() {
        return this._fetching;
    }

    get title() {
        switch (this.feature) {
            case FEATURE.LIVECHAT:
                if (this._data?.data?.addOnLivechat) return $localize`:@@confirm-disable-livechat:Confirm Disable ${this.featureName}`;
                else return $localize`:@@confirm-enable-livechat:Confirm Enable ${this.featureName}`;
            case FEATURE.ADS:
                if (this._data?.data?.addOnAds) return $localize`:@@confirm-disable-livechat:Confirm Disable ${this.featureName}`;
                else return $localize`:@@confirm-enable-livechat:Confirm Enable ${this.featureName}`;
            default:
                return $localize`:@@confirm-upgrade-title:Confirm Upgrade Chatbot`;
        }
    }

    get featureName() {
        return this.feature == FEATURE.LIVECHAT ? $localize`:@@livechat:Livechat` : $localize`:@@advertisement:Advertisement`;
    }

    get warningAddOnPricingMessage() {
        if (!!this.feature)
            return $localize`:@@warning-message-upgrade-feature-desc:มีการคิดค่าบริการในส่วนของ ${this.featureName} เพิ่มเติม <a class="text-blue" target="_blank" href="https://botnoi.ai/package/">ดูเพิ่มเติม</a>`;
        return $localize`:@@warning-message-upgrade-desc:มีการคิดค่าบริการ การใช้งานแชทบอทตามแพ็คเกจเมื่อทำการอัพเกรดเป็นแพ็คเกจใหม่ <a class="text-blue" target="_blank" href="https://botnoi.ai/package/">ดูเพิ่มเติม</a>`;
    }

    get convertPackageDaysMessage() {
        return $localize`:@@convert-package-remain-day-desc:ระบบจะปรับวันใช้งานคงเหลือให้เหมาะสมตามราคาแพ็คเกจใหม่ ที่ได้อัพเกรด<br>จากเดิม วันใช้งานคงเหลือ <span class="text-blue">${this.remainDays}</span><br>ปรับเป็น วันใช้งานคงเหลือ <span class="text-blue">${this.newRemainDays}</span>`;
    }

    get upgradePackageWarningMessage() {
        return $localize`:@@upgrade-package-warning-desc:เมื่ออัพเกรดไปแล้วจะไม่สามารถย้อนกลับมาใช้แพ็คเกจเดิมได้อีก`;
    }

    get freePackageToggleAddOnMessage() {
        switch (this.feature) {
            case FEATURE.ADS:
                return this._data?.data?.addOnAds
                    ? $localize`:@@confirmation-disable-ads-free-desc:คุณต้องการปิดการใช้งานโฆษณาใช่หรือไม่`
                    : $localize`:@@confirmation-enable-ads-free-desc:คุณต้องการเปิดการใช้งานโฆษณาใช่หรือไม่`;
            default:
                return this._data?.data?.addOnLivechat
                    ? $localize`:@@confirmation-disable-livechat-free-desc:คุณต้องการปิดการใช้งานไลฟ์แชทใช่หรือไม่`
                    : $localize`:@@confirmation-enable-livechat-free-desc:คุณต้องการเปิดการใช้งานไลฟ์แชทใช่หรือไม่`;
        }
    }

    private _loadingData: boolean;
    get loadingData() {
        return this._loadingData;
    }

    private _upgradePlan: SubscriptionPackage;
    get loading() {
        return !this._upgradePlan;
    }

    get feature() {
        return this._data?.feature ?? null;
    }

    get isFreePlan() {
        return this._data?.data?.current_plan?.isFreePlan ?? false;
    }

    get planId() {
        if (!!this.feature) return this._items?.find((ao) => ao.feature == this.feature)?._id;
        return this._upgradePlan?._id;
    }

    get remainDays() {
        return this._data?.data?.current_plan?.isExpired
            ? $localize`:@@no-remaining-day:ไม่มีวันเหลือใช้งาน`
            : this._dateDuration.transform(this._data?.data?.current_plan?.remainDaysMoment, "Y M D h", true);
    }

    get newRemainDays() {
        return this._data?.data?.current_plan?.isExpired
            ? $localize`:@@no-remaining-day:ไม่มีวันเหลือใช้งาน`
            : this._dateDuration.transform(moment(this._upgradePlan.convertDays), "Y M D h", true, true);
    }

    get cancelLabel() {
        return this.feature == FEATURE.LIVECHAT || FEATURE.ADS ? getI18nState(I18nState.BTN_CANCEL) : $localize`:@@later:Later`;
    }

    get submitLabel() {
        return this.feature == FEATURE.LIVECHAT || FEATURE.ADS ? getI18nState(I18nState.BTN_SUBMIT) : getI18nState(I18nState.BTN_UPGRADE);
    }

    get addOnLivechat() {
        return this._data.data.addOnLivechat;
    }

    private _items: Array<MarketItem>;
    get items() {
        return this._items;
    }
    private _casePackageFree: Array<SubscriptionPackage>;
    get casePackageFree() {
        return this._casePackageFree;
    }

    constructor(
        private _matDialog: MatDialog,
        private _matDialogRef: MatDialogRef<ConfirmationUpgradeComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: ConfirmationUpgradeData,
        private _manageBotService: ManageBotRequestService,
        private _subscriptionService: SubscriptionService,
        private _marketService: MarketPlaceService,
        private _capitalize: CapitalizePipe,
        private _dateDuration: DateDurationFormatPipe,
        private _snackbar: SnackBarAlertService
    ) {
        this._subscription = new Subscription();
        this._fetching = false;
        this._loadingData = true;
    }

    ngOnInit(): void {
        this._subscription.add(
            this._subscriptionService
                .$getSubscriptionPackage()
                .pipe(
                    map((response) =>
                        response.filter((res) => res.limit_subscribe_number > this._data?.data.current_plan.plan.limit_subscribe_number && !res.is_default_plan)
                    ),
                    map((response) =>
                        response.sort((a, b) =>
                            a.limit_subscribe_number > b.limit_subscribe_number ? 1 : a.limit_subscribe_number < b.limit_subscribe_number ? -1 : 0
                        )
                    ),
                    switchMap((response) => {
                        if (!!this.feature)
                            return this._marketService.$getMarketItemList().pipe(
                                map((res) => {
                                    this._items = res;
                                    return response;
                                })
                            );
                        return of(response);
                    }),
                    switchMap((response) => {
                        if (this._data.data.current_plan.isFreePlan) {
                            return this._subscriptionService.$getSubscriptionPackage().pipe(
                                map((res) => {
                                    res = res.filter((item) => item.cost_per_day > this._data?.data.current_plan.plan.cost_per_day && !item.is_default_plan);
                                    return res;
                                })
                            );
                        }
                        return of(response);
                    })
                )
                .subscribe({
                    next: (response) => {
                        this._upgradePlan = !!response.length ? response[0] : this._data?.data?.current_plan.plan;
                        this._subscriptionService.$getConvertDay(this._data?.data?._id, this.planId).subscribe({
                            next: (res) => {
                                this._loadingData = false;
                                if (!!res?.convert_day_info?.datetime) this._upgradePlan.setConvertDays(res?.convert_day_info?.datetime);
                                else {
                                    const datetime = moment().add(res?.convert_day_info?.remaining_day, "day").toDate().toString();
                                    this._upgradePlan.setConvertDays(datetime);
                                }
                            },
                            error: (error) => {
                                console.error(error);
                                this._loadingData = false;
                            },
                        });
                    },
                    error: (error) => {
                        console.error(error);
                        this._loadingData = false;
                        this._upgradePlan = this._data?.data?.current_plan.plan;
                    },
                })
        );
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    onUpgrade = (): void => {
        if (this.fetching) return;
        this._fetching = true;
        let state = false;

        switch (this.feature) {
            case FEATURE.LIVECHAT:
                state = this._data?.data?.addOnLivechat;
                break;
            case FEATURE.ADS:
                state = this._data?.data.addOnAds;
                break;
        }

        switch (this.feature) {
            case FEATURE.LIVECHAT:
            case FEATURE.ADS:
                this._subscription.add(
                    this._marketService.$toggleMarketItem(state, this.items.find((item) => item.feature == this.feature)?._id, this._data?.data?._id).subscribe({
                        next: () => {
                            this._fetching = false;
                            let message = state
                                ? $localize`:@@success-disable-extension:Successfully disable ${this._capitalize.transform(this.feature)}.`
                                : $localize`:@@success-enable-extension:Successfully enable ${this._capitalize.transform(this.feature)}.`;
                            this._snackbar.open("success", message);

                            if (this._data?.data?.current_plan?.isExpired) return this._openLimitationPlanModal(this._data?.data);
                            this._matDialogRef.close(true);
                        },
                        error: (error) => {
                            console.error(error);
                            this._fetching = false;
                            this._snackbar.open("error", getI18nState(I18nState.GLOBAL_ERR_MSG));
                        },
                    })
                );
                break;
            default:
                this._subscription.add(
                    this._subscriptionService.$upgradeSubscriptionPlan(this._data?.data._id, this._upgradePlan._id).subscribe({
                        next: () => {
                            this._data.data.current_plan.plan = this._upgradePlan;
                            this._fetching = false;
                            this._snackbar.open(
                                "success",
                                $localize`:@@success-upgrade-package:Successfully upgraded to the ${this._upgradePlan.product_name} package!`
                            );

                            if (this._data?.data?.current_plan?.isExpired) return this._openLimitationPlanModal(this._data?.data);
                            this._matDialogRef.close(true);
                        },
                        error: (error) => {
                            console.error(error);
                            this._fetching = false;
                            this._snackbar.open(
                                "error",
                                $localize`:@@error-upgrade-package:We couldn’t upgrade you to the ${this._upgradePlan.product_name} package. Please try again or contact our support team.`
                            );
                        },
                    })
                );
        }
    };

    private _openLimitationPlanModal = (data: BotProfile): void => {
        this._fetching = true;
        this._subscription.add(
            this._manageBotService.$getBotDetail(data._id).subscribe((response) => {
                this._fetching = false;
                this._matDialog
                    .open(NotificationUpgradeComponent, {
                        data: { data: response, isFromLiveChat: true },
                        disableClose: true,
                    })
                    .afterClosed()
                    .subscribe((result) => {
                        this._matDialogRef.close(result);
                    });
            })
        );
    };
}
