import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import * as moment from "moment";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment as env } from "@env/environment";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

interface UploadDownloadResponse {
    status_id: string;
}

export interface ExportProgressModel {
    status_id: string;
    status: string;
    message: string;
    email: string;
    process: number;
    url?: string;
}

export interface ImportProgressModel {
    status_id: string;
    status: string;
    message: string;
    success_excel: number;
    errors_excel: Array<string>;
    process: number;
}

@Injectable()
export class UploadService {
    get featureFlags() {
        return env.featureFlags;
    }

    get templateURL() {
        return `${API_GATEWAYS_URL}platform-go/assets/templates/`;
    }

    constructor(private _http: HttpClient) {}

    private _getDate(date: string, end?: boolean): number | string {
        if (end) return moment(date, "YYYY-MM-DD").endOf("day").unix();
        return moment(date, "YYYY-MM-DD").unix();
    }

    $onUploadImage = (file: File, _id?: string): Observable<{ file_url: string }> => {
        const form = new FormData();
        form.append("file", file);
        if (_id) form.append("bot_id", _id);
        return this._http.post<any>(`${API_GATEWAYS_URL}s3/image`, form).pipe(catchError((error) => throwError(() => error)));
    };

    $onDeleteImage = (url: Array<string>): Observable<string> => {
        return this._http
            .request("DELETE", `${API_GATEWAYS_URL}s3/image`, {
                body: url,
                responseType: "text",
            })
            .pipe(catchError((error) => throwError(() => error)));
    };

    $onUploadFile = (file: File, _id?: string): Observable<{ file_url: string }> => {
        const form = new FormData();
        form.append("file", file);
        if (_id) form.append("bot_id", _id);
        return this._http.post<any>(`${API_GATEWAYS_URL}s3/file`, form).pipe(catchError((error) => throwError(() => error)));
    };

    $onDeleteFile = (url: Array<string>): Observable<string> => {
        return this._http
            .request("DELETE", `${API_GATEWAYS_URL}s3/file`, {
                body: url,
                responseType: "text",
            })
            .pipe(catchError((error) => throwError(() => error)));
    };

    $onUploadWebchatFile = (token: string, file: File, _id?: string): Observable<{ file_url: string }> => {
        const form = new FormData();
        form.append("file", file);
        if (_id) form.append("bot_id", _id);
        return this._http
            .post<any>(`${API_GATEWAYS_URL}webchat/file`, form, {
                headers: new HttpHeaders().append("Authorization", `Bearer ${token}`),
            })
            .pipe(catchError((error) => throwError(() => error)));
    };

    $onDeleteWebchatFile = (token: string, url: Array<string>): Observable<string> => {
        return this._http
            .request("DELETE", `${API_GATEWAYS_URL}webchat/file`, {
                headers: new HttpHeaders().append("Authorization", `Bearer ${token}`),
                body: url,
                responseType: "text",
            })
            .pipe(catchError((error) => throwError(() => error)));
    };

    $importObject = (type: string, file: File): Observable<UploadDownloadResponse> => {
        const form = new FormData();
        form.append("file", file);

        if (type == "nlp")
            return this._http
                .post<UploadDownloadResponse>(`${API_GATEWAYS_URL}platform-go/nlp/record/import/new`, form)
                .pipe(catchError((error) => throwError(() => error)));

        if (type == "entity")
            return this._http
                .post<UploadDownloadResponse>(`${API_GATEWAYS_URL}platform-go/entity/import/new`, form)
                .pipe(catchError((error) => throwError(() => error)));

        return this._http.post<UploadDownloadResponse>(`${API_GATEWAYS_URL}platform-go/object/${type}/import/new`, form).pipe(
            catchError((error) => {
                if (error.status == 404)
                    return this._http.post<void>(`${API_GATEWAYS_URL}platform-go/object/${type}/import`, form).pipe(
                        map((_) => {
                            return { status_id: null };
                        }),
                        catchError((err) => throwError(() => err))
                    );
                return throwError(() => error);
            })
        );
    };

    $importObjectValidate = (type: string, status_id: string): Observable<ImportProgressModel> => {
        if (type == "nlp")
            return this._http
                .get<ImportProgressModel>(`${API_GATEWAYS_URL}platform-go/nlp/record/import/check?status_id=${status_id}`)
                .pipe(catchError((error) => throwError(() => error)));

        if (type == "entity")
            return this._http
                .get<ImportProgressModel>(`${API_GATEWAYS_URL}platform-go/entity/import/check?status_id=${status_id}`)
                .pipe(catchError((error) => throwError(() => error)));

        return this._http
            .get<ImportProgressModel>(`${API_GATEWAYS_URL}platform-go/object/${type}/import/check?status_id=${status_id}`)
            .pipe(catchError((error) => throwError(() => error)));
    };

    $exportNlpRecord = (
        start_date: moment.Moment,
        end_date: moment.Moment,
        type: Array<string>,
        category: Array<string>
    ): Observable<UploadDownloadResponse> => {
        return this._http
            .get<UploadDownloadResponse>(
                `${API_GATEWAYS_URL}platform-go/nlp/record/export/new?startDate=${start_date.startOf("day").unix()}&endDate=${end_date
                    .endOf("day")
                    .unix()}&type=${type.join("%26")}&category=${category.join("%26")}`
            )
            .pipe(catchError((error) => throwError(() => error)));
    };

    $exportObject = (type: string): Observable<Blob | UploadDownloadResponse> => {
        return this._http.get<UploadDownloadResponse>(`${API_GATEWAYS_URL}platform-go/object/${type}/export/new`).pipe(
            catchError((error) => {
                if (error.status == 404)
                    return this._http
                        .request(`GET`, `${API_GATEWAYS_URL}platform-go/object/${type}/export`, {
                            headers: new HttpHeaders({
                                "Content-Type": "application/octet-stream",
                                Accept: "application/octet-stream",
                            }),
                            responseType: "blob",
                        })
                        .pipe(
                            map((response) => {
                                return new Blob([response], {
                                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                });
                            }),
                            catchError((error) => throwError(() => error))
                        );
                return throwError(() => error);
            })
        );
    };

    $exportRecordValidate = (type: string, status_id: string): Observable<ExportProgressModel> => {
        if (type == "nlp")
            return this._http
                .get<ExportProgressModel>(`${API_GATEWAYS_URL}platform-go/nlp/record/export/check?status_id=${status_id}`)
                .pipe(catchError((error) => throwError(() => error)));

        return this._http
            .get<ExportProgressModel>(`${API_GATEWAYS_URL}platform-go/object/${type}/export/check?status_id=${status_id}`)
            .pipe(catchError((error) => throwError(() => error)));
    };

    $getExportOverallDashboardData = (start: string, end: string): Observable<UploadDownloadResponse> => {
        const start_time = this._getDate(start);
        const end_time = this._getDate(end, true);

        return this._http
            .get<UploadDownloadResponse>(`${API_GATEWAYS_URL}platform-go/stat/msg/export?start=${start_time}&end=${end_time}`)
            .pipe(catchError((error) => throwError(() => error)));
    };

    $exportOverallDashboardValidate = (status_id: string): Observable<ExportProgressModel> => {
        return this._http
            .get<ExportProgressModel>(`${API_GATEWAYS_URL}platform-go/stat/msg/export/check?status_id=${status_id}`)
            .pipe(catchError((error) => throwError(() => error)));
    };

    $getExportDialogPerformance = (start: string, end: string, _id: string): Observable<Blob> => {
        const start_time = this._getDate(start);
        const end_time = this._getDate(end, true);

        return this._http
            .request(`GET`, `${API_GATEWAYS_URL}platform-go/stat/dialogue/export?start=${start_time}&end=${end_time}&dialogue_id=${_id}`, {
                headers: new HttpHeaders({
                    "Content-Type": "application/octet-stream",
                    Accept: "application/octet-stream",
                }),
                responseType: "blob",
            })
            .pipe(
                map((response) => {
                    return new Blob([response], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                }),
                catchError((error) => throwError(() => error))
            );
    };

    $getExportEntityData = (): Observable<UploadDownloadResponse> => {
        return this._http.get<UploadDownloadResponse>(`${API_GATEWAYS_URL}platform-go/entity/export/new`).pipe(catchError((error) => throwError(() => error)));
    };

    $exportEntityValidate = (status_id: string): Observable<ExportProgressModel> => {
        return this._http
            .get<ExportProgressModel>(`${API_GATEWAYS_URL}platform-go/entity/export/check?status_id=${status_id}`)
            .pipe(catchError((error) => throwError(() => error)));
    };
}
