import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { LocalService } from "@core/services/cache/local.service";
import { CrsObject } from "@core/models/object/crs-object.model";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

interface CRSObjectResponse {
    _id: string;
    object_name: string;
    type: string;
    list_object: Array<{
        title: string;
        subtitle: string;
        image_url: string;
        buttons: Array<{
            label: string;
            type: string;
            data: string;
        }>;
    }>;
}

@Injectable()
export class ManageCrsObjectService {
    get url() {
        return `${API_GATEWAYS_URL}platform-go/object/carousel`;
    }

    constructor(private _http: HttpRequest, private local: LocalService) {}

    $getCrsObjectList = (): Observable<Array<CrsObject>> => {
        return this._http
            .get<Array<CRSObjectResponse>>({
                url: this.url.toString(),
                cacheMins: 1,
            })
            .pipe(
                retry(genericRetry),
                map((response) =>
                    response.map((res) =>
                        new CrsObject().deserialize({
                            _id: res._id,
                            name: res.object_name,
                            cards: !!res.list_object
                                ? res.list_object.map((c) => {
                                      return {
                                          url: c.image_url,
                                          title: c.title,
                                          description: c.subtitle,
                                          buttons: !!c.buttons
                                              ? c.buttons.map((b) => {
                                                    return {
                                                        label: b.label,
                                                        type: b.type == "postback" ? "message" : b.type == "web_url" ? "url" : "phone",
                                                        message: b.data,
                                                    };
                                                })
                                              : [],
                                      };
                                  })
                                : [],
                        })
                    )
                ),
                catchError((error) => throwError(() => error))
            );
    };

    $insertCrsObject = (body: CrsObject): Observable<{ _id: string }> => {
        const request = body.convertObjectToServer();
        delete request._id;
        return this._http
            .post<{ _id: string }>({
                url: this.url.toString(),
                body: request,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $duplicateCrsObject = (_id: string): Observable<CrsObject> => {
        return this._http
            .post<{ data: CRSObjectResponse }>({
                url: `${API_GATEWAYS_URL}platform-go/object/duplicate`,
                body: { _id: _id },
            })
            .pipe(
                retry(genericRetry),
                map((response) => {
                    this.clearCacheData();
                    return new CrsObject().deserialize({
                        _id: response?.data?._id,
                        name: response?.data?.object_name,
                        cards: !!response?.data?.list_object
                            ? response?.data?.list_object.map((c) => {
                                  return {
                                      url: c.image_url,
                                      title: c.title,
                                      description: c.subtitle,
                                      buttons: !!c.buttons
                                          ? c.buttons.map((b) => {
                                                return {
                                                    label: b.label,
                                                    type: b.type == "postback" ? "message" : b.type == "web_url" ? "url" : "phone",
                                                    message: b.data,
                                                };
                                            })
                                          : [],
                                  };
                              })
                            : [],
                    });
                }),
                catchError((error) => throwError(() => error))
            );
    };

    $updateCrsObject = (body: CrsObject): Observable<{ msg: string }> => {
        const request = body.convertObjectToServer();
        return this._http
            .put<{ msg: string }>({
                url: this.url.toString(),
                body: request,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteCrsObjectList = (body: Array<CrsObject>): Observable<{ msg: string }> => {
        return this._http
            .delete<{ msg: string }>({
                url: this.url.toString(),
                body: { _id: body.map((b) => b._id) },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    clearCacheData = (): void => {
        this.local.delete(this.url.toString());
    };
}
