import { Directive, Renderer2, ElementRef, HostListener, EventEmitter, Self, Optional } from "@angular/core";
import { NgControl } from "@angular/forms";
import { timer } from "rxjs";
import { filter, delay } from "rxjs/operators";

@Directive({
    selector: "input[bnInput], textarea[bnInput]",
})
export class BnInputDirective {
    @HostListener("change", ["$event"]) private _onChange = ($event) => {
        timer(100).subscribe((_) => this._setValidity($event.target));
    };
    @HostListener("keyup", ["$event"]) private _ngOnKeyup = ($event) => {
        this._setValidity($event.target);
    };
    @HostListener("focus") private _onFocus = () => {
        this._stateFocus.emit("focus");
    };
    @HostListener("focusout") private _onFocusOut = () => {
        this._stateFocus.emit("focusout");
    };

    private _stateFocus: EventEmitter<"focus" | "focusout" | "invalid" | "valid" | "disabled"> = new EventEmitter<
        "focus" | "focusout" | "invalid" | "valid" | "disabled"
    >();

    private _setValidity = (target: HTMLInputElement | HTMLTextAreaElement): void => {
        if (!!this.control) {
            this._stateFocus.emit(this.control.valid ? "valid" : "invalid");
            return;
        }

        this._stateFocus.emit(target?.validity?.valid ? "valid" : "invalid");
    };

    constructor(private ele: ElementRef, private _renderer: Renderer2, @Self() @Optional() private control: NgControl) {
        this._renderer.addClass(this.ele.nativeElement, "bn-input-element");

        if (!!this.control)
            timer(50).subscribe(() => this.control.statusChanges.pipe(delay(50)).subscribe((change) => this._stateFocus.emit(change.toLowerCase())));
    }

    focus = (): void => {
        this.ele.nativeElement.focus();
        this._stateFocus.emit("focus");
        timer(50).subscribe((_) => this.ele.nativeElement.focus());
    };

    getStateFocus = (): EventEmitter<"focus" | "focusout" | "invalid" | "valid" | "disabled"> => {
        return this._stateFocus;
    };

    setValue = (_value: string): void => {
        if (this.control) this.control.control.setValue(_value);
    };
}
