import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { Observable, of, throwError } from "rxjs";
import { map, catchError, retry, switchMap } from "rxjs/operators";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { LocalService } from "@core/services/cache/local.service";
import { UploadService } from "@core/http/s3/upload.service";
import { AudioObject } from "@core/models/object/audio-object.model";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

interface AudioObjectResponse {
    _id: string;
    object_name: string;
    type: string;
    list_object: Array<{
        audio_url: string;
        audio_duration: number;
    }>;
}

@Injectable()
export class ManageAudioObjectService {
    get url() {
        return `${API_GATEWAYS_URL}platform-go/object/audio`;
    }

    constructor(private _http: HttpRequest, private _local: LocalService, private _uploadService: UploadService) {}

    $getAudioObjectList = (): Observable<Array<AudioObject>> => {
        return this._http
            .get<Array<AudioObjectResponse>>({
                url: this.url.toString(),
                cacheMins: 1,
            })
            .pipe(
                retry(genericRetry),
                map((response) =>
                    response.map((res) =>
                        new AudioObject().deserialize({
                            _id: res._id,
                            name: res.object_name,
                            url: res.list_object[0].audio_url,
                            duration: res.list_object[0].audio_duration,
                        })
                    )
                ),
                catchError((error) => throwError(() => error))
            );
    };

    $insertAudioObject = (body: AudioObject, file?: File): Observable<{ _id: string }> => {
        return of(!!file).pipe(
            switchMap((state) => {
                if (state)
                    return this._uploadService.$onUploadFile(file).pipe(
                        retry(genericRetry),
                        map((response) => {
                            return { state: state, file_url: response.file_url };
                        }),
                        catchError((error) => throwError(() => error))
                    );
                return of({ state: state, file_url: null });
            }),
            switchMap((response) => {
                if (response?.file_url) body.url = response.file_url;
                const request = body.convertObjectToServer();
                delete request._id;
                return this._http
                    .post<{ _id: string }>({
                        url: this.url.toString(),
                        body: request,
                    })
                    .pipe(
                        retry(genericRetry),
                        catchError((error) => throwError(() => error))
                    );
            })
        );
    };

    $duplicateAudioObject = (_id: string): Observable<AudioObject> => {
        return this._http
            .post<{ data: AudioObjectResponse }>({
                url: `${API_GATEWAYS_URL}platform-go/object/duplicate`,
                body: { _id: _id },
            })
            .pipe(
                retry(genericRetry),
                map((response) => {
                    this.clearCacheData();
                    return new AudioObject().deserialize({
                        _id: response?.data?._id,
                        name: response?.data?.object_name,
                        url: response?.data.list_object[0]?.audio_url,
                        duration: response?.data?.list_object[0]?.audio_duration,
                    });
                }),
                catchError((error) => throwError(() => error))
            );
    };

    $updateAudioObject = (body: AudioObject, file?: File): Observable<{ msg: string }> => {
        return of(!!file).pipe(
            switchMap((state) => {
                if (state)
                    return this._uploadService.$onUploadFile(file).pipe(
                        retry(genericRetry),
                        map((response) => {
                            this._uploadService.$onDeleteFile([body.url]).subscribe();
                            return { state: state, file_url: response.file_url };
                        }),
                        catchError((error) => throwError(() => error))
                    );
                return of({ state: state, file_url: null });
            }),
            switchMap((response) => {
                if (response?.file_url) body.url = response.file_url;
                const request = body.convertObjectToServer();
                return this._http
                    .put<{ msg: string }>({
                        url: this.url.toString(),
                        body: request,
                    })
                    .pipe(
                        retry(genericRetry),
                        catchError((error) => throwError(() => error))
                    );
            })
        );
    };

    $deleteAudioObjectList = (body: Array<AudioObject>): Observable<{ msg: string }> => {
        return this._http
            .delete<{ msg: string }>({
                url: this.url.toString(),
                body: { _id: body.map((b) => b._id) },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    clearCacheData = (): void => {
        this._local.delete(this.url.toString());
    };
}
