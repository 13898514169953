import { Deserializable } from "../deserializable.model";
import { CrsObject } from "../object/crs-object.model";

export class CrsList implements Deserializable {
    _id: string;
    selected: boolean;
    name: string;
    cards: Array<Card>;

    deserialize(input: any) {
        Object.assign(this, input);
        this.cards = this.cards.map((card) => new Card().deserialize(card));
        return this;
    }

    convertObjectToList(input: CrsObject) {
        Object.assign(this, {
            ...input,
            name: input.name.includes("CRS_") ? input.name : `CRS_${input.name}`,
        });
        this.selected = false;
        return this;
    }

    convertListToObject(): CrsObject {
        return new CrsObject().deserialize({
            _id: this._id,
            name: this.name,
            cards: this.cards,
        });
    }
}

class Card implements Deserializable {
    url: string;
    title: string;
    description: string;
    buttons: Array<Button>;

    deserialize(input: any) {
        Object.assign(this, input);
        if (this.buttons) this.buttons = this.buttons.map((button) => new Button().deserialize(button));
        return this;
    }
}

class Button implements Deserializable {
    label: string;
    type: "message" | "url" | "phone";
    message: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
