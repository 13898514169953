import { Injectable } from "@angular/core";

@Injectable()
export class SnackBarTitleService {
    private _description: string;
    get description() {
        return this._description;
    }
    set description(desc: string) {
        this._description = desc;
    }

    private _title: string;
    get title() {
        return this._title;
    }
    set title(title: string) {
        this._title = title;
    }

    private _icon: string;
    get icon() {
        return this._icon;
    }
    set icon(icon: string) {
        this._icon = icon;
    }

    private _color: string;
    get color() {
        return this._color;
    }
    set color(color: string) {
        this._color = color;
    }

    constructor() {
        this.description = "";
        this.title = "";
        this.icon = "";
        this.color = "";
    }
}
