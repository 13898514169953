import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { BillingInformation } from "@core/models/bot/billing-information.model";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

@Injectable()
export class BillingInformationService {
    constructor(private _http: HttpRequest) {}

    $getDetailsInfo = (): Observable<BillingInformation> => {
        return this._http
            .get<any>({
                url: `${API_GATEWAYS_URL}payment/information`,
                cacheMins: 5,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $addDetailsInfo = (body: BillingInformation): Observable<{ _id: string }> => {
        return this._http
            .post<any>({
                url: `${API_GATEWAYS_URL}payment/information`,
                body: body,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $updateDetailsInfo = (body: BillingInformation): Observable<{ _id: string }> => {
        return this._http
            .put<any>({
                url: `${API_GATEWAYS_URL}payment/information`,
                body: body,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };
}
