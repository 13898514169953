import { Directive, ElementRef, Renderer2 } from "@angular/core";

@Directive({
    selector: "table[bn-table]",
})
export class BnTableDirective {
    constructor(private ele: ElementRef, private _renderer: Renderer2) {
        this._renderer.addClass(this.ele.nativeElement, "bn-table-wrapper");
    }
}
