<div class="container d-flex flex-column justify-content-center">
    <ng-container *ngIf="!isUploading">
        <div class="d-block mb-1">
            <mat-icon color="primary" svgIcon="document-text"></mat-icon>
            <div class="ml-1">
                <h2 i18n="@@import-file" class="mb-0">Import file</h2>
            </div>
        </div>

        <div [ngSwitch]="type" class="d-flex flex-column align-items-start mb-1">
            <ng-container *ngSwitchCase="'nlp'">
                <button mat-button bn-small-button color="default" class="pl-0 pr-0" (click)="onDownloadNLPTemplate('intent-phrase')">
                    <mat-icon class="mr-1" svgIcon="download"></mat-icon>
                    <ng-container i18n="@@download-intent-phrase-template-title">Download Intent-Phrase Template</ng-container>
                </button>
                <button mat-button bn-small-button color="default" class="pl-0 pr-0" (click)="onDownloadNLPTemplate('intent-response')">
                    <mat-icon class="mr-1" svgIcon="download"></mat-icon>
                    <ng-container i18n="@@download-intent-response-template-title">Download Intent-Response Template</ng-container>
                </button>
                <button mat-button bn-small-button color="default" class="pl-0 pr-0" (click)="onDownloadNLPTemplate('chitchat-response')">
                    <mat-icon class="mr-1" svgIcon="download"></mat-icon>
                    <ng-container i18n="@@download-chitchat-response-template-title">Download Chitchat-Response Template</ng-container>
                </button>
            </ng-container>

            <button *ngSwitchDefault mat-button bn-small-button color="default" class="pl-0 pr-0" (click)="onDownloadTemplate()">
                <mat-icon class="mr-1" svgIcon="download"></mat-icon>
                <ng-container i18n="@@download-template-title">Download Template</ng-container>
            </button>
        </div>

        <div class="browse-file-container d-flex justify-content-center align-items-center px-1 mb-2" [ngClass]="{'attached': hasFile}" (click)="file.click()">
            <ng-container [ngSwitch]="hasFile">
                <ng-container *ngSwitchCase="true">
                    <mat-icon svgIcon="paper"></mat-icon>
                    <span class="ml-1 mat-caption">{{ fileName }}</span>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <mat-icon svgIcon="click"></mat-icon>
                    <span i18n="@@click-here-to-browse" class="ml-1 mat-caption">Click here to browse .xlsx files</span>
                </ng-container>
            </ng-container>

            <input
                #file
                style="display: none"
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                (change)="onSelectFile($event)"
            />
        </div>

        <div class="d-flex justify-content-center">
            <button i18n="@@ok" mat-raised-button bn-large-button color="primary" [disabled]="!hasFile" (click)="onUploadObject()">OK</button>
        </div>
    </ng-container>

    <ng-container *ngIf="isUploading">
        <div class="d-block mb-2">
            <mat-icon color="primary" svgIcon="download"></mat-icon>
            <div class="ml-1 mat-status">
                <h2 class="mb-0">
                    <state-i18n key="BTN_IMPORT"></state-i18n>
                </h2>
            </div>
        </div>

        <div class="mb-2 mat-status">
            <p class="mb-0 text-d-grey">{{ fileName }}</p>
            <mat-progress-bar mode="determinate" [value]="$progress | async" class="my-1"></mat-progress-bar>
            <p class="mb-0">
                <small i18n="@@upload-of-file-size" class="text-d-grey">{{ uploadSize }} of {{ fileSize }} MB</small>
            </p>
        </div>

        <div class="d-flex justify-content-center">
            <button mat-stroked-button bn-large-button mat-dialog-close color="default">
                <state-i18n key="BTN_CLOSE"></state-i18n>
            </button>
        </div>
    </ng-container>
</div>
