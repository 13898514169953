import { Deserializable } from "../deserializable.model";

export class CrsObject implements Deserializable {
    _id: string;
    name: string;
    cards: Array<Card>;

    deserialize(input: any) {
        Object.assign(this, input);
        this.cards = this.cards.map((card) => new Card().deserialize(card));
        return this;
    }

    onAddCard = (): void => {
        this.cards.push(new Card());
    };

    onDeleteCard = (index: number): void => {
        this.cards.splice(index, 1);
    };

    onUpdateCard = (carousel: CrsObject): void => {
        this.name = carousel.name;
        this.cards.forEach((c, index) => c.onUpdateCard(carousel.cards[index]));
    };

    convertObjectToServer() {
        return {
            _id: this._id,
            type: "carousel",
            object_name: this.name.includes("CRS_") ? this.name : `CRS_${this.name}`,
            list_object: this.cards.map((c) => c.convertObjectToServer()),
        };
    }
}

export interface CrsObject {
    _id: string;
    name: string;
    cards: Array<Card>;
}

export class Card implements Deserializable {
    option: string;
    url: string;
    title: string;
    description: string;
    buttons: Array<Button>;

    private _cacheFile: { file: File; binary: string };

    get hasFile(): boolean {
        return this._cacheFile ? true : false;
    }

    get cacheFile(): File {
        return this._cacheFile?.file ?? null;
    }

    get cacheBinary(): string {
        return this._cacheFile?.binary ?? "";
    }

    deserialize(input: any) {
        Object.assign(this, input);
        this.option = this.option ?? "url";
        if (this.buttons) this.buttons = this.buttons.map((button) => new Button().deserialize(button));
        return this;
    }

    convertObjectToServer() {
        return {
            image_url: this.url,
            title: this.title,
            subtitle: this.description,
            buttons: this.buttons.map((b) => b.convertObjectToServer()),
        };
    }

    onDeleteButton = (index: number): void => {
        this.buttons.splice(index, 1);
    };

    onUpdateCard = (card: Card): void => {
        this.option = card.option;
        this.url = card.url;
        this.title = card.title;
        this.description = card.description;
        if (card.buttons) {
            this.buttons = [];
            this.buttons = card.buttons.map((button) => new Button().deserialize(button));
        }
    };

    onChangeImage = (file: File, binary: string): void => {
        this._cacheFile = { file: file, binary: binary };
    };
}

export interface Card {
    url: string;
    title: string;
    description: string;
    buttons: Array<Button>;
}

export class Button implements Deserializable {
    label: string;
    type: "message" | "url" | "phone";
    message: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    convertObjectToServer() {
        return {
            type: this.type == "message" ? "postback" : this.type == "url" ? "web_url" : "phone",
            label: this.label,
            data: this.message,
        };
    }

    onUpdateButton = (button: Button): void => {
        this.label = button.label;
        this.type = button.type;
        this.message = button.message;
    };
}

export interface Button {
    label: string;
    type: "message" | "url" | "phone";
    message: string;
}
