import { Component, OnInit } from "@angular/core";
import { trigger, state, style, transition, animate, AUTO_STYLE } from "@angular/animations";
import { PdpaService } from "@core/http/user";
import {Router} from "@angular/router";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: "app-pdpa-acception-dialog",
    templateUrl: "./pdpa-acception-dialog.component.html",
    styleUrls: ["./pdpa-acception-dialog.component.scss"],
    animations: [
        trigger("expandCollapse", [
            state(
                "collapsed",
                style({
                    height: "0",
                    overflow: "hidden",
                    visibility: "hidden",
                    opacity: "0",
                })
            ),
            state(
                "expanded",
                style({
                    height: AUTO_STYLE,
                    overflow: "hidden",
                    opacity: "1",
                })
            ),
            transition("collapsed <=> expanded", animate(".5s ease")),
        ]),
    ],
})
export class PdpaAcceptionDialogComponent implements OnInit {
    pdpaCheck: boolean;

    htmlPDPA: string;

    private _pdpaCollapse: boolean;
    get pdpaCollapse() {
        return this._pdpaCollapse;
    }

    constructor(private _pdpaService: PdpaService, ) {
        this._pdpaCollapse = true;
    }

    ngOnInit(): void {
        this._pdpaService.$getPDPAContent().subscribe((res) => {
            this.htmlPDPA = res;
        });
    }

    onToggleDetailsPDPA = (): void => {
        this._pdpaCollapse = !this.pdpaCollapse;
    };

    openPrivacyPolicy = (): void => {
        window.open('/legal/privacy', '_blank')
    };

    onAcceptPDPA = (): void => {
        this._pdpaService.$acceptPDPA().subscribe(() => {});
    };
}
