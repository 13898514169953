import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Login } from "@core/models/user/login.model";
import { HttpRequest, HttpOptions } from "@core/http/http.service";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

@Injectable()
export class LoginRequestService {
    constructor(private _http: HttpRequest) {}

    $sendLoginForm = (body: { username: string; password: string }): Observable<Login> => {
        return this._http.post<any>({ url: `${API_GATEWAYS_URL}auth/login`, body: body }).pipe(
            map((response) => new Login().deserialize(response)),
            catchError((error) => throwError(() => error))
        );
    };

    $sendLoginWithToken = (token?: string): Observable<Login> => {
        let options: HttpOptions = {
            url: `${API_GATEWAYS_URL}auth/token-login`,
        };
        if (!!token) options.headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);

        return this._http.get<any>(options).pipe(
            map((response) => new Login().deserialize(response)),
            catchError((error) => throwError(() => error))
        );
    };

    $sendForgetForm = (body: { username: string }): Observable<{ status: number; message: string }> => {
        return this._http.post<any>({ url: `${API_GATEWAYS_URL}auth/forget-password`, body: body }).pipe(
            map((response) => response),
            catchError((error) => throwError(() => error))
        );
    };

    $changePassword = (body: { password: string; token: string; confirm_password: string }): Observable<{ message: string }> => {
        return this._http.put<any>({ url: `${API_GATEWAYS_URL}auth/change-password`, body: body }).pipe(
            map((response) => response),
            catchError((error) => throwError(() => error))
        );
    };

    getLineLoginRedirectURL = (): string => {
        return `${API_GATEWAYS_URL}line/v2-line-login?callback_uri=${window.location.pathname}`;
    };

    $sendSignUpForm = (body: { username: string; password: string; first_name: string; last_name: string }): Observable<{ token: string; message: string }> => {
        return this._http.post<any>({ url: `${API_GATEWAYS_URL}auth/register`, body: body }).pipe(
            map((response) => response),
            catchError((error) => throwError(() => error))
        );
    };

    $sendResendEmailValidation = (body: { username: string; token: string }): Observable<any> => {
        return this._http.get<any>({ url: `${API_GATEWAYS_URL}auth/send-email?username=${body.username}&token=${body.token}` }).pipe(
            map((response) => response),
            catchError((error) => throwError(() => error))
        );
    };

    $sendValidateEmailToken = (body: { token: string }): Observable<Login> => {
        return this._http.post<any>({ url: `${API_GATEWAYS_URL}auth/verify`, body: body }).pipe(
            map((response) => new Login().deserialize(response)),
            catchError((error) => throwError(() => error))
        );
    };

    $getUserInformation = (token?: string): Observable<Login> => {
        let options: HttpOptions = {
            url: `${API_GATEWAYS_URL}user`,
        };
        if (!!token) options.headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);

        return this._http.get<any>(options).pipe(
            map((response) => {
                if ("authentication" in response) return new Login().deserialize(response);
                else
                    throw {
                        status: response.status,
                        message: response.message,
                    };
            }),
            catchError((error) => throwError(() => error))
        );
    };
}
