import { Deserializable } from '../deserializable.model'
import { DlObject } from '../object/dl-object.model'

export class DlList implements Deserializable {
    _id: string
    selected: boolean
    name: string

    deserialize(input: any) {
        Object.assign(this, input)
        return this
    }

    convertObjectToList(input: DlObject) {
        Object.assign(this, {
            ...input,
            name: input.name.includes('DL_') ? input.name : `DL_${input.name}`
        })
        this.selected = false
        return this
    }

    convertListToObject(): DlObject {
        return new DlObject().deserialize({
            _id: this._id,
            name: this.name
        })
    }
}
