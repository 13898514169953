import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { FileInfo } from "@core/models/gapi/file-info";
import { UserProfileService } from "@core/services/authentication/user-profile.service";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

@Injectable()
export class FileRepository {
    get email() {
        return this._userProfile?.profile?.google_link_accounts[0]?.email;
    }
    constructor(private _http: HttpRequest, private _userProfile: UserProfileService) {}

    getFiles = (folderId?: string): Observable<Array<FileInfo>> => {
        const url = new URL(`${API_GATEWAYS_URL}platform-go/google-sheet`);
        url.searchParams.set("email", this.email);
        if (!!folderId) url.searchParams.set("folder_id", folderId);

        return this._http
            .get<{ file: Array<any> }>({
                url: url.toString(),
            })
            .pipe(
                map((response) => response.file.map((_file) => FileInfo.fromGoogleFile(_file))),
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };
}
