import { Deserializable } from '../deserializable.model'
import { ImageObject } from '../object/image-object.model'

export class ImageList implements Deserializable {
    _id: string
    selected: boolean
    name: string
    url: string

    deserialize(input: any) {
        Object.assign(this, input)
        return this
    }

    convertObjectToList(input: ImageObject) {
        Object.assign(this, {
            ...input,
            name: input.name.includes('IMG_') ? input.name : `IMG_${input.name}`
        })
        this.selected = false
        return this
    }

    convertListToObject(): ImageObject {
        return new ImageObject().deserialize({
            _id: this._id,
            name: this.name,
            url: this.url
        })
    }
}
