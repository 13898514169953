import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { environment as env } from "@env/environment";
import { genericRetryHttp, HttpRequest } from "@core/http/http.service";
import { LocalService } from "@core/services/cache/local.service";
import { Observable, throwError } from "rxjs";
import { catchError, retryWhen, map, switchMap } from "rxjs/operators";
import { AnnouncementComponent } from "./announcement/announcement.component";

interface AnnouncementModel {
    path: string;
    start_date: number;
    end_date: number;
}

@Injectable()
export class AnnouncementService {
    get featureFlags() {
        return env.featureFlags;
    }

    private _html: string;
    get html() {
        return this._html;
    }

    constructor(private _matDialog: MatDialog, private _http: HttpRequest, private _localService: LocalService) {}

    private $getAnnouncementPath = (): Observable<AnnouncementModel> => {
        return this._http
            .get({
                url: "assets/announcement/announcement.json",
            })
            .pipe(
                retryWhen(genericRetryHttp()),
                map((response: Array<AnnouncementModel>) => {
                    const now = Date.now();
                    return response.filter((res) => res.start_date < now && res.end_date > now)[0];
                }),
                catchError((_) => throwError({ status: 0, message: "" }))
            );
    };

    private $getAnnouncement = (): Observable<string> => {
        if (!this.featureFlags.featureAnnouncement) return throwError({ status: 0, message: "" });

        return this.$getAnnouncementPath().pipe(
            switchMap((response) => {
                if (!!response)
                    return this._http.get<string>({
                        url: response.path,
                        responseType: "text",
                    });
                return throwError({ status: 0, message: "" });
            }),
            catchError((_) => throwError({ status: 0, message: "" }))
        );
    };

    openAnnounceDialog = (username?: string): void => {
        const _ann = this._localService.decryptGet("_ann");

        if (!!username) if (_ann.includes(username)) return;

        this.$getAnnouncement().subscribe((html) => {
            this._html = html;
            this._matDialog
                .open(AnnouncementComponent, {
                    disableClose: true,
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result) if (!!username) this._localService.encryptSet("_ann", !!_ann ? `${_ann} ${username}` : username);
                });
        });
    };
}
