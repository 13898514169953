import { Component, Input, Renderer2, ElementRef, HostListener, ChangeDetectorRef } from "@angular/core";

@Component({
    selector: "bn-option",
    templateUrl: "./bn-option.component.html",
    styleUrls: ["./bn-option.component.scss"],
})
export class BnOptionComponent {
    @HostListener("click") private onclick = () => {
        this.selected = !this.selected;
    };

    @Input() value: any;

    private _multiple: boolean;
    set multiple(state: boolean) {
        this._multiple = state;
    }
    get multiple() {
        return this._multiple;
    }

    get ele() {
        return this._ele;
    }

    selected: boolean = false;

    constructor(private _renderer: Renderer2, private _ele: ElementRef, private _changeDetect: ChangeDetectorRef) {
        this._renderer.addClass(this._ele.nativeElement, "bn-option");
        this.multiple = false;
    }

    onUpdateMultiple = (state: boolean): void => {
        this.multiple = state;
        this._changeDetect.detectChanges();
    };
}
