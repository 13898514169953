import { Injectable } from "@angular/core";
import { AppSession } from "@core/services/gapi/sessions/app.session.service";
import { AppRepository } from "@core/services/gapi/repositories/app.repository.service";

@Injectable()
export class AppContext {
    constructor(private _appSession: AppSession, private _appRepository: AppRepository) {}

    get Repository(): AppRepository {
        return this._appRepository;
    }

    get Session(): AppSession {
        return this._appSession;
    }
}
