import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CookieService } from "@core/services/cache/cookie.service";
import { Login, Profile } from "@core/models/user";
import { UserProfileService } from "./user-profile.service";

@Injectable()
export class AuthService {
    private _accessToken: string;
    private _fbToken: string;
    private _userType: string;

    get getAccessToken() {
        return this._accessToken;
    }

    get getFbAccessToken() {
        return this._fbToken;
    }

    get userType() {
        return this._userType;
    }

    isLoggedIn$: BehaviorSubject<boolean>;

    constructor(private _cookie: CookieService, private _usrProfile: UserProfileService) {
        this.isLoggedIn$ = new BehaviorSubject<boolean>(false);
        this._usrProfile.$noData.subscribe({
            next: () => this.setLogout(),
        });
    }

    isLoggedIn = (): boolean => {
        return !!this._accessToken ? true : false;
    };

    hasFbToken = (): boolean => {
        return this._fbToken ? true : false;
    };

    verifyToken = (): void => {
        // Set User Token
        const cookie = this._cookie.get("usr_t");
        this._accessToken = cookie ?? undefined;

        // Set Facebook Token
        const fbToken = this._cookie.get("usr_fb_t");
        this._fbToken = fbToken ?? undefined;

        // Set User Type Login
        const _userType = this._cookie.decryptGet("_t");
        this._userType = _userType ?? undefined;

        // Verify User Profile
        this._usrProfile.verifyUserProfile(this._accessToken);
        this._usrProfile.$profile.subscribe((profile) => {
            if (Object.keys(profile).length > 0) {
                if (this._accessToken !== profile.access_token) this._cookie.set("usr_t", profile.access_token);
                if (this._fbToken !== profile.user_facebook_access_token) this._cookie.set("usr_fb_t", profile.user_facebook_access_token);
            }
        });

        // Emit Login Status
        if (this.isLoggedIn) this.isLoggedIn$.next(true);
        else this.setLogout();
    };

    setAccessToken = (data: Login, remember: boolean, userType: string = "botnoi"): void => {
        // Set usr_t
        this._cookie.set("usr_t", data.access_token, remember ? 240 : 0);
        this._accessToken = data.access_token;

        // Set usr_fb_t
        this._cookie.set("usr_fb_t", data.user_facebook_access_token, remember ? 240 : 0);
        this._fbToken = data?.user_facebook_access_token ?? "";

        // Vertify User Profile
        this._cookie.encryptSet("_t", userType, remember ? 240 : 0);
        this._userType = userType;

        const profile = new Profile().deserialize(data);
        this._usrProfile.initUserProfile(profile);

        // Emit Login Status
        this.isLoggedIn$.next(true);
    };

    changeFacebookToken = (token: string): void => {
        this._fbToken = token;
        this._cookie.set("usr_fb_t", token);
    };

    setLogout = (): void => {
        // Set urs_t && usr_fb_t
        this._accessToken = undefined;
        this._fbToken = undefined;
        this._userType = undefined;

        // Clear Cookie Session
        this._cookie.clearAll();

        // Clear User Profile
        this._usrProfile.setLogout();

        // Emit Login Status
        this.isLoggedIn$.next(false);
    };
}
