import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";

export interface RawProvince extends Array<string | Array<RawDistrict>> {
    0: string;
    1: Array<RawDistrict>;
}
export interface RawDistrict extends Array<string | Array<RawSubDistrict>> {
    0: string;
    1: Array<RawSubDistrict>;
}
export interface RawSubDistrict extends Array<string | Array<number>> {
    0: string;
    1: Array<number>;
}

@Injectable()
export class LocalfileService {
    constructor(private http: HttpClient) {}

    getListIconName = (): Observable<Array<string>> => {
        return this.http.get<any>(`assets/icon-svg/icon-svg.json`).pipe(
            map((response) => response),
            catchError((error) => error)
        );
    };

    getMarkdownDocument = (): Observable<Array<{ file: string; label: string; route: string }>> => {
        return this.http.get<any>(`assets/markdowns/document-list.json`).pipe(
            map((response) => response),
            catchError((error) => error)
        );
    };

    getAddressList = (): Observable<Array<RawProvince>> => {
        return this.http.get<any>(`assets/address-list/raw_database.json`).pipe(
            map((response) => response),
            catchError((error) => error)
        );
    };
}
