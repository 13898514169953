import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "capitalize",
})
export class CapitalizePipe implements PipeTransform {
    transform(value: string) {
        return `${value.slice(0, 1).toLocaleUpperCase()}${value.slice(1).toLocaleLowerCase()}`.replace(/-/g, " ");
    }
}
