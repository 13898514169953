import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { AnnouncementService } from "../announcement.service";

@Component({
    selector: "app-announcement",
    templateUrl: "./announcement.component.html",
    styleUrls: ["./announcement.component.scss"],
})
export class AnnouncementComponent implements OnInit, OnDestroy {
    private _subscription: Subscription;

    html: string;
    notShowChecked: boolean;

    constructor(private _matDialogRef: MatDialogRef<AnnouncementComponent>, private _announceService: AnnouncementService) {
        this._subscription = new Subscription();
        this.notShowChecked = false;
    }

    ngOnInit(): void {
        this.html = this._announceService.html;
        this._detectBackDropClick();
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    private _detectBackDropClick = (): void => {
        this._subscription.add(
            this._matDialogRef.backdropClick().subscribe((_) => {
                this._matDialogRef.close(this.notShowChecked);
            })
        );
    };

    onClose = (): void => {
        this._matDialogRef.close(this.notShowChecked);
    };
}
