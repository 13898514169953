import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, tap, retry, catchError } from "rxjs/operators";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { LocalService } from "@core/services/cache/local.service";
import { FbSubscribeList } from "@core/models/connection/fb-subscribe-list.model";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

@Injectable()
export class FacebookService {
    constructor(private _http: HttpRequest, private local: LocalService) {}

    $getRedirectFacebookLogin = (): Observable<{ redirect_url: string }> => {
        return this._http
            .get<any>({
                url: `${API_GATEWAYS_URL}fb/v2/v2-login?callback_uri=${window.location.pathname}`,
            })
            .pipe(
                tap((_) => this.local.delete(`${API_GATEWAYS_URL}fb/v2/page/list_page_subscribed`)),
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getFacebookLogout = (token: string): Observable<{ status: string }> => {
        return this._http
            .get<any>({
                headers: new HttpHeaders().append("X-Fb-User-Token", token),
                url: `${API_GATEWAYS_URL}fb/logout`,
            })
            .pipe(
                retry(genericRetry),
                map((response) => {
                    this.local.delete(`${API_GATEWAYS_URL}fb/v2/page/list_page_subscribed`);
                    return response;
                }),
                catchError((error) => throwError(() => error))
            );
    };

    $getListPageSubscribe = (token: string): Observable<FbSubscribeList> => {
        return this._http
            .get<any>({
                headers: new HttpHeaders().append("X-Fb-User-Token", token),
                url: `${API_GATEWAYS_URL}fb/v2/page/list_page_subscribed`,
            })
            .pipe(
                retry(genericRetry),
                map((response) => new FbSubscribeList().deserialize(response)),
                catchError((error) => throwError(() => error))
            );
    };

    $subscribePage = (token: string, bot_id: string, page_id: string): Observable<{ status: boolean }> => {
        return this._http
            .post<any>({
                headers: new HttpHeaders().append("X-Fb-User-Token", token),
                url: `${API_GATEWAYS_URL}fb/page/subscribe?page_detail_id=${page_id}&bot_id=${bot_id}`,
            })
            .pipe(
                retry(genericRetry),
                map((response) => {
                    this.local.delete(`${API_GATEWAYS_URL}fb/v2/page/list_page_subscribed`);
                    return response;
                }),
                catchError((error) => throwError(() => error))
            );
    };

    $unsubscribePage = (token: string, bot_id: string, page_id: string): Observable<{ status: boolean }> => {
        return this._http
            .delete<any>({
                headers: new HttpHeaders().append("X-Fb-User-Token", token),
                url: `${API_GATEWAYS_URL}fb/page/subscribe?page_detail_id=${page_id}&bot_id=${bot_id}`,
            })
            .pipe(
                retry(genericRetry),
                map((response) => {
                    this.local.delete(`${API_GATEWAYS_URL}fb/v2/page/list_page_subscribed`);
                    return response;
                }),
                catchError((error) => throwError(() => error))
            );
    };

    clear = (): void => {
        this.local.delete(`${API_GATEWAYS_URL}fb/v2/page/list_page_subscribed`);
    };
}
