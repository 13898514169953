<div class="d-flex justify-content-center p-relative pb-1">
    <ng-container [ngSwitch]="success">
        <h2 i18n="@@payment-successful-title" *ngSwitchCase="true">Payment Successful</h2>
        <h2 i18n="@@payment-failed-title" *ngSwitchDefault>Payment Failed!</h2>
    </ng-container>

    <button mat-mini-fab bn-small-button color="primary" class="esc-bttn" (click)="onClose()">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<div class="d-flex justify-content-center pb-2">
    <ng-container [ngSwitch]="success">
        <mat-icon *ngSwitchCase="true" svgIcon="ok-circle"></mat-icon>
        <mat-icon *ngSwitchDefault svgIcon="close-circle"></mat-icon>
    </ng-container>
</div>

<ng-container [ngSwitch]="success">
    <ng-container *ngSwitchCase="true">
        <p class="text-center">
            <ng-container i18n="@@success-paid">You have succesfully paid a total of </ng-container>
            <span class="text-blue">{{ price | currency }} <ng-container i18n="@@current-label">THB</ng-container></span>
        </p>
        <p class="text-center">
            <ng-container i18n="@@current-remaining-usage">Your current remaining usage time is </ng-container>
            <span class="text-blue">{{ remainDays | currency }} <state-i18n key="TITLE_DAYS"></state-i18n></span>
        </p>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <p i18n="@@error-paid" class="text-center">
            Sorry, we failed to verify your payment. Please try again<br />
            or contact your payment provider to resolve the issue.
        </p>
    </ng-container>
</ng-container>

<div *ngIf="invoice && success" class="d-flex justify-content-center">
    <button i18n="@@payment-receipt-title" bn-small-button mat-raised-button color="primary" (click)="onExportReceipt()">Payment Receipt</button>
</div>

<div *ngIf="!success" class="d-flex justify-content-center">
    <button i18n="@@okay" bn-small-button mat-raised-button color="primary" mat-dialog-close>Okay</button>
</div>
