import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { LocalService } from "@core/services/cache/local.service";
import { DlObject } from "@core/models/object/dl-object.model";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

interface DLObjectResponse {
    _id: string;
    object_name: string;
    type: string;
    list_object?: Array<{
        start_node: string;
        nodeList: Array<any>;
        edgeList: Array<any>;
        position: Array<{
            _id: string;
            tranform: string;
            left: string;
            top: string;
        }>;
    }>;
}

@Injectable()
export class ManageDlObjectService {
    get url() {
        return `${API_GATEWAYS_URL}platform-go/object/dialogue`;
    }

    constructor(private _http: HttpRequest, private local: LocalService) {}

    $getDlObjectList = (): Observable<Array<DlObject>> => {
        return this._http
            .get<Array<DLObjectResponse>>({
                url: this.url.toString(),
                cacheMins: 1,
            })
            .pipe(
                retry(genericRetry),
                map((response) =>
                    response.map((res) =>
                        new DlObject().deserialize({
                            _id: res._id,
                            name: res.object_name,
                        })
                    )
                ),
                catchError((error) => throwError(() => error))
            );
    };

    $getDlBody = (_id: string): Observable<DlObject> => {
        return this._http
            .get<DLObjectResponse>({
                url: `${API_GATEWAYS_URL}platform-go/object/dialogue/detail?object_id=${_id}`,
            })
            .pipe(
                retry(genericRetry),
                map((response) =>
                    new DlObject().deserialize({
                        _id: response._id,
                        name: response.object_name,
                        body: {
                            ...response?.list_object[0],
                            edgeList: response?.list_object[0]?.edgeList ?? [],
                            nodeList: response?.list_object[0]?.nodeList ?? [],
                            position: response?.list_object[0]?.position.map((n) => {
                                return {
                                    ...n,
                                    transform: n?.tranform,
                                };
                            }),
                        },
                    })
                ),
                catchError((error) => throwError(() => error))
            );
    };

    $insertDlObject = (body: DlObject): Observable<{ _id: string }> => {
        const request = body.convertObjectToServer();
        delete request._id;
        return this._http
            .post<{ _id: string }>({
                url: this.url.toString(),
                body: request,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $duplicateDlObject = (_id: string): Observable<DlObject> => {
        return this._http
            .post<{ data: DLObjectResponse }>({
                url: `${API_GATEWAYS_URL}platform-go/object/duplicate`,
                body: { _id: _id },
            })
            .pipe(
                retry(genericRetry),
                map((response) => {
                    this.clearCacheData();
                    return new DlObject().deserialize({
                        _id: response?.data?._id,
                        name: response?.data?.object_name,
                        body: {
                            ...response?.data?.list_object[0],
                            edgeList: response?.data?.list_object[0]?.edgeList ?? [],
                            nodeList: response?.data?.list_object[0]?.nodeList ?? [],
                            position: response?.data?.list_object[0]?.position.map((n) => {
                                return {
                                    ...n,
                                    transform: n?.tranform,
                                };
                            }),
                        },
                    });
                }),
                catchError((error) => throwError(() => error))
            );
    };

    $updateDlObject = (body: DlObject): Observable<{ msg: string }> => {
        const request = body.convertObjectToServer();
        return this._http
            .put<{ msg: string }>({
                url: this.url.toString(),
                body: request,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteDlObjectList = (body: Array<DlObject>): Observable<{ msg: string }> => {
        return this._http
            .delete<{ msg: string }>({
                url: this.url.toString(),
                body: { _id: body.map((b) => b._id) },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    clearCacheData = (): void => {
        this.local.delete(this.url.toString());
    };
}
