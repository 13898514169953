import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { LocalService } from "@core/services/cache/local.service";
import { environment as env } from "@env/environment";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

export interface ParamsModel {
    _id: string;
    bot_id: string;
    name: string;
    is_build_in: boolean;
}

@Injectable()
export class ManageParamsService {
    get url() {
        return `${API_GATEWAYS_URL}platform-go/param`;
    }

    constructor(private _http: HttpRequest, private local: LocalService) {}

    $getParams = (): Observable<Array<ParamsModel>> => {
        return this._http
            .get<Array<ParamsModel>>({
                url: this.url.toString(),
                cacheMins: 1,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $insertParams = (param: string): Observable<ParamsModel> => {
        return this._http
            .post<ParamsModel>({
                url: this.url.toString(),
                body: {
                    name: param,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteParams = (_id: string): Observable<void> => {
        return this._http
            .delete<void>({
                url: this.url.toString(),
                body: {
                    _id: _id,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    clearCacheData = (): void => {
        this.local.delete(this.url.toString());
    };
}
