<mat-drawer-container>
    <mat-drawer mode="side" opened disableClose>
        <div #sideBar class="side-bar-container" [ngClass]="{'menu-expand': expand, 'pl-1': expand}">
            <div class="user-container">
                <div class="user-logo-container" [ngClass]="{'logo-collapse': !expand}">
                    <img [src]="avatar" />
                </div>
                <div class="user-text-contain">
                    <div class="d-flex align-items-center">
                        <p>{{ bot_name ?? '&nbsp;' }}</p>
                        <div bnSpacer></div>
                        <mat-icon
                            style="color: #5b6370"
                            [svgIcon]="isSelectBotToggle ? 'up' : 'down'"
                            cdkOverlayOrigin
                            #selectBotTrigger="cdkOverlayOrigin"
                            (click)="onToggleSelectBot()"
                        ></mat-icon>
                    </div>
                    <div class="d-block p-relative channel-container mt-1">
                        <div class="d-flex flex-center channel" style="z-index: 1">
                            <img src="assets/brand-logo/messenger.svg" [ngClass]="{'filter-grey': !isConnectedFacebook}" />
                        </div>
                        <div class="d-flex flex-center channel" style="left: 15px">
                            <img [src]="isConnectedLine ? '/assets/brand-logo/line-square.png' : '/assets/brand-logo/line-logo-disable.png'" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="menu-n-sub-container">
                <a class="menu-item-container" [routerLink]="['/home/dashboard']" routerLinkActive="activate-route-item">
                    <div [ngClass]="{'menu-collapse': !expand, 'd-flex': expand}">
                        <mat-icon svgIcon="analytics"></mat-icon>
                        <div class="menu-item-text-contain">
                            <h4 i18n="@@analytics">Analytics</h4>
                        </div>
                    </div>
                </a>
                <div class="sub-menu-item-container" [ngStyle]="{'height': sideBar.classList.contains('menu-expand') ? '70px' : 'auto'}">
                    <div class="sub-menu-title-contain">
                        <h4 i18n="@@analytics">Analytics</h4>
                    </div>
                    <a class="sub-menu-text-contain" [routerLink]="['/home/dashboard/overall']" routerLinkActive="activate-route-text">
                        <span i18n="@@overall-statistics-title">Overall Statistics</span>
                    </a>
                    <a
                        class="sub-menu-text-contain"
                        [routerLink]="['/home/dashboard/dialog-performance']"
                        routerLinkActive="activate-route-text"
                    >
                        <span i18n="@@dialogue-performance">Dialogue Performance</span>
                    </a>
                </div>
            </div>

            <a
                data-cy="go-to-training-page-button"
                class="menu-item-container"
                cdkOverlayOrigin
                #sidebarTrainbotToolTip="cdkOverlayOrigin"
                [routerLink]="['/home/training']"
                routerLinkActive="activate-route-item"
            >
                <div [ngClass]="{'menu-collapse': !expand, 'd-flex': expand}">
                    <mat-icon svgIcon="robot"></mat-icon>
                    <div class="menu-item-text-contain">
                        <h4><state-i18n key="TITLE_TRAIN_BOT"></state-i18n></h4>
                    </div>
                </div>
            </a>

            <a
                data-cy="go-to-object-page-button"
                class="menu-item-container"
                cdkOverlayOrigin
                #sidebarObjectToolTip="cdkOverlayOrigin"
                [routerLink]="['/home/objects']"
                routerLinkActive="activate-route-item"
            >
                <div [ngClass]="{'menu-collapse': !expand, 'd-flex': expand}">
                    <mat-icon svgIcon="intent"></mat-icon>
                    <div class="menu-item-text-contain">
                        <h4 i18n="@@object">Object</h4>
                    </div>
                </div>
            </a>

            <a
                data-cy="go-to-dialog-page-button"
                class="menu-item-container"
                cdkOverlayOrigin
                #sidebarDialogueToolTip="cdkOverlayOrigin"
                [routerLink]="['/home/dialog']"
                routerLinkActive="activate-route-item"
            >
                <div [ngClass]="{'menu-collapse': !expand, 'd-flex': expand}">
                    <mat-icon svgIcon="conversation"></mat-icon>
                    <div class="menu-item-text-contain">
                        <h4 i18n="@@dialogue">Dialogue</h4>
                    </div>
                </div>
            </a>

            <a
                class="menu-item-container"
                cdkOverlayOrigin
                #sidebarAPIObjectToolTip="cdkOverlayOrigin"
                [routerLink]="['/home/api']"
                routerLinkActive="activate-route-item"
            >
                <div [ngClass]="{'menu-collapse': !expand, 'd-flex': expand}">
                    <mat-icon svgIcon="cloud-API"></mat-icon>
                    <div class="menu-item-text-contain">
                        <h4 i18n="@@api">API</h4>
                    </div>
                </div>
            </a>

            <a
                class="menu-item-container"
                cdkOverlayOrigin
                #sidebarEntitiesToolTip="cdkOverlayOrigin"
                [routerLink]="['/home/entity']"
                routerLinkActive="activate-route-item"
            >
                <div [ngClass]="{'menu-collapse': !expand, 'd-flex': expand}">
                    <mat-icon svgIcon="map"></mat-icon>
                    <div class="menu-item-text-contain">
                        <h4 i18n="@@entity">Entity</h4>
                    </div>
                </div>
            </a>
            <div cdkOverlayOrigin #sidebarDatabaseToolTip="cdkOverlayOrigin">
                <a *ngIf="featureFlags.featureDatabase" class="menu-item-container" [routerLink]="['/home/lead-gen']" routerLinkActive="activate-route-item">
                    <div [ngClass]="{'menu-collapse': !expand, 'd-flex': expand}">
                        <mat-icon svgIcon="database"></mat-icon>
                        <div class="menu-item-text-contain">
                            <h4 i18n="@@database">Database</h4>
                        </div>
                    </div>
                </a>
            </div>

            <a
                data-cy="go-to-livechat-page-button"
                class="menu-item-container"
                cdkOverlayOrigin
                #sidebarLivechatToolTip="cdkOverlayOrigin"
                [routerLink]="['/home/chat']"
                routerLinkActive="activate-route-item"
            >
                <div [ngClass]="{'menu-collapse': !expand, 'd-flex': expand}">
                    <mat-icon
                        svgIcon="bubble"
                        [matBadge]="userNumberInAgent"
                        [matBadgeHidden]="!hasUserInAgent"
                        matBadgeSize="small"
                        matBadgeColor="warn"
                    ></mat-icon>
                    <div class="menu-item-text-contain">
                        <h4 i18n="@@live-chat-beta">Live Chat</h4>
                    </div>
                </div>
            </a>

            <a
                class="menu-item-container"
                cdkOverlayOrigin
                #sidebarDocumentToolTip="cdkOverlayOrigin"
                [routerLink]="['/home/docs']"
                routerLinkActive="activate-route-item"
            >
                <div [ngClass]="{'menu-collapse': !expand, 'd-flex': expand}">
                    <mat-icon svgIcon="document-text"></mat-icon>
                    <div class="menu-item-text-contain">
                        <h4 i18n="@@document">Document</h4>
                    </div>
                </div>
            </a>

            <div cdkOverlayOrigin #sidebarMarketToolTip="cdkOverlayOrigin">
                <a
                    *ngIf="featureFlags.featureMarketplace"
                    class="menu-item-container"
                    [routerLink]="['/home/marketplace']"
                    routerLinkActive="activate-route-item"
                >
                    <div [ngClass]="{'menu-collapse': !expand, 'd-flex': expand}">
                        <mat-icon svgIcon="shop-2"></mat-icon>
                        <div class="menu-item-text-contain">
                            <h4 i18n="@@marketplace">Marketplace</h4>
                        </div>
                    </div>
                </a>
            </div>

            <a
                data-cy="go-to-setting-page-button"
                class="menu-item-container"
                cdkOverlayOrigin
                #sidebarSettingsToolTip="cdkOverlayOrigin"
                [routerLink]="['/home/settings']"
                routerLinkActive="activate-route-item"
            >
                <div [ngClass]="{'menu-collapse': !expand, 'd-flex': expand}">
                    <mat-icon svgIcon="cog-filled"></mat-icon>
                    <div class="menu-item-text-contain">
                        <h4 i18n="@@settings">Settings</h4>
                    </div>
                </div>
            </a>
        </div>
    </mat-drawer>

    <mat-drawer-content class="content-contain" [ngClass]="{'menu-expand': expand}">
        <ng-content></ng-content>
    </mat-drawer-content>
</mat-drawer-container>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="selectBotTrigger"
    [cdkConnectedOverlayOpen]="isSelectBotToggle"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayPositions]="defaultPositions"
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    (backdropClick)="isSelectBotToggle = false"
    (overlayKeydown)="overlayKeydown($event)"
>
    <div class="overlay-container">
        <div class="header-title">
            <h4 i18n="@@my-bot-title" class="mb-0">My Bot</h4>
            <hr />
            <cdk-virtual-scroll-viewport itemSize="33" class="view-port-container" [ngStyle]="{'height': (($myBotsLength | async) * 33) + 'px'}">
                <ng-container [ngSwitch]="$myBotsLength | async">
                    <ng-container *ngSwitchCase="0">
                        <p i18n="@@no-my-bot-title" class="text-center text-grey mb-0">No My Bot</p>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <button *cdkVirtualFor="let item of $myBots | async" class="bot-label-container" (click)="onChangeBot(item)">
                            <div class="avatar-container">
                                <img [src]="item?.bot_avatar ? item?.bot_avatar : '/assets/botnoi-logo/botnoi.svg'" alt="Botnoi" />
                            </div>
                            <p class="mb-0 ml-1">{{ item?.bot_name }}</p>
                        </button>
                    </ng-container>
                </ng-container>
            </cdk-virtual-scroll-viewport>
        </div>

        <div class="header-title">
            <h4 i18n="@@shared-bot-title" class="mb-0">Shared Bot</h4>
            <hr />
            <cdk-virtual-scroll-viewport itemSize="33" class="view-port-container" [ngStyle]="{'height': (($sharedBotsLength | async) * 33) + 'px'}">
                <ng-container [ngSwitch]="$sharedBotsLength | async">
                    <ng-container *ngSwitchCase="0">
                        <p i18n="@@no-shared-bot-title" class="text-center text-grey mb-0">No Shared Bot</p>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <button *cdkVirtualFor="let item of $sharedBots | async" class="bot-label-container" (click)="onChangeBot(item)">
                            <div class="avatar-container">
                                <img [src]="item?.bot_avatar ? item?.bot_avatar : '/assets/botnoi-logo/botnoi.svg'" alt="Botnoi" />
                            </div>
                            <p class="mb-0 ml-1">{{ item?.bot_name }}</p>
                        </button>
                    </ng-container>
                </ng-container>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</ng-template>
