import { Deserializable } from "../deserializable.model";
import { Button } from "./button-object.model";

export class PersistentObject implements Deserializable {
    _id: string = "";
    name: string = "";
    is_active: boolean = false;
    buttons: Array<Button> = [];

    deserialize(input: any) {
        switch (true) {
            case !!input?._id:
                this._id = input._id;
            case !!input?.name:
                this.name = input.name.replace("PM_", "");
            case input?.is_active:
                this.is_active = input.is_active;
            case !!input?.buttons?.length:
                this.buttons = input?.buttons?.map((button: any) =>
                    new Button().deserialize({
                        type: button.type ?? "",
                        label: button.label ?? "",
                        message: button.message ?? "",
                    })
                );
        }

        return this;
    }

    convertObjectToServer() {
        return {
            _id: this._id,
            type: "persistent_menu",
            is_active: this.is_active,
            object_name: this.name.includes("PM_") ? this.name.trim() : `PM_${this.name.trim()}`,
            persistent_menu: [
                {
                    call_to_actions: this.buttons.map((bttn) => {
                        const bn = bttn.convertObjectToServer();
                        switch (bn.type) {
                            case "web_url":
                                return {
                                    type: bn.type,
                                    title: bn.label,
                                    url: bn.data,
                                };
                            default:
                                return {
                                    type: bn.type,
                                    title: bn.label,
                                    payload: bn.data,
                                };
                        }
                    }),
                },
            ],
        };
    }
}
