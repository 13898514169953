import { Deserializable } from "../deserializable.model";

export class RmObject implements Deserializable {
    _id: string;
    name: string;
    url: string;
    selected_auto: boolean;
    default: boolean;
    actions: Array<Area>;
    size: Size;

    private _cacheFile: { file: File; binary: string };

    get hasFile(): boolean {
        return this._cacheFile ? true : false;
    }

    get cacheFile(): File {
        return this._cacheFile.file;
    }

    get hasInitDetail(): boolean {
        return this.size ? true : false;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        if (input.actions) this.actions = input.actions.map((action: any) => new Area().deserialize(action));
        if (input.size) {
            this.size = new Size().deserialize(input.size);
            this.size.onUpdateLayout(input.actions ? input.actions : null);
        }
        return this;
    }

    convertObjectToServer() {
        const object = {
            _id: this._id,
            object_name: this.name.includes("RM_") ? this.name : `RM_${this.name}`,
            rich_menu_image_url: this.url,
            rich_menu_chat_bar_text: this.name,
            rich_menu_size: this.size.convertObjectToServer(),
            rich_menu_selected: this.selected_auto ? true : false,
            is_default_rich_menu: this.default ? true : false,
            areas: this.actions.map((action) => action.convertObectToServer(this.size.mode, this.size.column, this.size.row)),
        };
        return object;
    }

    onChangeImage = (file: File, binary: string): void => {
        this._cacheFile = { file: file, binary: binary };
    };

    onUpdateObject = (input: any): void => {
        if (input._id) this._id = input._id;
        if (input.name) this.name = input.name;
        if (input.actions) this.actions = input.actions.map((action: any) => new Area().deserialize(action));
        if (input.size) {
            this.size = new Size().deserialize(input.size);
            this.size.onUpdateLayout(input.actions ? input.actions : null);
        }
        this.default = input.default ? true : false;
        this.selected_auto = input.selected_auto ? true : false;
    };

    onUpdateRichImage = (url: string): void => {
        this.url = url;
    };
}

class Size implements Deserializable {
    mode: "rectangle" | "square";
    row: number;
    column: number;

    deserialize(input: any) {
        Object.assign(this, input);
        this.mode = input.height == 1686 ? "rectangle" : "square";
        if (input.mode) this.mode = input.mode;
        return this;
    }

    onUpdateLayout = (actions: any): void => {
        if (typeof this.row == "string") this.row = parseInt(this.row);
        if (typeof this.column == "string") this.column = parseInt(this.column);
        if (actions) {
            this.row =
                actions.filter((action: any) => action.bound.x === 0).length == 0 ? this.row : actions.filter((action: any) => action.bound.x === 0).length;
            this.column =
                actions.filter((action: any) => action.bound.y === 0).length == 0 ? this.column : actions.filter((action: any) => action.bound.y === 0).length;
        }
    };

    convertObjectToServer() {
        switch (this.mode) {
            case "rectangle":
                return { width: 2500, height: 1686 };
            default:
                return { width: 2500, height: 843 };
        }
    }
}

class Area implements Deserializable {
    action: Action;
    bound: Bound;

    deserialize(input: Area) {
        this.action = new Action().deserialize(input.action);
        this.bound = new Bound().deserialize(input.bound);
        return this;
    }

    convertObectToServer(mode: "rectangle" | "square", column: number, row: number) {
        return {
            action: this.action.convertObectToServer(),
            bounds: this.bound.convertObectToServer(mode, column, row),
        };
    }
}

interface Area {
    action: Action;
    bound: Bound;
}

class Action implements Deserializable {
    label: string;
    type: "message" | "url" | "object" | "intent" | "richmenuswitch";
    message: string;

    deserialize(input: Action) {
        Object.assign(this, input);
        this.type =
            input.type == "intent"
                ? input.type
                : input.type == "url"
                ? input.type
                : input.type == "object"
                ? input.type
                : "message"
                ? input.type
                : "richmenuswitch";
        return this;
    }

    convertObectToServer() {
        const object = {
            label: this.label,
            type: this.type == "url" ? "uri" : this.type,
            data: this.message,
        };
        return object;
    }
}

interface Action {
    label: string;
    type: "message" | "url" | "object" | "intent" | "richmenuswitch";
    message: string;
}

class Bound implements Deserializable {
    order: number;

    deserialize(input: Bound) {
        this.order = input.order;
        return this;
    }

    convertObectToServer(mode: "rectangle" | "square", column: number, row: number) {
        switch (mode) {
            case "rectangle":
                return {
                    height: 1684 / row,
                    width: 2498 / column,
                    x: Math.floor(this.order % column) * (2498 / column) + (Math.floor(this.order % column) > 0 ? 1 : 0),
                    y: Math.floor(this.order / column) * (1684 / row) + (Math.floor(this.order / column) > 0 ? 1 : 0),
                };
            default:
                return {
                    height: 841 / row,
                    width: 2498 / column,
                    x: (2498 / column) * this.order,
                    y: 0,
                };
        }
    }
}

interface Bound {
    order: number;
}
