import { Component, Input } from "@angular/core";
import { I18nState } from "../i18n-state";

@Component({
    selector: "state-i18n",
    templateUrl: "./state-i18n.component.html",
    styleUrls: ["./state-i18n.component.scss"],
})
export class StateI18nComponent {
    @Input() key: I18nState | string;
}
