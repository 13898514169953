<ng-container i18n="@@state-i18n.component">
    {
        key, select,
        GLOBAL_ERR_MSG {Please try again or contact our support.}
        ERR_USR_REQUIRED {Username is required}
        ERR_PWD_REQUIRED {Password is required}
        ERR_INV_EMAIL {Please enter a valid email address}
        ERR_LOGIN {Username or password incorrect}
        ERR_TYPE_REQUIRED {Type is required}
        ERR_COMPANY_REQUIRED {Company name is required}
        ERR_FIRST_NAME_REQUIRED {First name is required}
        ERR_LAST_NAME_REQUIRED {Last name is required}
        ERR_BILL_ADDRESS_REQUIRED {Billing address is required}
        ERR_PROVINCE_REQUIRED {Province is required}
        ERR_DIST_REQUIRED {District is required}
        ERR_SUB_DIST_REQUIRED {Sub-district is required}
        ERR_POSTAL_REQUIRED {Postal code is required}
        ERR_PHONE_REQUIRED {Phone number is required}
        ERR_VAT_REQUIRED {VAT / GST is required}
        ERR_CONFIRMPASS_REQ {Confirm password is required}
        ERR_PASSWORD_NOT_MATCH {Password is not match}

        LABEL_LOGIN {Log in}
        LABEL_EMAIL {Email}
        LABEL_EMAIL_PHD {Enter email}
        LABEL_PWD {Password}
        LABEL_PWD_PHD {Enter password}
        LABEL_SIGNUP {Sign up}
        LABEL_REMEMBER {Remember me}
        LABEL_FORGET_PWD {Forget password}
        LABEL_LINE_LOGIN {Log in with LINE}
        LABEL_BILLING_INFORM {Billing information}
        LABEL_TYPE {Type}
        LABEL_TYPE_PHD {Select type}
        LABEL_PERSONAL {Personal}
        LABEL_COMPANY {Company}
        LABEL_COMPANY_PHD {Enter company name}
        LABEL_FIRST_NAME {First name}
        LABEL_FIRST_NAME_PHD {Enter first name}
        LABEL_LAST_NAME {Last name}
        LABEL_LAST_NAME_PHD {Enter last name}
        LABEL_BILL_ADDRESS {Billing address}
        LABEL_BILL_ADDRESS_PHD {Enter billing address}
        LABEL_PROVINCE {Province}
        LABEL_PROVINCE_PHD {Select province}
        LABEL_DIST {District}
        LABEL_DIST_PHD {Select district}
        LABEL_SUB_DIST {Sub-district}
        LABEL_SUB_DIST_PHD {Select sub-district}
        LABEL_POSTAL {Postal code}
        LABEL_POSTAL_PHD {Select postal code}
        LABEL_PHONE {Phone number}
        LABEL_PHONE_PHD {Enter phone number}
        LABEL_VAT {VAT / GST}
        LABEL_VAT_PHD {Enter VAT / GST registration number}
        LABEL_LOADING {Loading data}
        LABEL_NO_DATA {No matching data}
        LABEL_SEARCH_PHD {Search ....}
        LABEL_CON {Confident}
        LABEL_MARKET {Marketplace}
        LABEL_HELP {Help}

        OBJ_API {API}
        OBJ_DL {Dialogue}
        OBJ_CUSTOM {Custom payload}
        OBJ_RM {Rich menu}
        OBJ_FX {Flex message}
        OBJ_QRP {Quick reply}
        OBJ_BTN {Button}
        OBJ_CRS {Carousel}
        OBJ_IMG {Image}
        OBJ_ENT {Entities}
        OBJ_PM {Persistent menu}

        TITLE_CONFIRM {Are you sure?}
        TITLE_IM_EX {Import/Export}
        TITLE_TRAIN_BOT {Train Bot}
        TITLE_CHATBOT_PROFILE {Chatbot Profile}
        TITLE_HOW_TO_GET_KEY {How do I get a chatbot clone key?}
        TITLE_DAYS {Days}

        BTN_CANCEL {Cancel}
        BTN_SAVE {Save}
        BTN_ADD {Add}
        BTN_SUBMIT {Submit}
        BTN_EDIT {Edit}
        BTN_DELETE {Delete}
        BTN_CLOSE {Close}
        BTN_EXPORT {Export}
        BTN_IMPORT {Import}
        BTN_CLONE_CHATBOT {Clone Chatbot}
        BTN_DONE {Done}
        BTN_ADD_BUTTON {Add Button}
        BTN_UPGRADE {Upgrade}
        BTN_TOPUP {Top Up}
    }
</ng-container>
