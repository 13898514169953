<div class="d-flex flex-column flex-center p-relative">
    <h2 i18n="@@delete-chatbot-title" class="mb-3">Delete Chatbot</h2>
    <button mat-mini-fab bn-small-button color="primary" class="esc-bttn" (click)="onClose()">
        <mat-icon svgIcon="close"></mat-icon>
    </button>

    <div class="d-flex align-items-center mb-2">
        <div class="profile-container mr-1">
            <img [src]="logo" />
        </div>
        <h3 data-cy="header-delete-chatbot-name" class="mb-0">{{ bot_name }}</h3>
    </div>

    <p i18n="@@delete-chatbot-desc-1" class="mb-1 text-center text-light-grey">This chatbot will be deleted immediately, and deletion can’t be undone.</p>
    <p class="mb-3 text-center text-light-grey">
        <ng-container i18n="@@delete-chatbot-desc-2">Confirm deletion by typing</ng-container><span class="prefix-font"> {{ bot_name }}</span>?
    </p>

    <bn-form-field bnFormLarge class="w-100 mb-3">
        <input
            data-cy="input-delete-chatbot-name"
            i18n-placeholder="@@enter-chatbot-name-title"
            bnInput
            type="text"
            placeholder="Enter Chatbot Name"
            [(ngModel)]="typingName"
        />
    </bn-form-field>

    <div class="d-flex align-items-center">
        <button mat-stroked-button color="primary" class="mr-1" (click)="onClose()">
            <state-i18n key="BTN_CLOSE"></state-i18n>
        </button>
        <button data-cy="confirm-delete-chatbot-button" mat-raised-button color="warn" (click)="onDelete()" [disabled]="!canDelete">
            <div *ngIf="fetching" class="loader"></div>
            <state-i18n key="BTN_DELETE"></state-i18n>
        </button>
    </div>
</div>
