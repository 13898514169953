import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { Subscription, Observable, BehaviorSubject, interval, throwError, of } from "rxjs";
import { tap, switchMap, startWith } from "rxjs/operators";
import { UploadService, ExportProgressModel } from "@core/http/s3/upload.service";
import { ConfirmationDialogComponent } from "@shared/components/dialog";
import { SnackBarAlertService } from "@shared/services/snack-bar-alert/snack-bar-alert.service";
import { I18nState, getI18nState } from "@shared/translation/i18n-state";

interface DownloadFileDataModel {
    type: string;
    ref_id?: string;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    dataType?: Array<string>;
    dataCategory?: Array<string>;
}

@Component({
    selector: "app-download-file",
    templateUrl: "./download-file.component.html",
    styleUrls: ["./download-file.component.scss"],
})
export class DownloadFileComponent implements OnInit, OnDestroy {
    private _subscription: Subscription;

    $progress: BehaviorSubject<number>;
    private _progress: number;

    constructor(
        private _matDialogRef: MatDialogRef<DownloadFileComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: DownloadFileDataModel,
        private _matDialog: MatDialog,
        private _uploadService: UploadService,
        private _snackbar: SnackBarAlertService
    ) {
        this._subscription = new Subscription();
        this._progress = 0;
        this.$progress = new BehaviorSubject(this._progress);
    }

    ngOnInit(): void {
        this._onExportRecordData();
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    private _onExportRecordData = (): void => {
        this._subscription.add(
            of(this._data?.type)
                .pipe(
                    switchMap((type) => {
                        if (type == "nlp") return this._onExportNlpRecordData();
                        if (type == "dashboard") return this._onExportDashboardData();
                        if (type == "dialog-performance") return this._onExportDialogPerformance();
                        if (type == "entity") return this._onExportEntityData();
                        return this._onExportObjectRecordData();
                    })
                )
                .subscribe({
                    next: (response) => {
                        if (!!response?.process) this._progress = response.process;
                        if (!response?.process) if (this._progress < 90) this._progress += 10;
                        this.$progress.next(parseInt(this._progress.toFixed(0)));

                        if (response.status == "success") {
                            this._matDialogRef.close(true);
                            this._matDialog.open(ConfirmationDialogComponent, {
                                data: {
                                    state: "inform",
                                    title: $localize`:@@export-success:Export success`,
                                    description: $localize`:@@export-success-desc:Please use the download button below or check<br />your <span class="text-blue">${response?.email}</span> email for the download link.`,
                                    icon: "ok-circle",
                                    submitLabel: $localize`:@@download:Download`,
                                    action: "download",
                                    data: response.url,
                                },
                            });
                        }
                    },
                    error: (error) => {
                        console.error(error);
                        if (error.status == 0) {
                            this._matDialogRef.close(true);
                            return;
                        }
                        this._snackbar.open("error", getI18nState(I18nState.GLOBAL_ERR_MSG));
                        this.onClose();
                    },
                })
        );
    };

    private _onExportNlpRecordData = (): Observable<ExportProgressModel> => {
        return this._uploadService.$exportNlpRecord(this._data?.startDate, this._data?.endDate, this._data?.dataType, this._data?.dataCategory).pipe(
            tap((_) => {
                this._progress = 0;
                this.$progress.next(this._progress);
            }),
            switchMap((response) =>
                interval(3000).pipe(
                    startWith(0),
                    switchMap((_) => this._uploadService.$exportRecordValidate("nlp", response.status_id))
                )
            )
        );
    };

    private _onExportDashboardData = (): Observable<ExportProgressModel> => {
        return this._uploadService
            .$getExportOverallDashboardData(moment(this._data?.startDate).format("YYYY-MM-DD"), moment(this._data?.endDate).format("YYYY-MM-DD"))
            .pipe(
                tap((_) => {
                    this._progress = 0;
                    this.$progress.next(this._progress);
                }),
                switchMap((response) => {
                    if (!(response instanceof Blob))
                        return interval(3000).pipe(
                            startWith(0),
                            switchMap((_) => this._uploadService.$exportOverallDashboardValidate(response.status_id))
                        );

                    const url = window.URL.createObjectURL(response);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `dashboard_${new Date().getTime()}.xlsx`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    return throwError(() => ({ status: 0 }));
                })
            );
    };

    private _onExportDialogPerformance = (): Observable<ExportProgressModel> => {
        return this._uploadService
            .$getExportDialogPerformance(
                moment(this._data?.startDate).format("YYYY-MM-DD"),
                moment(this._data?.endDate).format("YYYY-MM-DD"),
                this._data?.ref_id
            )
            .pipe(
                tap((_) => {
                    this._progress = 0;
                    this.$progress.next(this._progress);
                }),
                switchMap((response) => {
                    const url = window.URL.createObjectURL(response);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `dialog_performance_${new Date().getTime()}_${this._data?.ref_id}.xlsx`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    return throwError(() => ({ status: 0 }));
                })
            );
    };

    private _onExportEntityData = (): Observable<ExportProgressModel> => {
        return this._uploadService.$getExportEntityData().pipe(
            tap((_) => {
                this._progress = 0;
                this.$progress.next(this._progress);
            }),
            switchMap((response) => {
                if (!(response instanceof Blob))
                    return interval(3000).pipe(
                        startWith(0),
                        switchMap((_) => this._uploadService.$exportEntityValidate(response.status_id))
                    );

                const url = window.URL.createObjectURL(response);
                const a = document.createElement("a");
                a.href = url;
                a.download = `entity_${new Date().getTime()}.xlsx`;
                a.click();
                window.URL.revokeObjectURL(url);
                return throwError(() => ({ status: 0 }));
            })
        );
    };

    private _onExportObjectRecordData = (): Observable<ExportProgressModel> => {
        return this._uploadService.$exportObject(this._data?.type).pipe(
            tap((_) => {
                this._progress = 0;
                this.$progress.next(this._progress);
            }),
            switchMap((response) => {
                if (!(response instanceof Blob))
                    return interval(3000).pipe(
                        startWith(0),
                        switchMap((_) => this._uploadService.$exportRecordValidate(this._data?.type, response.status_id))
                    );

                const url = window.URL.createObjectURL(response);
                const a = document.createElement("a");
                a.href = url;
                a.download = `${this._data?.type}_${new Date().getTime()}.xlsx`;
                a.click();
                window.URL.revokeObjectURL(url);
                return throwError(() => ({ status: 0 }));
            })
        );
    };

    onClose = (): void => {
        this._matDialogRef.close();
    };
}
