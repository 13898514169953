export enum I18nState {
    // Error message
    GLOBAL_ERR_MSG = "GLOBAL_ERR_MSG",
    ERR_USR_REQUIRED = "ERR_USR_REQUIRED",
    ERR_PWD_REQUIRED = "ERR_PWD_REQUIRED",
    ERR_INV_EMAIL = "ERR_INV_EMAIL",
    ERR_LOGIN = "ERR_LOGIN",
    ERR_TYPE_REQUIRED = "ERR_TYPE_REQUIRED",
    ERR_COMPANY_REQUIRED = "ERR_COMPANY_REQUIRED",
    ERR_FIRST_NAME_REQUIRED = "ERR_FIRST_NAME_REQUIRED",
    ERR_LAST_NAME_REQUIRED = "ERR_LAST_NAME_REQUIRED",
    ERR_BILL_ADDRESS_REQUIRED = "ERR_BILL_ADDRESS_REQUIRED",
    ERR_PROVINCE_REQUIRED = "ERR_PROVINCE_REQUIRED",
    ERR_DIST_REQUIRED = "ERR_DIST_REQUIRED",
    ERR_SUB_DIST_REQUIRED = "ERR_SUB_DIST_REQUIRED",
    ERR_POSTAL_REQUIRED = "ERR_POSTAL_REQUIRED",
    ERR_PHONE_REQUIRED = "ERR_PHONE_REQUIRED",
    ERR_VAT_REQUIRED = "ERR_VAT_REQUIRED",
    ERR_CONFIRMPASS_REQ = "ERR_CONFIRMPASS_REQ",
    ERR_PASSWORD_NOT_MATCH = "ERR_PASSWORD_NOT_MATCH",
    // Label text
    LABEL_LOGIN = "LABEL_LOGIN",
    LABEL_EMAIL = "LABEL_EMAIL",
    LABEL_EMAIL_PHD = "LABEL_EMAIL_PHD",
    LABEL_PWD = "LABEL_PWD",
    LABEL_PWD_PHD = "LABEL_PWD_PHD",
    LABEL_SIGNUP = "LABEL_SIGNUP",
    LABEL_REMEMBER = "LABEL_REMEMBER",
    LABEL_FORGET_PWD = "LABEL_FORGET_PWD",
    LABEL_LINE_LOGIN = "LABEL_LINE_LOGIN",
    LABEL_BILLING_INFORM = "LABEL_BILLING_INFORM",
    LABEL_TYPE = "LABEL_TYPE",
    LABEL_TYPE_PHD = "LABEL_TYPE_PHD",
    LABEL_PERSONAL = "LABEL_PERSONAL",
    LABEL_COMPANY = "LABEL_COMPANY",
    LABEL_COMPANY_PHD = "LABEL_COMPANY_PHD",
    LABEL_FIRST_NAME = "LABEL_FIRST_NAME",
    LABEL_FIRST_NAME_PHD = "LABEL_FIRST_NAME_PHD",
    LABEL_LAST_NAME = "LABEL_LAST_NAME",
    LABEL_LAST_NAME_PHD = "LABEL_LAST_NAME_PHD",
    LABEL_BILL_ADDRESS = "LABEL_BILL_ADDRESS",
    LABEL_BILL_ADDRESS_PHD = "LABEL_BILL_ADDRESS_PHD",
    LABEL_PROVINCE = "LABEL_PROVINCE",
    LABEL_PROVINCE_PHD = "LABEL_PROVINCE_PHD",
    LABEL_DIST = "LABEL_DIST",
    LABEL_DIST_PHD = "LABEL_DIST_PHD",
    LABEL_SUB_DIST = "LABEL_SUB_DIST",
    LABEL_SUB_DIST_PHD = "LABEL_SUB_DIST_PHD",
    LABEL_POSTAL = "LABEL_POSTAL",
    LABEL_POSTAL_PHD = "LABEL_POSTAL_PHD",
    LABEL_PHONE = "LABEL_PHONE",
    LABEL_PHONE_PHD = "LABEL_PHONE_PHD",
    LABEL_VAT = "LABEL_VAT",
    LABEL_VAT_PHD = "LABEL_VAT_PHD",
    LABEL_LOADING = "LABEL_LOADING",
    LABEL_NO_DATA = "LABEL_NO_DATA",
    LABEL_SEARCH_PHD = "LABEL_SEARCH_PHD",
    LABEL_CON = "LABEL_CON",
    LABEL_MARKET = "LABEL_MARKET",
    LABEL_HELP = "LABEL_HELP",
    // Object label
    OBJ_API = "OBJ_API",
    OBJ_DL = "OBJ_DL",
    OBJ_CUSTOM = "OBJ_CUSTOM",
    OBJ_RM = "OBJ_RM",
    OBJ_FX = "OBJ_FX",
    OBJ_QRP = "OBJ_QRP",
    OBJ_BTN = "OBJ_BTN",
    OBJ_CRS = "OBJ_CRS",
    OBJ_IMG = "OBJ_IMG",
    OBJ_ENT = "OBJ_ENT",
    OBJ_PM = "OBJ_PM",
    // Title modal
    TITLE_CONFIRM = "TITLE_CONFIRM",
    TITLE_IM_EX = "TITLE_IM_EX",
    TITLE_TRAIN_BOT = "TITLE_TRAIN_BOT",
    TITLE_CHATBOT_PROFILE = "TITLE_CHATBOT_PROFILE",
    TITLE_HOW_TO_GET_KEY = "TITLE_HOW_TO_GET_KEY",
    TITLE_DAYS = "TITLE_DAYS",
    // Button label
    BTN_CANCEL = "BTN_CANCEL",
    BTN_SAVE = "BTN_SAVE",
    BTN_ADD = "BTN_ADD",
    BTN_SUBMIT = "BTN_SUBMIT",
    BTN_EDIT = "BTN_EDIT",
    BTN_DELETE = "BTN_DELETE",
    BTN_CLOSE = "BTN_CLOSE",
    BTN_EXPORT = "BTN_EXPORT",
    BTN_IMPORT = "BTN_IMPORT",
    BTN_CLONE_CHATBOT = "BTN_CLONE_CHATBOT",
    BTN_DONE = "BTN_DONE",
    BTN_ADD_BUTTON = "BTN_ADD_BUTTON",
    BTN_UPGRADE = "BTN_UPGRADE",
    BTN_TOPUP = "BTN_TOPUP",
}

export function getI18nState(state: I18nState) {
    switch (state) {
        // Error message
        case I18nState.GLOBAL_ERR_MSG:
            return $localize`:@@GLOBAL_ERR_MSG:Please try again or contact our support.`;
        case I18nState.ERR_USR_REQUIRED:
            return $localize`:@@ERR_USR_REQUIRED:Username is required`;
        case I18nState.ERR_PWD_REQUIRED:
            return $localize`:@@ERR_PWD_REQUIRED:Password is required`;
        case I18nState.ERR_INV_EMAIL:
            return $localize`:@@ERR_INV_EMAIL:Please enter a valid email address`;
        case I18nState.ERR_LOGIN:
            return $localize`:@@ERR_LOGIN:Username or password incorrect`;
        case I18nState.ERR_TYPE_REQUIRED:
            return $localize`:@@ERR_TYPE_REQUIRED:Type is required`;
        case I18nState.ERR_COMPANY_REQUIRED:
            return $localize`:@@ERR_COMPANY_REQUIRED:Company name is required`;
        case I18nState.ERR_FIRST_NAME_REQUIRED:
            return $localize`:@@ERR_FIRST_NAME_REQUIRED:First name is required`;
        case I18nState.ERR_LAST_NAME_REQUIRED:
            return $localize`:@@ERR_LAST_NAME_REQUIRED:Last name is required`;
        case I18nState.ERR_BILL_ADDRESS_REQUIRED:
            return $localize`:@@ERR_BILL_ADDRESS_REQUIRED:Billing address is required`;
        case I18nState.ERR_PROVINCE_REQUIRED:
            return $localize`:@@ERR_PROVINCE_REQUIRED:Province is required`;
        case I18nState.ERR_DIST_REQUIRED:
            return $localize`:@@ERR_DIST_REQUIRED:District is required`;
        case I18nState.ERR_SUB_DIST_REQUIRED:
            return $localize`:@@ERR_SUB_DIST_REQUIRED:Sub-district is required`;
        case I18nState.ERR_POSTAL_REQUIRED:
            return $localize`:@@ERR_POSTAL_REQUIRED:Postal code is required`;
        case I18nState.ERR_PHONE_REQUIRED:
            return $localize`:@@ERR_PHONE_REQUIRED:Phone number is required`;
        case I18nState.ERR_VAT_REQUIRED:
            return $localize`:@@ERR_VAT_REQUIRED:VAT / GST is required`;
        case I18nState.ERR_CONFIRMPASS_REQ:
            return $localize`:@@ERR_CONFIRMPASS_REQ:Confirm password is required`;
        case I18nState.ERR_PASSWORD_NOT_MATCH:
            return $localize`:@@ERR_PASSWORD_NOT_MATCH:Password is not match`;
        // Label text
        case I18nState.LABEL_LOGIN:
            return $localize`:@@LABEL_LOGIN:Log in`;
        case I18nState.LABEL_EMAIL:
            return $localize`:@@LABEL_EMAIL:Email`;
        case I18nState.LABEL_EMAIL_PHD:
            return $localize`:@@LABEL_EMAIL_PHD:Enter email`;
        case I18nState.LABEL_PWD:
            return $localize`:@@LABEL_PWD:Password`;
        case I18nState.LABEL_PWD_PHD:
            return $localize`:@@LABEL_PWD_PHD:Enter password`;
        case I18nState.LABEL_SIGNUP:
            return $localize`:@@LABEL_SIGNUP:Sign up`;
        case I18nState.LABEL_REMEMBER:
            return $localize`:@@LABEL_REMEMBER:Remember me`;
        case I18nState.LABEL_FORGET_PWD:
            return $localize`:@@LABEL_FORGET_PWD:Forget password`;
        case I18nState.LABEL_LINE_LOGIN:
            return $localize`:@@LABEL_LINE_LOGIN:Log in with LINE`;
        case I18nState.LABEL_BILLING_INFORM:
            return $localize`:@@LABEL_BILLING_INFORM:Billing information`;
        case I18nState.LABEL_TYPE:
            return $localize`:@@LABEL_TYPE:Type`;
        case I18nState.LABEL_TYPE_PHD:
            return $localize`:@@LABEL_TYPE_PHD:Select type`;
        case I18nState.LABEL_PERSONAL:
            return $localize`:@@LABEL_PERSONAL:Personal`;
        case I18nState.LABEL_COMPANY:
            return $localize`:@@LABEL_COMPANY:Company`;
        case I18nState.LABEL_COMPANY_PHD:
            return $localize`:@@LABEL_COMPANY_PHD:Enter company name`;
        case I18nState.LABEL_FIRST_NAME:
            return $localize`:@@LABEL_FIRST_NAME:First name`;
        case I18nState.LABEL_FIRST_NAME_PHD:
            return $localize`:@@LABEL_FIRST_NAME_PHD:Enter first name`;
        case I18nState.LABEL_LAST_NAME:
            return $localize`:@@LABEL_LAST_NAME:Last name`;
        case I18nState.LABEL_LAST_NAME_PHD:
            return $localize`:@@LABEL_LAST_NAME_PHD:Enter last name`;
        case I18nState.LABEL_BILL_ADDRESS_PHD:
            return $localize`:@@LABEL_BILL_ADDRESS_PHD:Enter billing address`;
        case I18nState.LABEL_PROVINCE:
            return $localize`:@@LABEL_PROVINCE:Province`;
        case I18nState.LABEL_PROVINCE_PHD:
            return $localize`:@@LABEL_PROVINCE_PHD:Select province`;
        case I18nState.LABEL_DIST:
            return $localize`:@@LABEL_DIST:District`;
        case I18nState.LABEL_DIST_PHD:
            return $localize`:@@LABEL_DIST_PHD:Select district`;
        case I18nState.LABEL_SUB_DIST:
            return $localize`:@@LABEL_SUB_DIST:Sub-district`;
        case I18nState.LABEL_SUB_DIST_PHD:
            return $localize`:@@LABEL_SUB_DIST_PHD:Select sub-district`;
        case I18nState.LABEL_POSTAL:
            return $localize`:@@LABEL_POSTAL:Postal code`;
        case I18nState.LABEL_POSTAL_PHD:
            return $localize`:@@LABEL_POSTAL_PHD:Select postal code`;
        case I18nState.LABEL_PHONE:
            return $localize`:@@LABEL_PHONE:Phone number`;
        case I18nState.LABEL_PHONE_PHD:
            return $localize`:@@LABEL_PHONE_PHD:Enter phone number`;
        case I18nState.LABEL_VAT:
            return $localize`:@@LABEL_VAT:VAT / GST`;
        case I18nState.LABEL_VAT_PHD:
            return $localize`:@@LABEL_VAT_PHD:Enter VAT / GST registration number`;
        case I18nState.LABEL_LOADING:
            return $localize`:@@LABEL_LOADING:Loading data`;
        case I18nState.LABEL_NO_DATA:
            return $localize`:@@LABEL_NO_DATA:No matching data`;
        case I18nState.LABEL_SEARCH_PHD:
            return $localize`:@@LABEL_SEARCH_PHD:Search ....`;
        case I18nState.LABEL_CON:
            return $localize`:@@LABEL_CON:Confident`;
        case I18nState.LABEL_MARKET:
            return $localize`:@@LABEL_MARKET:Marketplace`;
        case I18nState.LABEL_HELP:
            return $localize`:@@LABEL_HELP:Help`;
        // Object labe
        case I18nState.OBJ_API:
            return $localize`:@@OBJ_API:API`;
        case I18nState.OBJ_DL:
            return $localize`:@@OBJ_DL:Dailogue`;
        case I18nState.OBJ_CUSTOM:
            return $localize`:@@OBJ_CUSTOM:Custom payload`;
        case I18nState.OBJ_RM:
            return $localize`:@@OBJ_RM:Rich menu`;
        case I18nState.OBJ_FX:
            return $localize`:@@OBJ_FX:Flex message`;
        case I18nState.OBJ_QRP:
            return $localize`:@@OBJ_QRP:Quick reply`;
        case I18nState.OBJ_BTN:
            return $localize`:@@OBJ_BTN:Button`;
        case I18nState.OBJ_CRS:
            return $localize`:@@OBJ_CRS:Carousel`;
        case I18nState.OBJ_IMG:
            return $localize`:@@OBJ_IMG:Image`;
        case I18nState.OBJ_ENT:
            return $localize`:@@OBJ_ENT:Entities`;
        case I18nState.OBJ_PM:
            return $localize`:@@OBJ_PM:Persistent menu`;
        // Title modal
        case I18nState.TITLE_CONFIRM:
            return $localize`:@@TITLE_CONFIRM:Are you sure?`;
        case I18nState.TITLE_IM_EX:
            return $localize`:@@TITLE_IM_EX:Import/Export`;
        case I18nState.TITLE_TRAIN_BOT:
            return $localize`:@@TITLE_TRAIN_BOT:Train Bot`;
        case I18nState.TITLE_CHATBOT_PROFILE:
            return $localize`:@@TITLE_CHATBOT_PROFILE:Chatbot Profile`;
        case I18nState.TITLE_HOW_TO_GET_KEY:
            return $localize`:@@TITLE_HOW_TO_GET_KEY:How do I get a chatbot clone key?`;
        case I18nState.TITLE_DAYS:
            return $localize`:@@TITLE_DAYS:Days`;
        // Button label
        case I18nState.BTN_CANCEL:
            return $localize`:@@BTN_CANCEL:Cancel`;
        case I18nState.BTN_SAVE:
            return $localize`:@@BTN_SAVE:Save`;
        case I18nState.BTN_ADD:
            return $localize`:@@BTN_ADD:Add`;
        case I18nState.BTN_SUBMIT:
            return $localize`:@@BTN_SUBMIT:Submit`;
        case I18nState.BTN_EDIT:
            return $localize`:@@BTN_EDIT:Edit`;
        case I18nState.BTN_DELETE:
            return $localize`:@@BTN_DELETE:Delete`;
        case I18nState.BTN_CLOSE:
            return $localize`:@@BTN_CLOSE:Close`;
        case I18nState.BTN_EXPORT:
            return $localize`:@@BTN_EXPORT:Export`;
        case I18nState.BTN_IMPORT:
            return $localize`:@@BTN_IMPORT:Import`;
        case I18nState.BTN_CLONE_CHATBOT:
            return $localize`:@@BTN_CLONE_CHATBOT:Clone Chatbot`;
        case I18nState.BTN_DONE:
            return $localize`:@@BTN_DONE:Done`;
        case I18nState.BTN_ADD_BUTTON:
            return $localize`:@@BTN_ADD_BUTTON:Add Button`;
        case I18nState.BTN_UPGRADE:
            return $localize`:@@BTN_UPGRADE:Upgrade`;
        case I18nState.BTN_TOPUP:
            return $localize`:@@BTN_TOPUP:Top Up`;
        default:
            return $localize`:@@unknown:unknown`;
    }
}
