import { Deserializable } from "../deserializable.model";

export class FbSubscribeList implements Deserializable {
    is_connected: boolean;
    my_pages: { owner_id: string; resource: Array<FbResource> };
    type: "fb";
    used_pages: { resource: Array<FbResource> };

    deserialize(input: any) {
        Object.assign(this, input);
        if (!!input.is_connected) this.is_connected = input.is_connected == "false" ? false : input.is_connected == "true" ? true : input.is_connected;
        if (input.my_pages) this.my_pages.resource = input.my_pages.resource.map((r: any) => new FbResource().deserialize(r));
        if (input.used_pages) this.used_pages.resource = input.used_pages.resource.map((r: any) => new FbResource().deserialize(r));
        return this;
    }

    onSubscribePage(bot_id: string, page_id: string) {
        this.used_pages.resource.push(
            new FbResource().deserialize({
                bot_id: bot_id,
                page_id: page_id,
                owner_id: this.my_pages.owner_id,
            })
        );
    }

    onUnsubscribePage(page_id: string) {
        this.used_pages.resource = this.used_pages.resource.filter((r) => r.page_id !== page_id);
    }
}

class FbResource implements Deserializable {
    bot_id: string;
    owner_id: string;
    name: string;
    page_id: string;
    page_pic: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
