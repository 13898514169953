import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { I18nState, getI18nState } from "@shared/translation/i18n-state";

interface ConfirmationDialogModel {
    state: "delete" | "confirm" | "inform";
    title: string;
    description: string;
    icon?: string;
    submitLabel?: string;
    cancelLabel?: string;
    action?: string;
    data?: any;
    noIcon?: boolean;
    hasCheckbox?: boolean;
    checkboxLabel?: string;
}

@Component({
    selector: "app-confirmation-dialog",
    templateUrl: "./confirmation-dialog.component.html",
    styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent {
    get state() {
        return this.data?.state ?? "";
    }

    get title() {
        return this.data?.title ?? "";
    }

    get description() {
        return this.data?.description ?? "";
    }

    get noIcon() {
        return this.data?.noIcon ?? false;
    }
    get icon() {
        return !!this.data?.icon ? this.data.icon : this.state == "delete" ? "trash" : this.state == "inform" ? "information" : "ok-circle";
    }
    get iconTheme() {
        return this.state == "confirm" ? "mat-status" : "";
    }

    get primary() {
        return this.state == "delete" ? "warn" : "primary";
    }
    get accent() {
        return this.state == "inform" ? "primary" : "default";
    }

    get hasCheckbox() {
        return this.data?.hasCheckbox ?? false;
    }
    get checkboxLabel() {
        return this.data?.checkboxLabel ?? "";
    }

    get submitTitle() {
        if (!!this.data?.submitLabel) return this.data?.submitLabel;
        return this.state == "inform" ? $localize`:@@sure:Sure` : `${this.state.charAt(0).toUpperCase()}${this.state.slice(1)}`;
    }

    get cancelLabel() {
        return this.data?.cancelLabel ?? getI18nState(I18nState.BTN_CANCEL);
    }

    checked: boolean;
    get canSubmit() {
        return this.hasCheckbox ? this.checked : true;
    }

    constructor(private _matDialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: ConfirmationDialogModel) {}

    onSubmit = (): void => {
        if (!!this.data?.action) {
            switch (this.data.action) {
                case "download":
                    window.open(this.data.data, "_blank");
                    break;
                default:
                    this._matDialogRef.close(true);
            }
            return;
        }

        this._matDialogRef.close(true);
    };

    onCancel = (): void => {
        this._matDialogRef.close(false);
    };
}
