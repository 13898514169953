import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { ConversationModel, UserChatLogModel, SourceModel, Configuration, LivechatConfig } from "@core/models/livechat";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

@Injectable()
export class LivechatService {
    constructor(private _http: HttpRequest) {}

    $getUserList = (
        agent?: boolean,
        last?: Number,
        filter?: string,
        channel?: Array<string>,
        statusMessage?: Array<string>,
        tags?: Array<string>
    ): Observable<Array<ConversationModel>> => {
        let params = new HttpParams();
        if (!!agent) params = params.set("agent_status", agent ? "true" : "");
        if (!!last) params = params.set("before", last.toString());
        if (!!filter) params = params.set("search", filter);
        if (!!channel) params = params.set("channel", channel.join(","));
        if (!!statusMessage) {
            if (statusMessage.includes("spam")) params = params.set("spam", "true");
            statusMessage = statusMessage.filter((s) => s !== "spam");
            params = params.set("status_message", statusMessage.join(","));
        }
        if (!!tags) params = params.set("tags", tags.map((t) => t.trim()).join(","));

        return this._http
            .get<Array<ConversationModel>>({
                url: `${API_GATEWAYS_URL}livechat/conversations`,
                params: params,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getBlockUserList = (last?: number, filter?: string): Observable<Array<ConversationModel>> => {
        let params = new HttpParams();
        params = params.set("agent_status", "true,false");
        params = params.set("spam", "true");
        if (!!last) params = params.set("before", last.toString());
        if (!!filter) params = params.set("search", filter);

        return this._http
            .get<Array<ConversationModel>>({
                url: `${API_GATEWAYS_URL}livechat/conversations`,
                params: params,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getUserChatlog = (source: SourceModel, last?: number): Observable<Array<UserChatLogModel>> => {
        let params = new HttpParams();

        if (!!source) {
            params = params.set("source_id", source?.source_id);
            params = params.set("source_channel", source?.source_channel);
            params = params.set("source_app_id", source?.source_app_id);
            params = params.set("source_type", source?.source_type);
        }
        if (!!last) params = params.set("before", last.toString());

        return this._http
            .get<Array<UserChatLogModel>>({
                url: `${API_GATEWAYS_URL}livechat/messages`,
                params: params,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $setAgentState = (body: SourceModel, state: boolean): Observable<{ status: string; message: string }> => {
        return this._http
            .put<{ status: string; message: string }>({
                url: `${API_GATEWAYS_URL}livechat/conversations/agent_state`,
                body: {
                    ...body,
                    active: state,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $setReadMessage = (_id: string): Observable<void> => {
        return this._http
            .put({
                url: `${API_GATEWAYS_URL}livechat/conversations/read-status`,
                body: { msg_id: _id },
            })
            .pipe(
                retry(genericRetry),
                map((_) => null),
                catchError((error) => throwError(() => error))
            );
    };

    $getConfig = (): Observable<Configuration> => {
        return this._http
            .get<any>({
                url: `${API_GATEWAYS_URL}livechat/config`,
                cacheMins: 0.5,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $setConfig = (config: LivechatConfig): Observable<void> => {
        return this._http
            .put<void>({
                url: `${API_GATEWAYS_URL}livechat/config`,
                body: {
                    livechat_config: config,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $setUserSpamState = (sources: Array<SourceModel>, state: boolean): Observable<void> => {
        return this._http
            .put<void>({
                url: `${API_GATEWAYS_URL}livechat/conversations/spam-status`,
                body: {
                    source: sources,
                    is_spam: state,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $setUnblockAllUser = (): Observable<void> => {
        return this._http
            .put<void>({
                url: `${API_GATEWAYS_URL}livechat/conversations/unblock-all`,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };
}
