import { Deserializable } from "../deserializable.model";
import { PayloadObject } from "../object/payload-object.model";

export class PayloadList implements Deserializable {
    _id: string;
    selected: boolean;
    name: string;
    type: string;
    body: Object

    get channel() {
        switch(this.type) {
            case "line":
                return "LINE"
            default:
                return this.type.charAt(0).toUpperCase() + this.type.slice(1)
        }
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    convertObjectToList(input: PayloadObject) {
        Object.assign(this, {
            ...input,
            name: input.name.includes("CP_") ? input.name : `CP_${input.name}`,
        });
        this.selected = false;
        return this;
    }

    convertListToObject(): PayloadObject {
        return new PayloadObject().deserialize({
            _id: this._id,
            name: this.name,
            type: this.type,
            body: this.body
        });
    }
}
