import { Deserializable } from '../deserializable.model'
import { ButtonObject } from '../object/button-object.model'

export class ButtonList implements Deserializable {
    _id: string
    selected: boolean
    name: string
    title: string
    buttons: Array<Button>

    deserialize(input: any) {
        Object.assign(this, input)
        this.buttons = this.buttons.map(button => new Button().deserialize(button))
        return this
    }

    convertObjectToList(input: ButtonObject) {
        Object.assign(this, {
            ...input,
            name: input.name.includes('BTN_') ? input.name : `BTN_${input.name}`
        })
        this.selected = false
        return this
    }

    convertListToObject(): ButtonObject {
        return new ButtonObject().deserialize({
            _id: this._id,
            name: this.name,
            title: this.title,
            buttons: this.buttons
        })
    }
}

class Button implements Deserializable {
    label: string
    type: 'message' | 'url' | 'phone'
    message: string

    deserialize(input: any) {
        Object.assign(this, input)
        return this
    }
}
