import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { LocalService } from "@core/services/cache/local.service";
import { EntitiesObjectList } from "@core/models/entities/entities.model";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

interface EntObjectResponse {
    _id: string;
    object_name: string;
    type: string;
    confidence: number;
    list_object: Array<string>;
}

@Injectable()
export class ManageEntitiesService {
    get url() {
        return `${API_GATEWAYS_URL}platform-go/entity`;
    }

    constructor(private _http: HttpRequest, private local: LocalService) {}

    $getEntityObjectList = (): Observable<Array<EntitiesObjectList>> => {
        return this._http
            .get<Array<EntObjectResponse>>({
                url: this.url.toString(),
                cacheMins: 1,
            })
            .pipe(
                retry(genericRetry),
                map((response) =>
                    response.map((res) =>
                        new EntitiesObjectList().deserialize({
                            _id: res._id,
                            name: res.object_name,
                            confidence: res.confidence,
                            trained: res.list_object,
                            untrained: res.list_object,
                        })
                    )
                ),
                catchError((error) => throwError(() => error))
            );
    };

    $insertEntityObject = (body: EntitiesObjectList): Observable<{ _id: string }> => {
        const request = body.convertObjectToServer();
        delete request._id;
        return this._http
            .post<{ _id: string }>({
                url: this.url.toString(),
                body: request,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $updateEntityObject = (body: EntitiesObjectList): Observable<{ msg: string }> => {
        const request = body.convertObjectToServer();
        return this._http
            .put<{ msg: string }>({
                url: this.url.toString(),
                body: request,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteEntityObjectList = (body: Array<EntitiesObjectList>): Observable<{ msg: string }> => {
        return this._http
            .delete<{ msg: string }>({
                url: this.url.toString(),
                body: { _id: body.map((b) => b._id) },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    clearCacheData = (): void => {
        this.local.delete(this.url.toString());
    };
}
