import { Deserializable } from "../deserializable.model";
import { ApiObject } from "../object/api-object.model";

export class ApiList implements Deserializable {
    _id: string;
    selected: boolean;
    name: string;
    url: string;
    header: Array<string>;
    value: Array<string>;
    json: string;
    method: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    convertObjectToList(input: ApiObject) {
        Object.assign(this, {
            ...input,
            name: input.name.includes("API_") ? input.name : `API_${input.name}`,
            method: input.method,
            header: input.header.map((header) => header.name),
            value: input.header.map((header) => header.value),
        });

        if (!!input.json) {
            try {
                this.json = JSON.parse(input.json);
            } catch (_) {
                this.json = input.json;
            }
        }

        this.selected = false;
        return this;
    }

    convertListToObject(): ApiObject {
        const apiObject = new ApiObject().deserialize({
            _id: this._id,
            name: this.name,
            url: this.url,
            header: this.header.map((header, index) => {
                return { name: header, value: this.value[index] };
            }),
            method: this.method,
            json: this.json,
        });
        return apiObject;
    }
}
