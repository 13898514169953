import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConnectionPositionPair } from "@angular/cdk/overlay";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { BillingInformationService } from "@core/http/bot/billing-information.service";
import { SubscriptionService } from "@core/http/bot/subscription.service";
import { CalculatePrice, SubscriptionPackage } from "@core/models/bot/subscription-plan.model";
import { BotProfile } from "@core/models/bot/bot-profile.model";
import { BillingInformation } from "@core/models/bot/billing-information.model";
import { QrCodePaymentComponent, BillingInformationDialogComponent } from "@shared/components/dialog";
import { BotProfileService } from "@core/services/authentication";

interface TopUpDataModel {
    data: BotProfile;
}

enum Features {
    livechat = "livechat",
    ads = "advertising",
}

@Component({
    selector: "app-top-up-dialog",
    templateUrl: "./top-up-dialog.component.html",
    styleUrls: ["./top-up-dialog.component.scss"],
})
export class TopUpDialogComponent implements OnInit, OnDestroy {
    private _subscription: Subscription;

    private _subscriptionList: Array<SubscriptionPackage>;
    get packageList() {
        return this._subscriptionList?.filter((sub) => !sub.is_default_plan && !!sub.cost_per_day);
    }

    get packageID() {
        return this._data.data.current_plan.plan._id ?? "";
    }

    get packageName() {
        return this._data?.data?.current_plan?.plan?.product_name ?? "";
    }
    get costPerDay() {
        return this._data?.data?.current_plan?.plan?.cost_per_day ?? 0;
    }

    private _costPerDayByPackage: number;
    get costPerDayByPackage() {
        return this._costPerDayByPackage;
    }

    private _feature: Array<SubscriptionPackage>;
    get feature() {
        return this._feature;
    }
    featureName(feature: string) {
        return Features[feature];
    }
    addOnLabel(value: number) {
        return value > 0 ? $localize`:@@increased:increased` : $localize`:@@reduced:reduced`;
    }
    addOnPercentage(value: number) {
        return (value * 100 - 100) * (value >= 1 ? 1 : -1);
    }

    private _daysList: Array<number>;
    get daysList() {
        return this._daysList;
    }

    days: number;
    packageCost: number;
    get calculateCost() {
        return this.calculate?.calculate_price?.total_amount;
    }
    get addonCost() {
        if (!this.packageCost) return 0;
        return this.packageCost / 2;
    }
    get addonCostPerday() {
        if (!this.costPerDayByPackage) return 0;
        return this.costPerDayByPackage / 2;
    }

    requestTax: boolean;
    invoiceInformation: BillingInformation;

    get hasInvoiceInformation() {
        return !!this.invoiceInformation?._id;
    }

    get canChangeBillingData() {
        return this.calculateCost > 0;
    }
    get billingInformationTooltip() {
        return !this.canChangeBillingData ? $localize`:@@please-add-amount-days:Please add amount of days` : "";
    }

    get canSubmit() {
        return this.requestTax ? !!this.calculateCost && !!this.invoiceInformation : !!this.calculateCost;
    }

    get informationType() {
        return this.invoiceInformation?.type === "company" ? true : false;
    }

    get showInformationText() {
        return this.isShowInformation === true ? "Hide" : "Show";
    }

    isShowInformation: boolean;

    private _positions = [
        new ConnectionPositionPair(
            {
                originX: "start",
                originY: "bottom",
            },
            {
                overlayX: "start",
                overlayY: "top",
            },
            0,
            10
        ),
    ];
    get positions() {
        return this._positions;
    }

    get botID() {
        return this._botProfile.getID;
    }

    calculate: CalculatePrice;
    holdingTax: boolean;
    deliveryTax: boolean;

    get WithholdingTaxCost() {
        return this.calculate?.calculate_price?.Withholding_tax;
    }
    get deliveryTaxCost() {
        return this.calculate?.calculate_price?.derivery_tax_invoice;
    }

    constructor(
        private _matDialog: MatDialog,
        private _matDialogRef: MatDialogRef<TopUpDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: TopUpDataModel,
        private _subscriptionService: SubscriptionService,
        private _billingService: BillingInformationService,
        private _botProfile: BotProfileService
    ) {
        this._subscription = new Subscription();
        this._daysList = [7, 15, 30, 60, 90, 180, 360];
        this.days = 0;
        this.requestTax = false;
        this.holdingTax = false;
        this.invoiceInformation = new BillingInformation();
        this.isShowInformation = true;
    }

    private _getInvoiceInformation = (): void => {
        this._subscription.add(this._billingService.$getDetailsInfo().subscribe((response) => (this.invoiceInformation = response)));
    };

    ngOnInit(): void {
        this._getInvoiceInformation();

        this._subscription.add(
            this._subscriptionService
                .$getSubscriptionPackage()
                .pipe(map((response) => response.sort((a, b) => (a.cost_per_day > b.cost_per_day ? 1 : a.cost_per_day < b.cost_per_day ? -1 : 0))))
                .subscribe((response) => {
                    this._subscriptionList = response;
                    this._subscriptionList.filter((item) => {
                        if (item._id == this.packageID) {
                            this._costPerDayByPackage = item.cost_per_day;
                        }
                    });
                    this._feature = this._data?.data?.add_ons.map((ao) => this._subscriptionList.find((sub) => sub._id == ao?.addons_detail?._id));
                    this._feature[0]?.product_name;
                })
        );
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    onChangeDay = (value: number, holding_tax: boolean, delivery_tax: boolean): void => {
        this.days = value;
        this._subscriptionService.$getCalculatePrice(this._data.data._id, value, holding_tax, delivery_tax).subscribe((response) => {
            this.calculate = response;
            if (this.calculateCost > 1000 && this.informationType) {
                this.requestTax = true;
                this.calculate.calculate_price.get_invoice = true;
            }

            this.calculate.calculate_price.products.filter((item) => {
                if (item.product_id == this.packageID) {
                    this._costPerDayByPackage = item.cost_per_day;
                    this.packageCost = item.total;
                }
            });
        });
    };

    openInvoiceInformationDialog = (): void => {
        this._subscription.add(
            this._matDialog
                .open(BillingInformationDialogComponent, {
                    data: {
                        data: this.invoiceInformation,
                        amount: this.calculateCost,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result) {
                        this.invoiceInformation = result;
                        this._getInvoiceInformation();
                    }
                })
        );
    };

    onSubmit = (): void => {
        this.calculate.calculate_price.get_invoice = this.requestTax;
        this._subscription.add(
            this._matDialog
                .open(QrCodePaymentComponent, {
                    data: { calculate: this.calculate, data: this._data?.data },
                    disableClose: true,
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result) this._matDialogRef.close(true);
                    else this._matDialogRef.close();
                })
        );
    };
}
