import { Deserializable } from '../deserializable.model'
import { FlexObject } from '../object/flex-object.model'

export class FlexList implements Deserializable {
    _id: string
    selected: boolean
    name: string

    deserialize(input: any) {
        Object.assign(this, input)
        return this
    }

    convertObjectToList(input: FlexObject) {
        Object.assign(this, {
            ...input,
            name: input.name.includes('FX_') ? input.name : `FX_${input.name}`
        })
        this.selected = false
        return this
    }

    convertListToObject(): FlexObject {
        return new FlexObject().deserialize({
            _id: this._id,
            name: this.name
        })
    }
}
