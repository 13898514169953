import { Component, Directive, Renderer2, ElementRef, AfterContentInit, ContentChildren, QueryList } from "@angular/core";
import { ToolbarRowComponent } from "../toolbar-row/toolbar-row.component";

@Component({
    selector: "bn-toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
})
export class ToolbarComponent implements AfterContentInit {
    @ContentChildren(ToolbarRowComponent) rows: QueryList<ToolbarRowComponent>;

    constructor(private ele: ElementRef, private _renderer: Renderer2) {
        this._renderer.addClass(this.ele.nativeElement, "bn-toolbar");
    }

    ngAfterContentInit() {
        if (this.rows.length > 0) {
            this._renderer.addClass(this.ele.nativeElement, "bn-toolbar-multiple-rows");
        } else {
            this._renderer.addClass(this.ele.nativeElement, "bn-toolbar-single-row");
        }
    }
}

@Directive({
    selector: "div[bnSpacer]",
})
export class BnSpacerDirective {
    constructor(private ele: ElementRef, private _renderer: Renderer2) {
        this._renderer.setStyle(this.ele.nativeElement, `flex`, `1 1 auto`);
    }
}
