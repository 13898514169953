<div class="d-block text-center px-5">
    <h2>{{ title }}</h2>
    <mat-icon svgIcon="notifications" color="primary"></mat-icon>
    <p class="mt-1">{{ description }}</p>
</div>
<mat-dialog-actions align="center">
    <div class="d-flex flex-column flex-center w-100">
        <!--<mat-checkbox i18n="@@do-not-show-this-again" [(ngModel)]="notShowChecked" class="mb-2">Do not show this again</mat-checkbox>-->
        <!--<button mat-raised-button bn-small-button color="primary" (click)="onClose()">-->
        <button mat-raised-button bn-small-button color="primary" (click)="onSubmit()">
            <state-i18n *ngIf="isFromLiveChat" key="BTN_TOPUP"></state-i18n>
            <state-i18n *ngIf="!isFromLiveChat" key="BTN_CLOSE"></state-i18n>
        </button>
    </div>
</mat-dialog-actions>
