import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService, BotProfileService } from "@core/services/authentication";
import { SnackBarAlertService } from "@shared/services/snack-bar-alert/snack-bar-alert.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private _auth: AuthService, private _botProfile: BotProfileService, private _snack: SnackBarAlertService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let changeRequest = request.clone();
        if (this._auth?.isLoggedIn()) {
            if (!changeRequest.headers.get("Authorization"))
                changeRequest = changeRequest.clone({
                    headers: request.headers.append("Authorization", `Bearer ${this._auth.getAccessToken ?? ""}`),
                });
        }
        switch (request.method) {
            case "GET":
                if (this._botProfile?.isSelectedBOT()) {
                    if (!request?.params.has("bot_id")) {
                        changeRequest = changeRequest.clone({
                            params: request?.params.set("bot_id", this._botProfile.getID ?? ""),
                        });
                    }
                }
                break;
            case "POST":
            case "PUT":
            case "DELETE":
                if (this._botProfile?.isSelectedBOT()) {
                    switch (true) {
                        case request?.body instanceof FormData:
                            if (!(request?.body as FormData).has("bot_id")) {
                                (request?.body as FormData).append("bot_id", this._botProfile.getID ?? "");
                                changeRequest = changeRequest.clone({
                                    body: request?.body,
                                });
                            }
                            break;
                        case request?.body instanceof Array:
                            break;
                        default:
                            const body: any = request?.body ?? {};
                            if (!body?.bot_id) {
                                changeRequest = changeRequest.clone({
                                    body: {
                                        ...(body ?? {}),
                                        bot_id: this._botProfile.getID ?? "",
                                    },
                                });
                            }
                    }
                }
                break;
        }
        return next.handle(changeRequest).pipe(
            map((response) => {
                if (response?.type == 0) return;
                if (response instanceof HttpResponse) if (response.headers.has("x-redirect")) window.location.href = response.headers.get("x-redirect");
                return response;
            }),
            catchError((error: HttpErrorResponse) => {
                switch (error?.status) {
                    case 401:
                    case 403:
                        let message =
                            this._getErrorMessage(error.error) ??
                            $localize`:@@warning-token-expired-message:Your access token has expired or is no longer active, you have to reconnect to the site.`;
                        this._snack.open("warn", message);

                        this._auth.setLogout();
                        window.location.reload();

                        return throwError(() => ({
                            status: error.status,
                            message: message,
                        }));
                    case 0:
                        return throwError(() => ({
                            status: 404,
                            message: this._getErrorMessage(error.error) ?? error.message,
                        }));
                    default:
                        return throwError(() => ({
                            status: error.status,
                            message: this._getErrorMessage(error.error) ?? error.message,
                        }));
                }
            })
        );
    }

    private _getErrorMessage = (error: any): string => {
        try {
            switch (true) {
                case Array.isArray(error):
                    return (error as Array<string>).toString().split(",").join("\n");
                default:
                    if (error?.error) {
                        if (Array.isArray(error.error)) {
                            return (error.error as Array<string>).toString().split(",").join("\n");
                        }
                    }

                    return error?.error?.message ?? error?.message ?? error?.error ?? null;
            }
        } catch (_) {
            return null;
        }
    };
}
