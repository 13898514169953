import { Component, Input, Output, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ConnectionPositionPair } from "@angular/cdk/overlay";
import { AuthService } from "@core/services/authentication/auth.service";
import { UserProfileService } from "@core/services/authentication/user-profile.service";

@Component({
    selector: "navigation-bar",
    templateUrl: "./navigation-bar.component.html",
    styleUrls: ["./navigation-bar.component.scss"],
})
export class NavigationBarComponent implements OnInit {
    private _expand: boolean;
    isOpenMoreToggle: boolean;
    get expand() {
        return this._expand;
    }
    set expand(exp: boolean) {
        this._expand = exp;
    }

    @Input() hasMenu: boolean = true;
    @Input() transparent: boolean = false;
    @Output() $expand: BehaviorSubject<boolean> = new BehaviorSubject(true);
    @Output() $breakpoint: BehaviorSubject<boolean> = new BehaviorSubject(true);

    private _breakPoint: boolean = false;
    get breakPoint() {
        return this._breakPoint;
    }

    private _username: string;
    get username() {
        return this._username;
    }
    set username(usr: string) {
        this._username = usr;
    }

    isToggle: boolean;
    positions = [
        new ConnectionPositionPair(
            {
                originX: "end",
                originY: "bottom",
            },
            {
                overlayX: "end",
                overlayY: "top",
            },
            0,
            0
        ),
    ];

    overlayKeydown = ($event: KeyboardEvent): void => {
        if ($event.keyCode == 27) {
            this.isToggle = false;
        }
    };

    constructor(private router: Router, private breakpointObserver: BreakpointObserver, private _auth: AuthService, private _usrProfile: UserProfileService) {
        this.username = "";
        this.expand = false;
        this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.HandsetPortrait]).subscribe((result) => {
            this._breakPoint = result.matches;
            if (result.matches) {
                this.expand = false;
                this.$expand.next(this.expand);
                this.$breakpoint.next(!this.expand);
            } else {
                this.expand = false;
                this.$expand.next(this.expand);
                this.$breakpoint.next(!this.expand);
            }
        });
    }

    ngOnInit(): void {
        switch (true) {
            case !!this._usrProfile?.getUsername().length:
                this.username = this._usrProfile.getUsername();
            case !!this._usrProfile?.getName().length:
                this.username = this._usrProfile.getName();
        }
    }

    toggleExpand = (): void => {
        this.expand = !this.expand;
        if (!this._breakPoint) this.$expand.next(this.expand);
    };

    goManageBOT = (): void => {
        this.router.navigate(["/manage"]);
        this.isToggle = false;
    };

    goPaymentCenter = (): void => {
        this.router.navigate(["/manage/payment"]);
        this.isToggle = false;
    };

    onLogout = (): void => {
        this._auth.setLogout();
        this.isToggle = false;
        this.router.navigate(["/"]);
    };
}
