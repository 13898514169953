import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { Observable, throwError } from "rxjs";
import { map, retry, catchError } from "rxjs/operators";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { LocalService } from "@core/services/cache/local.service";
import { SmartLeadGenRequest, SmartLeadGenResponse } from "@core/models/smart-lead/lead-gen.model";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

@Injectable()
export class LeadGenService {
    get url() {
        return `${API_GATEWAYS_URL}platform-go/object/smart_lead`;
    }

    constructor(private _http: HttpRequest, private local: LocalService) {}

    get = (): Observable<Array<SmartLeadGenResponse>> => {
        return this._http
            .get<Array<SmartLeadGenResponse>>({
                url: this.url,
                cacheMins: 0.5,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    post = (body: SmartLeadGenRequest): Observable<string> => {
        return this._http
            .post<{ _id: string }>({
                url: this.url,
                body: body,
            })
            .pipe(
                retry(genericRetry),
                map((response) => response?._id),
                catchError((error) => throwError(() => error))
            );
    };

    put = (body: SmartLeadGenRequest): Observable<string> => {
        return this._http
            .put<{ msg: string }>({
                url: this.url,
                body: body,
            })
            .pipe(
                retry(genericRetry),
                map((response) => response?.msg),
                catchError((error) => throwError(() => error))
            );
    };

    delete = (_id: Array<string>): Observable<string> => {
        return this._http
            .delete<{ msg: string }>({
                url: this.url,
                body: {
                    _id: _id,
                },
            })
            .pipe(
                retry(genericRetry),
                map((response) => response?.msg),
                catchError((error) => throwError(() => error))
            );
    };

    clearCacheData = (): void => {
        this.local.delete(this.url.toString());
    };
}
