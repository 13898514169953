import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map, catchError, retryWhen } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { HttpRequest, genericRetryHttp } from "@core/http/http.service";
import { LocalService } from "@core/services/cache/local.service";
import { ApiObject } from "@core/models/object/api-object.model";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

interface APIObjectResponse {
    _id: string;
    object_name: string;
    type: string;
    list_object?: Array<{
        method: string;
        api_url: string;
        api_header: Object;
        api_body: string;
    }>;
}

@Injectable()
export class ManageApiObjectService {
    get url() {
        return `${API_GATEWAYS_URL}platform-go/object/api`;
    }

    constructor(private _http: HttpRequest, private local: LocalService) {}

    $getAPIObjectList = (): Observable<Array<ApiObject>> => {
        return this._http
            .get<Array<APIObjectResponse>>({
                url: this.url.toString(),
                cacheMins: 1,
            })
            .pipe(
                retryWhen(genericRetryHttp()),
                map((response) =>
                    response.map((res) =>
                        new ApiObject().deserialize({
                            _id: res._id,
                            name: res.object_name,
                            url: res.list_object[0].api_url,
                            header: !!res.list_object[0]?.api_header
                                ? Object.keys(res.list_object[0].api_header).map((header) => {
                                      return {
                                          name: header,
                                          value: res.list_object[0].api_header[header],
                                      };
                                  })
                                : [],
                            method: res.list_object[0].method,
                            json: res.list_object[0].api_body,
                        })
                    )
                ),
                catchError((error) => throwError(error))
            );
    };

    $insertAPIObject = (body: ApiObject): Observable<{ _id: string }> => {
        const request = body.convertObjectToServer();
        delete request._id;
        return this._http
            .post<{ _id: string }>({
                url: this.url.toString(),
                body: request,
            })
            .pipe(
                retryWhen(genericRetryHttp()),
                catchError((error) => throwError(error))
            );
    };

    $duplicateAPIObject = (_id: string): Observable<ApiObject> => {
        return this._http
            .post<{ data: APIObjectResponse }>({
                url: `${API_GATEWAYS_URL}platform-go/object/duplicate`,
                body: { _id: _id },
            })
            .pipe(
                retryWhen(genericRetryHttp()),
                map((response) => {
                    this.clearCacheData();
                    return new ApiObject().deserialize({
                        _id: response?.data?._id,
                        name: response?.data?.object_name,
                        url: response?.data?.list_object[0].api_url,
                        header: !!response?.data?.list_object[0]?.api_header
                            ? Object.keys(response?.data?.list_object[0].api_header).map((header) => {
                                  return {
                                      name: header,
                                      value: response?.data?.list_object[0].api_header[header],
                                  };
                              })
                            : [],
                        method: response?.data?.list_object[0].method,
                        json: response?.data?.list_object[0].api_body,
                    });
                }),
                catchError((error) => throwError(error))
            );
    };

    $updateAPIObject = (body: ApiObject): Observable<{ msg: string }> => {
        const request = body.convertObjectToServer();
        return this._http
            .put<{ msg: string }>({
                url: this.url.toString(),
                body: request,
            })
            .pipe(
                retryWhen(genericRetryHttp()),
                catchError((error) => throwError(error))
            );
    };

    $deleteAPIObjectList = (body: Array<ApiObject>): Observable<{ msg: string }> => {
        return this._http
            .delete<{ msg: string }>({
                url: this.url.toString(),
                body: { _id: body.map((b) => b._id) },
            })
            .pipe(
                retryWhen(genericRetryHttp()),
                catchError((error) => throwError(error))
            );
    };

    clearCacheData = (): void => {
        this.local.delete(this.url.toString());
    };
}
