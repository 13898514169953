import { Deserializable } from '../deserializable.model'
import { QrpObject } from '../object/qrp-object.model'

export class QrpList implements Deserializable {
    _id: string
    selected: boolean
    name: string
    message: string
    buttons: Array<Button>

    deserialize(input: any) {
        Object.assign(this, input)
        this.buttons = this.buttons.map(button => new Button().deserialize(button))
        return this
    }

    convertObjectToList(input: QrpObject) {
        Object.assign(this, {
            ...input,
            name: input.name.includes('QRP_') ? input.name : `QRP_${input.name}`
        })
        this.selected = false
        return this
    }

    convertListToObject(): QrpObject {
        return new QrpObject().deserialize({
            _id: this._id,
            name: this.name,
            message: this.message,
            buttons: this.buttons
        })
    }
}

class Button implements Deserializable {
    label: string
    type: 'message' | 'url' | 'phone'
    message: string

    deserialize(input: any) {
        Object.assign(this, input)
        return this
    }
}
