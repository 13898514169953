import { Deserializable } from "../deserializable.model";

export class EntitiesObjectList implements Deserializable {
    _id: string;
    name: string;
    confidence: number;
    trained: Array<string>;

    get trainedNo() {
        return this.trained.length;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        this.name = this.name.includes("ENT_") ? this.name : `ENT_${this.name}`;
        return this;
    }

    convertObjectToServer() {
        return {
            _id: this._id,
            type: "entity",
            object_name: this.name.includes("ENT_") ? this.name : `ENT_${this.name}`,
            confidence: this.confidence,
            list_object: this.trained,
        };
    }
}

export class EntitiesList implements Deserializable {
    private _data: Array<Entities> = [];
    get data() {
        return this._data;
    }

    filter: string = "";

    get filteredData() {
        return this._data.filter((d) => d.value.includes(this.filter));
    }

    getDataByRef(_id: string): string {
        return this._data.find((d) => d._id == _id)?.value ?? "";
    }

    updateDataByRef(_id: string, value: string): void {
        this._data = this._data.map((d) => {
            if (d._id == _id) d.value = value;
            return d;
        });
    }

    deserialize(input: Array<string>) {
        this._data = input.map((i) => new Entities().deserialize(i));
        return this;
    }

    dropoutValue(index: number) {
        this._data = this._data.filter((_, i) => i !== index);
    }

    appendValue(index: number, value: string) {
        this._data.splice(index, 0, new Entities().deserialize(value));
    }
}

export class Entities implements Deserializable {
    _id: string;
    selected: boolean;
    value: string;

    private get _uuname() {
        return `${Math.floor(1000 + Math.random() * 9000)}`;
    }

    deserialize(input: any) {
        this._id = this._uuname;
        this.selected = false;
        this.value = input;
        return this;
    }
}
