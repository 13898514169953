<div mat-dialog-title>
    <h3 class="text-center">
        <state-i18n key="LABEL_BILLING_INFORM"></state-i18n>
    </h3>
    <button mat-mini-fab bn-small-button mat-dialog-close bn-dialog-close color="primary">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<mat-dialog-content class="container" [formGroup]="object">
    <div class="d-flex align-items-center mb-1">
        <label>
            <state-i18n key="LABEL_TYPE"></state-i18n>
            {{ type.value }}
        </label>

        <div bnSpacer></div>

        <bn-form-field class="w-100">
            <bn-select formControlName="type" [placeholder]="typePlaceholder">
                <bn-option value="personal"> {{ personalLable }} </bn-option>
                <bn-option value="company"> {{ companyLabel }} </bn-option>
            </bn-select>
            <bn-error>
                <state-i18n key="ERR_TYPE_REQUIRED"></state-i18n>
            </bn-error>
        </bn-form-field>
    </div>

    <!-- Company -->
    <div *ngIf="isCompany" class="d-flex align-items-center mb-1">
        <label>
            <state-i18n key="LABEL_COMPANY"></state-i18n>
        </label>

        <div bnSpacer></div>

        <bn-form-field class="w-100">
            <input bnInput formControlName="company" [placeholder]="companyPlaceholder" type="text" />
            <bn-error>
                <state-i18n key="ERR_COMPANY_REQUIRED"></state-i18n>
            </bn-error>
        </bn-form-field>
    </div>

    <!-- First name -->
    <div class="d-flex align-items-center mb-1">
        <label>
            <state-i18n key="LABEL_FIRST_NAME"></state-i18n>
        </label>

        <div bnSpacer></div>

        <bn-form-field class="w-100">
            <input bnInput formControlName="first_name" [placeholder]="firstNamePlaceholder" type="text" />
            <bn-error>
                <state-i18n key="ERR_FIRST_NAME_REQUIRED"></state-i18n>
            </bn-error>
        </bn-form-field>
    </div>

    <!-- Last name -->
    <div class="d-flex align-items-center mb-1">
        <label>
            <state-i18n key="LABEL_LAST_NAME"></state-i18n>
        </label>

        <div bnSpacer></div>

        <bn-form-field class="w-100">
            <input bnInput formControlName="last_name" [placeholder]="lastNamePlaceholder" type="text" />
            <bn-error>
                <state-i18n key="ERR_LAST_NAME_REQUIRED"></state-i18n>
            </bn-error>
        </bn-form-field>
    </div>

    <!-- Address -->
    <div class="d-flex align-items-center mb-1">
        <label>
            <state-i18n key="LABEL_BILL_ADDRESS"></state-i18n>
        </label>

        <div bnSpacer></div>

        <bn-form-field class="w-100">
            <input bnInput formControlName="bill_address" [placeholder]="billAddressPlaceholder" type="text" />
            <bn-error>
                <state-i18n key="ERR_BILL_ADDRESS_REQUIRED"></state-i18n>
            </bn-error>
        </bn-form-field>
    </div>

    <div class="d-flex align-items-center mb-1">
        <div bnSpacer></div>

        <bn-form-field class="mr-1" style="width: 37.8%">
            <input
                type="text"
                [placeholder]="provincePlaceholder"
                bnInput
                formControlName="province"
                [matAutocomplete]="auto"
                (keyup.enter)="onSelectProvince()"
            />
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let province of filteredProvince | async;" [value]="province" (click)="onSelectProvince()"> {{ province }} </mat-option>
            </mat-autocomplete>
            <bn-error>
                <state-i18n key="ERR_PROVINCE_REQUIRED"></state-i18n>
            </bn-error>
        </bn-form-field>

        <bn-form-field style="width: 37.8%">
            <input
                type="text"
                [placeholder]="districtPlaceholder"
                bnInput
                formControlName="district"
                [matAutocomplete]="auto2"
                (keyup.enter)="onSelectDistrict()"
            />
            <mat-autocomplete #auto2="matAutocomplete">
                <mat-option *ngFor="let district of filteredDistrict | async " [value]="district" (click)="onSelectDistrict()"> {{ district }} </mat-option>
            </mat-autocomplete>
            <bn-error>
                <state-i18n key="ERR_DIST_REQUIRED"></state-i18n>
            </bn-error>
        </bn-form-field>
    </div>

    <div class="d-flex align-items-center mb-1">
        <div bnSpacer></div>

        <bn-form-field class="mr-1" style="width: 37.8%">
            <input
                type="text"
                [placeholder]="subDistrictPlaceholder"
                bnInput
                formControlName="sub_district"
                [matAutocomplete]="auto3"
                (keyup.enter)="onSelectSubDistrict()"
            />
            <mat-autocomplete #auto3="matAutocomplete">
                <mat-option *ngFor="let subDistrict of filteredSubDistrict | async;" [value]="subDistrict" (click)="onSelectSubDistrict()">
                    {{ subDistrict }}
                </mat-option>
            </mat-autocomplete>
            <bn-error>
                <state-i18n key="ERR_SUB_DIST_REQUIRED"></state-i18n>
            </bn-error>
        </bn-form-field>

        <bn-form-field style="width: 37.8%">
            <input type="text" [placeholder]="postalPlaceholder" bnInput formControlName="postal" [matAutocomplete]="auto4" />
            <mat-autocomplete #auto4="matAutocomplete">
                <mat-option *ngFor="let post of filteredPostal | async " [value]="post"> {{ post }} </mat-option>
            </mat-autocomplete>
            <bn-error>
                <state-i18n key="ERR_POSTAL_REQUIRED"></state-i18n>
            </bn-error>
        </bn-form-field>
    </div>

    <!--Phone -->
    <div class="d-flex align-items-center mb-1">
        <label>
            <state-i18n key="LABEL_PHONE"></state-i18n>
        </label>

        <div bnSpacer></div>

        <bn-form-field class="w-100">
            <input bnInput formControlName="phone" [placeholder]="phonePlaceholder" type="text" maxlength="10" />
            <bn-error>
                <state-i18n key="ERR_PHONE_REQUIRED"></state-i18n>
            </bn-error>
        </bn-form-field>
    </div>

    <div class="d-flex align-items-center mb-1">
        <label>
            <state-i18n key="LABEL_EMAIL"></state-i18n>
        </label>

        <div bnSpacer></div>

        <bn-form-field class="w-100">
            <input bnInput formControlName="email" [placeholder]="emailPlaceholder" type="text" />
            <bn-error>
                <state-i18n key="ERR_INV_EMAIL"></state-i18n>
                <ng-container i18n>. For example, “Botnoi@Email.com”.</ng-container>
            </bn-error>
        </bn-form-field>
    </div>

    <!-- VAT / GST -->
    <div class="d-flex align-items-center" [ngClass]="{'mb-1': !isCompany}">
        <label>
            <state-i18n key="LABEL_VAT"></state-i18n>
        </label>

        <div bnSpacer></div>

        <bn-form-field class="w-100">
            <input bnInput formControlName="tax_number" [placeholder]="vatPlaceholder" type="text" />
            <bn-error>
                <state-i18n key="ERR_VAT_REQUIRED"></state-i18n>
            </bn-error>
        </bn-form-field>
    </div>

    <div *ngIf="isCompany" class="d-flex">
        <label i18n>3% Withholding Tax</label>

        <div bnSpacer></div>
        <mat-checkbox i18n formControlName="holding_tax">Yes</mat-checkbox>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" mat-dialog-close class="mr-1">
        <state-i18n key="BTN_CANCEL"></state-i18n>
    </button>
    <button mat-raised-button color="primary" (click)="onSubmit()">
        <state-i18n key="BTN_SAVE"></state-i18n>
    </button>
</mat-dialog-actions>
