import { Deserializable } from "../deserializable.model";

const formatter = new Intl.NumberFormat();

export class SubscriptionPackage implements Deserializable {
    _id: string;
    product_name: string;
    cost_per_day: number;
    limit_subscribe_number: number;
    feature: string;
    is_default_plan: boolean;
    cost_per_days_fractor: number;

    private _convertDays: string;
    get convertDays() {
        return this._convertDays;
    }

    get limitSubscribeLabel() {
        return `${formatter.format(this.limit_subscribe_number)} Users`;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        if (input?.plan_name) this.product_name = input?.plan_name;
        return this;
    }

    setConvertDays = (date: string): void => {
        this._convertDays = date;
    };
}

export class CalculatePrice {
    calculate_price: CalculateModel;
}

export interface CalculateModel {
    VAT: number;
    Withholding_tax: number;
    day_amount: number;
    derivery_tax_invoice: number;
    excludind_vat: number;
    get_derivery: boolean;
    get_holdding_tax: boolean;
    get_invoice: boolean;
    including_vat: number;
    products: Array<ProductPackage>;
    total_amount: number;
}
interface ProductPackage {
    product_id: string;
    name: string;
    cost_per_day: number;
    cost_per_day_factor?: string;
    total: number;
}

export class SubscriptionAddOns implements Deserializable {
    _id: string;
    feature: string;
    name: string;

    deserialize(input: any) {
        Object.assign(this, input);
        this.name = input?.name.replace("-", " ") ?? "";
        return this;
    }
}

export interface HistoryTransaction {
    amount: number;
    bot_name: string;
    invoice: string;
    paid_at: string;
    product_name: string;
    subscription_id: string;
    get_invoice: boolean;
}

export interface ProductDetailModel {
    product_id: string;
    price_amount: number;
    day_amount: number;
}

export interface ConfirmationQRModel {
    subscription_id: string;
    qr_code: string;
    status: string;
}

export interface CheckStatusModel {
    invoice: string;
    status: string;
    subscription_id: string;
    total_amount: number;
}

export interface CacheSubscriptionListModel extends Array<CacheSubscriptionModel> {}

export interface CacheSubscriptionModel {
    bot_id: string;
    subscription_id: string;
    create_date: number;
}
