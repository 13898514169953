import { Deserializable } from "../deserializable.model";

export interface DialogPerformance {
    start: number;
    end: number;
    dropout: number;
    stats_by_time_series: Array<StatesSeries>;
    sankey_diagram: Array<DialogPerformanceData>;
}

interface StatesSeries {
    date_range: string;
    start: number;
    end: number;
    dropout: number;
}

interface DialogPerformanceData {
    node_ID: string;
    node_name: string;
    send: number;
    take_action: number;
    timeout: number;
    to: Array<{
        node_ID: string;
        node_name: string;
        send: number;
        error: number;
    }>;
}

export class SankeyDiagram implements Deserializable {
    private data: DialogPerformance;
    get rawData() {
        return this.data;
    }

    get hasData() {
        return !!this.data?.sankey_diagram.length || !!this.data?.stats_by_time_series.length;
    }

    get concatData() {
        let in_flow = [];
        const sankeyLength = this.data?.sankey_diagram?.length;
        return this.data?.sankey_diagram.reduce((prev, cur, index) => {
            prev.push(cur);
            in_flow = in_flow.concat(cur.to.map((t) => t));
            if (index + 1 == sankeyLength) prev = prev.concat(in_flow);
            return prev;
        }, []);
    }
    get filteredDuplicate() {
        return this.concatData.filter((d, index, self) => index == self.findIndex((data) => d.node_ID == data.node_ID));
    }

    get nodelabels() {
        return this.filteredDuplicate.map((d) => d.node_name.slice(0, 150));
    }
    get nodeColors() {
        return this.filteredDuplicate.map((d) => (d.node_ID.includes("error_") ? "#F87979" : "#75DBCA"));
    }
    get nodeDatas() {
        return this.filteredDuplicate.map((d) => {
            return {
                name: d?.node_name ?? "",
                send: d?.send ?? 0,
                take_action: d?.take_action ?? 0,
                timeout: d?.timeout ?? 0,
                error: d?.error ?? 0,
            };
        });
    }

    get linkSources() {
        return this.data?.sankey_diagram.reduce((prev, cur) => {
            prev = prev.concat(cur.to.map((_) => this.filteredDuplicate.findIndex((d) => d.node_ID == cur.node_ID)));
            return prev;
        }, []);
    }
    get linkTargets() {
        return this.data?.sankey_diagram.reduce((prev, cur) => {
            prev = prev.concat(cur.to.map((c) => this.filteredDuplicate.findIndex((d) => d.node_ID == c.node_ID)));
            return prev;
        }, []);
    }
    get linkValues() {
        return this.data?.sankey_diagram.reduce((prev, cur) => {
            prev = prev.concat(cur.to.map((c) => (c.node_ID.includes("error_") ? c.error : c.send)));
            return prev;
        }, []);
    }
    get linkColors() {
        return this.data?.sankey_diagram.reduce((prev, cur) => {
            prev = prev.concat(cur.to.map((c) => (c.node_ID.includes("error_") ? "rgba(248, 121, 121, 0.5)" : "rgba(117, 219, 202, 0.3)")));
            return prev;
        }, []);
    }

    sankeyDiagramData: any;
    get sankey() {
        return [
            {
                type: "sankey",
                domain: {
                    x: [0, 1],
                    y: [0, 1],
                },
                orientation: "h",
                valueformat: "",
                node: {
                    pad: 15,
                    thickness: 15,
                    line: { width: 0 },
                    lable: [],
                    color: this.nodeColors,
                    customdata: this.nodeDatas,
                    hovertemplate:
                        "<b>%{customdata.name}</b> <br>Send: %{customdata.send}<br>Take action: %{customdata.take_action}<br>Ignor/Timeout: %{customdata.timeout}<br>Error/Dropout: %{customdata.error}<extra></extra>",
                    hoverlabel: {
                        align: "left",
                        bgcolor: "rgb(0, 0, 0)",
                    },
                },
                link: {
                    source: this.linkSources,
                    target: this.linkTargets,
                    value: this.linkValues,
                    color: this.linkColors,
                    hoverinfo: "none",
                },
            },
        ];
    }

    deserialize(input: DialogPerformance) {
        if (!input?.sankey_diagram) input.sankey_diagram = [];
        if (!input?.stats_by_time_series) input.stats_by_time_series = [];
        this.data = input;
        this.sankeyDiagramData = this.sankey;
        this.sankeyDiagramData[0].node.label = this.nodelabels;
        return this;
    }
}
