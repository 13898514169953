<div class="export-container d-flex flex-column justify-content-center">
    <div class="d-flex justify-content-center mb-2">
        <div class="mat-status">
            <h2 class="mb-0" i18n="@@export-file-title"><b>Export File</b></h2>
        </div>
    </div>

    <div class="d-flex justify-content-center mb-2">
        <mat-icon svgIcon="clock"></mat-icon>
    </div>

    <div class="mb-2 mat-status">
        <p i18n="@@export-file-progress" class="mb-0 text-d-grey text-center">Export flie {{ $progress | async }}%</p>
        <mat-progress-bar mode="determinate" [value]="$progress | async" class="my-1"></mat-progress-bar>
    </div>

    <div class="d-flex justify-content-center">
        <button mat-stroked-button bn-large-button color="primary" (click)="onClose()">
            <state-i18n key="BTN_CANCEL"></state-i18n>
        </button>
    </div>
</div>
