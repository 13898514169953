import { Deserializable } from "../deserializable.model";

export class DlObject implements Deserializable {
    _id: string;
    name: string;
    body: DialogBody;

    get hasBody() {
        return this.body ? true : false;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        if (input.body) this.body = new DialogBody().deserialize(input.body);
        return this;
    }

    onUpdateManageObject(data: DlObject): void {
        Object.assign(this, data);
        if (data.body) this.body = new DialogBody().deserialize(data.body);
    }

    convertObjectToServer() {
        return {
            _id: this._id,
            type: "dialogue",
            object_name: this.name.includes("DL_") ? this.name : `DL_${this.name}`,
            list_object: [
                {
                    ...this.body,
                    edgeList: this.body.edgeList.map((edge) => edge.convertObjectToServer()),
                    position: this.body.position.map((p) => {
                        return {
                            _id: p._id,
                            tranform: p.transform,
                            left: p.left,
                            top: p.top,
                        };
                    }),
                },
            ],
        };
    }
}

//Dialog Model
class DialogBody implements Deserializable {
    start_node: string;
    nodeList: Array<NodeModel>;
    edgeList: Array<EdgeModel>;
    position: Array<PositionModel>;

    deserialize(input: any) {
        Object.assign(this, input);
        if (input.nodeList) this.nodeList = input.nodeList.map((n: any) => new NodeModel().deserialize(n));
        if (input.edgeList) this.edgeList = input.edgeList.map((e: any) => new EdgeModel().deserialize(e));
        if (input.position) this.position = input.position.map((p: any) => new PositionModel().deserialize(p));
        return this;
    }
}

class NodeModel implements Deserializable {
    _nid: string;
    name: string;
    questionList: Array<string>;
    error: string;
    timeout_actions: Array<TimeoutActionModel>;

    deserialize(input: any) {
        Object.assign(this, input);
        if (input.timeout_actions) this.timeout_actions = input.timeout_actions.map((action: any) => new TimeoutActionModel().deserialize(action));
        return this;
    }
}

class EdgeModel implements Deserializable {
    _eid: string;
    lineName: string;
    fromNode: string;
    toNode: string;
    conditions: Array<{ type: string; data: string }>;
    param: string;

    deserialize(input: any) {
        Object.assign(this, input);
        if (!!input?.conditions)
            this.conditions = input?.conditions.map((con: any) => {
                return {
                    ...con,
                    type: con?.type == "Keyword" ? "Entity" : con?.type,
                };
            });
        return this;
    }

    convertObjectToServer() {
        return {
            ...this,
            conditions: this.conditions.map((con) => {
                return {
                    ...con,
                    type: con?.type == "Entity" ? (con.data.includes("ENT_") ? "Entity" : "Keyword") : con?.type,
                };
            }),
        };
    }
}

class PositionModel implements Deserializable {
    _id: string;
    transform: string;
    left: string;
    top: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}

class TimeoutActionModel implements Deserializable {
    type: "exit" | "stay_this_node" | "move_to_node";
    unit: "minute" | "hour" | "day";
    duration: number;
    message: string;
    next_node_id: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
/////////////////

// Dialog Interface
export interface NodeInterface {
    _id: string;
    name: string;
    questionList: Array<MessageInterface>;
    error: MessageInterface;
    timeoutActions: Array<TimeoutActionInterface>;
    position?: PositionInterface;
}

export interface ConditionInterface {
    _id: string;
    name: string;
    fromNode: Array<string>;
    toNode: string;
    conditions: Array<ConditionDataInterface>;
    position?: PositionInterface;
}

export interface MessageInterface {
    message: string;
    objects: Array<any>;
}

interface PositionInterface {
    _id?: string;
    transform?: string;
    left?: string;
    top?: string;
}

export interface ConditionDataInterface {
    type: string;
    data: string;
}

export interface TimeoutActionInterface {
    type: "exit" | "stay_this_node" | "move_to_node";
    unit: "minute" | "hour" | "day";
    duration: number;
    message: string;
    next_node_id: string;
}
///////////////////

//Message Template Render Model
export class MessageTemplate implements Deserializable {
    name: string;
    type: "message" | "template" | "image" | "flex";
    message: string;
    url: string;
    template: Array<TemplateType>;

    deserialize(input: any) {
        Object.assign(this, input);
        if (input.template) this.template = input.template.map((temp: any) => new TemplateType().deserialize(temp));
        return this;
    }
}

class TemplateType implements Deserializable {
    url: string;
    title: string;
    subtitle: string;
    buttons: Array<string>;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
///////////
