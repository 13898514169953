import { Component, OnDestroy } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ConnectionPositionPair } from "@angular/cdk/overlay";
import { Subscription } from "rxjs";
import { ManageBotRequestService } from "@core/http/bot/manage-bot-request.service";
import { BotProfile } from "@core/models/bot";
import { SnackBarAlertService } from "@shared/services/snack-bar-alert/snack-bar-alert.service";

@Component({
    selector: "app-add-key-chatbot-dialog",
    templateUrl: "./add-key-chatbot-dialog.component.html",
    styleUrls: ["./add-key-chatbot-dialog.component.scss"],
})
export class AddKeyChatbotDialogComponent implements OnDestroy {
    private _subscription: Subscription;

    key: string;

    private _fetching: boolean;
    get fetching() {
        return this._fetching;
    }

    private _onHover: boolean = false;
    get onHover() {
        return this._onHover;
    }

    private _positions = [
        new ConnectionPositionPair(
            {
                originX: "end",
                originY: "center",
            },
            {
                overlayX: "start",
                overlayY: "center",
            },
            40,
            0
        ),
    ];
    get positions() {
        return this._positions;
    }

    constructor(
        private _matDialogRef: MatDialogRef<AddKeyChatbotDialogComponent>,
        private _manageBotService: ManageBotRequestService,
        private _snackbar: SnackBarAlertService
    ) {
        this._subscription = new Subscription();
        this.key = "";
        this._fetching = false;
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    mouseOnTooltip = (hover: boolean): void => {
        this._onHover = hover;
    };

    confirmClone = (): void => {
        if (this.fetching) return;
        if (!this.key) return;

        this._fetching = true;

        this._subscription.add(
            this._manageBotService
                .$postCreateBot(
                    new BotProfile().deserialize({
                        age: 0,
                        bot_name: "",
                        sex: "",
                        bot_avatar: "",
                        personality: [],
                        business_type: "",
                    })
                )
                .subscribe({
                    next: (response) => {
                        this._validatePublicKey(response?._id);
                    },
                    error: (error) => {
                        console.error(error);
                        this._snackbar.open(
                            "error",
                            $localize`:@@error-create-chatbot:We couldn’t clone your chatbot. Please try again or contact our support team.`
                        );
                    },
                })
        );
    };

    private _validatePublicKey = (_id: string): void => {
        this._subscription.add(
            this._manageBotService.$validatePublicKey(_id, this.key).subscribe({
                next: (_) => {
                    this._fetching = false;
                    this._snackbar.open("success", $localize`:@@success-clone-chatbot:The chatbot has been succesfully cloned!`);
                    this._matDialogRef.close(true);
                },
                error: (error) => {
                    console.error(error);
                    this._fetching = false;
                    this._snackbar.open(
                        "error",
                        $localize`:@@error-clone-chatbot:We couldn’t clone your chatbot. Please try again or contact our support team.`
                    );
                    this._matDialogRef.close();
                },
            })
        );
    };

    onClose = (): void => {
        this._matDialogRef.close();
    };
}
