import { Component, OnInit, OnDestroy } from "@angular/core";
import { trigger, state, style, transition, animate, keyframes, AUTO_STYLE, AnimationEvent } from "@angular/animations";
import { MatDialog } from "@angular/material/dialog";
import { SelectionModel } from "@angular/cdk/collections";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { catchError, Subscription, switchMap, throwError } from "rxjs";
import { BotProfileService } from "@core/services/authentication";
import { AutomateTestService, AutomateTestResult } from "@core/http/automate-test/automate-test.service";
import { SelectObjectComponent } from "@shared/components/dialog";
import { SnackBarAlertService } from "@shared/services/snack-bar-alert/snack-bar-alert.service";
import { I18nState, getI18nState } from "@shared/translation/i18n-state";

@Component({
    selector: "auto-test",
    templateUrl: "./auto-test-button.component.html",
    styleUrls: ["./auto-test-button.component.scss"],
    animations: [
        trigger("expandCollapse", [
            state(
                "collapsed",
                style({
                    right: "-260px",
                })
            ),
            state(
                "expanded",
                style({
                    right: "0",
                })
            ),
            transition("collapsed <=> expanded", animate(".3s ease")),
        ]),
        trigger("reload", [
            transition("* <=> loop-state", [animate("1s ease-in-out", keyframes([style({ transform: "rotate(0)" }), style({ transform: "rotate(360deg)" })]))]),
        ]),
        trigger("expandCollapsePanel", [
            state(
                "collapsed",
                style({
                    height: "0",
                    overflow: "hidden",
                    opacity: "0",
                    paddingTop: "0",
                    paddingBottom: "0",
                    marginTop: "0",
                })
            ),
            state(
                "expanded",
                style({
                    height: AUTO_STYLE,
                    overflow: "unset",
                    opacity: "1",
                    marginTop: "6px",
                })
            ),
            transition("collapsed <=> expanded", animate(".3s ease")),
        ]),
    ],
})
export class AutoTestButtonComponent implements OnInit, OnDestroy {
    private _subscription: Subscription;
    private _processingSubscription: Subscription;

    autoTestCollapse: boolean;
    openedAutotest: boolean;

    get isAllCheckedIntent(): boolean {
        return this.selectedIntent.length == this.selectionIntent.selected.length && !!this.selectedIntent.length;
    }
    get isIndeterminateIntent(): boolean {
        return !this.isAllCheckedIntent && !this.selectionIntent.isEmpty();
    }
    get hasSelectionIntent(): boolean {
        return !this.selectionIntent.isEmpty();
    }

    get hasSelectedIntent() {
        return !!this.selectedIntent.length;
    }
    get selectedIntent() {
        return this._selectedIntent ?? [];
    }
    private _selectedIntent: Array<string>;
    selectionIntent: SelectionModel<string>;

    get titleTestBot() {
        return this.openedAutotest ? $localize`:@@automate-test:Automate Test` : $localize`:@@manual-test:Manual Test`;
    }

    private _avatar: string;
    get avatar() {
        return this._avatar === "" ? "assets/botnoi-logo/logo.svg" : this._avatar;
    }

    reloadState: string;
    private _processing: boolean;
    get processing() {
        return this._processing;
    }

    private _percentage: number;
    get percentage() {
        return this._percentage;
    }

    toggleResults: SelectionModel<number>;
    get hasResults() {
        return !!this.results?.length;
    }
    get successResult() {
        return this.results?.filter((r) => r.is_correct)?.length ?? 0;
    }
    get failResult() {
        return this.results?.filter((r) => !r.is_correct)?.length ?? 0;
    }
    private _results: Array<AutomateTestResult>;
    get results() {
        return this._results;
    }

    private _currentStatusID: string;

    constructor(
        private _botProfile: BotProfileService,
        private _matDialog: MatDialog,
        private _automateTestService: AutomateTestService,
        private _snackbar: SnackBarAlertService
    ) {
        this._subscription = new Subscription();
        this.reloadState = "loop-state";
        this.autoTestCollapse = true;
        this.openedAutotest = true;
        this._selectedIntent = [];
        this.selectionIntent = new SelectionModel(true, []);
        this.toggleResults = new SelectionModel(false, []);
    }

    ngOnInit(): void {
        this._subscription.add(
            this._botProfile.$profile.subscribe({
                next: (response) => {
                    this._avatar = response?.bot_avatar;
                },
            })
        );
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    toggleAutoTestButton = (): void => {
        this.autoTestCollapse = !this.autoTestCollapse;
    };

    toggleAutoTest = ($event: boolean): void => {
        this.openedAutotest = $event;
    };

    allCheckedChange = ($event: MatCheckboxChange): void => {
        switch ($event.checked) {
            case true:
                this.selectedIntent.forEach((item) => {
                    if (!this.selectionIntent.isSelected(item)) this.selectionIntent.select(item);
                });
                break;
            default:
                this.selectionIntent.clear();
        }
    };
    isCheckedIntent = (value: string): boolean => {
        return this.selectionIntent.isSelected(value);
    };
    toggleCheckedIntent = (value: string): void => {
        this.selectionIntent.toggle(value);
    };

    isExpandedResult = (index: number): boolean => {
        return this.toggleResults.isSelected(index);
    };
    toggleSelectResult = (index: number): void => {
        if (this.isExpandedResult(index)) this.toggleResults.clear();
        else this.toggleResults.select(index);
    };

    openSelectObjectDialog = (): void => {
        const dialogRef = this._matDialog.open(SelectObjectComponent, {
            panelClass: "no-padding-container",
            data: { type: "intent", multipleSelect: true, intent: this.selectedIntent },
        });

        dialogRef.afterClosed().subscribe((result: { data: Array<string> }) => {
            if (result) this._selectedIntent = result.data;
        });
    };

    onDoneReloadState = ($event: AnimationEvent): void => {
        switch ($event["fromState"]) {
            case "void":
                this.reloadState = "void";
                break;
            default:
                this.reloadState = "loop-state";
        }
    };

    onRunTesting = (): void => {
        this._processing = true;
        this._percentage = 0;

        this._processingSubscription = this._automateTestService
            .$postAutomateTest(this.selectionIntent.selected)
            .pipe(
                switchMap((result) => {
                    this.selectionIntent.clear();
                    this._currentStatusID = result?.status_id;
                    return this._automateTestService.$getAutomateTestStatus(result?.status_id);
                }),
                catchError((error) => throwError(() => error))
            )
            .subscribe({
                next: (response) => {
                    switch (response?.process) {
                        case 100:
                            this._processing = false;
                            this._percentage = response?.process;
                            this._results = response?.result;
                            break;
                        default:
                            this._percentage = response?.process;
                            this._processing = true;
                    }
                },
                error: (error) => {
                    console.error(error);
                    this._processing = false;
                    this._results = [];
                    this._snackbar.open("error", getI18nState(I18nState.GLOBAL_ERR_MSG));
                },
            });
        this._subscription.add(this._processingSubscription);
    };

    onCancelProcessing = (): void => {
        if (!!this._processingSubscription) this._processingSubscription.unsubscribe();
        if (!!this._currentStatusID)
            this._subscription.add(
                this._automateTestService.$putCancelAutomateTest(this._currentStatusID).subscribe({
                    error: (error) => console.error(error),
                })
            );

        this._processing = false;
    };

    onOkayResults = (): void => {
        this._results = [];
    };
}
