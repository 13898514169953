<div class="container d-flex flex-column justify-content-center">
    <div class="d-block text-center mb-2">
        <h2 i18n="@@import-file-status-title" class="mb-0 fw-7">Import File Status</h2>
    </div>

    <div class="d-flex align-items-center mat-status mb-1">
        <mat-icon svgIcon="ok-circle-filled" color="primary"></mat-icon>
        <span i18n="@@successful-numer-record" class="ls-1 ml-1 text-green">Successful {{ successRecordsLabel }} record</span>
    </div>

    <ng-container *ngIf="hasErrorRecord">
        <div class="d-flex mat-status mb-1">
            <mat-icon svgIcon="close-circle-filled" color="warn"></mat-icon>
            <div class="d-block ml-1">
                <p i18n="@@failed-number-record" class="ls-1 text-red mb-1">Failed {{ errorRecordsLabel }} record</p>
                <p i18n="@@failed-record-desc" class="text-red mb-0">Please correct the mistake in your import file and re-import it.</p>
            </div>
        </div>

        <div class="d-bloc bg-grey px-1 py-1 overflow-vertical" style="border-radius: 5px; max-height: 200px">
            <div *ngFor="let error of errorMessages index as i" class="d-flex align-items-center">
                <span class="mr-1">{{ i + 1 }}</span>
                <span class="text-red">{{ error }}</span>
            </div>
        </div>
    </ng-container>

    <div class="d-flex justify-content-center mt-2">
        <button mat-stroked-button bn-large-button mat-dialog-close color="primary">
            <state-i18n key="BTN_CLOSE"></state-i18n>
        </button>
    </div>
</div>
