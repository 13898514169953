<div mat-dialog-title>
    <h3>นโยบายและเงื่อนไข</h3>
    <button mat-mini-fab bn-small-button mat-dialog-close bn-dialog-close color="primary">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<mat-dialog-content class="container m-0 p-0">
    <div class="bg"></div>

    <h4>นโยบายความเป็นส่วนตัวของลูกค้า/ผู้มาติดต่อ/พันธมิตรทางธุรกิจหรือคู่ค้า</h4>
    <p>
        บริษัท บอทน้อยคอนซัลติ้ง จำกัด ตระหนักดีว่าข้อมูลส่วนบุคคลของลูกค้า ผู้มาติดต่อ และพันธมิตรทางธุรกิจคู่ค้าของเรานั้นมีความสำคัญ
        เราจะยึดถือและปฏิบัติตามมาตรฐานขั้นสูงในด้านความเป็นส่วนตัวและรักษาความปลอดภัยข้อมูลส่วนบุคคลของท่านโดยเก็บข้อมูลดังกล่าวเป็นความลับ
        เพื่อให้มั่นใจว่าข้อมูลของท่านถูกเก็บใช้ และเปิดเผยเป็นไปตามความประสงค์ของท่าน
        และเพื่อเป็นการปฏิบัติตามกฎหมายเราจึงขอเสนอประกาศนโยบายความเป็นส่วนตัวของลูกค้าและคู่ค้าแก่ท่าน <br />
        รายละเอียดเพิ่มเติมเกี่ยวกับนโยบายความเป็นส่วนตัว สำหรับลูกค้าบริษัท
        <a class="text-blue" (click)="openPrivacyPolicy()">คลิกที่นี่</a> นโยบายความเป็นส่วนตัวสำหรับลูกค้าของ บริษัท บอทน้อยคอนซัลติ้ง จำกัด นโยบายความเป็นส่วนตัวฉบับนี้ออกโดย
        บริษัท บอทน้อยคอนซัลติ้ง จำกัด (ใช้คำเรียกแทนว่า “บริษัท” หรือ “ของบริษัท”) เพื่อแจ้งบุคคลภายนอกที่บริษัทติดต่อด้วย รวมถึงลูกค้า ผู้ใช้บริการเว็บไซต์
        แพลตฟอร์ม และผู้รับบริการอื่น ๆ ของบริษัท (ทั้งหมดรวมเรียกว่า “คุณ” หรือ “ของคุณ”) ได้ทราบถึงจุดประสงค์ของบริษัทเกี่ยวกับการเก็บรวบรวม การใช้ การเปิดเผย
        และ/หรือการโอนข้อมูลส่วนบุคคลของคุณ
    </p>
    <div class="d-flex align-items-center px-1 mb-1 accept-box">
        <mat-checkbox [(ngModel)]="pdpaCheck">ยินยอมให้บริษัทจัดเก็บและเผยแพร่ข้อมูลส่วนบุคคล</mat-checkbox>
        <div bnSpacer></div>
        <button mat-mini-fab bn-small-button color="default" [disabled]="!htmlPDPA" (click)="onToggleDetailsPDPA()">
            <mat-icon [svgIcon]="pdpaCollapse ? 'down' : 'up'"></mat-icon>
        </button>
    </div>
    <div [@expandCollapse]="pdpaCollapse ? 'collapsed' : 'expanded'">
        <div [innerHTML]="htmlPDPA | safeHTML"></div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" mat-dialog-close [disabled]="!pdpaCheck" (click)="onAcceptPDPA()">ยืนยัน</button>
</mat-dialog-actions>
