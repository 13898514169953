import * as moment from "moment";
import { Deserializable } from "../deserializable.model";
import { MessageModel } from "@core/models/customer-chat/customer-socket.model";

export class BotDetail implements Deserializable {
    bot_id: string;
    display_name: string;
    profile_img_url: string;

    get name() {
        return this.display_name;
    }

    get logo() {
        return this.profile_img_url;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}

export class UserDetail implements Deserializable {
    access_token: string;
    bot_id: string;
    uid: string;
    display_name: string;
    profile_img_url: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}

export class Messages {
    role: "user" | "admin";
    timestamp: Date;
    messages: Array<MessageTemplate>;

    private _showTime: boolean;
    get showTime() {
        return this._showTime;
    }

    get messagelength() {
        return this.messages.length;
    }

    get dateString() {
        const now = moment().startOf("day");
        const timestamp = moment(this.timestamp || new Date());
        const startTimestamp = moment(this.timestamp || new Date()).startOf("day");
        const diffDay = now.startOf("day").diff(startTimestamp, "day");
        const diffWeek = now.diff(startTimestamp, "week");
        switch (diffDay) {
            case 0:
                return `Today, ${timestamp.format("HH:mm A")}`;
            case 1:
                return `Yesterday, ${timestamp.format("HH:mm A")}`;
            default:
                switch (diffWeek) {
                    case 0:
                        return timestamp.format("ddd HH:mm A");
                    default:
                        return timestamp.format("MMM DD, YYYY, HH:mm A");
                }
        }
    }
    get messagesLength() {
        return this.messages.length || 0;
    }
    get lastMessage() {
        return this.messages[this.messagesLength - 1] || null;
    }
    get lastMessageType() {
        return this.messages[this.messagesLength - 1].type || null;
    }
    get hasQuickReply() {
        return this.lastMessageType == "message" && this.lastMessage.quickReply;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        if (input.messages) this.messages = input.messages.map((mss) => new MessageTemplate().deserialize(mss));
        this._showTime = true;
        return this;
    }

    appendMessage(message: MessageTemplate): void {
        this.timestamp = message.timestamp;
        this.messages.push(message);
    }

    onToggleShowTime = (state: boolean): void => {
        this._showTime = state;
    };
}

export class MessageTemplate implements Deserializable {
    sender_id: string;
    type: "message" | "template" | "image" | "flex" | "video" | "file" | "audio";
    message: string;
    url: string;
    template: Array<TemplateType>;
    quickReply: Array<ButtonType>;
    contents: any;
    timestamp: Date;

    private _regexURL: RegExp;

    deserialize(input: any) {
        Object.assign(this, input);
        if (input.template) this.template = input.template.map((temp) => new TemplateType().deserialize(temp));
        this._regexURL = RegExp(/(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+/, "gi");
        return this;
    }

    get htmlTextMessage() {
        switch (this.type) {
            case "message":
                let exec;
                let execArr = [];
                let mss = this.message;
                while ((exec = this._regexURL.exec(mss)) !== null) {
                    execArr.push(exec[0]);
                }
                execArr.forEach((e) => {
                    const aElement = document.createElement("a");
                    aElement.href = e;
                    aElement.target = "_blank";
                    aElement.innerHTML = e;
                    mss = mss.replace(e, aElement.outerHTML);
                });
                return mss;
            default:
                return "";
        }
    }
}

export class TemplateType implements Deserializable {
    url: string;
    title: string;
    subtitle: string;
    buttons: Array<ButtonType>;

    deserialize(input: any) {
        Object.assign(this, input);
        if (input.buttons) this.buttons = input.buttons.map((button) => new ButtonType().deserialize(button));
        return this;
    }
}

export class ButtonType implements Deserializable {
    label: string;
    data: string;
    type: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}

export class ChatlogMessage implements Deserializable {
    role: "admin" | "user";
    message: MessageModel;
    time: Date;

    deserialize(input: any) {
        Object.assign(this, input);
        if (input.time) this.time = new Date(input.time);
        return this;
    }

    get messageTemplate() {
        switch (this.message.type) {
            case "text":
                return { type: this.messageTemplateType, message: this.messageTemplateValue };
            case "image":
            case "audio":
            case "video":
            case "file":
                return { type: this.messageTemplateType, url: this.messageTemplateValue };
            case "carousel":
                return { type: this.messageTemplateType, template: this.messageTemplateValue };
            case "flex":
            case "buttons":
                return { type: this.messageTemplateType, template: [this.messageTemplateValue] };
            case "quick_reply":
                return { type: this.messageTemplateType, message: this.messageTemplateValue, quickReply: this.message.quick_reply_choices };
            default:
                return { type: this.messageTemplateType, template: [this.messageTemplateValue] };
        }
    }

    get messageTemplateType() {
        switch (this.message.type) {
            case "text":
                return "message";
            case "carousel":
            case "buttons":
                return "template";
            case "quick_reply":
                return "message";
            default:
                return this.message.type;
        }
    }

    get messageTemplateValue() {
        switch (this.message.type) {
            case "text":
                return this.message.text;
            case "image":
                return this.message.image.preview_img_url;
            case "audio":
                return this.message.audio.audio_url;
            case "video":
                return this.message.video.video_url;
            case "file":
                return this.message.file.file_url;
            case "carousel":
                return this.message.carousel_cards.map((crs) => {
                    return { url: crs.image_url, title: crs.title, subtitle: crs.subtitle, buttons: crs.buttons };
                });
            case "buttons":
                return { title: this.message.button_title, buttons: this.message.button_actions };
            case "flex":
                return this.message.flex_contents;
            case "quick_reply":
                return this.message.text;
            default:
                return this.message;
        }
    }
}
