import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError, retry } from "rxjs/operators";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import {
    SubscriptionPackage,
    ConfirmationQRModel,
    HistoryTransaction,
    CalculatePrice,
    CalculateModel,
    CheckStatusModel,
} from "@core/models/bot/subscription-plan.model";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

@Injectable()
export class SubscriptionService {
    constructor(private _http: HttpRequest) {}

    $getSubscriptionPackage = (): Observable<Array<SubscriptionPackage>> => {
        return this._http
            .get<any>({
                url: `${API_GATEWAYS_URL}payment/plan`,
                cacheMins: 720,
            })
            .pipe(
                retry(genericRetry),
                map((response) => response.data.map((res: any) => new SubscriptionPackage().deserialize(res))),
                catchError((error) => throwError(() => error))
            );
    };

    $getConvertDay = (bot_id: string, plan_id: string): Observable<{ convert_day_info: { remaining_day: number; convert_day: number; datetime: string } }> => {
        return this._http
            .get<any>({
                url: `${API_GATEWAYS_URL}payment/convert-day?bot_id=${bot_id}&plan_id=${plan_id}`,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $upgradeSubscriptionPlan = (bot_id: string, product_id: string): Observable<{ product_id: string; product_name: string }> => {
        return this._http
            .post<any>({
                url: `${API_GATEWAYS_URL}payment/upgrade-plan`,
                body: {
                    bot_id: bot_id,
                    product_id: product_id,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getSubscriptionPlan = (product_detail: CalculateModel, bot_id: string): Observable<string> => {
        return this._http
            .post<{ data: { _id: string } }>({
                url: `${API_GATEWAYS_URL}payment/subscription`,
                body: {
                    calculate_price: product_detail,
                    bot_id: bot_id,
                },
            })
            .pipe(
                retry(genericRetry),
                map((response) => response?.data?._id),
                catchError((error) => throwError(() => error))
            );
    };

    $cancelSubscription = (subscription_id: string, bot_id: string): Observable<{ message: string }> => {
        return this._http
            .post<any>({
                url: `${API_GATEWAYS_URL}payment/subscription/cancel`,
                body: {
                    subscription_id: subscription_id,
                    bot_id: bot_id,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $confirmSubscription = (subscription_id: string, bot_id: string): Observable<ConfirmationQRModel> => {
        return this._http
            .post<{ data: ConfirmationQRModel }>({
                url: `${API_GATEWAYS_URL}payment/subscription/confirm`,
                body: {
                    subscription_id: subscription_id,
                    bot_id: bot_id,
                },
            })
            .pipe(
                retry(genericRetry),
                map((response) => response?.data),
                catchError((error) => throwError(() => error))
            );
    };

    $subscriptionCheckStatus = (subscription_id: string, bot_id: string): Observable<CheckStatusModel> => {
        return this._http
            .get<CheckStatusModel>({
                url: `${API_GATEWAYS_URL}payment/subscription/payment-status?subscription_id=${subscription_id}&bot_id=${bot_id}`,
            })
            .pipe(
                retry(genericRetry),
                map((response) => response),
                catchError((error) => throwError(() => error))
            );
    };

    $getHistoryPayment = (): Observable<Array<HistoryTransaction>> => {
        return this._http
            .get<Array<HistoryTransaction>>({
                url: `${API_GATEWAYS_URL}payment/history`,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getReceiptPayment = (subscription_id: string): Observable<Blob> => {
        return this._http
            .get<any>({
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    Accept: "application/json",
                }),
                url: `${API_GATEWAYS_URL}payment/receipt?subscription_id=${subscription_id}`,
                responseType: "blob",
            })
            .pipe(
                retry(genericRetry),
                map(
                    (response) =>
                        new Blob([response], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        })
                ),
                catchError((error) => throwError(() => error))
            );
    };

    $getCalculatePrice = (bot_id: string, day: number, holding_tax: boolean, derivery: boolean): Observable<CalculatePrice> => {
        return this._http
            .get<CalculatePrice>({
                url: `${API_GATEWAYS_URL}payment/calculate-price?bot_id=${bot_id}&day_amount=${day}&get_holding_tax=${holding_tax}&get_derivery=${derivery}`,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };
}
