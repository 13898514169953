import {
    Component,
    AfterContentInit,
    AfterContentChecked,
    OnDestroy,
    Input,
    Output,
    Renderer2,
    ElementRef,
    QueryList,
    ContentChildren,
    forwardRef,
    EventEmitter,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { Subscription, fromEvent, merge } from "rxjs";
import { map } from "rxjs/operators";
import { BnOptionComponent } from "../bn-option/bn-option.component";

const ANIMATION_TIMINGS = "50ms cubic-bezier(0.25, 0.8, 0.25, 1)";

@Component({
    selector: "bn-select",
    templateUrl: "./bn-select.component.html",
    styleUrls: ["./bn-select.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BnSelectComponent),
            multi: true,
        },
    ],
    animations: [
        trigger("slideContent", [
            state(
                "void",
                style({
                    transform: "translate3d(0, -25%, 0) scale(0.9)",
                    opacity: 0,
                })
            ),
            state("enter", style({ transform: "none", opacity: 1 })),
            state("leave", style({ transform: "translate3d(0, -25%, 0)", opacity: 0 })),
            transition("* => *", animate(ANIMATION_TIMINGS)),
        ]),
        trigger("rotateContent", [
            state("void", style({ transform: "none" })),
            state("close", style({ transform: "none" })),
            state("open", style({ transform: "rotateZ(-180deg)" })),
            transition("* => *", animate(ANIMATION_TIMINGS)),
        ]),
    ],
})
export class BnSelectComponent implements AfterContentInit, AfterContentChecked, OnDestroy, ControlValueAccessor {
    private _subscription: Subscription;

    private _changed = (_: any) => {};
    private _touched: () => {};

    @Input() name: string;
    @Input() placeholder: string = "";
    @Input() disabled: boolean = false;

    @Output() openedChange: EventEmitter<boolean>;

    @ContentChildren(BnOptionComponent) bnOptionList: QueryList<BnOptionComponent>;

    private _openPanel: boolean;
    get openPanel() {
        return this._openPanel;
    }
    set openPanel(state: boolean) {
        this._openPanel = state;
    }

    private _value: string | Array<string>;
    get value(): string | Array<string> {
        return this._value;
    }
    set value(value: string | Array<string>) {
        if (this._value !== value) {
            this._value = value;
            this._changed(value);
        }
    }

    private _multiple: boolean;
    set multiple(state: boolean) {
        this._multiple = state;
    }
    get multiple() {
        return this._multiple;
    }

    containerWidth: string;
    label: string;

    constructor(private _renderer: Renderer2, private _ele: ElementRef) {
        this._renderer.addClass(this._ele.nativeElement, "bn-select");
        this.openPanel = false;
        this.multiple = false;
        this.label = "";

        this.openedChange = new EventEmitter(this.openPanel);
    }

    ngAfterContentInit() {
        this.containerWidth = `${this._ele.nativeElement.clientWidth}px`;
        this._detectBnOptionEvent();
    }

    ngAfterContentChecked(): void {
        this.containerWidth = `${this._ele.nativeElement.clientWidth}px`;
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    private _detectBnOptionEvent = (): void => {
        if (this._subscription) this._subscription.unsubscribe();
        this._subscription = new Subscription();

        this.bnOptionList.forEach((bnOption) => {
            bnOption.onUpdateMultiple(this.multiple);
            if (bnOption.value === this.value) {
                this.label = bnOption.ele.nativeElement.querySelector(".bn-option-text").innerHTML.toString();
            }
        });

        this._subscription.add(
            merge(
                ...this.bnOptionList.map((bnOption) =>
                    fromEvent(bnOption.ele.nativeElement, "click").pipe(
                        map(($event) => {
                            return { component: bnOption, event: $event };
                        })
                    )
                )
            ).subscribe(($event) => {
                let content = $event.component.ele.nativeElement.querySelector(".bn-option-text").innerHTML.toString();
                switch (this.multiple) {
                    case true:
                        const value = this.value as Array<string>;
                        const label = this.label.split(",").filter((l) => l.length > 0);
                        switch ($event.component.selected) {
                            case true:
                                value.push($event.component.value);
                                this.value = value;
                                label.push(content);
                                this.label = label.toString();
                                break;
                            default:
                                const index = value.indexOf($event.component.value);
                                value.splice(index, 1);
                                this.value = value;
                                label.splice(index, 1);
                                this.label = label.toString();
                        }
                        break;
                    default:
                        this.onToggleClose();
                        this.label = content;
                        this.value = $event.component.value;
                }
            })
        );
    };

    registerOnChange(fn: any) {
        this._changed = fn;
    }

    registerOnTouched(fn: any) {
        this._touched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    writeValue(value: string | Array<string>) {
        this._value = value;

        if (this.multiple && !!this.value) this.label = (this.value as Array<string>).join(",");

        if (this.bnOptionList) {
            this.bnOptionList.forEach((bnOption) => {
                switch (this.multiple) {
                    case true:
                        if (this.value.includes(bnOption.value)) bnOption.selected = true;
                        break;
                    default:
                        bnOption.onUpdateMultiple(this.multiple);
                        if (bnOption.value === this.value) {
                            this.label = bnOption.ele.nativeElement.querySelector(".bn-option-text").innerHTML.toString();
                        }
                }
            });
        }
    }

    onToggleOpen = (): void => {
        if (!this.disabled) {
            this.openPanel = true;
            this.openedChange.next(this.openPanel);
        }
    };

    onToggleClose = (): void => {
        this.openPanel = false;
        this.openedChange.next(this.openPanel);
    };

    overlayKeydown = ($event: KeyboardEvent): void => {
        if ($event.keyCode == 27) {
            this.onToggleClose();
        }
    };
}
