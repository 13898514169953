import { Deserializable } from "../deserializable.model";

export class MarketItem implements Deserializable {
    _id: string;
    name: string;
    logo: string;
    detail: MarketItemDetail;
    usage: number;
    feature: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    fromResponse(input: MarketplaceResponse) {
        this._id = input?._id ?? "";
        this.name = input?.product_name.replace("-", " ") ?? "";
        this.logo = input?.product_img ?? "";
        this.usage = input?.ins_num_user ?? 0;
        this.feature = input?.feature ?? "";
        this.detail = {
            images: [],
            description: "",
            subDescription: [],
            pricing: input?.cost_per_day ?? 0,
        };
        return this;
    }

    appendDetail(input: MarketplaceDetailResponse) {
        this.detail = {
            images: input.img,
            description: input.th_des,
            subDescription: input.th_sub_des,
            pricing: input.price,
        };
    }
}

interface MarketItemDetail {
    images: Array<string>;
    description: string;
    subDescription: Array<string>;
    pricing: number;
}

export interface MarketplaceResponse {
    _id: string;
    product_name: string;
    cost_per_day: number;
    limit_subscribe_number: number;
    feature: string;
    is_default_plan: boolean;
    product_img: string;
    ins_num_user: number;
    cost_per_days_fractor: number;
}

export interface MarketplaceDetailResponse {
    img: Array<string>;
    th_des: string;
    th_sub_des: Array<string>;
    eng_des: string;
    eng_sub_des: Array<string>;
    price: number;
}
