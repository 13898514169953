<div mat-dialog-title class="mb-0">
    <h3 i18n="@@title-button" class="mb-0">{{ title }} Button</h3>
</div>

<mat-dialog-content class="border-top-none">
    <form id="button-type-form" class="d-flex flex-column" [formGroup]="object" (ngSubmit)="onSubmit()">
        <h4 i18n="@@button-label-title">Button Label</h4>
        <bn-form-field class="mb-1" cdkOverlayOrigin #nameTooltip="cdkOverlayOrigin">
            <input
                data-cy="input-button-type-label"
                i18n-placeholder="@@enter-button-label"
                #label
                bnInput
                placeholder="Enter button label"
                type="text"
                cdkFocusInitial
                formControlName="label"
                maxlength="20"
            />
            <span bnSuffix>{{label.value.length}}/20</span>
        </bn-form-field>

        <h4 i18n="@@button-type-title" *ngIf="hasType">Button Type</h4>
        <div class="d-flex" cdkOverlayOrigin #typeTooltip="cdkOverlayOrigin">
            <ng-container *ngIf="hasType">
                <ng-container [ngSwitch]="isPersistent">
                    <bn-form-field *ngSwitchCase="true" data-cy="input-select-button-type" class="w-100 mb-1">
                        <bn-select i18n-placeholder="@@select-button-type" name="type" formControlName="type" placeholder="Select button type">
                            <bn-option data-cy="input-select-button-message-value" i18n="@@message" value="message">Message</bn-option>
                            <bn-option data-cy="input-select-button-url-value" i18n="@@url-link-title" value="url">URL Link</bn-option>
                        </bn-select>
                    </bn-form-field>

                    <bn-form-field *ngSwitchDefault data-cy="input-select-button-type" class="w-100 mb-1">
                        <bn-select i18n-placeholder="@@select-button-type" name="type" formControlName="type" placeholder="Select button type">
                            <bn-option data-cy="input-select-button-message-value" i18n="@@message" value="message">Message</bn-option>
                            <bn-option data-cy="input-select-button-phone-value" i18n="@@phone-number-title" value="phone">Phone Number</bn-option>
                            <bn-option data-cy="input-select-button-url-value" i18n="@@url-link-title" value="url">URL Link</bn-option>
                        </bn-select>
                    </bn-form-field>
                </ng-container>
            </ng-container>
        </div>

        <h4 i18n="@@message">Message</h4>
        <bn-form-field
            class="mb-1"
            [hasObject]="type.value == 'message' || !type.value ? true : false"
            [hasEmoji]="type.value == 'message' || !type.value ? true : false"
            cdkOverlayOrigin
            #responseTooltip="cdkOverlayOrigin"
        >
            <textarea
                data-cy="input-button-type-data"
                i18n-placeholder="@@enter-message"
                *ngIf="type.value == 'message' || !type.value"
                bnInput
                bnTextareaAutosize
                [bnAutosizeMinRows]="1"
                [bnAutosizeMaxRows]="3"
                placeholder="Enter message"
                type="text"
                formControlName="message"
                maxlength="300"
            ></textarea>
            <span *ngIf="type.value == 'message' || !type.value" bnSuffix>{{ message.value.length }}/300</span>
            <input
                i18n-placeholder="@@add-phone-number"
                *ngIf="type.value == 'phone'"
                bnInput
                placeholder="Add phone number"
                type="tel"
                formControlName="message"
            />
            <input i18n-placeholder="@@add-url-link" *ngIf="type.value == 'url'" bnInput placeholder="Add URL link" type="url" formControlName="message" />
            <bn-hint align="start">
                <ng-container [ngSwitch]="type.value">
                    <ng-container *ngSwitchCase="'url'" i18n="@@url-hint" align="start">**Only use https:// or http://</ng-container>
                    <ng-container *ngSwitchCase="'phone'" i18n="@@phone-hint" align="start">**Only use 0-9</ng-container>
                </ng-container>
            </bn-hint>

            <bn-error>
                <ng-container [ngSwitch]="type.value">
                    <ng-container *ngSwitchCase="'url'" i18n="@@error-url-format">Required (https:// or http://)</ng-container>
                    <ng-container *ngSwitchCase="'phone'" i18n="@@error-phone-format">Please enter a valid phone number</ng-container>
                    <ng-container *ngSwitchDefault i18n="@@error-message-required">Message is required</ng-container>
                </ng-container>
            </bn-error>
        </bn-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button data-cy="save-button-type-button" form="button-type-form" type="submit" mat-raised-button bn-large-button color="primary">
        <state-i18n key="BTN_ADD"></state-i18n>
    </button>
</mat-dialog-actions>

<!-- Tooltip Template -->
<ng-template bnTooltip [origin]="nameTooltip" verticalAlign="top" horizontalAlign="center">
    <h3 i18n="@@button-name-title">Button Name</h3>
    <p i18n="@@button-name-desc">Name the button</p>
</ng-template>

<ng-template bnTooltip [origin]="typeTooltip" verticalAlign="top" horizontalAlign="center">
    <h3 i18n="@@button-type-title">Button Type</h3>
    <p i18n="@@button-type-desc">Select button type</p>
</ng-template>

<ng-template bnTooltip [origin]="responseTooltip" verticalAlign="top" horizontalAlign="center">
    <h3 i18n="@@button-response-title">Button Response</h3>
    <p i18n="@@button-response-desc-1">Put the action you wish to happen</p>
    <p i18n="@@buton-response-desc-2">when the user touches this button.</p>
</ng-template>
