import { Injectable } from "@angular/core";
import { catchError, retry, Observable, throwError, Subject, interval } from "rxjs";
import { takeUntil, debounceTime, switchMap, tap } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { HttpRequest, genericRetry } from "@core/http/http.service";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

interface PostAutomateTestResponse {
    status_id: string;
}

interface GetAutomateTestStatusResponse {
    process: number;
    status_id: string;
    bot_id: string;
    status: string;
    message: string;
    result?: Array<AutomateTestResult>;
}

export interface AutomateTestResult {
    intent: string;
    is_correct: boolean;
    response: string;
    correct_ans: string;
}

@Injectable()
export class AutomateTestService {
    get url() {
        return `${API_GATEWAYS_URL}automate-test`;
    }

    private $defer: Subject<boolean> = new Subject();

    constructor(private _http: HttpRequest) {}

    $postAutomateTest = (intents: Array<string>): Observable<PostAutomateTestResponse> => {
        return this._http
            .post<PostAutomateTestResponse>({
                url: `${this.url}/create`,
                body: {
                    intent: intents,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $getAutomateTestStatus = (_id: string): Observable<GetAutomateTestStatusResponse> => {
        return interval(1000).pipe(
            takeUntil(this.$defer.pipe(debounceTime(100))),
            switchMap(() =>
                this._http
                    .get<GetAutomateTestStatusResponse>({
                        url: `${this.url}/check?status_id=${_id}`,
                    })
                    .pipe(
                        retry(genericRetry),
                        tap((response) => (response?.process == 100 ? this.$defer.next(true) : null)),
                        catchError((error) => throwError(() => error))
                    )
            ),
            catchError((error) => throwError(() => error))
        );
    };

    $putCancelAutomateTest = (_id: string): Observable<void> => {
        return this._http
            .put<void>({
                url: `${this.url}/cancel`,
                body: {
                    status_id: _id,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };
}
