import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { Observable, throwError, of } from "rxjs";
import { catchError, map, retry, switchMap } from "rxjs/operators";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { MarketplaceResponse, MarketplaceDetailResponse, MarketItem } from "@core/models/market-place/market-item.model";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

@Injectable()
export class MarketPlaceService {
    constructor(private _http: HttpRequest) {}

    $getMarketItemList = (): Observable<Array<MarketItem>> => {
        return this._http
            .get<{ data: Array<MarketplaceResponse> }>({
                url: `${API_GATEWAYS_URL}payment/marketplace`,
                cacheMins: 5,
            })
            .pipe(
                retry(genericRetry),
                map((response) => response.data.map((res) => new MarketItem().fromResponse(res))),
                catchError((error) => throwError(() => error))
            );
    };

    $getMatketItemDetail = (item: MarketItem): Observable<MarketItem> => {
        return this._http
            .get<MarketplaceDetailResponse>({
                url: `${API_GATEWAYS_URL}payment/marketplace/${item.feature}`,
                cacheMins: 5,
            })
            .pipe(
                retry(genericRetry),
                map((response) => {
                    item.appendDetail(response);
                    return item;
                }),
                catchError((error) => throwError(() => error))
            );
    };

    $installMarketItem = (product_id: string, _id?: string): Observable<void> => {
        return this._http
            .post({
                url: `${API_GATEWAYS_URL}payment/marketplace`,
                body: { bot_id: _id, product_id: product_id },
            })
            .pipe(
                retry(genericRetry),
                map((_) => null),
                catchError((error) => throwError(() => error))
            );
    };

    $uninstallMarketItem = (product_id: string, _id?: string): Observable<void> => {
        return this._http
            .delete({
                url: `${API_GATEWAYS_URL}payment/marketplace`,
                body: { bot_id: _id, product_id: product_id },
            })
            .pipe(
                retry(genericRetry),
                map((_) => null),
                catchError((error) => throwError(() => error))
            );
    };

    $toggleMarketItem = (state: boolean, product_id: string, _id?: string): Observable<void> => {
        return of(state).pipe(
            switchMap((s) => {
                if (s) return this.$uninstallMarketItem(product_id, _id);
                return this.$installMarketItem(product_id, _id);
            }),
            catchError((error) => throwError(() => error))
        );
    };
}
