import { Injectable } from "@angular/core";
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { Observable, of, throwError, timer } from "rxjs";
import { switchMap, mergeMap, finalize } from "rxjs/operators";
import { LocalService } from "@core/services/cache/local.service";

class LocalStorageSaveOptions {
    key: string;
    data: any;
    expirationMins?: number;
}

enum Verbs {
    GET = "GET",
    PUT = "PUT",
    POST = "POST",
    DELETE = "DELETE",
}

export class HttpOptions {
    headers?: HttpHeaders;
    url: string;
    params?: HttpParams;
    body?: any;
    cacheMins?: number;
    responseType?: any;
}

enum RETRY {
    NEVER = 1,
}

@Injectable()
export class HttpRequest {
    constructor(private _http: HttpClient, private _cacheService: CacheService) {}

    get<T>(options: HttpOptions): Observable<T> {
        return this.httpCall(Verbs.GET, options);
    }

    delete<T>(options: HttpOptions): Observable<T> {
        return this.httpCall(Verbs.DELETE, options);
    }

    post<T>(options: HttpOptions): Observable<T> {
        return this.httpCall(Verbs.POST, options);
    }

    put<T>(options: HttpOptions): Observable<T> {
        return this.httpCall(Verbs.PUT, options);
    }

    private httpCall<T>(verb: Verbs, options: HttpOptions): Observable<T> {
        // Setup default values
        options.headers = options.headers || null;
        options.body = options.body || null;
        options.responseType = options.responseType || "json";
        options.cacheMins = options.cacheMins || 0;

        // Append query params into URL
        if (!!options?.params) options.url = `${options.url}?${options.params.toString()}`;

        if (options.cacheMins > 0) {
            // Get data from cache
            const data = this._cacheService.load(options.url);
            // Return data from cache
            if (data !== null) {
                return of<T>(data);
            }
        }

        return this._http
            .request<T>(verb, options.url, {
                headers: options.headers,
                body: options.body,
                responseType: options.responseType,
            })
            .pipe(
                switchMap((response) => {
                    if (options.cacheMins > 0) {
                        // Data will be cached
                        this._cacheService.save({
                            key: options.url,
                            data: response,
                            expirationMins: options.cacheMins,
                        });
                    }

                    if (verb !== "GET") this._cacheService.remove(options.url);

                    return of<T>(response);
                })
            );
    }
}

@Injectable()
export class CacheService {
    constructor(private local: LocalService) {}

    save(options: LocalStorageSaveOptions) {
        // Set default values for optionals
        options.expirationMins = options.expirationMins || 0;

        // Set expiration date in miliseconds
        const expirationMS = options.expirationMins !== 0 ? options.expirationMins * 60 * 1000 : 0;

        const record = {
            value: options.data,
            expiration: expirationMS !== 0 ? new Date().getTime() + expirationMS : null,
            hasExpiration: expirationMS !== 0 ? true : false,
        };

        this.local.encryptSet(options.key, record);

        let _cacheKeys = JSON.parse(this.local.decryptGet("_cache") || null);
        if (!!_cacheKeys) if (!_cacheKeys.includes(options.key)) _cacheKeys.push(options.key);

        if (!_cacheKeys) {
            _cacheKeys = [];
            _cacheKeys.push(options.key);
        }

        this.local.encryptSet("_cache", JSON.stringify(_cacheKeys));
    }

    load(key: string) {
        // Get cached data from localstorage
        const record = this.local.decryptGet(key);

        if (!!record) {
            const now = new Date().getTime();
            // Expired data will return null
            if (!record || (record.hasExpiration && record.expiration <= now)) {
                return null;
            } else {
                return record.value;
            }
        }
        return null;
    }

    remove(key: string) {
        this.local.delete(key);
        let _cacheKeys = JSON.parse(this.local.decryptGet("_cache") || null);
        if (!!_cacheKeys) _cacheKeys = _cacheKeys.filter((k: string) => k !== key);
        this.local.encryptSet("_cache", JSON.stringify(_cacheKeys));
    }

    clearCacheResponse() {
        let _cacheKeys = JSON.parse(this.local.decryptGet("_cache") || null);
        if (!!_cacheKeys) _cacheKeys.forEach((k: string) => this.local.delete(k));
        this.local.delete("_cache");
    }
}

const genericRetryHttpNotifier =
    ({
        delay = 1000,
    }: {
        delay?: number;
    } = {}) =>
    (error: any, retryCount: number) => {
        if (error.status) {
            console.log(`Attempt ${retryCount}: retrying in ${retryCount * delay}ms`);
            return timer(retryCount * delay);
        }
        console.log("We are done!");
        return throwError(() => error);
    };

export const genericRetry = {
    count: RETRY.NEVER,
    delay: genericRetryHttpNotifier(),
};

export const genericRetryHttp =
    ({
        maxRetryAttempts = RETRY.NEVER,
        scalingDuration = 1000,
        excludedStatusCodes = [],
    }: {
        maxRetryAttempts?: number;
        scalingDuration?: number;
        excludedStatusCodes?: number[];
    } = {}) =>
    (attempts: Observable<any>) => {
        return attempts.pipe(
            mergeMap((error, i) => {
                const retryAttempt = i + 1;
                // if maximum number of retries have been met
                // or response is a status code we don't wish to retry, throw error
                if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find((e) => e === error.status)) {
                    return throwError(() => error);
                }
                console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`);
                // retry after 1s, 2s, etc...
                return timer(retryAttempt * scalingDuration);
            }),
            finalize(() => console.log("We are done!"))
        );
    };
