import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule, MAT_CHECKBOX_DEFAULT_OPTIONS } from "@angular/material/checkbox";
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from "@angular/material/radio";
import { MatInputModule } from "@angular/material/input";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTableModule } from "@angular/material/table";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatStepperModule } from "@angular/material/stepper";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { MatSliderModule } from "@angular/material/slider";
import { MatChipsModule } from "@angular/material/chips";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { QRCodeModule } from "angularx-qrcode";

// Directives
import { BnSmallButtonDirective, BnLargeButtonDirective, MatButtonDirective, BnSquareButtonDirective } from "./directives/button";
import { BnFormFieldSmallDirective, BnFormFieldLargeDirective, BnInputDirective, BnTextareaAutosizeDirective } from "./directives/forms-element";
import { BnTableDirective } from "./directives/table-element";
import { CloseButtonDirective } from "./directives/dialog";
import { BnSelectMultipleDirective } from "./directives/bn-select";
import { BnTooltipDirective } from "./directives/bn-tooltip/bn-tooltip.directive";
import { MediaOverlayDirective } from "./directives/overlays";
import { HighlightKeywordDirective } from "./directives/highlight-keyword";
import { LangDetectionDirective } from "./directives/lang-detection";

// Components
import { BnFormFieldComponent, BnHintComponent, BnErrorComponent } from "./components/forms-element";
import { SuccessAlertComponent, ErrorAlertComponent, WarnAlertComponent, NormalAlertComponent } from "./components/alert";
import { ToolbarComponent, ToolbarRowComponent, BnSpacerDirective } from "./components/toolbar";
import { BnPaginatorComponent } from "./components/bn-paginator/bn-paginator.component";
import { BnSelectComponent, BnOptionComponent } from "./components/bn-select";
import {
    SelectObjectComponent,
    AlertDialogComponent,
    BrowseFileComponent,
    DownloadFileComponent,
    ImportFileStatusComponent,
    ConfirmationDialogComponent,
    AddButtonTypeDialogComponent,
    ParamsDialogComponent,
    BuyPackageDialogComponent,
    QrCodePaymentComponent,
    CheckStatusPaymentComponent,
    DeleteChatbotDialogComponent,
    AddKeyChatbotDialogComponent,
    CloneChatbotDialogComponent,
    BillingInformationDialogComponent,
    TopUpDialogComponent,
    CreateEntityDialogComponent,
    ConfirmationUpgradeComponent,
    NotificationUpgradeComponent,
    ContactSupportDialogComponent,
    PdpaAcceptionDialogComponent,
} from "./components/dialog";
import { FlexTemplateComponent } from "./components/flex-template/flex-template.component";
import { ImageSliderComponent } from "./components/image-slider/image-slider.component";
import { AnnouncementComponent } from "./services/announcement/announcement/announcement.component";
import { BnAudioPlayerComponent } from "./components/bn-audio-player/bn-audio-player.component";

// Services
import { SnackBarAlertService } from "./services/snack-bar-alert/snack-bar-alert.service";
import { SnackBarTitleService } from "./services/snack-bar-alert/snack-bar-title.service";
import { AnnouncementService } from "./services/announcement/announcement.service";

// Pipe Transform
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { CapitalizePipe } from "./pipes/capitalize.pipe";
import { CurrencyPipe } from "./pipes/currency.pipe";
import { RoundDemicalPipe } from "./pipes/round-demical.pipe";
import { UppercasePipe } from "./pipes/uppercase.pipe";
import { UrlFileNamePipe } from "./pipes/url-file-name.pipe";
import { DateDurationFormatPipe } from "./pipes/date-duration-format.pipe";

// Translation
import { StateI18nComponent } from "./translation/state-i18n/state-i18n.component";

@NgModule({
    declarations: [
        BnSmallButtonDirective,
        BnLargeButtonDirective,
        MatButtonDirective,
        BnSquareButtonDirective,
        BnFormFieldSmallDirective,
        BnFormFieldLargeDirective,
        BnInputDirective,
        BnFormFieldComponent,
        BnHintComponent,
        BnErrorComponent,
        SuccessAlertComponent,
        ErrorAlertComponent,
        WarnAlertComponent,
        NormalAlertComponent,
        ToolbarComponent,
        ToolbarRowComponent,
        BnSpacerDirective,
        BnPaginatorComponent,
        BnTableDirective,
        CloseButtonDirective,
        BnSelectComponent,
        BnOptionComponent,
        BnSelectMultipleDirective,
        BnTooltipDirective,
        MediaOverlayDirective,
        HighlightKeywordDirective,
        LangDetectionDirective,
        BnTextareaAutosizeDirective,
        SelectObjectComponent,
        AlertDialogComponent,
        BrowseFileComponent,
        DownloadFileComponent,
        ImportFileStatusComponent,
        ConfirmationDialogComponent,
        AddButtonTypeDialogComponent,
        ParamsDialogComponent,
        BuyPackageDialogComponent,
        QrCodePaymentComponent,
        CheckStatusPaymentComponent,
        DeleteChatbotDialogComponent,
        AddKeyChatbotDialogComponent,
        CloneChatbotDialogComponent,
        TopUpDialogComponent,
        CreateEntityDialogComponent,
        ConfirmationUpgradeComponent,
        NotificationUpgradeComponent,
        FlexTemplateComponent,
        ImageSliderComponent,
        AnnouncementComponent,
        BnAudioPlayerComponent,
        SafeHtmlPipe,
        CapitalizePipe,
        CurrencyPipe,
        RoundDemicalPipe,
        UppercasePipe,
        UrlFileNamePipe,
        DateDurationFormatPipe,
        BillingInformationDialogComponent,
        StateI18nComponent,
        ContactSupportDialogComponent,
        PdpaAcceptionDialogComponent,
        LangDetectionDirective,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatRadioModule,
        MatInputModule,
        MatSnackBarModule,
        MatDialogModule,
        MatTableModule,
        OverlayModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatProgressBarModule,
        MatTabsModule,
        MatStepperModule,
        MatAutocompleteModule,
        ClipboardModule,
        MatSliderModule,
        MatChipsModule,
        PickerModule,
        QRCodeModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatRadioModule,
        MatInputModule,
        MatDialogModule,
        MatTableModule,
        OverlayModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatExpansionModule,
        BnSmallButtonDirective,
        BnLargeButtonDirective,
        MatButtonDirective,
        BnSquareButtonDirective,
        BnFormFieldSmallDirective,
        BnFormFieldLargeDirective,
        BnInputDirective,
        BnTextareaAutosizeDirective,
        BnFormFieldComponent,
        BnHintComponent,
        BnErrorComponent,
        ToolbarComponent,
        ToolbarRowComponent,
        BnSpacerDirective,
        BnPaginatorComponent,
        BnTableDirective,
        CloseButtonDirective,
        BnSelectComponent,
        BnOptionComponent,
        BnSelectMultipleDirective,
        BnTooltipDirective,
        MediaOverlayDirective,
        HighlightKeywordDirective,
        LangDetectionDirective,
        SelectObjectComponent,
        AlertDialogComponent,
        BrowseFileComponent,
        DownloadFileComponent,
        ImportFileStatusComponent,
        ConfirmationDialogComponent,
        AddButtonTypeDialogComponent,
        ParamsDialogComponent,
        BuyPackageDialogComponent,
        QrCodePaymentComponent,
        CheckStatusPaymentComponent,
        DeleteChatbotDialogComponent,
        AddKeyChatbotDialogComponent,
        CloneChatbotDialogComponent,
        FlexTemplateComponent,
        ImageSliderComponent,
        BnAudioPlayerComponent,
        SafeHtmlPipe,
        CapitalizePipe,
        CurrencyPipe,
        RoundDemicalPipe,
        UppercasePipe,
        UrlFileNamePipe,
        DateDurationFormatPipe,
        StateI18nComponent,
    ],
    providers: [
        {
            provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
            useValue: { color: "primary" },
        },
        { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: "primary" } },
        SnackBarAlertService,
        SnackBarTitleService,
        AnnouncementService,
        SafeHtmlPipe,
        CapitalizePipe,
        CurrencyPipe,
        RoundDemicalPipe,
        UppercasePipe,
        UrlFileNamePipe,
        DateDurationFormatPipe,
    ],
})
export class SharedModule {}
