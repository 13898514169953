import { Component, Renderer2, ElementRef } from "@angular/core";

@Component({
    selector: "bn-error",
    templateUrl: "./bn-error.component.html",
    styleUrls: ["./bn-error.component.scss"],
})
export class BnErrorComponent {
    constructor(private ele: ElementRef, private _renderer: Renderer2) {
        this._renderer.addClass(this.ele.nativeElement, "bn-error");
        this._renderer.addClass(this.ele.nativeElement, "mat-caption");
    }
}
