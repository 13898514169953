import { Deserializable } from "../deserializable.model";

export class BotPermission implements Deserializable {
  bot_id: string;
  user_access: Array<UserAccess>;

  get userAccess() {
    return this.user_access.map((u) => u.email);
  }

  get userList() {
    return this.user_access.map((u) => {
      return new PermissionUser().deserialize({
        ...u.permission.AccessList,
        _id: u._id,
        email: u.email,
      	verify: u.verify
      });
    });
  }

  deserialize(input: Array<any>) {
    this.user_access = [];
    if (input.length > 0) {
      this.bot_id = input[0].bot_id;
      this.user_access = input.map((i) => {
        return new UserAccess().deserialize({
          _id: i._id,
          email: i.email,
          permission: i.permission,
          verify: i.verify,
        });
      });
    }
    return this;
  }

  getUser(email: string) {
    const index = this.user_access.findIndex((u) => u.email == email);
    if (index == -1) return null;
    return new PermissionUser().deserialize({
      ...this.user_access[index].permission.AccessList,
      _id: this.user_access[index]._id,
      email: email,
    });
  }
}

class UserAccess implements Deserializable {
  _id: string;
  email: string;
  verify: boolean;
  permission: Access;

  deserialize(input: any) {
    Object.assign(this, input);
    if (input.permission)
      this.permission = new Access().deserialize(input.permission);
    return this;
  }
}

export class Access implements Deserializable {
  train_bot: "Active" | "Inactive";
  view_train_bot: "Active" | "Inactive";
  entity: "Active" | "Inactive";
  dialogue: "Active" | "Inactive";
  object: "Active" | "Inactive";
  live_chat: "Active" | "Inactive";

  get AccessList() {
    return {
      train: this.train_bot == "Active" ? true : false,
      view: this.view_train_bot == "Active" ? true : false,
      entity: this.entity == "Active" ? true : false,
      object: this.object == "Active" ? true : false,
      dialog: this.dialogue == "Active" ? true : false,
      livechat: this.live_chat == "Active" ? true : false,
    };
  }

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class PermissionUser implements Deserializable {
  verify: boolean;
  _id: string;
  email: string;
  train: boolean;
  view: boolean;
  entity: boolean;
  object: boolean;
  dialog: boolean;
  livechat: boolean;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  transmit() {
    return {
      _id: this._id,
      email: this.email,
      permission: {
        train_bot: this.train ? "Active" : "Inactive",
        view_train_bot: this.view ? "Active" : "Inactive",
        entity: this.entity ? "Active" : "Inactive",
        dialogue: this.dialog ? "Active" : "Inactive",
        object: this.object ? "Active" : "Inactive",
        live_chat: this.livechat ? "Active" : "Inactive",
      },
      verify: this.verify
    };
  }
}
