import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { HttpHeaders } from "@angular/common/http";
import { HttpRequest, HttpOptions, genericRetry } from "@core/http/http.service";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

@Injectable()
export class ChangePasswordService {
    constructor(private _http: HttpRequest) {}

    $checkPassword = (username: string, password: string): Observable<boolean> => {
        return this._http
            .post<boolean>({
                url: `${API_GATEWAYS_URL}platform-go/user/check-password`,
                body: {
                    username: username,
                    password: password,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $updatePassword = (username: string, password: string, confirmed_password: string): Observable<string> => {
        return this._http
            .put<any>({
                url: `${API_GATEWAYS_URL}platform-go/user/change-password`,
                body: {
                    username: username,
                    password: password,
                    confirmed_password: confirmed_password,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $updateEmail = (username: string, token: string): Observable<string> => {
        let options: HttpOptions = {
            url: `${API_GATEWAYS_URL}platform-go/user/update-email`,
            body: { email: username },
        };
        if (!!token) options.headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);

        return this._http.post<string>(options).pipe(
            retry(genericRetry),
            catchError((error) => throwError(() => error))
        );
    };
}
