import { Component } from "@angular/core";
import { SnackBarTitleService } from "../../../services/snack-bar-alert/snack-bar-title.service";

@Component({
    selector: "app-warn-alert",
    templateUrl: "./warn-alert.component.html",
    styleUrls: ["./warn-alert.component.scss"],
})
export class WarnAlertComponent {
    private _description: string;
    get description() {
        return this._description;
    }
    set description(desc: string) {
        this._description = desc;
    }

    constructor(private _snackBar: SnackBarTitleService) {
        this.description = this._snackBar.description;
    }
}
