import { Component, Inject, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable, of, Subscription } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";
import { BillingInformationService } from "@core/http/bot";
import { BillingInformation } from "@core/models/bot/billing-information.model";
import { LocalfileService, RawProvince, RawDistrict, RawSubDistrict } from "@core/services/localfile/localfile.service";
import { TopUpDialogComponent } from "@shared/components/dialog";
import { SnackBarAlertService } from "@shared/services/snack-bar-alert/snack-bar-alert.service";
import { I18nState, getI18nState } from "@shared/translation/i18n-state";

@Component({
    selector: "app-billing-information-dialog",
    templateUrl: "./billing-information-dialog.component.html",
    styleUrls: ["./billing-information-dialog.component.scss"],
})
export class BillingInformationDialogComponent implements OnDestroy {
    private _subscription: Subscription;

    object: FormGroup;

    province: Array<RawProvince>;
    district: Array<RawDistrict>;
    subDistrict: Array<RawSubDistrict>;
    postal: Array<number>;

    filteredProvince: Observable<Array<string>>;
    filteredDistrict: Observable<Array<string>>;
    filteredSubDistrict: Observable<Array<string>>;
    filteredPostal: Observable<Array<number>>;

    get checkDisable() {
        return this._data?.amount;
    }
    get isCompany() {
        return this.type.value == "company";
    }

    get personalLable() {
        return getI18nState(I18nState.LABEL_PERSONAL);
    }
    get companyLabel() {
        return getI18nState(I18nState.LABEL_COMPANY);
    }

    get typePlaceholder() {
        return getI18nState(I18nState.LABEL_TYPE_PHD);
    }
    get companyPlaceholder() {
        return getI18nState(I18nState.LABEL_COMPANY_PHD);
    }
    get firstNamePlaceholder() {
        return getI18nState(I18nState.LABEL_FIRST_NAME_PHD);
    }
    get lastNamePlaceholder() {
        return getI18nState(I18nState.LABEL_LAST_NAME_PHD);
    }
    get billAddressPlaceholder() {
        return getI18nState(I18nState.LABEL_BILL_ADDRESS_PHD);
    }
    get provincePlaceholder() {
        return getI18nState(I18nState.LABEL_PROVINCE_PHD);
    }
    get districtPlaceholder() {
        return getI18nState(I18nState.LABEL_DIST_PHD);
    }
    get subDistrictPlaceholder() {
        return getI18nState(I18nState.LABEL_SUB_DIST_PHD);
    }
    get postalPlaceholder() {
        return getI18nState(I18nState.LABEL_POSTAL_PHD);
    }
    get phonePlaceholder() {
        return getI18nState(I18nState.LABEL_PHONE_PHD);
    }
    get emailPlaceholder() {
        return getI18nState(I18nState.LABEL_EMAIL_PHD);
    }
    get vatPlaceholder() {
        return getI18nState(I18nState.LABEL_VAT_PHD);
    }

    get type() {
        return this.object.get("type");
    }
    get firstName() {
        return this.object.get("first_name");
    }
    get lastName() {
        return this.object.get("last_name");
    }
    get billAddress() {
        return this.object.get("bill_address");
    }
    get provinceForm() {
        return this.object.get("province");
    }
    get districtForm() {
        return this.object.get("district");
    }
    get subDistrictForm() {
        return this.object.get("sub_district");
    }
    get postalForm() {
        return this.object.get("postal");
    }
    get taxNumber() {
        return this.object.get("tax_number");
    }

    get postalValue() {
        return this.object.get("postal").value;
    }

    constructor(
        private _localService: LocalfileService,
        private billing: BillingInformationService,
        private _snackBar: SnackBarAlertService,
        private _dialogRef: MatDialogRef<TopUpDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        private _data: {
            data: BillingInformation;
            amount: number;
        }
    ) {
        this._subscription = new Subscription();

        this.object = new FormGroup({
            _id: new FormControl(this._data?.data?._id ?? ""),
            user_id: new FormControl(this._data?.data?.user_id ?? ""),
            type: new FormControl(this._data?.data?.type ?? "personal", [Validators.required]),
            first_name: new FormControl(this._data?.data?.first_name ?? "", [Validators.required]),
            last_name: new FormControl(this._data?.data?.last_name ?? "", [Validators.required]),
            company: new FormControl(this._data?.data?.company ?? ""),
            bill_address: new FormControl(this._data?.data?.bill_address ?? "", [Validators.required]),
            province: new FormControl(this._data?.data?.province ?? "", [Validators.required]),
            district: new FormControl(this._data?.data?.district ?? "", [Validators.required]),
            sub_district: new FormControl(this._data?.data?.sub_district ?? "", [Validators.required]),
            postal: new FormControl(this._data?.data?.postal ?? "", [Validators.required]),
            phone: new FormControl(this._data?.data?.phone ?? "", [Validators.required]),
            email: new FormControl(this._data?.data?.email ?? "", [Validators.required, Validators.email]),
            tax_number: new FormControl(this._data?.data?.tax_number ?? "", [Validators.required]),
            holding_tax: new FormControl(false),
        });

        this._updateCompanyValidity();
        this._subscription.add(this.object.get("type").valueChanges.subscribe((_) => this._updateCompanyValidity()));
        if (this.checkDisable < 1000) this.object.get("holding_tax").disable();

        this._subscription.add(
            this._localService.getAddressList().subscribe((response) => {
                this.province = response;
                if (this.province) {
                    this.filteredProvince = this.object.get("province").valueChanges.pipe(
                        startWith(""),
                        map((value) => this._filter(value))
                    );
                }
            })
        );
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    private _filter(value: string): string[] {
        const filterValue = value;
        return this.province.filter((x) => x[0].includes(filterValue)).map((x) => x[0]);
    }

    private _filterDistrict(value: string): string[] {
        const filterValue = value;
        return this.district.filter((x) => x[0].includes(filterValue)).map((x) => x[0]);
    }
    private _filterSubDistrict(value: string): string[] {
        const filterValue = value;
        return this.subDistrict.filter((x) => x[0].includes(filterValue)).map((x) => x[0]);
    }
    private _filterPostal(value: string): number[] {
        const filterValue = value;
        return this.postal.filter((x) => x.toString().includes(filterValue));
    }

    onSelectProvince = (): void => {
        this.province.filter((x) => {
            if (x[0] === this.provinceForm.value) {
                this.district = x[1];
            }
            this.filteredDistrict = this.object.get("district").valueChanges.pipe(
                startWith(""),
                map((value) => this._filterDistrict(value))
            );
        });
        this.object.get("district").reset();
        this.object.get("sub_district").reset();
        this.object.get("postal").reset();
    };

    onSelectDistrict = (): void => {
        this.district.filter((x) => {
            if (x[0] === this.districtForm.value) {
                this.subDistrict = x[1];
            }
            this.filteredSubDistrict = this.object.get("sub_district").valueChanges.pipe(
                startWith(""),
                map((value) => this._filterSubDistrict(value))
            );
        });
        this.object.get("sub_district").reset();
        this.object.get("postal").reset();
    };

    onSelectSubDistrict = (): void => {
        this.subDistrict.filter((x) => {
            if (x[0] == this.subDistrictForm.value) {
                this.postal = x[1];
                if (this.postal.length == 1) this.object.get("postal").patchValue(x[1][0]);
            }
            this.filteredPostal = this.object.get("postal").valueChanges.pipe(
                startWith(""),
                map((value) => this._filterPostal(value))
            );
        });
    };

    private _updateCompanyValidity = (): void => {
        switch (this.type.value) {
            case "company":
                this.object.get("company").setValidators([Validators.required]);
                this.object.get("company").updateValueAndValidity();
                break;
            default:
                this.object.get("company").setValidators([]);
                this.object.get("company").updateValueAndValidity();
        }
        this.object.get("holding_tax").patchValue(false);
    };

    onSubmit(): void {
        if (this.object.invalid) {
            if (this.firstName.errors) return this._snackBar.open("error", getI18nState(I18nState.ERR_FIRST_NAME_REQUIRED));
            if (this.lastName.errors) return this._snackBar.open("error", getI18nState(I18nState.ERR_LAST_NAME_REQUIRED));
            if (this.billAddress.errors) return this._snackBar.open("error", getI18nState(I18nState.ERR_BILL_ADDRESS_REQUIRED));
            if (this.provinceForm.errors) return this._snackBar.open("error", getI18nState(I18nState.ERR_PROVINCE_REQUIRED));
            if (this.districtForm.errors) return this._snackBar.open("error", getI18nState(I18nState.ERR_DIST_REQUIRED));
            if (this.subDistrictForm.errors) return this._snackBar.open("error", getI18nState(I18nState.ERR_SUB_DIST_REQUIRED));
            if (this.postalForm.errors) return this._snackBar.open("error", getI18nState(I18nState.ERR_POSTAL_REQUIRED));
            if (this.taxNumber.errors) return this._snackBar.open("error", getI18nState(I18nState.ERR_VAT_REQUIRED));
        }

        this.object.get("postal").patchValue(this.postalValue.toString());

        this._subscription.add(
            of(!!this.object.value?._id)
                .pipe(
                    switchMap((state) => {
                        if (state) return this.billing.$updateDetailsInfo(this.object.value);
                        return this.billing.$addDetailsInfo(this.object.value);
                    })
                )
                .subscribe(
                    (_) => this._dialogRef.close(this.object.value),
                    (error) => {
                        console.error(error);
                        this._snackBar.open("error", getI18nState(I18nState.GLOBAL_ERR_MSG));
                    }
                )
        );
    }
}
