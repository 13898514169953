<ng-container [ngSwitch]="awaitPlatform | async">
    <ng-container *ngSwitchCase="true">
        <div *ngIf="(awaitRegister | async) && htmlMessage && !(awaitWebchatRoute | async)" class="notification-container">
            <div [innerHTML]="htmlMessage | safeHTML" class="d-flex align-items-center text-white"></div>

            <button mat-icon-button (click)="onCloseNotificationMessage()">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <div
            class="page-container"
            [ngStyle]="{
                'height': pageContainerHeight
            }"
        >
            <router-outlet *ngIf="awaitRegister | async"></router-outlet>

            <ng-container *ngIf="(awaitRegister | async) && (isLoggedIn$ | async)">
                <a class="chat-bubble-button" (click)="onToggleChat()">
                    <mat-icon svgIcon="botnoi-logo"></mat-icon>
                </a>
                <div #customerChat class="chat-container bn_customer_chat_bounce_out">
                    <router-outlet *ngIf="openChat" name="chat"></router-outlet>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="false">
        <div class="not-supported-container">
            <mat-icon class="mb-2">error_outline</mat-icon>
            <h2 class="mb-1">Browser not supported</h2>
            <h4 class="mb-0">Web browser does not support this screen size.</h4>
            <h4 class="mb-0">Please use a device with a larger screen.</h4>
        </div>
    </ng-container>
</ng-container>
