import { Directive, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, ViewContainerRef, TemplateRef } from "@angular/core";
import { style, animate, AnimationBuilder, AnimationMetadata } from "@angular/animations";
import { Overlay, OverlayRef, CdkOverlayOrigin } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { Subscription, Subject, fromEvent, timer } from "rxjs";
import { debounceTime } from "rxjs/operators";

type MarkFunctionProperties<Directive> = {
    [Key in keyof Directive]: Directive[Key] extends Function ? never : Key;
};
type ExcludeFunctionPropertyNames<T> = MarkFunctionProperties<T>[keyof T];
type ExcludeFunctions<T> = Pick<T, ExcludeFunctionPropertyNames<T>>;
type NgChanges<Directive, Props = ExcludeFunctions<Directive>> = {
    [Key in keyof Props]: {
        previousValue: Props[Key];
        currentValue: Props[Key];
        firstChange: boolean;
        isFirstChange(): boolean;
    };
};

const ANIMATION_TIMINGS = "50ms cubic-bezier(0.25, 0.8, 0.25, 1)";

@Directive({
    selector: "ng-template[bnTooltip]",
})
export class BnTooltipDirective implements OnInit, OnChanges, OnDestroy {
    @Input() origin: CdkOverlayOrigin;
    @Input() overlayOpen: boolean = undefined;
    @Input() verticalAlign: "top" | "center" | "bottom" = "bottom";
    @Input() horizontalAlign: "start" | "center" | "end" = "center";
    @Input() hasBackdrop: boolean = false;
    @Input() backdropClass: string = "cdk-overlay-transparent-backdrop";
    @Input() showDealay: number = 0;
    @Input() hideDelay: number = 0;

    @Output() backdropClick: EventEmitter<MouseEvent> = new EventEmitter();
    @Output() outsideClick: EventEmitter<MouseEvent> = new EventEmitter();
    @Output() overlayKeydown: EventEmitter<KeyboardEvent> = new EventEmitter();

    get isToggle() {
        return this.overlayOpen !== undefined;
    }

    private _subscription: Subscription;
    private _subscriptionOverlay: Subscription;
    private _overlayRef: OverlayRef;
    private changeMouseOnState: Subject<boolean>;

    private _isOpen: boolean;
    get isOpen() {
        return this._isOpen;
    }

    private _mouseonState: boolean;

    constructor(
        private _builder: AnimationBuilder,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _templateRef: TemplateRef<unknown>
    ) {
        this._subscription = new Subscription();
        this.changeMouseOnState = new Subject();

        this._isOpen = false;
        this._mouseonState = false;
    }

    ngOnInit(): void {
        if (!!this.origin) {
            this._detectChangeMouseOnState();
            if (!this.isToggle) this._detectMouseOnOriginElement();
        }
    }

    ngOnChanges(changes: NgChanges<BnTooltipDirective>): void {
        if (!!changes?.origin) {
            if (!changes?.origin.isFirstChange()) {
                if (!!changes.origin.currentValue) {
                    this.ngOnInit();
                }
            }
        }

        if (!!changes?.overlayOpen) {
            if (!changes?.overlayOpen.isFirstChange()) {
                if (changes.overlayOpen.previousValue !== changes.overlayOpen.currentValue) {
                    this._onToggleOverlay(changes.overlayOpen.currentValue);
                }
            }
        }
    }

    ngOnDestroy(): void {
        if (!!this._overlayRef) this._overlayRef.detach();
        this._subscription.unsubscribe();
        if (!!this._subscriptionOverlay) this._subscriptionOverlay.unsubscribe();
    }

    private _onToggleOverlay = (state: boolean): void => {
        switch (state) {
            case true:
                this.show();
                break;
            default:
                this.hide();
        }
    };

    private _detectMouseOnOriginElement = (): void => {
        this._subscription.add(
            fromEvent(this.origin.elementRef.nativeElement, "mouseenter").subscribe(() => {
                this.changeMouseOnState.next(true);
            })
        );

        this._subscription.add(
            fromEvent(this.origin.elementRef.nativeElement, "mouseleave").subscribe(() => {
                this.changeMouseOnState.next(false);
            })
        );
    };

    private _detectMouseOnOverlayElement = (): void => {
        if (!!this._subscriptionOverlay) this._subscriptionOverlay.unsubscribe();
        this._subscriptionOverlay = new Subscription();

        if (!this.isToggle) {
            this._subscriptionOverlay.add(
                fromEvent(this._overlayRef.overlayElement, "mouseenter").subscribe(() => {
                    this.changeMouseOnState.next(true);
                })
            );

            this._subscriptionOverlay.add(
                fromEvent(this._overlayRef.overlayElement, "mouseleave").subscribe(() => {
                    this.changeMouseOnState.next(false);
                })
            );
        }
    };

    private _detectChangeMouseOnState = (): void => {
        this._subscription.add(
            this.changeMouseOnState.pipe(debounceTime(100)).subscribe((state) => {
                this._mouseonState = state;
                this._onToggleOverlay(this._mouseonState);
            })
        );
    };

    private get getOriginPosition(): { originX: "start" | "center" | "end"; originY: "top" | "center" | "bottom" } {
        return {
            originY: this.verticalAlign,
            originX: this.horizontalAlign,
        };
    }

    private get getOverlayPosition(): { overlayX: "start" | "center" | "end"; overlayY: "top" | "center" | "bottom" } {
        return {
            overlayY: this.verticalAlign == "top" ? "bottom" : this.verticalAlign == "bottom" ? "top" : "center",
            overlayX: this.horizontalAlign == "start" ? "end" : this.horizontalAlign == "end" ? "start" : "center",
        };
    }

    private fadeIn(): AnimationMetadata[] {
        return [style({ opacity: 0 }), animate(ANIMATION_TIMINGS, style({ opacity: 1 }))];
    }

    private fadeOut(): AnimationMetadata[] {
        return [style({ opacity: "*" }), animate(ANIMATION_TIMINGS, style({ opacity: 0 }))];
    }

    show = (): void => {
        this._subscription.add(
            timer(this.showDealay).subscribe(() => {
                this._isOpen = true;

                if (!!this._overlayRef) if (this._overlayRef.hasAttached()) return;

                this._overlayRef = this._overlay.create({
                    panelClass: "bn-tooltip-overlay",
                    positionStrategy: this._overlay
                        .position()
                        .flexibleConnectedTo(this.origin.elementRef)
                        .withPositions([
                            {
                                ...this.getOriginPosition,
                                ...this.getOverlayPosition,
                            },
                        ]),
                });

                this._overlayRef.attach(new TemplatePortal(this._templateRef, this._viewContainerRef));
                const factory = this._builder.build(this.fadeIn());
                const player = factory.create(this._overlayRef.overlayElement);
                player.play();

                this._detectMouseOnOverlayElement();
            })
        );
    };

    hide = (): void => {
        this._subscription.add(
            timer(this.hideDelay).subscribe(() => {
                this._isOpen = false;

                if (!!this._overlayRef) {
                    if (!this._overlayRef.hasAttached()) return;

                    const factory = this._builder.build(this.fadeOut());
                    const player = factory.create(this._overlayRef.overlayElement);
                    player.play();

                    timer(50).subscribe(() => this._overlayRef.detach());
                }
            })
        );
    };

    onBackdropClick = ($event: MouseEvent): void => {
        this.backdropClick.emit($event);
    };

    onOutsideClick = ($event: MouseEvent): void => {
        this.outsideClick.emit($event);
    };

    onOverlayKeydown = ($event: KeyboardEvent): void => {
        this.overlayKeydown.emit($event);
    };
}
