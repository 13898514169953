import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { Observable, throwError } from "rxjs";
import { map, catchError, shareReplay, retry } from "rxjs/operators";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { BotProfile } from "@core/models/bot";
import { LocalService } from "@core/services/cache/local.service";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

@Injectable()
export class ManageBotRequestService {
    get url() {
        return `${API_GATEWAYS_URL}platform-go/bot`;
    }

    constructor(private _http: HttpRequest, private _local: LocalService) {}

    $getBotList = (reset?: boolean): Observable<Array<BotProfile>> => {
        if (reset) this._local.delete(this.url.toString());

        return this._http
            .get<{ data: any }>({
                url: this.url,
            })
            .pipe(
                shareReplay(1),
                retry(genericRetry),
                map((response) => response.data.map((res: any) => new BotProfile().deserialize(res))),
                catchError((error) => throwError(() => error))
            );
    };

    $getBotDetail = (_id: string, reset?: boolean): Observable<BotProfile> => {
        if (reset) this._local.delete(this.url.toString());

        return this._http
            .get<{ data: any }>({
                url: this.url,
            })
            .pipe(
                shareReplay(1),
                retry(genericRetry),
                map((response) => response.data.filter((res: any) => res._id == _id).map((res: any) => new BotProfile().deserialize(res))[0]),
                catchError((error) => throwError(() => error))
            );
    };

    $postCreateBot = (body: BotProfile): Observable<{ _id: string; status: string }> => {
        return this._http
            .post<any>({
                url: this.url,
                body: body,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $updateBotDetail = (body: BotProfile): Observable<{ message: string }> => {
        return this._http
            .put<any>({
                url: this.url,
                body: body,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $deleteBot = (_id: string): Observable<{ status: string }> => {
        return this._http
            .delete<any>({
                url: this.url,
                body: {
                    _id: _id,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $generatePublicKey = (_id: string): Observable<{ key: string }> => {
        return this._http
            .post<any>({
                url: `${API_GATEWAYS_URL}platform-go/bot/generate-clone-key`,
                body: {
                    bot_id: _id,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $validatePublicKey = (_id: string, key: string): Observable<any> => {
        return this._http
            .post<any>({
                url: `${API_GATEWAYS_URL}platform-go/bot/clone`,
                body: {
                    bot_id: _id,
                    key: key,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $updateChannelActive = (_id: string, channel: string, status: boolean): Observable<any> => {
        return this._http
            .put<any>({
                url: `${API_GATEWAYS_URL}platform-go/bot/channel-active-status`,
                body: {
                    bot_id: _id,
                    channel: channel,
                    status: status,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };
}
