<div class="d-block p-relative px-4">
    <button mat-mini-fab bn-small-button mat-dialog-close bn-dialog-close color="primary">
        <mat-icon svgIcon="close"></mat-icon>
    </button>

    <h2 class="text-center mb-4">{{ title }}</h2>

    <ng-container [ngSwitch]="loading">
        <div *ngSwitchCase="true" class="d-flex flex-center mb-4">
            <div class="loader"></div>
            <state-i18n key="LABEL_LOADING"></state-i18n>
        </div>

        <ng-container *ngSwitchDefault>
            <ng-container [ngSwitch]="isFreePlan && !!(feature)">
                <p *ngSwitchCase="true" class="mb-0">{{ freePackageToggleAddOnMessage }}</p>

                <ul *ngSwitchDefault>
                    <li [innerHTML]="warningAddOnPricingMessage" class="mb-1"></li>
                    <li [innerHTML]="convertPackageDaysMessage" class="mb-1"></li>
                    <li *ngIf="!feature" [innerHTML]="upgradePackageWarningMessage"></li>
                </ul>
            </ng-container>
        </ng-container>
    </ng-container>
</div>

<mat-dialog-actions align="center">
    <button data-cy="cancel-confirm-dialog-button" mat-stroked-button mat-dialog-close color="primary">{{ cancelLabel }}</button>
    <button data-cy="confirm-dialog-button" mat-raised-button color="primary" (click)="onUpgrade()" [disabled]="loadingData">
        <div *ngIf="fetching" class="loader"></div>
        {{ submitLabel }}
    </button>
</mat-dialog-actions>
