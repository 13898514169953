import { Deserializable } from "../deserializable.model";
import jwt_decode from "jwt-decode";

export class Profile implements Deserializable {
    _id: string;
    access_token: string;
    create_by: string;
    first_name: string;
    last_name: string;
    username: string;
    email: string;
    user_facebook_access_token: string;
    google_link_accounts: Array<{ email: string }>;

    deserialize(input: any) {
        Object.assign(this, input);
        if (!!this.access_token?.length) {
            const jwt: any = jwt_decode(this.access_token);
            this._id = jwt?.identity;
        }
        return this;
    }

    get fullname(): string {
        return `${new String(`${!!this.first_name?.length ? this.first_name : ""} ${!!this.last_name?.length ? this.last_name : ""}`).trim()}`;
    }

    get isGoogleSignIn(): boolean {
        return !!this.google_link_accounts ? this.google_link_accounts.length > 0 : false;
    }
}
