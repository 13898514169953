import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import * as CryptoJS from "crypto-js";
import * as moment from "moment";
import { environment as env } from "@env/environment";
import { BotDetail, UserDetail, Messages, MessageTemplate, ChatlogMessage } from "@core/models/customer-chat/customer-chat.model";
import { LocalService } from "@core/services/cache/local.service";

const EncyptKey: string = env.key;
const API_GATEWAYS_URL: string = env.apiGatewaysURL;

@Injectable()
export class CustomerChatService {
    private _bft: string;
    private _bfid: string;

    constructor(private _http: HttpClient, private _cookie: CookieService, private _local: LocalService) {
        this._bft = null;
        this._bfid = null;
    }

    private _setLocalStorage = (
        type: "messaging_plugin_bot" | "messaging_plugin_urs" | "messaging_plugin_mss",
        bot_id: string,
        data: any,
        uid?: string
    ): void => {
        const expire = new Date().getTime() + 24 * 60 * 60 * 1000;
        const record = CryptoJS.AES.encrypt(JSON.stringify(data), EncyptKey).toString();
        const timestamp = new Date().getTime();

        this._cookie.set(`${type}_${bot_id}${uid ? `_${uid}` : ""}`, timestamp.toString(), new Date(expire), "/customer-chat", null, true);

        if (!this._cookie.check(`${type}_${bot_id}${uid ? `_${uid}` : ""}`)) {
            try {
                this._local.set(
                    `${type}_${bot_id}${uid ? `_${uid}` : ""}`,
                    JSON.stringify({
                        expire: new Date(expire),
                        value: record,
                    })
                );
            } catch {
                console.error(`${type}_${bot_id}${uid ? `_${uid}` : ""} ? : cant set localStorage`);
            }
        } else {
            try {
                this._local.set(timestamp.toString(), record);
            } catch {
                this._clearLocalStorage(type, bot_id, uid);
                console.error(`${type}_${bot_id}${uid ? `_${uid}` : ""} ? : cant set localStorage`);
            }
        }
    };

    private _loadLocalStorage = (type: "messaging_plugin_bot" | "messaging_plugin_urs" | "messaging_plugin_mss", bot_id: string, uid?: string): any => {
        const key = this._cookie.get(`${type}_${bot_id}${uid ? `_${uid}` : ""}`);

        if (key) {
            let item: string = null;
            try {
                item = CryptoJS.AES.decrypt(this._local.get(key), EncyptKey).toString(CryptoJS.enc.Utf8);
            } catch (e) {
                console.error(e);
            }

            const record = JSON.parse(item);
            if (!record || !this._cookie.check(`${type}_${bot_id}${uid ? `_${uid}` : ""}`)) {
                this._clearLocalStorage(type, bot_id);
                return null;
            } else {
                this._clearLocalStorage(type, bot_id, uid);
                this._setLocalStorage(type, bot_id, record);
                return record;
            }
        } else {
            let local: string = null;
            try {
                local = this._local.get(`${type}_${bot_id}${uid ? `_${uid}` : ""}`);
            } catch (e) {
                console.error(e);
            }

            if (local) {
                const record = JSON.parse(local);
                if (!record) {
                    this._clearLocalStorage(type, bot_id);
                    return null;
                } else {
                    if (new Date(record.expire).getTime() > new Date().getTime()) {
                        const value = JSON.parse(CryptoJS.AES.decrypt(record.value, EncyptKey).toString(CryptoJS.enc.Utf8));
                        this._setLocalStorage(type, bot_id, value);
                        return value;
                    } else {
                        this._clearLocalStorage(type, bot_id);
                        return null;
                    }
                }
            }
        }
        return null;
    };

    private _clearLocalStorage = (type: "messaging_plugin_bot" | "messaging_plugin_urs" | "messaging_plugin_mss", bot_id: string, uid?: string): void => {
        if (this._cookie.check(`${type}_${bot_id}${uid ? `_${uid}` : ""}`)) {
            const key = this._cookie.get(`${type}_${bot_id}${uid ? `_${uid}` : ""}`);
            this._cookie.delete(`${type}_${bot_id}${uid ? `_${uid}` : ""}`, "/customer-chat");
            this._local.delete(key);
        } else {
            this._local.delete(`${type}_${bot_id}${uid ? `_${uid}` : ""}`);
        }
    };

    private get _uuname() {
        return `${Math.floor(1000 + Math.random() * 9000)}`;
    }

    private _groupingMessages = (chatlog: Array<ChatlogMessage>): Array<Messages> => {
        let messages = [];
        let lastmessage = messages.slice(-1)[0];
        let length = messages.length;

        chatlog.forEach((mss) => {
            if (!lastmessage) {
                lastmessage = new Messages().deserialize({
                    role: mss.role,
                    timestamp: new Date(mss.time),
                    messages: [mss.messageTemplate],
                });
                messages.push(lastmessage);
            } else {
                if (lastmessage.role == mss.role && mss.time.getTime() - lastmessage.timestamp.getTime() < 30000) {
                    const template = new MessageTemplate().deserialize({
                        ...mss.messageTemplate,
                        timestamp: new Date(mss.time),
                    });
                    messages[length - 1].appendMessage(template);
                } else {
                    lastmessage = new Messages().deserialize({
                        role: mss.role,
                        timestamp: new Date(mss.time),
                        messages: [mss.messageTemplate],
                    });
                    messages.push(lastmessage);
                }
            }
            length = messages.length;

            if (messages.length > 1) {
                const m_1 = moment(messages[messages.length - 2].timestamp);
                const m_2 = moment(messages[messages.length - 1].timestamp);
                const diff_min = m_2.diff(m_1, "minute");
                if (diff_min == 0) messages[messages.length - 1].onToggleShowTime(false);
            }
        });

        return messages;
    };

    $getBotDetail = (_id: string): Observable<BotDetail> => {
        if (!this._loadLocalStorage("messaging_plugin_bot", _id)) {
            return this._http.get<any>(`${API_GATEWAYS_URL}webchat/config?bot_id=${_id}`).pipe(
                map((response) => {
                    this._setLocalStorage("messaging_plugin_bot", _id, response);
                    return new BotDetail().deserialize(response);
                }),
                catchError((error) => throwError(() => error))
            );
        }
        const bot = new BotDetail().deserialize(this._loadLocalStorage("messaging_plugin_bot", _id));

        return of(bot);
    };

    getUserSession = (_id: string): UserDetail => {
        const messaging_plugin_urs = this._loadLocalStorage("messaging_plugin_urs", _id);
        return messaging_plugin_urs ? new UserDetail().deserialize(messaging_plugin_urs) : null;
    };

    hasUserSession = (_id: string, uid: string): boolean => {
        const messaging_plugin_urs = this._loadLocalStorage("messaging_plugin_urs", _id);
        if (!messaging_plugin_urs) return false;
        if (uid) return uid == messaging_plugin_urs.uid ? true : false;
        return messaging_plugin_urs ? true : false;
    };

    $getCurrentMessageSession = (bot_id: string, timestamp: number): Observable<{ messages: Array<Messages> }> => {
        return this._http
            .get<any>(`${API_GATEWAYS_URL}webchat/lastest-msg?timestamp=${timestamp.toString()}`, {
                headers: new HttpHeaders().append("Authorization", `Bearer ${this.getUserSession(bot_id).access_token}`),
            })
            .pipe(
                map((response) => {
                    const chatlog: Array<ChatlogMessage> = response.data.map((data: any) =>
                        new ChatlogMessage().deserialize({
                            role: data.sender.sender_type == "customer" ? "user" : "admin",
                            time: data.message.timestamp,
                            message: data.message,
                        })
                    );

                    let messages = this._groupingMessages(chatlog);
                    return {
                        messages: messages,
                    };
                }),
                catchError((error) => throwError(() => error))
            );
    };

    $getLastMessageSession = (bot_id: string): Observable<{ messages: Array<Messages>; next: boolean }> => {
        return this._http
            .get<any>(`${API_GATEWAYS_URL}webchat/msg${this._bft && this._bfid ? `?bft=${this._bft}&bfid=${this._bfid}` : ""}`, {
                headers: new HttpHeaders().append("Authorization", `Bearer ${this.getUserSession(bot_id).access_token}`),
            })
            .pipe(
                map((response) => {
                    this._bft = response.next ? response.next.bft : null;
                    this._bfid = response.next ? response.next.bfid : null;
                    const chatlog: Array<ChatlogMessage> = response.data.map((data: any) =>
                        new ChatlogMessage().deserialize({
                            role: data.sender.sender_type == "customer" ? "user" : "admin",
                            time: data.message.timestamp,
                            message: data.message,
                        })
                    );

                    let messages = this._groupingMessages(chatlog);
                    return {
                        messages: messages,
                        next: response.next ? true : false,
                    };
                }),
                catchError((error) => throwError(() => error))
            );
    };

    $initSessionUser = (_id: string, lang: "en" | "th", uid: string, profile_image?: string, name?: string): Observable<UserDetail> => {
        const messaging_plugin_urs = this._loadLocalStorage("messaging_plugin_urs", _id);

        if (!messaging_plugin_urs) {
            return this._http
                .post<any>(`${API_GATEWAYS_URL}webchat/user`, {
                    bot_id: _id,
                    uid: uid,
                    profile_image: profile_image ? profile_image : "",
                    display_name: name ? name : lang == "en" ? `Guest ${this._uuname}` : `ผู้ได้รับเชิญ ${this._uuname}`,
                })
                .pipe(
                    map((response) => {
                        this._setLocalStorage("messaging_plugin_urs", _id, {
                            ...response.user_info,
                            access_token: response.access_token,
                        });
                        return new UserDetail().deserialize({
                            ...response.user_info,
                            access_token: response.access_token,
                        });
                    }),
                    catchError((error) => throwError(() => error))
                );
        }

        if (messaging_plugin_urs.uid !== _id) {
            this._clearLocalStorage("messaging_plugin_urs", _id);
            return this._http
                .post<any>(`${API_GATEWAYS_URL}webchat/user`, {
                    bot_id: _id,
                    uid: uid,
                    profile_image: profile_image ? profile_image : "",
                    display_name: name ? name : lang == "en" ? `Guest ${this._uuname}` : `ผู้ได้รับเชิญ ${this._uuname}`,
                })
                .pipe(
                    map((response) => {
                        this._setLocalStorage("messaging_plugin_urs", _id, {
                            ...response.user_info,
                            access_token: response.access_token,
                        });
                        return new UserDetail().deserialize({
                            ...response.user_info,
                            access_token: response.access_token,
                        });
                    }),
                    catchError((error) => throwError(() => error))
                );
        }

        return of(new UserDetail().deserialize(messaging_plugin_urs));
    };

    $initSessionGuestUser = (_id: string, lang: "en" | "th"): Observable<UserDetail> => {
        this._bft = null;
        this._bfid = null;

        if (!this._loadLocalStorage("messaging_plugin_urs", _id)) {
            return this._http
                .post<any>(`${API_GATEWAYS_URL}webchat/user`, {
                    bot_id: _id,
                    display_name: lang == "en" ? `Guest ${this._uuname}` : `ผู้ได้รับเชิญ ${this._uuname}`,
                })
                .pipe(
                    map((response) => {
                        this._setLocalStorage("messaging_plugin_urs", _id, {
                            ...response.user_info,
                            access_token: response.access_token,
                        });
                        return new UserDetail().deserialize({
                            ...response.user_info,
                            access_token: response.access_token,
                        });
                    }),
                    catchError((error) => throwError(() => error))
                );
        }

        return of(new UserDetail().deserialize(this._loadLocalStorage("messaging_plugin_urs", _id)));
    };

    $removeSessionUser = (_id: string): Observable<{ status: string; message: string }> => {
        return of({ status: "success", message: "success" }).pipe(
            map((response) => {
                this._clearLocalStorage("messaging_plugin_urs", _id);
                return response;
            }),
            catchError((error) => throwError(() => error))
        );
    };
}
