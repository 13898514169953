<navigation-bar #navbar> </navigation-bar>

<side-bar [expand]="navbar.$expand | async">
    <div class="drawer-content">
        <router-outlet></router-outlet>
    </div>
    <ng-container *ngIf="featureAutomateTest">
        <auto-test></auto-test>
    </ng-container>
</side-bar>
