<mat-tab-group mat-align-tabs="start">
    <mat-tab label="Use Parameter">
        <div class="d-flex flex-column px-1 py-1">
            <h3 i18n="@@parameter">Parameter</h3>
            <ng-container [ngSwitch]="userParams.length > 0 && !fetching">
                <div *ngSwitchCase="true" class="d-grid grid-col-3 param-container">
                    <button
                        *ngFor="let item of userParams"
                        mat-stroked-button
                        bn-small-button
                        color="primary"
                        class="round-button"
                        (click)="onSelectedItem(item, 'use')"
                    >
                        {{ useParam(item) }}
                    </button>
                </div>

                <div *ngSwitchDefault class="d-flex align-items-center">
                    <div *ngIf="fetching" class="loader"></div>
                    <ng-container [ngSwitch]="fetching">
                        <state-i18n *ngSwitchCase="true" key="LABEL_LOADING"></state-i18n>
                        <state-i18n *ngSwitchDefault key="LABEL_NO_DATA"></state-i18n>
                    </ng-container>
                </div>
            </ng-container>
        </div>

        <div class="d-flex flex-column px-1 py-1">
            <h3 i18n="@@global-parameter-title">Global Parameter</h3>
            <ng-container [ngSwitch]="globalParams.length > 0 && !fetching">
                <div *ngSwitchCase="true" class="d-grid grid-col-3 param-container">
                    <button
                        *ngFor="let item of globalParams"
                        mat-stroked-button
                        bn-small-button
                        color="primary"
                        class="round-button"
                        (click)="onSelectedItem(item, 'use')"
                    >
                        {{ useParam(item) }}
                    </button>
                </div>

                <div *ngSwitchDefault class="d-flex align-items-center">
                    <div *ngIf="fetching" class="loader"></div>
                    <ng-container [ngSwitch]="fetching">
                        <state-i18n *ngSwitchCase="true" key="LABEL_LOADING"></state-i18n>
                        <state-i18n *ngSwitchDefault key="LABEL_NO_DATA"></state-i18n>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </mat-tab>
    <mat-tab label="Add Parameter">
        <div class="d-flex flex-column px-1 py-1">
            <bn-form-field #form class="min-width-500">
                <input
                    i18n-placeholder="@@add-new-parameter"
                    #input
                    bnInput
                    type="text"
                    placeholder="Add a new parameter"
                    (keydown)="onKeyDownDetectEnter($event, 'add'); onTypingAddParam(tooltip)"
                />
                <button
                    i18n-matTooltip="press-enter-to-add"
                    bnSuffix
                    mat-raised-button
                    bn-small-button
                    color="primary"
                    class="my-5-px"
                    #tooltip="matTooltip"
                    matTooltip="Press “Enter” to add"
                    matTooltipPosition="above"
                    (click)="appendAddParam(input, form)"
                >
                    Add
                </button>
            </bn-form-field>
        </div>

        <div class="d-flex flex-column px-1 py-1">
            <h3 i18n="@@parameter">Parameter</h3>
            <ng-container [ngSwitch]="userParams.length > 0 && !fetching">
                <div *ngSwitchCase="true" class="d-grid grid-col-3 param-container">
                    <button
                        *ngFor="let item of userParams; index as i"
                        mat-stroked-button
                        bn-small-button
                        color="primary"
                        class="round-button"
                        (click)="onSelectedItem(item, 'add')"
                    >
                        <span class="text-overflow"> {{ addParam(item) }} </span>
                        <mat-icon svgIcon="close" class="ml-1" (click)="$event.stopPropagation(); removeAddParam(i)"></mat-icon>
                    </button>
                </div>

                <div *ngSwitchDefault class="d-flex align-items-center">
                    <div *ngIf="fetching" class="loader"></div>
                    <ng-container [ngSwitch]="fetching">
                        <state-i18n *ngSwitchCase="true" key="LABEL_LOADING"></state-i18n>
                        <state-i18n *ngSwitchDefault key="LABEL_NO_DATA"></state-i18n>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </mat-tab>
</mat-tab-group>
