<input *ngIf="label.length === 0" class="bn-input-element" [placeholder]="placeholder" disabled />

<div class="bn-select-trigger" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <div class="bn-select-value">
        <span [innerHTML]="label | safeHTML"></span>
    </div>
    <div class="select-arrow-wrapper">
        <mat-icon svgIcon="down" [@rotateContent]="openPanel ? 'open' : 'close'"></mat-icon>
    </div>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayHasBackdrop]="true"
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    [cdkConnectedOverlayOpen]="openPanel"
    (backdropClick)="onToggleClose()"
    (overlayKeydown)="overlayKeydown($event)"
>
    <div class="bn-option-container" [ngStyle]="{'width': containerWidth ? containerWidth : 'auto'}" [@slideContent]="openPanel ? 'enter' : 'leave'">
        <ng-content select="bn-option"></ng-content>
    </div>
</ng-template>
