import { Deserializable } from "../deserializable.model";

export class Login implements Deserializable {
    authentication: boolean;
    username: string;
    email: string;
    access_token: string;
    create_by: string;
    user_facebook_access_token: string;
    first_name: string;
    last_name: string;
    google_link_accounts: Array<{ email: string }>;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
