import { Component, Inject, OnInit } from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {BotProfile} from "@core/models/bot";

@Component({
    selector: "app-contact-support-dialog",
    templateUrl: "./contact-support-dialog.component.html",
    styleUrls: ["./contact-support-dialog.component.scss"],
})
export class ContactSupportDialogComponent implements OnInit {
    get planName() {
        return this._data.current_plan.plan.product_name 
    }
    constructor(
        @Inject(MAT_DIALOG_DATA)
        private _data: BotProfile
    ) {}

    ngOnInit(): void {
    }
}
