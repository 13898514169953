import { Component, Renderer2, ElementRef, HostListener, ContentChild, AfterContentInit, AfterContentChecked, Input, Output, EventEmitter } from "@angular/core";
import { CdkOverlayOrigin } from "@angular/cdk/overlay";
import { MatDialog } from "@angular/material/dialog";
import { ConnectionPositionPair } from "@angular/cdk/overlay";
import { interval, timer, Subscription } from "rxjs";
import { tap, delay, filter, take } from "rxjs/operators";
import { BnInputDirective } from "@shared/directives/forms-element/bn-input.directive";
import { BnTextareaAutosizeDirective } from "@shared/directives/forms-element/bn-textarea-autosize.directive";
import { BnHintComponent } from "@shared/components/forms-element/bn-hint/bn-hint.component";
import { BnErrorComponent } from "@shared/components/forms-element/bn-error/bn-error.component";
import { BnSelectComponent } from "@shared/components/bn-select/bn-select/bn-select.component";
import { SelectObjectComponent } from "@shared/components/dialog/select-object/select-object.component";
import { ParamsDialogComponent } from "@shared/components/dialog/params-dialog/params-dialog.component";

@Component({
    selector: "bn-form-field",
    templateUrl: "./bn-form-field.component.html",
    styleUrls: ["./bn-form-field.component.scss"],
})
export class BnFormFieldComponent implements AfterContentInit, AfterContentChecked {
    @HostListener("document:click", ["$event"])
    private _ = ($event: any) => {
        const fieldFlex = (this.ele.nativeElement as HTMLElement).getElementsByClassName("bn-form-field-flex")[0];
        if (fieldFlex.contains($event.target)) {
            if (this._inputElement) {
                this._inputElement.focus();
            }

            if (this.bnSelect) {
                this.bnSelect.onToggleOpen();

                if (!this.ele.nativeElement.classList.contains("bn-focus") && !this.bnSelect.disabled)
                    this._renderer.addClass(this.ele.nativeElement, "bn-focus");

                return;
            }

            if (!this.ele.nativeElement.classList.contains("bn-focus")) this._renderer.addClass(this.ele.nativeElement, "bn-focus");
        } else {
            if (this.bnSelect) {
                if (!this.bnSelect.openPanel) {
                    if (this.ele.nativeElement.classList.contains("bn-focus")) this._renderer.removeClass(this.ele.nativeElement, "bn-focus");
                }
                return;
            }

            if (this.hasActions && !this.isOpenToggle) this.showActions = false;
        }
    };

    @ContentChild(BnInputDirective) private bnInput: BnInputDirective;
    @ContentChild(BnTextareaAutosizeDirective)
    private bnTextareaAutosize: BnTextareaAutosizeDirective;
    @ContentChild(BnHintComponent) private bnHint: BnHintComponent;
    @ContentChild(BnErrorComponent) private bnError: BnErrorComponent;
    @ContentChild(BnSelectComponent) private bnSelect: BnSelectComponent;

    @Input() focusInitial: boolean = false;
    @Input() color: "primary" | "accent" | "warn" = "primary";
    @Input() borderRadius: number | string = 10;
    @Input() backgroundColor: string = "transparent";
    @Input() hasObject: boolean = false;
    @Input() hasParam: boolean = false;
    @Input() hasEmoji: boolean = false;
    @Input() objectType: "object" | "intent" | "entity" | "richmenu";
    @Input() borderColor: string = undefined;

    @Output() afterClosedDialog: EventEmitter<{
        name: string;
        data: any;
    }> = new EventEmitter<{ name: string; data: any }>();
    @Output() onblur: EventEmitter<void> = new EventEmitter<void>();

    private _inputElement: HTMLInputElement | HTMLTextAreaElement;

    private _isError: boolean;
    set isError(state) {
        this._isError = state;
    }
    get isError() {
        return this._isError;
    }

    private _showActions: boolean;
    set showActions(state) {
        this._showActions = state;
    }
    get showActions() {
        return this._showActions;
    }

    get hasActions() {
        return this.hasObject || this.hasEmoji || this.hasParam;
    }

    get isWrapping() {
        return this.bnHint || this.bnError ? true : false;
    }

    private _onHoverTemplate: boolean;
    private $hoverEvent: Subscription;

    isOpenToggle: boolean;
    triggerOrigin: CdkOverlayOrigin;

    actoionWrapperPositions = [];

    overlayKeydown = ($event: KeyboardEvent): void => {
        if ($event.keyCode == 27) {
            this.onCloseEmoji();
        }
    };

    constructor(private ele: ElementRef, private _renderer: Renderer2, private _matDialog: MatDialog) {
        this._renderer.addClass(this.ele.nativeElement, "bn-form-field");
        this.isError = false;
        this.showActions = false;
        this.$hoverEvent = new Subscription();
        this._onHoverTemplate = false;
        this.isOpenToggle = false;
    }

    ngAfterContentInit() {
        if (this.color) {
            this._onSetColorPalette();
        }

        if ((this.ele.nativeElement as HTMLElement).getElementsByTagName("input")[0]) {
            this._inputElement = (this.ele.nativeElement as HTMLElement).getElementsByTagName("input")[0];
            this._onDetectDisabledInput();
        }

        if ((this.ele.nativeElement as HTMLElement).getElementsByTagName("textarea")[0]) {
            this._inputElement = (this.ele.nativeElement as HTMLElement).getElementsByTagName("textarea")[0];
            this._onDetectDisabledInput();
        }

        this._onSetInitComponent();
    }

    ngAfterContentChecked() {
        if (this.bnInput) {
            if (
                this._inputElement !==
                ((this.ele.nativeElement as HTMLElement).getElementsByTagName("input")[0] ||
                    (this.ele.nativeElement as HTMLElement).getElementsByTagName("textarea")[0])
            ) {
                if ((this.ele.nativeElement as HTMLElement).getElementsByTagName("input")[0]) {
                    this._inputElement = (this.ele.nativeElement as HTMLElement).getElementsByTagName("input")[0];
                    this._onDetectDisabledInput();
                }

                if ((this.ele.nativeElement as HTMLElement).getElementsByTagName("textarea")[0]) {
                    this._inputElement = (this.ele.nativeElement as HTMLElement).getElementsByTagName("textarea")[0];
                    this._onDetectDisabledInput();
                }

                this._onSetInitComponent();
            }
        }

        if (this.bnSelect) this._onDetectDisabledInput();
    }

    private _onSetInitComponent = (): void => {
        const subscript = (this.ele?.nativeElement as HTMLElement).getElementsByClassName("bn-form-field-subscript-wrapper")[0];

        if (this.bnInput) {
            this.bnInput.getStateFocus().subscribe((response: any) => {
                switch (response) {
                    case "focus":
                        this.$hoverEvent.unsubscribe();
                        this.$hoverEvent = new Subscription();

                        if (!this.ele.nativeElement.classList.contains("bn-focus")) this._renderer.addClass(this.ele.nativeElement, "bn-focus");
                        if (this.hasActions) timer(100).subscribe((_) => (this.showActions = true));
                        break;
                    case "valid":
                        if (this.isError) {
                            timer(0).subscribe((_) => {
                                if (!!subscript) {
                                    const hintWrapper = subscript?.getElementsByClassName("bn-form-field-hint-wrapper")[0];
                                    if (!!hintWrapper) {
                                        this._renderer.addClass(hintWrapper, "bn-form-feild-wrapper-animations");
                                        timer(100).subscribe((_) => {
                                            this._renderer.removeClass(hintWrapper, "bn-form-feild-wrapper-animations");
                                        });
                                    }
                                }
                            });
                        }
                        this.isError = false;
                        if (this.ele.nativeElement.classList.contains("bn-form-field-invalid"))
                            this._renderer.removeClass(this.ele.nativeElement, "bn-form-field-invalid");
                        if (this.hasActions) timer(100).subscribe((_) => (this.showActions = true));
                        this._onDetectDisabledInput();
                        break;
                    case "invalid":
                        if (!this.isError) {
                            timer(0).subscribe((_) => {
                                if (!!subscript) {
                                    const errorWrapper = subscript?.getElementsByClassName("bn-form-field-error-wrapper")[0];
                                    if (!!errorWrapper) {
                                        this._renderer.addClass(errorWrapper, "bn-form-feild-wrapper-animations");
                                        timer(100).subscribe((_) => {
                                            this._renderer.removeClass(errorWrapper, "bn-form-feild-wrapper-animations");
                                        });
                                    }
                                }
                            });
                        }
                        this.isError = true;
                        if (!this.ele.nativeElement.classList.contains("bn-form-field-invalid"))
                            this._renderer.addClass(this.ele.nativeElement, "bn-form-field-invalid");
                        this._onDetectDisabledInput();
                        break;
                    default:
                        if (this.ele.nativeElement.classList.contains("bn-focus")) this._renderer.removeClass(this.ele.nativeElement, "bn-focus");
                        this._onDetectDisabledInput();

                        this.$hoverEvent.add(
                            interval(100)
                                .pipe(
                                    filter((_) => !this._onHoverTemplate),
                                    take(1)
                                )
                                .subscribe((_) => {
                                    if (this.hasActions && !this.isOpenToggle) this.showActions = false;

                                    this.onblur.next();
                                })
                        );
                }
            });
        }

        this.actoionWrapperPositions = [
            new ConnectionPositionPair(
                {
                    originX: "end",
                    originY: "bottom",
                },
                {
                    overlayX: "end",
                    overlayY: "top",
                },
                0,
                this.isWrapping ? -19 : 0
            ),
        ];

        if (this.bnTextareaAutosize) {
            this.bnTextareaAutosize.onChangeHeight
                .pipe(
                    tap((_) => {
                        this.showActions = false;
                    }),
                    delay(300)
                )
                .subscribe((_) => {
                    this.showActions = true;
                });
        }

        if (this.bnSelect) this._renderer.addClass(this.ele.nativeElement, "bn-type-select");

        if (this.focusInitial) this.bnInput.focus();
    };

    private _onSetColorPalette = (): void => {
        switch (this.color) {
            case "primary":
                this._renderer.addClass(this.ele.nativeElement, "bn-primary");
                break;
            case "accent":
                this._renderer.addClass(this.ele.nativeElement, "bn-accent");
                break;
            default:
                this._renderer.addClass(this.ele.nativeElement, "bn-warn");
                break;
        }
    };

    private _onDetectDisabledInput = (): void => {
        if (!!this.bnSelect) {
            if (this.bnSelect.disabled) {
                this._renderer.addClass(this.ele.nativeElement, "bn-form-field-disabled");
            } else {
                this._renderer.removeClass(this.ele.nativeElement, "bn-form-field-disabled");
            }
        }

        if (!!this._inputElement) {
            if (this._inputElement.disabled) {
                this._renderer.addClass(this.ele.nativeElement, "bn-form-field-disabled");
            } else {
                this._renderer.removeClass(this.ele.nativeElement, "bn-form-field-disabled");
            }
        }
    };

    onMouseEnterTemplate = (): void => {
        this._onHoverTemplate = true;
    };

    onMouseLeaveTemplate = (): void => {
        this._onHoverTemplate = false;
    };

    openSelectObjectDialog = (): void => {
        const dialogRef = this._matDialog.open(SelectObjectComponent, {
            panelClass: "no-padding-container",
            data: { type: this.objectType ? this.objectType : null },
        });

        dialogRef.afterClosed().subscribe((result: { name: string; data: any }) => {
            if (result) {
                this._inputElement.value = result.name ? result.name : "";
                this._inputElement.focus();
                this.bnInput.setValue(this._inputElement.value);
            } else {
                this._inputElement.focus();
            }

            this.afterClosedDialog.emit(result);
        });
    };

    openParamsDialog = (): void => {
        const dialogRef = this._matDialog.open(ParamsDialogComponent, {
            panelClass: "no-padding-container",
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this._inputElement.value += result;
                this._inputElement.focus();
                this.bnInput.setValue(this._inputElement.value);
            } else {
                this._inputElement.focus();
            }
        });
    };

    onToggleEmoji = (trigger: CdkOverlayOrigin): void => {
        timer(0).subscribe((_) => {
            this.onMouseEnterTemplate();
        });

        this.triggerOrigin = trigger;
        this.isOpenToggle = !this.isOpenToggle;
    };

    onSelectEmoji = ($event: any): void => {
        this._inputElement.value += $event.emoji.native;
        this._inputElement.focus();
        this.bnInput.setValue(this._inputElement.value);
    };

    onCloseEmoji = (): void => {
        this.isOpenToggle = false;
        this._inputElement.focus();
        timer(100).subscribe((_) => {
            this._renderer.addClass(this.ele.nativeElement, "bn-focus");
            this.showActions = true;
        });
    };

    onResetInput = (blur?: boolean): void => {
        this._inputElement.value = "";
        if (!blur) this._inputElement.focus();
        this.bnInput.setValue(this._inputElement.value);
    };
}
