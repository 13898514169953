import { Component } from "@angular/core";
import { SnackBarTitleService } from "../../../services/snack-bar-alert/snack-bar-title.service";

@Component({
    selector: "app-normal-alert",
    templateUrl: "./normal-alert.component.html",
    styleUrls: ["./normal-alert.component.scss"],
})
export class NormalAlertComponent {
    private _icon: string;
    get icon() {
        return this._icon;
    }
    set icon(icon: string) {
        this._icon = icon;
    }

    private _color: string;
    get color() {
        return this._color;
    }
    set color(color: string) {
        this._color = color;
    }

    private _title: string;
    get title() {
        return this._title;
    }
    set title(title: string) {
        this._title = title;
    }

    private _description: string;
    get description() {
        return this._description;
    }
    set description(desc: string) {
        this._description = desc;
    }

    constructor(private _snackBar: SnackBarTitleService) {
        this.title = this._snackBar.title;
        this.description = this._snackBar.description;
        this.icon = this._snackBar.icon;
        this.color = this._snackBar.color;
    }
}
