import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

interface AlertDialogModel {
    state: "success" | "error";
    icon?: string;
    title: string;
    description: string;
    label?: string;
}

@Component({
    selector: "app-alert-dialog",
    templateUrl: "./alert-dialog.component.html",
    styleUrls: ["./alert-dialog.component.scss"],
})
export class AlertDialogComponent {
    get state() {
        return this.data ? this.data.state : "";
    }

    get icon() {
        return !!this.data?.icon ? this.data.icon : this.data?.state == "error" ? "close-circle" : "ok-circle";
    }

    get title() {
        return this.data ? this.data.title : "";
    }

    get description() {
        return this.data ? this.data.description : "";
    }

    get label() {
        return this.data?.label ?? $localize`:@@ok:OK`;
    }

    constructor(@Inject(MAT_DIALOG_DATA) private data: AlertDialogModel) {}
}
