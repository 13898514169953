import { Directive, ElementRef, Renderer2 } from "@angular/core";

@Directive({
    selector: "button[bn-square-button], a[bn-square-button]",
})
export class BnSquareButtonDirective {
    constructor(private ele: ElementRef, private _renderer: Renderer2) {
        this._renderer.addClass(this.ele.nativeElement, "bn-square-button");
    }
}
