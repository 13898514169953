import { Directive, ElementRef, Renderer2 } from "@angular/core";

@Directive({
    selector: "button[bn-small-button], a[bn-small-button]",
})
export class BnSmallButtonDirective {
    constructor(private ele: ElementRef, private _renderer: Renderer2) {
        this._renderer.addClass(this.ele.nativeElement, "bn-small-button");
    }
}
