import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map, retry, tap } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { genericRetry, HttpRequest } from "@core/http/http.service";
import { Login } from "@core/models/user/login.model";
import { AuthService } from "@core/services/authentication/auth.service";
import { UserProfileService } from "@core/services/authentication/user-profile.service";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

@Injectable()
export class GapiSession {
    account: Login;

    get currentUser(): string {
        return this._userProfile?.profile?.google_link_accounts[0]?.email ?? null;
    }

    get isSignedIn(): Observable<boolean> {
        return this._userProfile?.$profile.pipe(map((profile) => profile?.isGoogleSignIn));
    }

    constructor(private _http: HttpRequest, private _authService: AuthService, private _userProfile: UserProfileService) {}

    signInGoogleURL = (redirectURL?: string): Observable<void> => {
        return this._http
            .get<void>({
                url: `${API_GATEWAYS_URL}google/connect?username=${this._userProfile.getUsername()}&redirect_url=${redirectURL}`,
            })
            .pipe(catchError((error) => throwError(() => error)));
    };

    signOutGoogleURL = (email: string): Observable<void> => {
        return this._http
            .post<void>({
                url: `${API_GATEWAYS_URL}google/sign-out?email=${email}`,
            })
            .pipe(
                retry(genericRetry),
                tap(() => this._authService.verifyToken()),
                catchError((error) => throwError(() => error))
            );
    };
}
