import { Injectable } from "@angular/core";
import { FileRepository } from "./file.repository.service";

@Injectable()
export class AppRepository {
    constructor(private _fileRepository: FileRepository) {}

    get File(): FileRepository {
        return this._fileRepository;
    }
}
