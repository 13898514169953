import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "currency",
})
export class CurrencyPipe implements PipeTransform {
    transform(value: number | string, digit?: number): string {
        if (typeof value == "string") value = parseFloat(value);
        return new Intl.NumberFormat("en-US", { minimumFractionDigits: digit ?? 0 }).format(value);
    }
}
