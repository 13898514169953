import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ImportProgressModel } from "@core/http/s3/upload.service";

@Component({
    selector: "app-import-file-status",
    templateUrl: "./import-file-status.component.html",
    styleUrls: ["./import-file-status.component.scss"],
})
export class ImportFileStatusComponent {
    get successRecords() {
        return this._data?.success_excel ?? 0;
    }
    get successRecordsLabel() {
        return new Intl.NumberFormat().format(this.successRecords);
    }

    get hasErrorRecord() {
        return !!this.errorRecords;
    }
    get errorRecords() {
        return this._data?.errors_excel?.length ?? 0;
    }
    get errorRecordsLabel() {
        return new Intl.NumberFormat().format(this.errorRecords);
    }

    get errorMessages() {
        return this._data?.errors_excel ?? [];
    }

    constructor(@Inject(MAT_DIALOG_DATA) private _data: ImportProgressModel) {}
}
