<div class="d-flex flex-column flex-center p-relative" style="min-width: 400px">
    <h2 i18n="@@create-a-chatbot-clone-key-title" class="mb-3">Create A Chatbot Clone Key</h2>
    <button mat-mini-fab bn-small-button color="primary" class="esc-bttn" (click)="onClose()">
        <mat-icon svgIcon="close"></mat-icon>
    </button>

    <div class="d-flex mb-2">
        <bn-form-field backgroundColor="#F7F8FA" style="width: 350px">
            <div *ngIf="fetching" bnPrefix class="loader"></div>
            <input data-cy="input-cloning-key" bnInput [value]="key" disabled />
            <button
                bnSuffix
                mat-mini-fab
                bn-small-button
                color="primary"
                matTooltip="Copy"
                matTooltipPosition="above"
                [cdkCopyToClipboard]="key"
                (click)="openSnackClipboard()"
            >
                <mat-icon svgIcon="duplicate"></mat-icon>
            </button>
        </bn-form-field>
    </div>

    <p i18n="@@copy-this-key-to-clone" class="mb-3 text-center text-light-grey">Copy this key to clone your chatbot.</p>

    <button mat-raised-button color="primary" (click)="onClose()">
        <state-i18n key="BTN_CLOSE"></state-i18n>
    </button>
</div>
