import { Component, OnInit, OnDestroy } from "@angular/core";
import { ENTER } from "@angular/cdk/keycodes";
import { MatDialogRef } from "@angular/material/dialog";
import { MatTooltip } from "@angular/material/tooltip";
import { Subscription, Subject } from "rxjs";
import { tap, debounceTime } from "rxjs/operators";
import { ManageParamsService, ParamsModel } from "@core/http/object/manage-params.service";
import { BnFormFieldComponent } from "@shared/components/forms-element/bn-form-field/bn-form-field.component";
import { SnackBarAlertService } from "@shared/services/snack-bar-alert/snack-bar-alert.service";
import { I18nState, getI18nState } from "@shared/translation/i18n-state";

@Component({
    selector: "app-params-dialog",
    templateUrl: "./params-dialog.component.html",
    styleUrls: ["./params-dialog.component.scss"],
})
export class ParamsDialogComponent implements OnInit, OnDestroy {
    private _subscription: Subscription;

    private _fetching: boolean;
    get fetching() {
        return this._fetching;
    }

    get globalParams() {
        return this._globalParams;
    }
    private _globalParams: Array<ParamsModel>;

    get userParams() {
        return this._userParams;
    }
    private _userParams: Array<ParamsModel>;

    private $tooltip: Subject<MatTooltip>;

    constructor(private _dialogRef: MatDialogRef<ParamsDialogComponent>, private _paramsService: ManageParamsService, private _snackbar: SnackBarAlertService) {
        this._fetching = true;
        this._subscription = new Subscription();

        this._userParams = [];
        this._globalParams = [];
        this.$tooltip = new Subject<MatTooltip>();

        this.$tooltip
            .pipe(
                tap((tooltip) => tooltip.show()),
                debounceTime(300)
            )
            .subscribe((tooltip) => {
                tooltip.hide();
            });
    }

    ngOnInit(): void {
        this._initialParams();
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    private _initialParams = (): void => {
        this._subscription.add(
            this._paramsService.$getParams().subscribe(
                (response) => {
                    this._fetching = false;
                    this._userParams = response.filter((res) => !res?.is_build_in);
                    this._globalParams = response.filter((res) => res?.is_build_in);
                },
                (error) => {
                    console.error(error);
                    this._fetching = false;
                    this._snackbar.open("error", getI18nState(I18nState.GLOBAL_ERR_MSG));
                }
            )
        );
    };

    private _insertParam = (value: string): void => {
        this._subscription.add(
            this._paramsService.$insertParams(value).subscribe(
                (response) => {
                    this._userParams.push(response);
                    this._dialogRef.close(`<${value}>`);
                },
                (error) => {
                    console.error(error);
                    this._snackbar.open("error", getI18nState(I18nState.GLOBAL_ERR_MSG));
                }
            )
        );
    };

    useParam = (value: ParamsModel): string => {
        return `<<${value.name}>>`;
    };

    addParam = (value: ParamsModel): string => {
        return `<${value.name}>`;
    };

    onTypingAddParam = (tooltip: MatTooltip): void => {
        this.$tooltip.next(tooltip);
    };

    onKeyDownDetectEnter = ($event: KeyboardEvent, state: "use" | "add"): void => {
        if ($event.keyCode == ENTER) {
            const value = (<HTMLInputElement>$event.target).value;
            switch (state) {
                case "use":
                    this._dialogRef.close(`<<${value}>>`);
                    break;
                case "add":
                    this._insertParam(value);
                    break;
            }
        }
    };

    onSelectedItem = (param: ParamsModel, state: "use" | "add"): void => {
        this._dialogRef.close(`${state == "use" ? `<<` : "<"}${param.name}${state == "use" ? `>>` : ">"}`);
    };

    appendAddParam = (input: HTMLInputElement, form: BnFormFieldComponent): void => {
        if (!!input.value) {
            const value = input.value;
            form.onResetInput();
            this._insertParam(value);
        }
    };

    removeAddParam = (index: number): void => {
        const cacheList = this.userParams;
        const value = this.userParams[index];
        this._userParams = this.userParams.filter((_, i) => i !== index);

        this._subscription.add(
            this._paramsService.$deleteParams(value._id).subscribe(
                (_) => {
                    this._snackbar.open("success", $localize`:@@success-delete-parameter:This parameter has been deleted`);
                },
                (error) => {
                    console.error(error);
                    this._userParams = cacheList;
                    this._snackbar.open("error", getI18nState(I18nState.GLOBAL_ERR_MSG));
                }
            )
        );
    };
}
