import { Deserializable } from "../deserializable.model";

export interface StatChitchat {
    total_msg: TotalMessageModel;
    data: Array<Data>;
}
export class StatChitchat implements Deserializable {
    total_msg: TotalMessageModel;
    data: Array<Data>;

    deserialize(input: StatChitchat) {
        Object.assign(this, input);
        if (!!input?.total_msg) this.total_msg = new TotalMessageModel().deserialize(input?.total_msg);
        this.data = input?.data?.map((d) => new Data().deserialize(d)) ?? [];
        return this;
    }
}

interface TotalMessageModel {
    total: number;
    change_percentage: number;
}
class TotalMessageModel implements Deserializable {
    total: number;
    change_percentage: number;

    get percentage() {
        return this.change_percentage ? this.change_percentage.toFixed(2) : "0";
    }

    deserialize(input: TotalMessageModel) {
        Object.assign(this, input);
        return this;
    }
}

interface Data {
    word_name: string;
    total: number;
}
class Data implements Deserializable {
    word_name: string;
    total: number;

    deserialize(input: Data) {
        Object.assign(this, input);
        return this;
    }
}
