import { Injectable, Component, HostListener } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "@shared/components/dialog";

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(private _matDialog: MatDialog) {}

    async canDeactivate(component: ComponentCanDeactivate) {
        if (!component.canDeactivate) {
            const confirmation = await this._confirmation();
            if (confirmation == undefined) return false;

            if (confirmation) {
                return await component.asyncFunction;
            }
            return true;
        }

        return true;
    }

    private async _confirmation(): Promise<boolean> {
        return await this._matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    state: "inform",
                    icon: "enter-right",
                    title: $localize`:@@save-a-change:Save a change?`,
                    description: $localize`:@@save-a-change-desc:Do you want to save a change before you leave?`,
                    submitLabel: $localize`:@@yes:Yes`,
                    cancelLabel: $localize`:@@no:No`,
                },
            })
            .afterClosed()
            .toPromise();
    }
}

@Component({
    template: "",
})
export abstract class ComponentCanDeactivate {
    abstract get canDeactivate(): boolean;
    abstract get asyncFunction(): Promise<boolean>;

    @HostListener("window:beforeunload", ["$event"]) beforeUnloadHandle() {
        if (!this.canDeactivate) return window.confirm($localize`:@@unload-message:Your have unsaved changes! Are you sure you want to leave this page?`);
    }
}
