import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject, Observable, throwError } from "rxjs";
import { catchError, debounceTime, filter, map, switchMap } from "rxjs/operators";
import { LocalService } from "@core/services/cache/local.service";
import { UserProfileService } from "@core/services/authentication/user-profile.service";
import { ManageBotRequestService } from "@core/http/bot/manage-bot-request.service";
import { BotProfile } from "@core/models/bot/bot-profile.model";

@Injectable()
export class BotProfileService {
    private _botProfile: BotProfile;
    private _$botProfile: BehaviorSubject<BotProfile>;

    private $eventFetchBotProfile: Subject<{ _id: string; reset?: boolean }>;

    get profile() {
        return this._botProfile ?? null;
    }

    get $profile() {
        return this._$botProfile ?? null;
    }

    get getID() {
        return this._botProfile?._id ?? "";
    }

    get getLogo() {
        return this._botProfile?.bot_avatar ?? "";
    }

    get getName() {
        return this._botProfile?.bot_name ?? "";
    }

    get isOwner() {
        return (this._botProfile?.owner == this._usrProfile?.getUsername() || this._botProfile?.owner == this._usrProfile?._id) ?? false;
    }

    get permission() {
        return this._botProfile?.permission ?? null;
    }

    get canExportImport() {
        return (!this._botProfile?.current_plan?.isFreePlan && !this._botProfile?.current_plan?.isExpired) ?? false;
    }

    get canTrain() {
        return this._botProfile?.permission?.AccessList?.train ?? false;
    }

    get canView() {
        return this._botProfile?.permission?.AccessList?.view ?? false;
    }

    get canEntity() {
        return this._botProfile?.permission?.AccessList?.entity ?? false;
    }

    get canObject() {
        return this._botProfile?.permission?.AccessList?.object ?? false;
    }

    get canDialog() {
        return this._botProfile?.permission?.AccessList?.dialog ?? false;
    }

    get canLivechat() {
        return this._botProfile?.permission?.AccessList?.livechat ?? false;
    }

    get activeStatus() {
        return this._botProfile?.channel_active_status ?? null;
    }

    constructor(private local: LocalService, private _manageAPI: ManageBotRequestService, private _usrProfile: UserProfileService) {
        this._botProfile = new BotProfile();
        this._$botProfile = new BehaviorSubject(this._botProfile);
        this.$eventFetchBotProfile = new Subject();

        this.$debounceGetBotProfile();
    }

    private $debounceGetBotProfile = (): void => {
        this.$eventFetchBotProfile
            .pipe(
                debounceTime(100),
                filter(($event) => !!$event?._id),
                switchMap(($event) => this._manageAPI.$getBotDetail($event?._id, $event?.reset))
            )
            .subscribe((response) => {
                this.initBotProfile(new BotProfile().deserialize(response ?? {}));
            });
    };

    emitGetBotProfile = (): void => {
        const _b_pf = this.local.decryptGet("_b_pf");

        this.$eventFetchBotProfile.next({ _id: _b_pf?._id ?? null });
    };

    isSelectedBOT = (): boolean => {
        return this._botProfile?._id ? true : false;
    };

    initBotProfile = (profile: BotProfile): void => {
        this.local.encryptSet("_b_pf", {
            _id: profile?._id ?? "",
            owner: profile?.owner ?? "",
            permission: profile?.permission ?? null,
            current_plan: profile?.current_plan ?? null,
            access_channel: profile?.access_channel ?? null,
            channel_active_status: profile?.channel_active_status ?? null,
            add_ons: profile?.add_ons ?? null,
        });

        this._botProfile = new BotProfile().deserialize(profile);
        this._$botProfile.next(this._botProfile);
    };

    verifyBotProfile = (reset?: boolean): void => {
        if (this.local.decryptGet("_b_pf")) {
            const _b_pf = this.local.decryptGet("_b_pf");

            this._botProfile = new BotProfile().deserialize(_b_pf);
            this.$eventFetchBotProfile.next({ _id: _b_pf?._id ?? null, reset: reset ?? false });
        }
    };

    updateBotProfile = (profile: {
        name: string;
        bot_avatar: string;
        sex: string;
        age: number;
        personality: string;
        business_type: string;
    }): Observable<{ message: string }> => {
        const _profile = new BotProfile().deserialize(this._botProfile);
        _profile.bot_name = profile?.name ?? "";
        _profile.bot_avatar = profile?.bot_avatar ?? "";
        _profile.sex = profile?.sex ?? "";
        _profile.age = profile?.age ?? 0;
        _profile.personality = profile?.personality.split(",").map((p) => p.trim()) ?? [];
        _profile.business_type = profile?.business_type ?? "";

        return this._manageAPI.$updateBotDetail(_profile).pipe(
            map((response) => {
                this._botProfile = new BotProfile().deserialize(_profile ?? {});
                this._$botProfile.next(this._botProfile);
                return response;
            }),
            catchError((error) => {
                this._$botProfile.next(this._botProfile);
                return throwError(() => error);
            })
        );
    };

    resetBotProfile = (): void => {
        this._botProfile = undefined;
        this.local.delete("_b_pf");
    };
}
