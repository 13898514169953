import { Deserializable } from "../deserializable.model";

export class LineSubscribeList implements Deserializable {
    manual: ManualChannel;
    modular: ModularChannel;

    get isConnectedLine() {
        return this.manual.hasChannel ?? false;
    }

    get isConnectedModular() {
        return this.modular.hasChannel ?? false;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        if (input.manual) this.manual = new ManualChannel().deserialize(input.manual);
        if (input.modular) this.modular = new ModularChannel().deserialize(input.modular);
        return this;
    }
}

export class ManualChannel implements Deserializable {
    basic_id: string;
    first_token: string;
    second_token: string;
    profile_image_url: string;
    display_name: string;
    total_followers_updated_at: Date;

    get hasChannel() {
        return !!this.first_token && !!this.second_token
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    reset(): void {
        this.basic_id = "";
        this.first_token = "";
        this.second_token = "";
        this.profile_image_url = "";
        this.display_name = "";
        this.total_followers_updated_at = null;
    }
}

export class ModularChannel implements Deserializable {
    bot_id: string;
    basic_id: string;
    profile_image_url: string;
    display_name: string;
    active: boolean;

    get hasChannel() {
        return !!this.basic_id
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    reset(): void {
        this.basic_id = "";
        this.profile_image_url = "";
        this.display_name = "";
        this.active = false;
    }
}
