<div class="container d-flex flex-column justify-content-center p-relative">
    <button mat-mini-fab bn-small-button [mat-dialog-close]="undefined" color="accent" class="p-absolute" style="right: -16px; top: -16px">
        <mat-icon svgIcon="close"></mat-icon>
    </button>

    <div class="d-block text-center mb-1">
        <h2 class="mb-0 fw-7" [innerHTML]="title"></h2>
    </div>

    <div *ngIf="!noIcon" class="d-flex justify-content-center mb-2" [ngClass]="iconTheme">
        <mat-icon [svgIcon]="icon" [color]="primary"></mat-icon>
    </div>

    <div class="d-block text-center mb-3 mx-3" style="max-width: 450px">
        <p class="mb-0 mat-body-2 lh-2" [innerHTML]="description | safeHTML"></p>
    </div>

    <div *ngIf="hasCheckbox" class="d-block text-center mb-3 mx-3">
        <mat-checkbox [(ngModel)]="checked" [color]="primary">{{ checkboxLabel }}</mat-checkbox>
    </div>

    <div class="d-flex justify-content-center">
        <button data-cy="cancel-confirm-dialog-button" mat-stroked-button bn-large-button [color]="accent" (click)="onCancel()">{{ cancelLabel }}</button>
        <button data-cy="confirm-dialog-button" mat-raised-button bn-large-button [color]="primary" class="ml-2" (click)="onSubmit()" [disabled]="!canSubmit">{{ submitTitle }}</button>
    </div>
</div>
