<div mat-dialog-title>
    <div class="d-flex flex-center">
        <h3 class="text-center">
            <state-i18n key="BTN_TOPUP"></state-i18n>
        </h3>
    </div>
    <button mat-mini-fab bn-small-button mat-dialog-close bn-dialog-close color="primary">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div class="d-flex flex-column pt-2">
        <div class="d-block enter-day-container mb-2" style="width: 530px">
            <h4 i18n="@@the-number-of-days">The number of the days</h4>
            <div class="d-flex flex-wrap">
                <ng-container *ngFor="let day of daysList">
                    <ng-container [ngSwitch]="day == days">
                        <button *ngSwitchCase="true" mat-raised-button bn-small-button color="primary">{{ day }}</button>
                        <button *ngSwitchDefault mat-stroked-button bn-small-button color="primary" (click)="onChangeDay(day, holdingTax, deliveryTax)">
                            {{ day }}
                        </button>
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <h3 i18n="@@price">Price</h3>
        <div class="d-flex align-items-center mb-1">
            <span class="mat-h4 mb-0"><ng-container i18n="@@package">Package</ng-container> {{ packageName }}</span>
            &nbsp;
            <span class="mat-body-2 text-grey">{{ costPerDayByPackage ?? 0 | currency:2 }} <ng-container i18n="@@thb-day">THB/Day</ng-container></span>
            <div bnSpacer></div>

            <span class="mat-subheading-2 mb-0">{{ packageCost ?? 0 | currency:2 }} <ng-container i18n="@@thb">THB</ng-container></span>
        </div>
        <div *ngFor="let item of feature" class="d-flex align-items-center mb-1">
            <span class="mat-h4 mb-0">{{ featureName(item?.feature) | capitalize }}</span>
            &nbsp;
            <span i18n="@@package-price-add-ons-description" class="mat-body-2 text-grey">
                {{ addOnPercentage(item?.cost_per_days_fractor) }}% of package price ({{ addonCostPerday | currency:2 }} THB/Day)
            </span>
            <div bnSpacer></div>

            <span class="mat-subheading-2 mb-0">{{ addonCost | currency:2 }} <ng-container i18n="@@thb">THB</ng-container></span>
        </div>

        <div *ngIf="informationType && calculateCost > 1000" class="d-flex mb-1">
            <mat-checkbox i18n="@@holding-tax" [(ngModel)]="holdingTax" (ngModelChange)="onChangeDay(days, holdingTax, deliveryTax)">
                3% Withholding Tax
            </mat-checkbox>
            <div bnSpacer></div>

            <span *ngIf="holdingTax" class="mat-subheading-2 mb-0"
                >{{ WithholdingTaxCost ?? 0 | currency:2 }} <ng-container i18n="@@thb">THB</ng-container></span
            >
        </div>

        <div *ngIf="deliveryTax" class="d-flex">
            <span class="mat-h4 mb-0">Delivery tax invoice</span>
            <div bnSpacer></div>

            <span class="mat-subheading-2 mb-0">{{ deliveryTaxCost ?? 0 | currency:2 }} <ng-container i18n="@@thb">THB</ng-container></span>
        </div>

        <div class="d-flex align-items-center mt-1 mb-2">
            <span i18n="@@amount" class="mat-h3 mb-0">Amount</span>
            <div bnSpacer></div>

            <ng-container>
                <span class="mat-title mb-0 text-blue">{{ calculateCost ?? 0 | currency:2 }}&nbsp;</span>
                <span i18n="@@thb" class="mat-subheading-2 mb-0">THB</span>
            </ng-container>
        </div>

        <div class="d-flex align-items-center" style="min-height: 36px">
            <mat-checkbox class="mr-1" i18n="@@request-tax-invoice" [(ngModel)]="requestTax" [disabled]="calculateCost > 1000 && informationType">
                Request a tax invoice
            </mat-checkbox>

            <mat-checkbox
                *ngIf="calculateCost > 1000 && informationType"
                i18n="@@request-tax-invoice"
                [(ngModel)]="deliveryTax"
                (ngModelChange)="onChangeDay(days, holdingTax, deliveryTax)"
            >
                Delivery tax invoice
            </mat-checkbox>

            <div bnSpacer></div>

            <ng-container [ngSwitch]="hasInvoiceInformation">
                <ng-container *ngSwitchCase="true">
                    <button mat-button bn-small-button color="primary" *ngIf="requestTax" (click)="isShowInformation = !isShowInformation">
                        {{ showInformationText }}
                    </button>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <button
                        *ngIf="requestTax"
                        mat-stroked-button
                        bn-small-button
                        color="primary"
                        (click)="openInvoiceInformationDialog()"
                        [disabled]="!canChangeBillingData"
                        [matTooltip]="billingInformationTooltip"
                    >
                        <mat-icon [svgIcon]="hasInvoiceInformation ? 'pencil' : 'plus'"></mat-icon>
                        <ng-container [ngSwitch]="hasInvoiceInformation">
                            <state-i18n *ngSwitchCase="true" key="BTN_EDIT"></state-i18n>
                            <state-i18n *ngSwitchDefault key="BTN_ADD"></state-i18n>
                        </ng-container>
                        <ng-container i18n="@@billing-information-title">Billing Information</ng-container>
                    </button>
                </ng-container>
            </ng-container>
        </div>

        <ng-container [ngSwitch]="hasInvoiceInformation && isShowInformation && requestTax">
            <ng-container *ngSwitchCase="true">
                <div class="info-container d-block">
                    <button
                        *ngIf="requestTax"
                        mat-button
                        bn-small-button
                        color="primary"
                        (click)="openInvoiceInformationDialog()"
                        [matTooltip]="billingInformationTooltip"
                        style="position: absolute; right: 10px; top: 10px"
                    >
                        Edit
                    </button>
                    <div class="d-flex align-items-center">
                        <p>Name:</p>
                        <div bnSpacer></div>
                        <span class="w-100">{{invoiceInformation.first_name}} {{invoiceInformation.last_name}}</span>
                    </div>
                    <div class="d-flex align-items-center">
                        <p>Address:</p>
                        <div bnSpacer></div>
                        <span class="w-100"
                            >{{invoiceInformation.bill_address}} {{invoiceInformation.sub_district}} {{invoiceInformation.district}}
                            {{invoiceInformation.province}} {{invoiceInformation.postal}}</span
                        >
                    </div>
                    <div class="d-flex align-items-center">
                        <p>Phone:</p>
                        <div bnSpacer></div>
                        <span class="w-100"> {{invoiceInformation.phone}}</span>
                    </div>
                    <div class="d-flex align-items-center">
                        <p>Email:</p>
                        <div bnSpacer></div>
                        <span class="w-100"> {{invoiceInformation.email}}</span>
                    </div>
                    <div class="d-flex align-items-center">
                        <p>VAT/GST:</p>
                        <div bnSpacer></div>
                        <span class="w-100"> {{invoiceInformation.tax_number}}</span>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" mat-dialog-close class="mr-1">
        <state-i18n key="BTN_CANCEL"></state-i18n>
    </button>
    <button i18n="@@pay" mat-raised-button color="primary" [disabled]="!canSubmit" (click)="onSubmit()">Pay</button>
</mat-dialog-actions>
