import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subject } from "rxjs";
import { BotProfileService } from "@core/services/authentication/bot-profile.service";
import { BotProfile } from "@core/models/bot/bot-profile.model";
import { ConfirmationUpgradeComponent, NotificationUpgradeComponent } from "@shared/components/dialog";

@Injectable()
export class DetectExtensionService {
    private _chatbotProfile: BotProfile;

    constructor(private _matDialog: MatDialog, private _botProfile: BotProfileService) {}

    detectUpgradeExtension = (expiredCheck: boolean, feature?: string, profile?: BotProfile): Subject<boolean> => {
        const $afterClose = new Subject<boolean>();
        this._chatbotProfile = profile ? profile : this._botProfile.profile;

        switch (feature) {
            case "livechat":
            case "ads":
                switch (true) {
                    case !expiredCheck:
                        this._openConfirmationUpgrade(feature, this._chatbotProfile).subscribe((result) => {
                            if (result) {
                                $afterClose.next(true);
                                return;
                            }

                            $afterClose.next(false);
                            this._matDialog.closeAll();
                        });
                        break;
                    case expiredCheck:
                        let state = false;
                        switch (feature) {
                            case "livechat":
                                state = this._chatbotProfile?.addOnLivechat;
                                break;
                            case "ads":
                                state = this._chatbotProfile?.addOnAds;
                                break;
                        }

                        if (!state) {
                            this._openConfirmationUpgrade(feature, this._chatbotProfile).subscribe((result) => {
                                if (result) {
                                    $afterClose.next(true);
                                    return;
                                }

                                $afterClose.next(false);
                                this._matDialog.closeAll();
                            });
                            break;
                        }

                        if (this._chatbotProfile?.current_plan?.isExpired) this._openNotificationTopup();
                        break;
                }
                break;
            default:
                $afterClose.next(false);
        }

        return $afterClose;
    };

    private _openConfirmationUpgrade = (feature?: string, profile?: BotProfile): Observable<boolean> => {
        this._chatbotProfile = profile ? profile : this._botProfile.profile;
        return this._matDialog
            .open(ConfirmationUpgradeComponent, {
                data: {
                    data: this._chatbotProfile,
                    feature: feature ?? null,
                },
            })
            .afterClosed();
    };

    private _openNotificationTopup = (profile?: BotProfile): void => {
        this._chatbotProfile = profile ? profile : this._botProfile.profile;

        this._matDialog.open(NotificationUpgradeComponent, {
            data: this._chatbotProfile,
            disableClose: true,
        });
    };
}
