import { Deserializable } from "../deserializable.model";

export class PayloadObject implements Deserializable {
    _id: string;
    name: string;
    type: string;
    body: Object;

    get hasTemplate() {
        return this.body ? true : false;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    retreiveBody(json: Object) {
        this.body = json;
    }

    onUpdateObject(data: PayloadObject) {
        if (data._id) this._id = data._id;
        if (data.name) this.name = data.name;
        if (data.type) this.type = data.type;
        if (data.body) this.body = data.body;
    }

    convertObjectToServer() {
        return {
            _id: this._id,
            type: "custom_payload",
            object_name: this.name.includes("CP_")
                ? this.name
                : `CP_${this.name}`,
            list_object: [{
                payload_channel: this.type,
                payload: JSON.stringify(this.body)
            }],
        };
    }
}
