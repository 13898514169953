import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime, filter, switchMap } from "rxjs/operators";
import { LocalService } from "@core/services/cache/local.service";
import { CacheService } from "@core/http/http.service";
import { LoginRequestService } from "@core/http/user/login-request.service";
import { Profile } from "@core/models/user/profile.model";

@Injectable()
export class UserProfileService {
    $noData: Subject<void>;

    private $eventFetchUserProfile: Subject<string>;

    private _profile: Profile;
    get profile() {
        return this._profile ?? null;
    }
    private _$profile: BehaviorSubject<Profile>;
    get $profile() {
        return this._$profile;
    }

    constructor(private local: LocalService, private _loginService: LoginRequestService, private _cacheService: CacheService) {
        this.$noData = new Subject();
        this._profile = new Profile();
        this._$profile = new BehaviorSubject(this._profile);
        this.$eventFetchUserProfile = new Subject();

        this.$debounceGetUserProfile();
    }

    private $debounceGetUserProfile = (): void => {
        this.$eventFetchUserProfile
            .pipe(
                debounceTime(100),
                filter((token) => !!token),
                switchMap((_id) => this._loginService.$getUserInformation())
            )
            .subscribe((response) => {
                this.initUserProfile(new Profile().deserialize(response ?? {}));
            });
    };

    initUserProfile = (profile: Profile): void => {
        this.local.encryptSet("_usr_pf", {
            _id: profile?._id,
            username: profile?.username,
            email: profile?.email,
            first_name: profile?.first_name,
            last_name: profile?.last_name,
            google_link_accounts: profile?.google_link_accounts ?? [],
        });

        this._profile = new Profile().deserialize(profile);
        this._$profile.next(this._profile);
    };

    verifyUserProfile = (token: string): void => {
        if (this.local.decryptGet("_usr_pf")) {
            const _usr_pf = this.local.decryptGet("_usr_pf");

            this._profile = new Profile().deserialize(_usr_pf);
            this.$eventFetchUserProfile.next(token);
        } else this.$noData.next();
    };

    get _id(): string {
        return this._profile?._id ?? "";
    }

    getName = (): string => {
        return this._profile?.fullname ?? "";
    };

    getUsername = (): string => {
        switch (true) {
            case !!this._profile?.username?.length:
                return this._profile.username;
            case !!this._profile?.email?.length:
                return this._profile.email;
            default:
                return "";
        }
    };

    getEmail = (): string => {
        return this._profile?.email;
    };

    setLogout = (): void => {
        this._profile = undefined;
        this.local.delete("_usr_pf");
        this._cacheService.clearCacheResponse();
    };
}
