import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { retry, map, catchError } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { IntentGroupRequest, IntentGroupResponse, GetTrainRecordResponse } from "@core/http/nlp/train-bot.service";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

interface getPredefineIntModel {
    data: Array<dataPredefineIntModel>;
    total_filtered: number;
}

export interface dataPredefineIntModel {
    _id: string;
    name: string;
    selected: boolean;
    new_update: boolean;
}

export interface dataPredefinePhraseModel extends Array<{ _id: string; keyword: string }> {}

@Injectable()
export class PredefineIntentService {
    constructor(private _http: HttpRequest) {}

    $getPredefineIntent = (next: IntentGroupRequest): Observable<IntentGroupResponse> => {
        let url = new URL(`${API_GATEWAYS_URL}platform-go/intent/pre-defined/intent`);
        if (!!next?.search) url.searchParams.set("search", next.search);
        if (!!next?.sort_by) url.searchParams.set("sort_by", next.sort_by);
        if (!!next?.sort_dir) url.searchParams.set("sort_dir", next.sort_dir);

        return this._http
            .get<getPredefineIntModel>({
                url: url.toString(),
            })
            .pipe(
                retry(genericRetry),
                map((response) => {
                    return {
                        data: response.data.map((res) => {
                            return {
                                _id: res._id,
                                name: res.name,
                                count_trained: 0,
                                new_update: res.new_update,
                                selected: res.selected,
                            };
                        }),
                        next: {},
                    };
                }),
                catchError((error) => throwError(() => error))
            );
    };

    $getPredefinePhrase = (intent?: string): Observable<GetTrainRecordResponse> => {
        let url = new URL(`${API_GATEWAYS_URL}platform-go/intent/pre-defined/record`);
        if (!!intent) url.searchParams.set("intent", intent);

        return this._http
            .get<dataPredefinePhraseModel>({
                url: url.toString(),
            })
            .pipe(
                retry(genericRetry),
                map((response) => {
                    return {
                        data: response.map((res) => {
                            return {
                                _id: res._id,
                                keyword: res.keyword,
                                intent: intent,
                            };
                        }),
                        next: {},
                    };
                }),
                catchError((error) => throwError(() => error))
            );
    };

    $postPredifineIntents = (intents: Array<string>): Observable<void> => {
        let url = new URL(`${API_GATEWAYS_URL}platform-go/intent/from-pre-defined`);

        return this._http
            .post<void>({
                url: url.toString(),
                body: { ids: intents },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };
}
