import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable, throwError } from "rxjs";
import { catchError, map, retry } from "rxjs/operators";
import { environment as env } from "@env/environment";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { UserMessageStat, DialogPerformance, SankeyDiagram } from "@core/models/dashboard";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

@Injectable()
export class DashboardService {
    get isDashboardRealtime() {
        return env?.featureFlags?.featureDashboardRealtime;
    }

    constructor(private _http: HttpRequest) {}

    private _getDate(date: string, end?: boolean): number | string {
        if (end) return moment(date, "YYYY-MM-DD").endOf("day").unix();
        return moment(date, "YYYY-MM-DD").unix();
    }

    private _getDateByHour(date: string): number | string {
        return moment(date, "YYYY-MM-DD HH:mm:ss").unix();
    }

    $getNewDashboard = (startDate: string, endDate: string): Observable<UserMessageStat> => {
        const start_time = this._getDate(startDate);
        const end_time = this._getDate(endDate, true);

        return this._http
            .get<UserMessageStat>({
                url: `${API_GATEWAYS_URL}platform-go/dashboard?start=${start_time}&end=${end_time}`,
            })
            .pipe(
                retry(genericRetry),
                map((response) => new UserMessageStat().deserialize(response)),
                catchError((error) => throwError(() => error))
            );
    };

    $getRealTimeDashbaord = (start: string, end: string, group_by: string = "day"): Observable<UserMessageStat> => {
        let start_time = this._getDate(start);
        let end_time = this._getDate(end, true);
        if (this.isDashboardRealtime) {
            start_time = this._getDateByHour(start);
            end_time = this._getDateByHour(end);
        }

        return this._http
            .get<UserMessageStat>({
                url: `${API_GATEWAYS_URL}platform-go/dashboard/realtime?start=${start_time}&end=${end_time}&group_by=${group_by}`,
            })
            .pipe(
                retry(genericRetry),
                map((response) => new UserMessageStat().deserialize(response)),
                catchError((error) => throwError(() => error))
            );
    };

    $getDialogPerformance = (start: string, end: string, _id: string): Observable<SankeyDiagram> => {
        const start_time = this._getDate(start);
        const end_time = this._getDate(end, true);

        return this._http
            .get<DialogPerformance>({
                url: `${API_GATEWAYS_URL}platform-go/stat/dialogue?start=${start_time}&end=${end_time}&dialogue_id=${_id}`,
            })
            .pipe(
                retry(genericRetry),
                map((response) => new SankeyDiagram().deserialize(response)),
                catchError((error) => throwError(() => error))
            );
    };
}
