import { Injectable } from "@angular/core";
import { environment as env } from "@env/environment";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { HttpRequest, genericRetry } from "@core/http/http.service";
import { AuthService } from "@core/services/authentication/auth.service";

const API_GATEWAYS_URL: string = env.apiGatewaysURL;

interface ManualLine {
    bot_id: string;
    first_token: string;
    second_token: string;
}

@Injectable()
export class LineService {
    constructor(private _http: HttpRequest, private _auth: AuthService) {}

    getSubscribePage = (bot_id: string): string => {
        return `${API_GATEWAYS_URL}line/v2-connect-line-modular?access_token=${this._auth.getAccessToken}&bot_id=${bot_id}&callback_uri=${window.location.pathname}`;
    };

    getLineWebhookURL = (bot_id: string): string => {
        return `${API_GATEWAYS_URL}webhook/${bot_id}/line`;
    };

    $unsubscribePage = (page_id: string): Observable<{ message: string }> => {
        return this._http
            .post<any>({
                url: `${API_GATEWAYS_URL}line/channel-detach`,
                body: {
                    line_bot_id: page_id,
                },
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };

    $updateManualLinePage = (body: ManualLine): Observable<{ message: string }> => {
        return this._http
            .put<any>({
                url: `${API_GATEWAYS_URL}platform-go/bot/connect-line`,
                body: body,
            })
            .pipe(
                retry(genericRetry),
                catchError((error) => throwError(() => error))
            );
    };
}
