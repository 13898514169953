<div class="side-bar-container">
    <div *ngFor="let item of stateColumn" class="menu-item-container" [ngClass]="{'active': !state || state == item.state}" (click)="onSelectState(item.state)">
        <mat-icon [svgIcon]="item.icon"></mat-icon>
        <p class="mb-0"><small>{{ item.label }}</small></p>
    </div>
</div>

<div class="content-container">
    <div class="d-flex">
        <h4 class="mat-body-2">{{ title }}</h4>

        <div bnSpacer></div>

        <button mat-mini-fab bn-small-button color="primary" (click)="onClose()">
            <mat-icon svgIcon="close"></mat-icon>
        </button>
    </div>
    <bn-form-field bnFormSmall [borderRadius]="100" [backgroundColor]="'#fff'">
        <input bnInput [placeholder]="searchPlaceholder" (keyup)="applyFilter($event)" />
        <mat-icon bnSuffix svgIcon="search"></mat-icon>
    </bn-form-field>

    <div #scrollContainer class="table-container">
        <table mat-table bn-table [dataSource]="dataSource">
            <!-- Selected Column -->
            <ng-container matColumnDef="selected">
                <th mat-header-cell *matHeaderCellDef width="70px">&nbsp;</th>
                <td mat-cell *matCellDef="let element index as i">
                    <ng-container [ngSwitch]="isMultiple">
                        <ng-container *ngSwitchCase="true">
                            <mat-checkbox [checked]="dataForCheck(element.name)" (change)="updateSelectedItem(element._id, element.name)"></mat-checkbox>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <mat-radio-button data-cy="radio-select-item-button" (change)="updateSelectedItem(element._id, element.name)"></mat-radio-button>
                        </ng-container>
                    </ng-container>
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th i18n="@@name" mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="type">
                <th i18n="@@type" mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element">{{ element.channel }}</td>
            </ng-container>

            <!-- Answer Column -->
            <ng-container matColumnDef="answer">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="answer-container">
                        <ng-container i18n="@@answer">Answer</ng-container>
                        <button
                            #expand
                            mat-mini-fab
                            bn-small-button
                            color="primary"
                            (mouseover)="expand._elementRef.nativeElement.classList.add('active')"
                            (mouseout)="expand._elementRef.nativeElement.classList.remove('active')"
                            (click)="expandContent = !expandContent"
                        >
                            <mat-icon *ngIf="!expandContent" svgIcon="out-minus"></mat-icon>
                            <mat-icon *ngIf="expandContent" svgIcon="in-minus"></mat-icon>
                        </button>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.answer }}</td>
            </ng-container>

            <!-- URL Column -->
            <ng-container matColumnDef="url">
                <th i18n="@@image" mat-header-cell *matHeaderCellDef>Image</th>
                <td mat-cell *matCellDef="let element">
                    <img style="max-height: 58px; margin: 11px 0" [src]="element.url" />
                </td>
            </ng-container>

            <!-- API URL Column -->
            <ng-container matColumnDef="api_url">
                <th i18n="@@url" mat-header-cell *matHeaderCellDef>URL</th>
                <td mat-cell *matCellDef="let element">
                    <span class="url-wrapping">{{ element.url }}</span>
                </td>
            </ng-container>

            <!-- Method Column -->
            <ng-container matColumnDef="method">
                <th i18n="@@method" mat-header-cell *matHeaderCellDef>Method</th>
                <td mat-cell *matCellDef="let element">{{ element.method }}</td>
            </ng-container>

            <!-- Buttons Column -->
            <ng-container matColumnDef="buttons">
                <th i18n="@@buttons" mat-header-cell *matHeaderCellDef>Buttons</th>
                <td mat-cell *matCellDef="let element">
                    <p class="my-1">{{ element.title ? element.title : element.message }}</p>
                    <div class="d-flex mb-1">
                        <div *ngFor="let button of element.buttons; let i = index" class="buttons-element mat-body-1" [ngClass]="{'ml-1': i > 0}">
                            <mat-icon *ngIf="button.type == 'message'" svgIcon="bubble" class="mr-1"></mat-icon>
                            <mat-icon *ngIf="button.type == 'url'" svgIcon="link-2" class="mr-1"></mat-icon>
                            <mat-icon *ngIf="button.type == 'phone'" svgIcon="smartphone" class="mr-1"></mat-icon>
                            {{ button.label }}
                        </div>
                    </div>
                </td>
            </ng-container>

            <!-- Cards Column -->
            <ng-container matColumnDef="cards">
                <th i18n="@@cards" mat-header-cell *matHeaderCellDef>Cards</th>
                <td mat-cell *matCellDef="let element">
                    <div class="cards-element-container">
                        <div *ngFor="let item of element.cards" class="cards-element">
                            <img [src]="item.url" />
                        </div>
                    </div>
                </td>
            </ng-container>

            <!-- Rich URL Column -->
            <ng-container matColumnDef="rich_url">
                <th i18n="@@image" mat-header-cell *matHeaderCellDef>Image</th>
                <td mat-cell *matCellDef="let element">
                    <div class="rm-element-container">
                        <img [src]="element.url" />
                    </div>
                </td>
            </ng-container>

            <!-- Confidence Column -->
            <ng-container matColumnDef="confidence">
                <th i18n="@@confidence" mat-header-cell *matHeaderCellDef>Confidence</th>
                <td mat-cell *matCellDef="let element">{{ element.confidence }}</td>
            </ng-container>

            <!-- Audio Column -->
            <ng-container matColumnDef="audio">
                <th i18n="@@audio" mat-header-cell *matHeaderCellDef>Audio</th>
                <td mat-cell *matCellDef="let element">
                    <div class="d-flex align-items-center">
                        <div class="audio-label-temp row-label">{{ element.url }}</div>
                        <div class="audio-label-temp player ml-1" (click)="onTogglePlayAudio(element)">
                            <mat-icon [svgIcon]="element.played ? 'pause' : 'play'"></mat-icon>
                            <ng-container [ngSwitch]="element.played">
                                <ng-container i18n="@@pause" *ngSwitchCase="true">Pause</ng-container>
                                <ng-container i18n="@@listen" *ngSwitchDefault>Listen</ng-container>
                            </ng-container>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr *matNoDataRow>
                <td colspan="5">
                    <div class="loading-container">
                        <div *ngIf="fetching" class="loader"></div>
                        <ng-container [ngSwitch]="fetching">
                            <state-i18n *ngSwitchCase="true" key="LABEL_LOADING"></state-i18n>
                            <state-i18n *ngSwitchDefault key="LABEL_NO_DATA"></state-i18n>
                        </ng-container>
                    </div>
                </td>
            </tr>
        </table>
    </div>

    <mat-dialog-actions align="end">
        <button data-cy="select-object-button" i18n="@@select" mat-raised-button color="primary" (click)="onSubmitSelectObject()">Select</button>
    </mat-dialog-actions>
</div>
